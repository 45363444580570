import styles from "./index.module.css";
import Button from "components/SixtyButton";
import SixtyLogo from "assets/images/New-topbar.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "queries/account";
import clsx from "clsx";
import { useCheckVendorOnboardingStatus } from "queries/vendor";
import { useEffect } from "react";

export default function ProfileSetupLayout({ children, className }) {
  const history = useNavigate();
  const { data: onBoardingStatus, isSuccess } = useCheckVendorOnboardingStatus(
    {}
  );

  useEffect(() => {
    if (
      isSuccess &&
      onBoardingStatus &&
      onBoardingStatus?.completedVendorSetup
    ) {
      if (
        onBoardingStatus?.subscriptionPaymentStatus === "FreeTrial" ||
        onBoardingStatus?.subscriptionPaymentStatus === "Active"
      )
         history("/dashboard");
      // else if (
      //   onBoardingStatus?.subscriptionPaymentStatus === "PendingForPayment" ||
      //   onBoardingStatus?.requiredPayment 
      // )
      //   history.push("/pricing-plan");  
    }
  }, [history, isSuccess, onBoardingStatus]);

  const { mutate, isLoading } = useLogout();
  return (
    <div className={clsx(styles.page, className)}>
      <div className={styles.navbar}>
        <Link to="/">
          <img src={SixtyLogo} alt="sixty sec logo" className="logo" />
        </Link>

        <Button
          variant="secondary"
          icon={<LogoutIcon />}
          onClick={mutate}
          disabled={isLoading}
        >
          Logout
        </Button>
      </div>
      {children}
    </div>
  );
}
