// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Settings_settingsCompanyPage__gfuag {
  width: calc(100vw - 250px);
  padding: 24px 24px 40px 0;
}

.Settings_row__3AcbV {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.Settings_panelWrap__4-\\+tr {
  margin: 16px 16px 16px 0px;
  display: block;
  min-height: unset;
  position: relative;
}

.Settings_lastPanel__xhmwY {
  margin-bottom: 0px;
}

.Settings_shippingPanelHeader__t7Doz {
  min-height: 64px !important;
}

.Settings_paymentRightContainer__SRRMd {
  display: flex;
}

.Settings_orderStatusPanel__sLRlI,
.Settings_topSellersPanel__\\+bHN- {
  min-width: 280px;
}

.Settings_orderHeader__-W7vy {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 8px;
  justify-content: space-between;
  align-items: center;
}

.Settings_titleBox__vwC7R {
  display: flex;
  align-items: center;
}

.Settings_titleContent__HvPTo {
  font-family: var(--medium500);
  font-size: 20px;
  color: var(--black);
  padding-left: 16px;
}

.Settings_loader__MCPU0 {
  min-height: 150px;
}

.Settings_footer__oP6UB {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px;
  padding-left: 250px;
  width: 100vw;
  background: white;
  border-top: 1px solid var(--black10);
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Settings/index.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;EAC1B,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,oCAAoC;EACpC,YAAY;AACd","sourcesContent":[".settingsCompanyPage {\n  width: calc(100vw - 250px);\n  padding: 24px 24px 40px 0;\n}\n\n.row {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n}\n\n.panelWrap {\n  margin: 16px 16px 16px 0px;\n  display: block;\n  min-height: unset;\n  position: relative;\n}\n\n.lastPanel {\n  margin-bottom: 0px;\n}\n\n.shippingPanelHeader {\n  min-height: 64px !important;\n}\n\n.paymentRightContainer {\n  display: flex;\n}\n\n.orderStatusPanel,\n.topSellersPanel {\n  min-width: 280px;\n}\n\n.orderHeader {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0px 8px;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.titleBox {\n  display: flex;\n  align-items: center;\n}\n\n.titleContent {\n  font-family: var(--medium500);\n  font-size: 20px;\n  color: var(--black);\n  padding-left: 16px;\n}\n\n.loader {\n  min-height: 150px;\n}\n\n.footer {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  padding: 16px;\n  padding-left: 250px;\n  width: 100vw;\n  background: white;\n  border-top: 1px solid var(--black10);\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsCompanyPage": `Settings_settingsCompanyPage__gfuag`,
	"row": `Settings_row__3AcbV`,
	"panelWrap": `Settings_panelWrap__4-+tr`,
	"lastPanel": `Settings_lastPanel__xhmwY`,
	"shippingPanelHeader": `Settings_shippingPanelHeader__t7Doz`,
	"paymentRightContainer": `Settings_paymentRightContainer__SRRMd`,
	"orderStatusPanel": `Settings_orderStatusPanel__sLRlI`,
	"topSellersPanel": `Settings_topSellersPanel__+bHN-`,
	"orderHeader": `Settings_orderHeader__-W7vy`,
	"titleBox": `Settings_titleBox__vwC7R`,
	"titleContent": `Settings_titleContent__HvPTo`,
	"loader": `Settings_loader__MCPU0`,
	"footer": `Settings_footer__oP6UB`
};
export default ___CSS_LOADER_EXPORT___;
