import GeneralReport from "../GeneralReport";
import styles from "./index.module.css";
// import LineChart from "components/Charts/LineChart";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "utils/common";

function getChartData() {
  let currentMonthLabels = [];
  let previousMonthLabels = [];
  salesData.forEach((data) => {
    currentMonthLabels.push(Object.keys(data)[0]);
    previousMonthLabels.push(Object.keys(data)[1]);
  });
  let data = [];
  for (var i = 0; i < salesData.length; i++) {
    const salesObject = salesData[i];
    const currentMonthValue = Object.values(salesObject)[0];
    const previousMonthValue = Object.values(salesObject)[1];
    data.push({
      label: currentMonthLabels[i],
      value: currentMonthValue / 250,
      previousMonthLabel: previousMonthLabels[i],
      previousMonthValue: previousMonthValue / 250,
      getOrginalValue: (value) => {
        return "RM" + value * 250 * 1000;
      },
    });
  }
  return data;
}

const salesData = [
  {
    "Dec 5": 240,
    "Nov 5": 140,
  },
  {
    "Dec 10": 230,
    "Nov 5": 100,
  },
  {
    "Dec 15": 250,
    "Nov 15": 175,
  },
  {
    "Dec 20": 475,
    "Nov 20": 250,
  },
  {
    "Dec 25": 750,
    "Nov 25": 500,
  },
  {
    "Dec 30": 900,
    "Nov 30": 750,
  },
];

const TotalSalesReport = ({ data }) => {
  // let data = [];

  // data.push({
  //     data: getChartData()
  // });

  // const getYaxislabel = (label) => {
  //     switch (label) {
  //         case 0:
  //             return '0';
  //         case 1:
  //             return '250K';
  //         case 2:
  //             return '500K';
  //         case 3:
  //             return '750K';
  //         case 4:
  //             return '1M';
  //         default:
  //             return '';
  //     }
  // }

  return (
    <div>
      <div className={styles.totalSalesReport}>
        <GeneralReport
          title={data?.title}
          currentMonthCount={formatCurrency(data?.total)}
          previousMonthCount={formatCurrency(data?.previousMonthTotal)}
          currentMonthNewRecord={data?.currentMonthPerformance}
        >
          {/* <LineChart
                        id="totalSalesReport"
                        data={data[0].data}
                        color="#3E517A"
                        getYaxislabel={(label) => getYaxislabel(label)}
                    /> */}
        </GeneralReport>
      </div>
    </div>
  );
};

export default TotalSalesReport;
