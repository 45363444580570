import React, { useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "components/SixtyButton";
import styles from "./index.module.css";
import Modal from "components/Modal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BaseIcon } from "assets/images/Base.svg";
import AddEditCardDetailsForm from "../AddEditCardDetailsForm";
import { isNil } from 'lodash';
import Loader from "components/Loader";
import { format } from "date-fns";
import {
    useGetMemberBilling,
    useGetCustomerDefaultPaymentMethod,
    useActivateCancelledSubscription,
    useChangeSubscription,
    useGetSubscriptionDetail
  } from "queries/subscription";
import Text from "components/SixtyText";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";

const cardDetails = {
    cardHolderName: 'Daru Sim',
    cardName: "MasterCard",
    cardNumber: "1234567812345678",
    expiryDate: "06/06/2022",
    cvv: "443"
};

let defaultValues = {
    promotionCode: "",
};

const BillingCardDetails = ({pricingPlan, close}) => {
    const [isAddCardModalOpen, setAddCardModalOpen] = useState(false);
    const history = useNavigate();
    const cardModalRef = useRef();

    const { data: paymentData, isLoading: isPaymentLoad } = useGetCustomerDefaultPaymentMethod();
    const { data: subscriptionDetail, isLoading: isSubscriptionLoad } = useGetSubscriptionDetail(pricingPlan?.id);
    const { data, refetch, isLoading: isBillingLoad } = useGetMemberBilling();

    const Title = () => {
        return (
        <div className={styles.modalTitleBar}>
                { data && data.subscriptionPaymentId && data.subscriptionCancelled === true ?
                    <div className={styles.modalTitle}>Activate Subscription</div> :
                    <div className={styles.modalTitle}>Change Subscription</div>
                }
        </div>
        )
    };

    const formMethods = useForm({
        defaultValues,
    });

    const { control, errors } = formMethods;

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const { 
        mutateAsync: activatePlan,  
        isLoading: activateLoading
      } = useActivateCancelledSubscription();

      const onActivate = async () => {
        try {

            const model = {
                referenceSubscriptionId: subscriptionDetail?.id,
                planId: subscriptionDetail?.referenceSubscription,
            }       
            
             var result = await activatePlan(model);
             await timeout(500);

             if(result){
                refetch();
                close();
                setToast("SUBSCRIPTION ACTIVATED");             
             }
            
        } catch (e) {
          reportError(
            "error in : BillingCardDetails: onActivate, error: ",
            e
          );
        }
    }

    const { 
        mutateAsync: changePlan,  
        isLoading: changeLoading
      } = useChangeSubscription();

      const onSubmit = async (data) => {

        try {

            const model = {
                referenceSubscriptionId: subscriptionDetail?.id,
                planId: subscriptionDetail?.referenceSubscription,
                promotionCode: data?.promotionCode ? data.promotionCode : null
            }     
                     
             var result = await changePlan(model);
             await timeout(500);

             if(result){
                refetch();
                close();
                setToast("SUBSCRIPTION UPDATED");             
             }
            
        } catch (e) {
          reportError(
            "error in : BillingCardDetails: onActivate, error: ",
            e
          );
        }
    }

    const onAddCard = () => {
        setAddCardModalOpen(true);
    }

    const onUpdateCard = () => {
        setAddCardModalOpen(true);
    }

    const handleSubmit = () => {
        setAddCardModalOpen(false);
    };

    const handleCancel = () => {
        setAddCardModalOpen(false);
    };

    return (
        <div className={styles.container}>
         {(isPaymentLoad || isSubscriptionLoad || isBillingLoad) && (
           <Loader/>
         )}
         {!isPaymentLoad && !isSubscriptionLoad && !isBillingLoad && (
            <>
             <FormProvider {...formMethods}>
             <form
                onSubmit={formMethods.handleSubmit(onSubmit)}
                className={styles.form}
             >
            <div className={styles.row}>
                <div className={styles.leftContainer}>
                    <div className={styles.bankAccountDetails}>
                        <div>
                        <div>
                            <div className={styles.rowHeader}>
                                 Selected Plan
                            </div>
                            <div className={styles.rowValue}>
                            </div>
                            <div className={styles.rowValueDetails}>
                              <span className={styles.rowValue}>
                                 {pricingPlan?.name}
                              </span>
                             <span className={styles.elipseIcon}><BaseIcon /></span>
                              <span>
                                 {'RM ' + pricingPlan?.priceWithTax}
                              </span>
                             </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightContainer}>

                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.leftContainer}>
                    <div className={styles.bankAccountDetails}>
                        <div>
                            {
                                cardDetails ? (
                                    <div>
                                        <div className={styles.rowHeader}>
                                            Active card
                                        </div>
                                        <div className={styles.rowValue}>
                                            {paymentData?.brand}
                                        </div>
                                        <div className={styles.rowValueDetails}>
                                            <span>
                                                {paymentData?.name}
                                            </span>
                                            <span className={styles.elipseIcon}><BaseIcon /></span>
                                            <span>
                                                {paymentData?.expired}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                        <div>
                                            <div className={styles.rowHeader}>
                                                Active card
                                            </div>
                                            <div className={styles.rowValue}>
                                                No active card found
                                            </div>
                                            <div className={styles.rowValueDetails}>
                                                Add a credit card to continue your subscription
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    {data && data.subscriptionPaymentId && data.subscriptionCancelled === true ? (
                        <Button className={styles.inviteSellerBtn} variant="secondary" label="Activate" disabled={activateLoading} onClick={onActivate} />
                    ) : (
                            <>
                             <div className={styles.spacing}>
                                <Input
                                    name="promotionCode"
                                    label="Promotion Code"
                                    error={errors?.promotionCode}
                                    defaultValue=""
                                />
                             </div>
                             <Button className={styles.inviteSellerBtn} type="submit" label="Change Plan" disabled={changeLoading} />
                            </>
                        )}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.desc}>
                    <Text color="secondary" type="p2">
                        You hereby authorise 60sec to charge you recurringly until you cancel your subscription.
                    </Text>
                </div>
           </div>
          </form>
          </FormProvider>
          </>
         )}

            {isAddCardModalOpen && (
                <Modal
                    isOpen={isAddCardModalOpen}
                    onAttemptClose={() => {
                        history.push("/dashboard/settings/billing");
                        setAddCardModalOpen(false);
                    }}
                    modalRef={cardModalRef}
                    Title={Title}
                >
                    <AddEditCardDetailsForm
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                        cardDetails={cardDetails}
                        isEdit={!isNil(cardDetails)}
                    />
                </Modal>
            )}
        </div>
    )
}

export default BillingCardDetails;