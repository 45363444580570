export const RegexExpressions = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  username: /^(?=[a-zA-Z0-9._]{2,18}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
  name: /^[\\p{L} .'-]+$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
  phone: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
};

export const PaymentUrl = (id) =>
  `https://payment.60sec.asia/Transaction/Stripe?transactionId=${id}`;

export const Endpoints = {
  Dashboard: {
    JoinAffiliateProgram: "Dashboard/JoinAffiliateProgram",
    GetSupplierCompanyStatus: "Dashboard/GetSupplierCompanyStatus",
    GetStepsCompletion: "Dashboard/GetStepsCompletion",
    GetSummaryReport: "Dashboard/GetSummaryReport",
    GetSideMenuRecordCount: "Dashboard/GetSideMenuRecordCount",
    GlobalSearch: "Dashboard/GlobalSearch",
    GetNewOrders: "Dashboard/GetNewOrders",
    GetAdminSummaryReport: "Dashboard/GetAdminSummaryReport",
    GetAdminReporting: "Dashboard/GetAdminReporting",
  },
  Account: {
    RegisterUserEmail: "Account/RegisterUserEmail",
    ResendEmailVerificationCode: "Account/ResendEmailVerificationCode",
    EmailOtpVerification: "Account/EmailOtpVerification",
    Login: "Account/Login",
    SetupUserAuthentication: "Account/SetupUserAuthentication",
    Logout: "Account/Logout",
    ForgotPassword: "Account/ForgotPassword",
    RefreshToken: "Account/RefreshToken",
    UpdateUserDevice: "Account/UpdateUserDevice",
  },
  Writer: {
    GenerateArticle: "Writer/GenerateArticle"
  },
  SocialMedia: {
    GenerateHashtag: "SocialMedia/GenerateHashtag",
    GenerateCaption: "SocialMedia/GenerateCaption",
    GenerateShortVideoScript: "SocialMedia/GenerateShortVideoScript"
  },
  Wallet: {
    GetVendorCreditBalance: "Vendor/GetVendorCreditBalance",
    GetVendorCreditHistories: "Vendor/GetVendorCreditHistories",
    TopupVendorCredits: "Vendor/TopupVendorCredits",
    DownloadCreditTopupReceipt: "Vendor/DownloadCreditTopupReceipt",
  },
  Balance: {
    GetCompanyCreditBalance: "Balance/GetCompanyCreditBalance",
    GetCompanyBalanceHistories: "Balance/GetCompanyBalanceHistories",
    ExportCompanyBalances: "Balance/ExportCompanyBalances",
    Withdraw: "Balance/Withdraw",
  },
  BankAccount: {
    GetCompanyBankAccount: "BankAccount/GetCompanyBankAccount",
    SetupCompanyBankAccount: "BankAccount/SetupCompanyBankAccount",
  },
  Customer: {
    GetCustomers: "Customer/GetCustomers",
  },
  Discount: {
    GetDiscounts: "Discount/GetDiscounts",
    GetDiscountDetail: "Discount/GetDiscountDetail",
    AddDiscount: "Discount/AddDiscount",
    UpdateDiscount: "Discount/UpdateDiscount",
    DeleteDiscount: "Discount/DeleteDiscount",
    ActivateDiscount: "Discount/ActivateDiscount",
    DeactivateDiscount: "Discount/DeactivateDiscount",
    GenerateDiscountCode: "Discount/GenerateDiscountCode",
  },
  Invitation: {
    GetPublicCompanyInvitation: "Invitation/GetPublicCompanyInvitation",
    UpdatePublicCompanyInvitation: "Invitation/UpdatePublicCompanyInvitation",
    GeneratePublicInvitation: "Invitation/GeneratePublicInvitation",
    InviteSellerByEmail: "Invitation/InviteSellerByEmail",
    ResendSellerInvitationByEmail: "Invitation/ResendSellerInvitationByEmail",
  },
  Media: {
    Uploads: "Media/Uploads",
    Upload: "Media/Upload",
    DownloadMedia: "Media/DownloadMedia"
  },
  Order: {
    GetBuyerRefundRequests: "Order/GetBuyerRefundRequests",
    ApproveRefund: "Order/ApproveRefund",
    RejectRefund: "Order/RejectRefund",
    DownloadOrderInvoice: "Order/DownloadOrderInvoice",
    GetOrderCount: "Order/GetOrderCount",
    GetOrders: "Order/GetOrders",
    GetOrderDetail: "Order/GetOrderDetail",
    GetOrderStatusByProduct: "Order/GetOrderStatusByProduct",
    GetRefundReasonDropdown: "Order/GetRefundReasonDropdown",
    AchieveOrder: "Order/AchieveOrder",
    MarkAsShipped: "Order/MarkAsShipped",
    MarkOrdersAsShipped: "Order/MarkOrdersAsShipped",
    UpdateShiping: "Order/UpdateShiping",
    OrderRefund: "Order/OrderRefund",
    ExportOrders: "Order/ExportOrders",
    UpdateOrderShipping: "Order/UpdateOrderShipping",
  },
  Product: {
    GetBrandProductRequests: "Product/GetBrandProductRequests",
    ApproveProductRequest: "Product/ApproveProductRequest",
    RejectProductRequest: "Product/RejectProductRequest",
    SetupProductSessionShipment: "Product/SetupProductSessionShipment",
    GetProductSessionDetail: "Product/GetProductSessionDetail",
    GetCategories: "/Product/GetCategories",
    GetCategoryDropdown: "Product/GetCategoryDropdown",
    GetCategoryDetail: "Product/GetCategoryDetail",
    AddCategory: "Product/AddCategory",
    UpdateCategory: "Product/UpdateCategory",
    DeleteCategory: "Product/DeleteCategory",
    GetPriceTiers: "Product/GetPriceTiers",
    GetPriceTierDetail: "Product/GetPriceTierDetail",
    GetPriceTierDropdown: "Product/GetPriceTierDropdown",
    AddPriceTier: "Product/AddPriceTier",
    UpdatePriceTier: "Product/UpdatePriceTier",
    DeletePriceTier: "Product/DeletePriceTier",
    GetTaxGroups: "Product/GetTaxGroups",
    GetTaxGroupDetail: "Product/GetTaxGroupDetail",
    GetTaxGroupDropdown: "Product/GetTaxGroupDropdown",
    AddTaxGroup: "Product/AddTaxGroup",
    UpdateTaxGroup: "Product/UpdateTaxGroup",
    DeleteTaxGroup: "Product/DeleteTaxGroup",
    AddTax: "Product/AddTax",
    UpdateTax: "Product/UpdateTax",
    DeleteTax: "Product/DeleteTax",
    GetProducts: "Product/GetProducts",
    AddProducts: "Product/AddProducts",
    UpdateProducts: "Product/UpdateProducts",
    GetProductDetail: "Product/GetProductDetail",
    GetProductDropdown: "Product/GetProductDropdown",
    DeleteProduct: "Product/DeleteProduct",
    AchieveProduct: "Product/AchieveProduct",
    ActivateProduct: "Product/ActivateProduct",
    DeactivateProduct: "Product/DeactivateProduct",
    GetStateDropdown: "Product/GetStateDropdown",
    GetShippingGroupDropdown: "Product/GetShippingGroupDropdown",
    GetAmountMeasurementDropdown: "Product/GetAmountMeasurementDropdown",
    GetDiscountStatusDropdown: "Product/GetDiscountStatusDropdown",
    GetDiscountUsageLimitConditionDropdown:
      "Product/GetDiscountUsageLimitConditionDropdown",
    GetDiscountLimitPerCustomerConditionDropdown:
      "Product/GetDiscountLimitPerCustomerConditionDropdown",
    GetDiscountOrderConditionDropdown:
      "Product/GetDiscountOrderConditionDropdown",
    GetShippingTypeDropdown: "Product/GetShippingTypeDropdown",
    GetProductStatusDropdown: "Product/GetProductStatusDropdown",
    ExportProducts: "Product/ExportProducts",
    ImportProducts: "Product/ImportProducts",
    ImportLazadaProducts: "Product/ImportLazadaProducts",
  },
  Report: {
    GetTotalSalesFullReport: "Report/GetTotalSalesFullReport",
    GetTopSellerSalesSummary: "Report/GetTopSellerSalesSummary",
    GetTopProductSalesSummary: "Report/GetTopProductSalesSummary",
    GetTotalOrdersSummary: "Report/GetTotalOrdersSummary",
    GetTotalSalesSummary: "Report/GetTotalSalesSummary",
    GetSellerAnalyticSummary: "Report/GetSellerAnalyticSummary",
    GetAnalyticSummary: "Report/GetAnalyticSummary",
    GetOrderSummary: "Report/GetOrderSummary",
  },
  Seller: {
    GetSellerRequests: "Seller/GetSellerRequests",
    GetSellers: "Seller/GetSellers",
    GetTopSellers: "Seller/GetTopSellers",
    GetSellerDetail: "Seller/GetSellerDetail",
    UpdateSellerStatus: "Seller/UpdateSellerStatus",
    GetSellerDropdown: "Seller/GetSellerDropdown",
    ApproveSellerApplications: "Seller/ApproveSellerApplications",
    RejectSellerApplications: "Seller/RejectSellerApplications",
    UpdateServiceAgreement: "Setting/UpdateServiceAgreement",
    GetServiceAgreement: "Setting/GetServiceAgreementContent",
    GetSellerRejectReasonDropdown: "Seller/GetSellerRejectReasonDropdown",
    GetSellerLatestOrders: "Seller/GetSellerLatestOrders",
    GetSellerProductSales: "Seller/GetSellerProductSales",
    AssignSellerPriceTier: "Seller/AssignSellerPriceTier",
    GetSellerRegistration: "Seller/GetSellerRegistration",
  },
  Setting: {
    GetNotificationSettings: "Setting/GetNotificationSettings",
    UpdateNotificationSettings: "Setting/UpdateNotificationSettings",
    GetSellerGlobalCommission: "Setting/GetSellerGlobalCommission",
    UpdateSellerGlobalCommission: "Setting/UpdateSellerGlobalCommission",
  },
  Shipping: {
    GetShippingRegionDropdown: "Shipping/GetShippingRegionDropdown",
    GetShippings: "Shipping/GetShippings",
    GetShippingDetail: "Shipping/GetShippingDetail",
    AddShipping: "Shipping/AddShipping",
    UpdateShipping: "Shipping/UpdateShipping",
    DeleteShipping: "Shipping/DeleteShipping",
  },
  Subscription: {
    SetupFreeTierSubscription: "Subscription/SetupFreeTierSubscription",
    GetSubscriptionListing: "Subscription/GetSubscriptionListing",
    GetCustomerDefaultPaymentMethod: "Subscription/GetCustomerDefaultPaymentMethod",
    ActivateCancelledSubscription: "Subscription/ActivateCancelledSubscription",
    ChangeSubscription: "Subscription/ChangeSubscription",
    UpdateCustomerPaymentMethod: "Subscription/UpdateCustomerPaymentMethod",
    GetRenewableSubscriptionPlans: "Subscription/GetRenewableSubscriptionPlans",
    GetSubscriptionPlans: "Subscription/GetSubscriptionPlans",
    GetSubscriptionDetail: "Subscription/GetSubscriptionDetail",
    ProceedCreditCardSubscription: "Subscription/ProceedCreditCardSubscription",
    CancelMemberSubscription: "Subscription/CancelMemberSubscription",
    DownloadAllBillingStatement: "Subscription/DownloadAllBillingStatement",
    DownloadBillingStatement: "Subscription/DownloadBillingStatement",
    GetMemberBilling: "Subscription/GetMemberBilling",
    GetCompanyBillingHistories: "Subscription/GetCompanyBillingHistories",
    ProceedSubscriptionPayment: "Subscription/ProceedSubscriptionPayment",
    RenewSubscription: "Subscription/RenewSubscription",
  },
  Vendor: {
    GetAffiliateProvider: "Vendor/GetAffiliateProvider",
    CheckVendorPermission: "Permission/CheckVendorPermission",
    CheckVendorOnboardingStatus: "Vendor/CheckVendorOnboardingStatus",
    GetVendorUserDetail: "Vendor/GetVendorUserDetail",
    GetVendorCompanyDetail: "Vendor/GetVendorCompanyDetail",
    UpdateVendorProfile: "Vendor/UpdateVendorProfile",
    UpdateVendorCompany: "Vendor/UpdateVendorCompany",
    SetupVendorAccountInfo: "Vendor/SetupVendorAccountInfo",
    GetBusinessTypeDropdown: "Vendor/GetBusinessTypeDropdown",
    GetCountryDropdown: "Vendor/GetCountryDropdown",
    GetStateDropdown: "Vendor/GetStateDropdown",
    GetGenderDropdown: "Vendor/GetGenderDropdown",
    GetNotifications: "Vendor/GetNotifications",
  },
  Campaign: {
    DeleteCampaign: "Campaign/DeleteCampaign",
    NotifyCampaign: "Campaign/NotifyCampaign",
    TopupCampaignReward: "Campaign/TopupCampaignReward",
    DownloadCampaignReceipt: "Campaign/DownloadCampaignReceipt",
    GetVideoStoryDropdown: "Campaign/GetVideoStoryDropdown",
    CreateUGCCampaign: "Campaign/CreateUGCCampaign",
    UpdateUGCCampaign: "Campaign/UpdateUGCCampaign",
    GetCampaignDetail: "Campaign/GetCampaignDetail",
    ExportCampaignReport: "Campaign/ExportCampaignReport",
    ImportCampaignShipment: "Campaign/ImportCampaignShipment",
    ExportCampaignShipment: "Campaign/GetCampaignShipments",
    GetCampaignDropdown:"Campaign/GetCampaignDropdown",
    GetLatestCampaign: "Campaign/GetLatestCampaign",
    UpdateCampaignProgress: "Campaign/UpdateCampaignProgress",
    GetCampaignSellerShipments: "Campaign/GetCampaignSellerShipments",
    GetSellerShipmentDetail: "Campaign/GetSellerShipmentDetail",
    UpdateSellerCampaignShipment: "Campaign/UpdateSellerCampaignShipment",
    GetPublishContentCampaigns: "Campaign/GetPublishContentCampaigns",
    GetCampaignStatusCount : "Campaign/GetCampaignStatusCount",
    GetCampaigns: "Campaign/GetCampaigns",
    GetEventCampaigns: "Campaign/GetEventCampaigns",
    GetCampaignSellers: "Campaign/GetCampaignSellers",
    GetCampaignProducts: "Campaign/GetCampaignProducts",
    GetCampaignAnalytics: "Campaign/GetCampaignAnalytics",
    GetCampaignSellerDetail: "Campaign/GetCampaignSellerDetail",
    GetCampaignProductDetail: "Campaign/GetCampaignProductDetail",
    RejectSellerCampaignAccess: "Campaign/RejectSellerCampaignAccess",
    GetCampaignSellerRequestCount: "Campaign/GetCampaignSellerRequestCount",
    GetCampaignSellerRequests: "Campaign/GetCampaignSellerRequests",
    RejectCampaignRequest: "Campaign/RejectCampaignRequest",
    ApproveCampaignRequest: "Campaign/ApproveCampaignRequest",
  },
  Posting: {
    GetAffiliateContentCount: "Posting/GetAffiliateContentCount",
    GetAffiliateProgramPostContents: "Posting/GetAffiliateProgramPostContents",
    GetCampaignRewardRequests: "Posting/GetCampaignRewardRequests",
    GetCampaignPublishContent: "Posting/GetCampaignPublishContent",
    GetPostingContentDetail : "Posting/GetPostingContentDetail",
    GetCampaignPublishContentRequestCount: "Posting/GetCampaignPublishContentRequestCount",
    UpdatePostStatus: "Posting/UpdatePostStatus",
    DownloadPostMedia: "Posting/DownloadPostMedia",
    SuspendOrActivateContent: "Posting/SuspendOrActivateContent"
  },
  Admin: {
    ExportPayouts: "Admin/ExportPayouts",
    GetUgcOrders: "Admin/GetUgcOrders",
    GetUgcOrderStatusCount: "Admin/GetUgcOrderStatusCount",
    GetVideoStories: "Admin/GetVideoStories",
    GetVideoStoryDetail: "Admin/GetVideoStoryDetail",
    CreateVideoStory: "Admin/CreateVideoStory",
    UpdateVideoStory: "Admin/UpdateVideoStory",
    RestrictAccount: "Admin/RestrictAccount",
    UnlockAccountRestriction: "Admin/UnlockAccountRestriction",
    ExportCampaignInfluencers: "Admin/ExportCampaignInfluencers",
    PublishOrUnpublishBlog: "Admin/PublishOrUnpublishBlog",
    GetBlogProductCount: "Admin/GetBlogProductCount",
    GetBlogDetailById: "Admin/GetBlogDetailById",
    RemoveProductFromBlog: "Admin/RemoveProductFromBlog",
    UpdateBlogProductRedirection: "Admin/UpdateBlogProductRedirection",
    GetBlogProductDetail: "Admin/GetBlogProductDetail",
    AddProductToBlog: "Admin/AddProductToBlog",
    SearchBlogProducts: "Admin/SearchBlogProducts",
    GetBlogProducts: "Admin/GetBlogProducts",
    CreateBlog: "Admin/CreateBlog",
    UpdateBlog: "Admin/UpdateBlog",
    GetBlogStatusCount: "Admin/GetBlogStatusCount",
    GetProductPreview: "Admin/GetProductPreview",
    AddProductToCampaign: "Admin/AddProductToCampaign",
    UpdateCampaignProductRedirection: "Admin/UpdateCampaignProductRedirection",
    RemoveProductFromCampaign: "Admin/RemoveProductFromCampaign",
    SearchCampaignProducts: "Admin/SearchCampaignProducts",
    OverrideSellerCommission: "Admin/OverrideSellerCommission",
    InviteSeller: "Admin/InviteSeller",
    GetRefundItemDetails: "Admin/GetRefundItemDetails",
    MarkBuyerRefundComplete: "Admin/MarkBuyerRefundComplete",
    GetPromotions: "Admin/GetPromotions",
    DeletePromotion: "Admin/DeletePromotion",
    CreatePromotion: "Admin/CreatePromotion",
    UpdateSubscription: "Admin/UpdateSubscription",
    GetSubscriptionListing: "Admin/GetSubscriptionListing",
    SentAdvertisement: "Admin/SentAdvertisement",
    GetSellerWallets: "Admin/GetSellerWallets",
    GetSellerCreditHistories: "Admin/GetSellerCreditHistories",
    GetCreditDetail: "Admin/GetCreditDetail",
    GetSellerCreditBalances: "Admin/GetSellerCreditBalances",
    GetSellerPayoutHistories: "Admin/GetSellerPayoutHistories",
    GetPayoutRecordCount: "Admin/GetPayoutRecordCount",
    GetSellerCreditCount: "Admin/GetSellerCreditCount",
    GetAdminSideMenuCount: "Admin/GetAdminSideMenuCount",
    GetCampaignProductCount: "Admin/GetCampaignProductCount",
    GetCampaignSellerRequestCount: "Admin/GetCampaignSellerRequestCount",
    GetSocialMediaDropdown: "Admin/GetSocialMediaDropdown",
    GetFollowerGroupDropdown: "Admin/GetFollowerGroupDropdown",
    GetAudiencePreferenceDropdown: "Admin/GetAudiencePreferenceDropdown",
    GetSellerStatusCount: "Admin/GetSellerStatusCount",
    GetAffiliateProgramStatusCount: "Admin/GetAffiliateProgramStatusCount",
    GetVendorStatusCount: "Admin/GetVendorStatusCount",
    GetPayoutStatusCount: "Admin/GetPayoutStatusCount",
    GetVendorRefundStatusCount: "Admin/GetVendorRefundStatusCount",
    GetCampaignStatusCount: "Admin/GetCampaignStatusCount",
    GetPublicCampaignRequests: "Admin/GetPublicCampaignRequests",
    GetCampaignSellerRequests: "Admin/GetCampaignSellerRequests",
    RejectCampaignRequest: "Admin/RejectCampaignRequest",
    ApproveCampaignRequest: "Admin/ApproveCampaignRequest",
    GetCampaigns: "Admin/GetCampaigns",
    GetCampaignDetail: "Admin/GetCampaignDetail",
    CreateCampaign: "Admin/CreateCampaign",
    UpdateCampaign: "Admin/UpdateCampaign",
    DeleteCampaign: "Admin/DeleteCampaign",
    GenerateCampaignPermalink: "Admin/GenerateCampaignPermalink",
    GetCampaignRewardRequirementDropdown: "Admin/GetCampaignRewardRequirementDropdown",
    GetPromotionDropdown: "Admin/GetPromotionDropdown",
    GetSubscriptionTypeDropdown: "Admin/GetSubscriptionTypeDropdown",
    GetCampaignPriceTypeDropdown: "Admin/GetCampaignPriceTypeDropdown",
    GetCampaignStatusDropdown: "Admin/GetCampaignStatusDropdown",
    GetSellerDropdown: "Admin/GetSellerDropdown",
    GetVendorProductDropdown: "Admin/GetVendorProductDropdown",
    GetPayouts: "Admin/GetPayouts",
    GetPayoutDetail: "Admin/GetPayoutDetail",
    IssuedPayout: "Admin/IssuedPayout",
    IssuedCampaignPayout: "Admin/IssuedCampaignPayout",
    VoidPayout: "Admin/VoidPayout",
    SuspendOrActivateSeller: "Admin/SuspendOrActivateSeller",
    GetSellerVendorTraffic: "Admin/GetSellerVendorTraffic",
    GetSellerDetails: "Admin/GetSellerDetails",
    GetSellers: "Admin/GetSellers",
    GetRejectedSellers: "Admin/GetRejectedSellers",
    ApproveSellerRegistration: "Admin/ApproveSellerRegistration",
    RejectSellerRegistration: "Admin/RejectSellerRegistration",
    GetSellerRegistration: "Admin/GetSellerRegistration",
    GetSellerRegistrations: "Admin/GetSellerRegistrations",
    GetVendorAffiliateAnalytics: "Admin/GetVendorAffiliateAnalytics",
    GetVendorRefunds: "Admin/GetVendorRefunds",
    GetAffiliateProgramVendors: "Admin/GetAffiliateProgramVendors",
    AddToAffiliateProgram: "Admin/AddToAffiliateProgram",
    RemoveFromAffiliateProgram: "Admin/RemoveFromAffiliateProgram",
    ApproveAccess: "Admin/ApproveAccess",
    GenerateAffiliateProgramPermalink: "Admin/GenerateAffiliateProgramPermalink",
    GetVendors: "Admin/GetVendors",
    GetWithdrawalRequests: "Admin/GetWithdrawalRequests",
    GetVendorDetail: "Admin/GetVendorDetail",
    MarkVendorRefundComplete: "Admin/MarkVendorRefundComplete",
    SuspendOrActivateVendor: "Admin/SuspendOrActivateVendor",
    UpdateWithdrawStatus: "Admin/UpdateWithdrawStatus",
    GetSellerRejectReasonDropdown: "Admin/GetWithdrawRejectReason",
    GetWithdrawRejectReason: "Admin/GetWithdrawRejectReason",
    GetWithdrawalRequestDetail: "Admin/GetWithdrawalRequestDetail",
  },
  socialMedia:{
    GetInstagramMediaInsights: "SocialMedia/GetInstagramMediaInsights",
  },
  Blog:{
    GetBlogs: "Blog/GetBlogs",
  }
};

export const QueryKeys = {
  user: "user",
  tokens: "tokens",
  tempTokens: "tempTokens",
};

export const StorageKey = "@SIXTYSEC_QUERY_CACHE";

export const Genders = [
  {
    id: "Male",
    name: "Male",
  },
  {
    id: "Female",
    name: "Female",
  },
  {
    id: "Other",
    name: "Other",
  },
];

export const unknown_Error =
  "Oops! Something went wrong. Please try again later. Meanwhile, please make sure you are connected to the internet.";
