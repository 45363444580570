// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SixtyPaper_paper__ZEOqM {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;
    border: #C7C5B3 1px solid ;
}`, "",{"version":3,"sources":["webpack://./src/components/SixtyPaper/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,0BAA0B;AAC9B","sourcesContent":[".paper {\n    display: flex;\n    flex-direction: column;\n    padding: 24px;\n    background-color: #fff;\n    border-radius: 4px;\n    border: #C7C5B3 1px solid ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `SixtyPaper_paper__ZEOqM`
};
export default ___CSS_LOADER_EXPORT___;
