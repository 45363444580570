import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Panel from "components/SixtyPanel";
import GridHeader from "components/GridHeader";
import PanelHeader from "components/PanelHeader";
import styles from "./index.module.css";
import CampaignReport from "./components/CampaignReport";
import Calendly from "./components/Calendly";
import ActionBox from "./components/ActionBox";
import OpenOrders from "./components/OpenOrders";
import Button from "components/SixtyButton";
import { ReactComponent as CalendarIcon } from "assets/icons/Icon-Calendar.svg";
import Reports from "./components/Reports";
import ProfileActionsProgress from "./components/ProfileActionsProgress";
import EmptyGrid from "components/EmptyGrid";
import Loader from "components/Loader";
import ErrorModal from "components/Error";
import { ReactComponent as SellerIcon } from "assets/icons/Icon-Sellers.svg";
import { useGetSellerRequests } from "queries/seller";
import { useGetOrders } from "queries/order";
import { useGetEventCampaigns, useGetCampaigns, useGetLatestCampaign } from "queries/campaign";
import { useGetBrandProductRequests } from "queries/product";
import {
  useGetNewOrders,
  useGetStepsCompletion,
  useGetSummaryReport,
  useGetSupplierCompanyStatus,
  useJoinAffiliateProgram
} from "queries/dashboard";
import { PanelActionButton } from "components/SixtyPanel";
import { unknown_Error } from "common/constants";
import { ButtonDatePicker } from "components/SixtyDatePicker";
import format from "date-fns/format";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import Modal from "components/Modal";

const DashboardHome = () => {
  const [month, setMonth] = useState(new Date());
  const history = useNavigate();
  const { data: profileActionsStatus, refetch } = useGetStepsCompletion();
  const { data: campanyStatus, refetch: statusRefetch } = useGetSupplierCompanyStatus();
  const { data: summaryData, isLoading, isFetching } = useGetSummaryReport(
    format(month, "MM/yyyy")
  );

  const [isCalendlyOpen, setCalendlyOpen] = useState(false);
  const calendlyRef = useRef();

  const CalendlyTitle = () => {
    return (
      <div className={styles.calendlyModalTitleBar}>
        <div className={styles.calendlyContainer}>
          <div className={styles.modalTitle}>{'Appointment'}</div>
        </div>
      </div>
    );
  };

  const [orderCount, setOrderCount] = useState(0);
  const [campaignCount, setCampaignCount] = useState(0);
  const [requestCount, setRequestCount] = useState(0);

  // pagination
  const startFrom = 0;
  const limit = 5;

  const getFilters = useCallback(() => {
    return {
      keyword: "",
      startFrom,
      limit,
    };
  }, []);

   const {
     data: latestCampaignData,
     isLoading: isLatestLoading,
     isError: isLatestError,
     refetch: latestRefetch,
   } = useGetLatestCampaign();

  // const {
  //   data: orderData,
  //   isLoading: isOrderLoading,
  //   isError: isOrderError,
  //   refetch: orderRefetch,
  //   error,
  // } = useGetNewOrders();

   const {
     data: campaignData,
     isError: isCampaignError,
     isLoading: isCampaignLoading,
     refetch : campaignRefetch,
   } = useGetCampaigns(getFilters());

  // const {
  //   data: requestData,
  //   isError: requestError,
  //   isLoading: requestLoading,
  //   refetch : requestRefetch,
  // } = useGetBrandProductRequests(getFilters());

  const { mutateAsync: joinAffiliateProgram, isLoading: isLoadAffilateProgram } = useJoinAffiliateProgram();

  const onJoinAffiliateProgram = async () => {

    try {

      const result =  await joinAffiliateProgram();

      if (result) {
        statusRefetch();
        setToast("Successfully join affiliate program");
      }
      
    } catch (error) {
      reportError("Advertisement: onSubmit: error: ", error);
    }
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (campanyStatus?.invitationLink)
    innerText = `${campanyStatus?.invitationLink}`;

    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Invitation link copied to clipboard");
    else setToast("No content to copy", "error");
};

  useEffect(() => {
  
    if (campaignData?.totalRecords) {
      setCampaignCount(campaignData.totalRecords);
    }
 
    // if(campanyStatus?.isPendingApproval){
    //   setCalendlyOpen(true);
    // }
  }, [campaignData,campanyStatus]);

  useEffect(() => {
    refetch();
  }, []);

  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    // orderRefetch();
    // requestRefetch();
  };

  useEffect(() => {
    if (isCampaignError) {
      setErrorOpen(true);
    }
  }, [isCampaignError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={unknown_Error}
      />
    );
  }

  // if no data in table (default filters)
  function isDataEmpty(data) {
    if (data?.length === 0) {
      return true;
    }
    return false;
  }

  function isAllCompleted(data) {
    let isCompleted = true;

    if(!data) return isCompleted;

    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        isCompleted = false;
      }
    });
    return isCompleted;
  }

  return (
    <div className={styles.dashBoardPage}>
      <GridHeader header="Dashboard" className={styles.dashboardHeader} />
      <div className={styles.row}>
        <div className={styles.leftContainer}>
        <Panel className={styles.panelWrap}>
          {/* <PanelHeader
              title={'What we offers'}
           /> */}
           <div className={styles.row} >
             {/* className={styles.row} */}
              {/* {profileActionsStatus && campanyStatus && (
                <>
                  <div className={styles.container}>
                    <ActionBox  
                        title={'Affiliate Program'}
                        desc={campanyStatus?.isRejectedFromAffiliateProgram ? 'Hi brand, you been removed from affiliate program, please contact 60sec admin to rejoin affiliate program.' : 
                                                                              'Hi brand, Complete all the steps on right side to start affiliate program.'}
                        onTriggered={onJoinAffiliateProgram}
                        isAllowed={isAllCompleted(profileActionsStatus)}
                        isCompleted={campanyStatus?.isJoinedAffiliateProgram || campanyStatus?.isRejectedFromAffiliateProgram}
                        actionTitle={campanyStatus?.isJoinedAffiliateProgram ? 'Joined' : 'Join'}
                        triggredLoading={isLoadAffilateProgram}
                    />   
                    </div>   
                    <div className={styles.container}>
                          <ActionBox  
                              title={'Affiliate Invitation'}
                              desc={campanyStatus?.isJoinedAffiliateProgram ? 
                                    'Invite influencers / creators to join your affiliate program.' :
                                    'Invite influencers / creators to join your affiliate program, join affiliate program to get started.'}                                                               
                              onTriggered={copyToClipboard}
                              isAllowed={campanyStatus?.isJoinedAffiliateProgram}
                              actionTitle={'Copy Invitation Link'}
                              triggredLoading={false}
                          />        
                    </div>   
                  </>       
                ) 
              } */}
  
                    {/* <ActionBox  
                        title={'Claim free media exposure'}
                        desc={'Claim media exposure from us.'}
                        className={styles.secondRow}
                        onTriggered={onJoinAffiliateProgram}
                        isAllowed={isAllCompleted(profileActionsStatus)}
                        actionTitle={'Claim'}
                    /> 
                    <ActionBox  
                        title={'Request Campaign Quatation'}
                        desc={'Request quotation from us, our marketing advisor will contact you shortly.'}
                        className={styles.secondRow}
                        onTriggered={onJoinAffiliateProgram}
                        isAllowed={isAllCompleted(profileActionsStatus)}
                        actionTitle={'Request'}
                    />  */}
            </div>
        </Panel>
        {isLatestLoading ? (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            ) :(
              <Panel className={styles.panelWrap}>
                <PanelHeader
                  title={latestCampaignData?.name}
                />
                <CampaignReport data={latestCampaignData} />
             </Panel>
            )
        } 


          {/* <Panel className={styles.panelWrap}>
            {(!Array.isArray(orderData) || isDataEmpty(orderData)) &&
              !isOrderLoading && (
                <>
                  <EmptyGrid
                    Icon={SellerIcon}
                    emptyMessage={
                      isOrderError
                        ? "error in loading table"
                        : "You have no processing orders"
                    }
                    className={styles.emptyTable}
                  />
                  {isOrderError && (
                    <PanelActionButton onClick={() => orderRefetch()}>
                      Try Again
                    </PanelActionButton>
                  )}
                </>
              )}
            {isOrderLoading ? (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            ) : (
              Array.isArray(orderData) &&
              !isDataEmpty(orderData) && (
                <>
                  <PanelHeader
                    title={`You have ${orderData?.length} processing orders`}
                  />
                  <OpenOrders openOrders={orderData} />
                  <div className={styles.viewReqContainer}>
                    <span
                      className={styles.viewReqBtn}
                      onClick={() => history.push("/dashboard/orders")}
                    >
                      View all orders
                    </span>
                  </div>
                </>
              )
            )}
          </Panel> */}
        </div>
        <div className={styles.rightContainer}>
          {/* {profileActionsStatus && !isAllCompleted(profileActionsStatus) && (
            <Panel
              className={[styles.panelWrap, styles.profileActionPanel].join(
                " "
              )}
            >
              <div>
                <PanelHeader title="Complete the steps below" />
                <ProfileActionsProgress
                  profileActionsStatus={profileActionsStatus}
                />
              </div>
            </Panel>
          )} */}
          {(summaryData) && (
            <Panel
              className={[styles.panelWrap, styles.reportsPanel].join(" ")}
            >
              <PanelHeader title="Summary">
                <div>
                  <ButtonDatePicker
                    value={month}
                    maxDate={new Date()}
                    handleChange={(date) => setMonth(date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </div>
              </PanelHeader>
              {isLoading ? (
                <Loader />
              ) : (
                <Reports summaryData={summaryData} orderCount={campaignCount} />
              )}
            </Panel>
          )}
        </div>
      </div>
      {isCalendlyOpen && (
        <Modal
          isOpen={isCalendlyOpen}
          onAttemptClose={() => setCalendlyOpen(true)}
          modalRef={calendlyRef}
          Title={CalendlyTitle}
        >
          <Calendly/>
        </Modal>
      )}
    </div>
  );
};

export default DashboardHome;
