import { useEffect } from "react";
import clsx from "clsx";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import styles from "../index.module.css";
import {
  useGetBusinessTypeDropdown,
  useGetCountryDropdown,
  useGetStateDropdown,
} from "queries/vendor";

export default function StepTwoForm({
  control,
  handleChange,
  values,
  setValue,
  errors,
  active,
}) {
  useEffect(() => {
    setValue("company", values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: businessData } = useGetBusinessTypeDropdown();
  const businessTypes = businessData?.length
    ?  businessData.map((option) => ({
       label: option.name,
       value: option.key,
    }))  
    : [];

  const { data: countryData } = useGetCountryDropdown();
  const countryOptions = countryData?.length
    ? [
        { label: "None", value: null },
        ...countryData.map((option) => ({
          label: option.name,
          value: option.key,
        })),
      ]
    : [];

  const { data: stateData } = useGetStateDropdown();
  const stateOptions = stateData?.length
    ? [
        { label: "None", value: null },
        ...stateData.map((option) => ({
          label: option.name,
          value: option.key,
        })),
      ]
    : [];

  return (
    <div
      className={clsx(styles.form, {
        [styles.activeForm]: active,
      })}
    >
      {/* <Input
        control={control}
        handleChange={handleChange}
        label="Brand"
        name="company.brand"
        error={errors?.company?.brand}
      /> */}
      <div className={styles.fullWidth}>
        <Input
          control={control}
          handleChange={handleChange}
          label="Company Name"
          name="company.companyName"
          error={errors?.company?.companyName}
          fullWidth={true}
        />
      </div>
      <Select
        control={control}
        handleChange={handleChange}
        label="Business Type"
        name="company.businessType"
        error={errors?.company?.businessType}
        defaultValue=""
        options={businessTypes}
        fullWidth={true}
      />
      {/* <Input
        control={control}
        handleChange={handleChange}
        label="Building Name"
        name="company.buildingName"
        error={errors?.company?.buildingName}
      /> */}
      {/* <div className={styles.mobileInputContainer}>
        <div className={styles.countryCode}>
          <Input
            control={control}
            handleChange={handleChange}
            label="Unit No"
            name="company.unitNumber"
            error={errors?.company?.unitNumber}
          />
        </div> 
        <div className={styles.phoneNumber}>
          <Input
            control={control}
            handleChange={handleChange}
            label="Floor No"
            name="company.floorNumber"
            error={errors?.company?.floorNumber}
          />
        </div> 
      </div> */}
       <div className={styles.fullWidth}>
        <Input
          control={control}
          handleChange={handleChange}
          label="Street 1"
          name="company.street1"
          error={errors?.company?.street1}
        />
      </div>
      <div className={styles.fullWidth}>
        <Input
          control={control}
          handleChange={handleChange}
          label="Street 2"
          name="company.street2"
          error={errors?.company?.street2}
          fullWidth={true}
        />
      </div>
      <Input
        control={control}
        handleChange={handleChange}
        label="Post code"
        name="company.postcode"
        error={errors?.company?.postcode}
      />  
      <Input
        control={control}
        handleChange={handleChange}
        label="City"
        name="company.city"
        error={errors?.company?.city}
      />
   
      <div className={styles.fullWidth}>
        <Select
          control={control}
          handleChange={handleChange}
          label="State"
          name="company.state"
          error={errors?.company?.state}
          defaultValue="Kuala Lumpur"
          options={stateOptions}
          fullWidth={true}
        />
      </div>
      <div className={styles.fullWidth}>
        <Select
          control={control}
          handleChange={handleChange}
          label="Country"
          name="company.country"
          error={errors?.company?.country}
          defaultValue="Malaysia"
          options={countryOptions}
          fullWidth={true}
        />
      </div>
    </div>
  );
}
