// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationsFlyOut_notificationsFlyout__xylks {
    width: 360px;
}

.NotificationsFlyOut_title__IjxZA {
    font-family: var(--medium500);
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    padding: 16px 16px 0px 16px;
}

.NotificationsFlyOut_elipseBlue__5rYw5 svg {
    fill: #4F82CE;
}

.NotificationsFlyOut_elipseGreen__4wVJ5 svg{
    fill: #4FCE82;
}

.NotificationsFlyOut_elipseYellow__8Ewdq svg{
    fill: #F5B755;
}

.NotificationsFlyOut_elipseOrange__cd93m svg{
    fill: #EF8777;
}

.NotificationsFlyOut_elipse__FwplE {
    padding-right: 8px;
}

.NotificationsFlyOut_elipseSmall__7xywx svg{
    width: 8px;
    height: 8px;
}

.NotificationsFlyOut_notificationContentContainer__t\\+WCi {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid var(--black5Transparent);
}

.NotificationsFlyOut_notificationType__cuC1I {
    font-family: var(--medium500);
}

.NotificationsFlyOut_contentIsRead__Jtmhj {
    opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/NotificationsFlyOut/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,yBAAyB;IACzB,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,aAAa;IACb,aAAa;IACb,iDAAiD;AACrD;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".notificationsFlyout {\n    width: 360px;\n}\n\n.title {\n    font-family: var(--medium500);\n    font-size: 12px;\n    text-transform: uppercase;\n    color: rgba(0, 0, 0, 0.5);\n    padding: 16px 16px 0px 16px;\n}\n\n.elipseBlue svg {\n    fill: #4F82CE;\n}\n\n.elipseGreen svg{\n    fill: #4FCE82;\n}\n\n.elipseYellow svg{\n    fill: #F5B755;\n}\n\n.elipseOrange svg{\n    fill: #EF8777;\n}\n\n.elipse {\n    padding-right: 8px;\n}\n\n.elipseSmall svg{\n    width: 8px;\n    height: 8px;\n}\n\n.notificationContentContainer {\n    display: flex;\n    padding: 16px;\n    border-bottom: 1px solid var(--black5Transparent);\n}\n\n.notificationType {\n    font-family: var(--medium500);\n}\n\n.contentIsRead {\n    opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationsFlyout": `NotificationsFlyOut_notificationsFlyout__xylks`,
	"title": `NotificationsFlyOut_title__IjxZA`,
	"elipseBlue": `NotificationsFlyOut_elipseBlue__5rYw5`,
	"elipseGreen": `NotificationsFlyOut_elipseGreen__4wVJ5`,
	"elipseYellow": `NotificationsFlyOut_elipseYellow__8Ewdq`,
	"elipseOrange": `NotificationsFlyOut_elipseOrange__cd93m`,
	"elipse": `NotificationsFlyOut_elipse__FwplE`,
	"elipseSmall": `NotificationsFlyOut_elipseSmall__7xywx`,
	"notificationContentContainer": `NotificationsFlyOut_notificationContentContainer__t+WCi`,
	"notificationType": `NotificationsFlyOut_notificationType__cuC1I`,
	"contentIsRead": `NotificationsFlyOut_contentIsRead__Jtmhj`
};
export default ___CSS_LOADER_EXPORT___;
