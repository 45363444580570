import { useFieldArray } from "react-hook-form";
import { useImmer } from "utils/hooks/useImmer";
import _findIndex from "lodash/findIndex";
import _cloneDeep from "lodash/cloneDeep";

import PanelHeader from "components/PanelHeader";
import Select from "components/SixtySelect";
import SixtyButton from "components/SixtyButton";
import SixtyInput from "components/SixtyInput";
import { ReactComponent as BaseIcon } from "assets/images/Base.svg";
import styles from "./index.module.css";
import clsx from "clsx";
import { useEffect } from "react";

const weightUnitOptions = [
  {
    label: "kg",
    value: "kg",
  },
  // {
  //   label: "g",
  //   value: "g",
  // },
];

export default function GroupFields({ formMethods }) {
  const { control, getValues, reset, watch } = formMethods;
  const {
    fields: groupFields,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: "shippingGroups",
  });

  const shippingGroups = watch("shippingGroups");

  const shippingOptions = [
    {
      label: "Free Shipping",
      value: "FreeShipping",
    },
    {
      label: "Weight Based",
      value: "WeightBased",
    },
    {
      label: "Flat Rate",
      value: "FlatRate",
    },
  ];

  // state to hold edit open and local state before saving
  const [weightBased, setWeightBased] = useImmer(() => ({
    active: false,
    open: false,
    state: {},
  }));
  const [flatRate, setFlatRate] = useImmer(() => ({
    active: false,
    open: false,
    state: {},
  }));
  const [freeShipping, setFreeShipping] = useImmer(() => ({
    active: false,
    open: false,
    state: {},
  }));

  const getIndex = (group) => {
    return _findIndex(shippingGroups, (o) => {
      return o.shippingType === group;
    });
  };

  // the state handler for localState of groupFields while edit
  const setState = (name, field, value) => {
    if (name === "WeightBased")
      setWeightBased((state) => {
        state.state[field] = value;
      });
    if (name === "FlatRate")
      setFlatRate((state) => {
        state.state[field] = value;
      });
    if (name === "FreeShippping")
      setFlatRate((state) => {
        state.state[field] = value;
      });
  };

  const handleSave = (name) => {
    let values = _cloneDeep(getValues());
    let index = getIndex(name);
    if (name === "WeightBased") {
      values.shippingGroups[index] = weightBased.state;
      reset(values);
      setWeightBased((draft) => {
        draft.open = false;
      });
    }
    if (name === "FreeShipping") {
      values.shippingGroups[index] = freeShipping.state;
      reset(values);
      setFreeShipping((draft) => {
        draft.open = false;
      });
    }
    if (name === "FlatRate") {
      values.shippingGroups[index] = flatRate.state;
      reset(values);
      setFlatRate((draft) => {
        draft.open = false;
      });
    }
  };

  useEffect(() => {
    if (groupFields?.find((field) => field.shippingType === "WeightBased"))
      setWeightBased((state) => {
        state.active = true;
      });
    if (groupFields?.find((field) => field.shippingType === "FlatRate"))
      setFlatRate((state) => {
        state.active = true;
      });
    if (groupFields?.find((field) => field.shippingType === "FreeShipping"))
      setFreeShipping((state) => {
        state.active = true;
      });
  }, [groupFields, setFlatRate, setFreeShipping, setWeightBased]);

  const handleAddShippingGroup = (value) => {
    switch (value) {
      case "WeightBased":
        if (!weightBased.active) {
          appendGroup({
            name: "Weight Based Calculation",
            firstWeight: 0,
            firstWeightUnit: "kg",
            price: 0,
            subsequentWeight: 0,
            subsequentWeightUnit: "kg",
            subsequentPrice: 0,
            shippingType: "WeightBased",
            minimumOrderAmount: null,
          });
          setWeightBased((state) => {
            state.active = true;
          });
        }
        break;
      case "FlatRate":
        if (!flatRate.active) {
          appendGroup({
            name: "Flat Rate",
            firstWeight: null,
            price: 0,
            subsequentWeight: null,
            subsequentPrice: null,
            shippingType: "FlatRate",
            minimumOrderAmount: null,
          });
          setFlatRate((state) => {
            state.active = true;
          });
        }
        break;
      case "FreeShipping":
        if (!freeShipping.active) {
          appendGroup({
            name: "Free Shipping",
            firstWeight: null,
            price: 0,
            subsequentWeight: null,
            subsequentPrice: null,
            shippingType: "FreeShipping",
            minimumOrderAmount: null,
          });
          setFreeShipping((state) => {
            state.active = true;
          });
        }
        break;
      default:
        break;
    }
  };

  const getShippingMethodOptions = () => {
    let options = [...shippingOptions];
    if (freeShipping.active) {
      options = options.filter((option) => option.value !== "FreeShipping");
    }
    if (weightBased.active) {
      options = options.filter((option) => option.value !== "WeightBased");
    }
    if (flatRate.active) {
      options = options.filter((option) => option.value !== "FlatRate");
    }
    return options;
  };

  return (
    <>
      <PanelHeader title="3. Shipping methods">
        {groupFields.length > 0 && (
          <Select
            variant="secondary"
            options={getShippingMethodOptions()}
            defaultOption={"Add new shipping method"}
            name="newShippingMethod"
            onChange={handleAddShippingGroup}
            fullWidth
            selectBoxHelperClass={styles.shippingMethodSelectBox}
            selectBoxFontHelper={styles.selectBoxText}
            optionBoxFontHelper={styles.selectBoxText}
          />
        )}
      </PanelHeader>
      {groupFields.length === 0 && (
        <div className={styles.shippingMethodsBody}>
          <div className={styles.shippingHelperText}>
            Click on the button below to add new shipping method
          </div>
          <Select
            variant="secondary"
            options={getShippingMethodOptions()}
            defaultOption={"Add new shipping method"}
            onChange={handleAddShippingGroup}
            selectBoxHelperClass={styles.shippingMethodSelectBox}
            selectBoxFontHelper={styles.selectBoxText}
            optionBoxFontHelper={styles.selectBoxText}
          />
        </div>
      )}

      {freeShipping.active && (
        <div>
          <div className={styles.methodContainer}>
            <div className={styles.leftContainer}>
              <div className={styles.methodHeader}>Free shipping</div>
            </div>
            <div>
              <SixtyButton
                variant="text"
                label="Edit"
                onClick={() =>
                  setFreeShipping((state) => {
                    state.open = true;
                    state.state = {
                      ...groupFields[getIndex("FreeShipping")],
                    };
                  })
                }
              />
              <span className={styles.elipseIcon}>
                <BaseIcon />
              </span>
              <SixtyButton
                variant="text"
                label="Remove"
                onClick={() => {
                  setFreeShipping((state) => ({
                    open: false,
                    active: false,
                  }));
                  let index = _findIndex(shippingGroups, (o) => {
                    return o.shippingType === "FreeShipping";
                  });
                  if (index !== -1) removeGroup(index);
                }}
              />
            </div>
          </div>
          {freeShipping.open && (
            <div className={clsx(styles.subForm, styles.bottomBorder)}>
              <SixtyInput
                value={freeShipping.state?.name}
                onChange={(e) => {
                  setState("FreeShipping", "name", e.target.value);
                }}
                label="Name"
              />
              <SixtyInput
                value={freeShipping.state?.description}
                onChange={(e) => {
                  setState("FreeShipping", "description", e.target.value);
                }}
                label="Description"
                lines={4}
                className={styles.freeShippingDescriptionHelper}
              />
              <SixtyInput
                value={flatRate.state?.price}
                onChange={(e) => {
                  setState(
                    "FreeShipping",
                    "minimumOrderAmount",
                    e.target.value
                  );
                }}
                label="Minimum amount"
                type="number"
                step=".01"
                min="0"
                className={styles.bottomPadding}
              />
              <SixtyButton onClick={() => handleSave("FreeShipping")}>
                Save
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() =>
                  setFreeShipping((state) => ({
                    ...state,
                    open: false,
                  }))
                }
              >
                Cancel
              </SixtyButton>
            </div>
          )}
        </div>
      )}

      {flatRate.active && (
        <div>
          <div className={styles.methodContainer}>
            <div className={styles.leftContainer}>
              <div className={styles.methodHeader}>Flat-rate</div>
            </div>
            <div>
              <SixtyButton
                variant="text"
                label="Edit"
                onClick={() =>
                  setFlatRate((state) => {
                    state.open = true;
                    state.state = {
                      ...groupFields[getIndex("FlatRate")],
                    };
                  })
                }
              />
              <span className={styles.elipseIcon}>
                <BaseIcon />
              </span>
              <SixtyButton
                variant="text"
                label="Remove"
                onClick={() => {
                  setFlatRate((state) => ({
                    open: false,
                    active: false,
                  }));
                  let index = _findIndex(shippingGroups, (o) => {
                    return o.shippingType === "FlatRate";
                  });
                  if (index !== -1) removeGroup(index);
                }}
              />
            </div>
          </div>
          {flatRate.open && (
            <div className={clsx(styles.subForm, styles.bottomBorder)}>
              <SixtyInput
                value={flatRate.state?.name}
                onChange={(e) => {
                  setState("FlatRate", "name", e.target.value);
                }}
                label="Name"
                className={styles.bottomPadding}
              />
              <SixtyInput
                name={`shippingGroup[${_findIndex(shippingGroups, (o) => {
                  return o.shippingType === "FlatRate";
                })}].description`}
                value={flatRate.state?.description}
                onChange={(e) => {
                  setState("FlatRate", "description", e.target.value);
                }}
                label="Description"
                lines={4}
                className={styles.bottomPadding}
              />
              <SixtyInput
                value={flatRate.state?.price}
                onChange={(e) => {
                  setState("FlatRate", "price", e.target.value);
                }}
                label="Rate amount"
                type="number"
                min="0"
                step=".01"
                className={styles.bottomPadding}
              />
              <SixtyButton onClick={() => handleSave("FlatRate")}>
                Save
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() =>
                  setFlatRate((state) => ({
                    ...state,
                    open: false,
                  }))
                }
              >
                Cancel
              </SixtyButton>
            </div>
          )}
        </div>
      )}
      {weightBased.active && (
        <div>
          <div className={styles.methodContainer}>
            <div className={styles.leftContainer}>
              <div className={styles.methodHeader}>Weight-based</div>
            </div>
            <div>
              <SixtyButton
                variant="text"
                label="Edit"
                onClick={() =>
                  setWeightBased((state) => {
                    state.open = true;
                    state.state = {
                      ...groupFields[getIndex("WeightBased")],
                    };
                  })
                }
              />
              <span className={styles.elipseIcon}>
                <BaseIcon />
              </span>
              <SixtyButton
                variant="text"
                label="Remove"
                onClick={() => {
                  setWeightBased((state) => ({
                    open: false,
                    active: false,
                  }));
                  let index = getIndex("WeightBased");
                  if (index !== -1) removeGroup(index);
                }}
              />
            </div>
          </div>
          {weightBased.open && (
            <div className={clsx(styles.subForm, styles.bottomBorder)}>
              <SixtyInput
                label="Name"
                value={weightBased.state?.name}
                onChange={(e) => {
                  setState("WeightBased", "name", e.target.value);
                }}
                className={styles.bottomPadding}
              />
              <SixtyInput
                value={weightBased.state?.description}
                onChange={(e) => {
                  setState("WeightBased", "description", e.target.value);
                }}
                label="Description"
                lines={4}
                className={styles.bottomPadding}
              />
              <div className={styles.weightrow}>
                <div className={styles.fieldContainer}>
                  <div className={styles.mainInputField}>
                    <SixtyInput
                      value={weightBased.state?.firstWeight}
                      onChange={(e) => {
                        setState("WeightBased", "firstWeight", e.target.value);
                      }}
                      type="number"
                      min="0"
                      step=".01"
                      label="Initial weight"
                    />
                  </div>

                  <div className={styles.unitField}>
                    <Select
                      value={weightBased.state?.firstWeightUnit}
                      onChange={(e) => {
                        setState(
                          "WeightBased",
                          "firstWeightUnit",
                          e.target.value
                        );
                      }}
                      options={weightUnitOptions}
                      fullWidth={true}
                      defaultValue={"kg"}
                    />
                  </div>
                </div>
                <div className={styles.fieldContainer}>
                  <SixtyInput
                    value={weightBased.state?.price}
                    onChange={(e) => {
                      setState("WeightBased", "price", e.target.value);
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    label="Initial price"
                    fullWidth={true}
                    className={styles.initialPrice}
                  />
                </div>
              </div>
              <div className={styles.weightrow}>
                <div className={styles.fieldContainer}>
                  <div className={styles.mainInputField}>
                    <SixtyInput
                      value={weightBased.state?.subsequentWeight}
                      onChange={(e) => {
                        setState(
                          "WeightBased",
                          "subsequentWeight",
                          e.target.value
                        );
                      }}
                      label="Subsequence weight"
                      type="number"
                      min="0"
                      step=".01"
                    />
                  </div>

                  <div className={styles.unitField}>
                    <Select
                      name={`shippingGroup[${getIndex(
                        "WeightBased"
                      )}].subsequentWeightUnit`}
                      value={weightBased.state?.subsequentWeightUnit}
                      onChange={(e) => {
                        setState(
                          "WeightBased",
                          "subsequentWeightUnit",
                          e.target.value
                        );
                      }}
                      options={weightUnitOptions}
                      fullWidth={true}
                      defaultValue={"kg"}
                    />
                  </div>
                </div>
                <div className={styles.fieldContainer}>
                  <SixtyInput
                    value={weightBased.state?.subsequentPrice}
                    onChange={(e) => {
                      setState(
                        "WeightBased",
                        "subsequentPrice",
                        e.target.value
                      );
                    }}
                    label="Subsequent price"
                    type="number"
                    min="0"
                    step=".01"
                    fullWidth={true}
                    className={styles.initialPrice}
                  />
                </div>
              </div>
              <SixtyButton onClick={() => handleSave("WeightBased")}>
                Save
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() =>
                  setWeightBased((draft) => {
                    draft.open = false;
                    draft.state = groupFields[getIndex("WeightBased")];
                  })
                } // reset local changes
              >
                Cancel
              </SixtyButton>
            </div>
          )}
        </div>
      )}
    </>
  );
}
