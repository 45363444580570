// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalSalesPageHeader_header__jnNAU {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 8px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
}

.TotalSalesPageHeader_titleBox__wyZpG {
    display: flex;
    align-items: center;
}

.TotalSalesPageHeader_titleContent__0859g {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
    padding-left: 16px;
}

.TotalSalesPageHeader_ButtonsContainer__douNA {
    display: flex;
    align-items: center;
}

.TotalSalesPageHeader_ButtonsContainer__douNA > div {
    margin: 4px;
}

.TotalSalesPageHeader_daysFilterHelper__ic7Lz span:first-child {
    margin-right: 7px;
    margin-top: -2px;
}

.TotalSalesPageHeader_daysFilterHelper__ic7Lz {
    border-radius: 0px;
    padding: 8px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Reports/components/TotalSalesDetails/components/TotalSalesPageHeader/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".header {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0px 8px;\n    justify-content: space-between;\n    align-items: center;\n    padding-bottom: 4px;\n}\n\n.titleBox {\n    display: flex;\n    align-items: center;\n}\n\n.titleContent {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n    padding-left: 16px;\n}\n\n.ButtonsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.ButtonsContainer > div {\n    margin: 4px;\n}\n\n.daysFilterHelper span:first-child {\n    margin-right: 7px;\n    margin-top: -2px;\n}\n\n.daysFilterHelper {\n    border-radius: 0px;\n    padding: 8px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `TotalSalesPageHeader_header__jnNAU`,
	"titleBox": `TotalSalesPageHeader_titleBox__wyZpG`,
	"titleContent": `TotalSalesPageHeader_titleContent__0859g`,
	"ButtonsContainer": `TotalSalesPageHeader_ButtonsContainer__douNA`,
	"daysFilterHelper": `TotalSalesPageHeader_daysFilterHelper__ic7Lz`
};
export default ___CSS_LOADER_EXPORT___;
