// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileActionsProgress_profileActionContainer__eRzYa {
    display: flex;
    border-bottom: 1px solid var(--black10Transparent);
    height: 56px;
    padding: 12px;
    align-items: center;
    font-family: var(--regular400);
    font-size: 14px;
    min-height: 85px;
    cursor: pointer;
}

.ProfileActionsProgress_secondColumn__z4yWQ {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
}

.ProfileActionsProgress_title__UfNFc {
    font-family: var(--medium500);
    font-size: 14px;
    color: var(--black);
    padding-bottom: 3px;
}

.ProfileActionsProgress_description__nrmsS{
    font-family: var(--regular400);
    font-size: 14px;
    color: var(--black50Transparent);
}

.ProfileActionsProgress_CheckIcon__gGZZV {
    margin: 0px;
}

.ProfileActionsProgress_firstColumn__jlzdn {
    font-family: var(--regular400);
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.ProfileActionsProgress_rightArrow__kQRCy svg{
    color: var(--black25Transparent);
    transform: rotate(-90deg);
}`, "",{"version":3,"sources":["webpack://./src/screens/Dashboard/components/ProfileActionsProgress/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kDAAkD;IAClD,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,gCAAgC;IAChC,yBAAyB;AAC7B","sourcesContent":[".profileActionContainer {\n    display: flex;\n    border-bottom: 1px solid var(--black10Transparent);\n    height: 56px;\n    padding: 12px;\n    align-items: center;\n    font-family: var(--regular400);\n    font-size: 14px;\n    min-height: 85px;\n    cursor: pointer;\n}\n\n.secondColumn {\n    display: flex;\n    flex-direction: column;\n    padding-right: 16px;\n}\n\n.title {\n    font-family: var(--medium500);\n    font-size: 14px;\n    color: var(--black);\n    padding-bottom: 3px;\n}\n\n.description{\n    font-family: var(--regular400);\n    font-size: 14px;\n    color: var(--black50Transparent);\n}\n\n.CheckIcon {\n    margin: 0px;\n}\n\n.firstColumn {\n    font-family: var(--regular400);\n    font-size: 14px;\n    display: flex;\n    align-items: flex-start;\n    flex-direction: column;\n}\n\n.rightArrow svg{\n    color: var(--black25Transparent);\n    transform: rotate(-90deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileActionContainer": `ProfileActionsProgress_profileActionContainer__eRzYa`,
	"secondColumn": `ProfileActionsProgress_secondColumn__z4yWQ`,
	"title": `ProfileActionsProgress_title__UfNFc`,
	"description": `ProfileActionsProgress_description__nrmsS`,
	"CheckIcon": `ProfileActionsProgress_CheckIcon__gGZZV`,
	"firstColumn": `ProfileActionsProgress_firstColumn__jlzdn`,
	"rightArrow": `ProfileActionsProgress_rightArrow__kQRCy`
};
export default ___CSS_LOADER_EXPORT___;
