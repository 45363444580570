// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SellerGlobalCommission_sellerGlobalCommission__rKixA {
    width: calc(100vw - 250px);
    padding: 24px;
}

.SellerGlobalCommission_panelWrap__IvPCk {
    margin: 16px 16px 16px 0px;
    display: block;
    min-height: unset;
    position: relative;
}

.SellerGlobalCommission_subHeader__4r1Oa {
    font-family: var(--regular400);
    font-size: 14px;
    color: var(--black50Transparent);
    padding: 16px;
    border-bottom: 1px solid var(--black10Transparent);
}
.SellerGlobalCommission_formRow__cpXg3 {
    display: flex;
    margin: 16px;
    justify-content: space-between;
  }
  
  .SellerGlobalCommission_formRow__cpXg3 > * {
    width: calc(50% - 8px);
  }

  .SellerGlobalCommission_formRow__cpXg3 .SellerGlobalCommission_businessEmailRow__2xVWk {
    display: flex;
    align-items: center;
    width: 100%;
  }

.SellerGlobalCommission_formSectionHeader__kMqU\\+ {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
    padding: 16px 16px 0px 16px;
}

.SellerGlobalCommission_phoneNumber__9UtUQ {
    width: 100%;
}

.SellerGlobalCommission_footer__S7NNO {
    width: 100vw;
    position: fixed;
    padding: 16px;
    bottom: 0;
    background: var(--white);
    padding-left: 250px;
    left: 0;
    border-top: 1px solid var(--black10);
    z-index: 100;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Settings/SellerGlobalCommission/index.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;IAChC,aAAa;IACb,kDAAkD;AACtD;AACA;IACI,aAAa;IACb,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;EACb;;AAEF;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,SAAS;IACT,wBAAwB;IACxB,mBAAmB;IACnB,OAAO;IACP,oCAAoC;IACpC,YAAY;EACd","sourcesContent":[".sellerGlobalCommission {\n    width: calc(100vw - 250px);\n    padding: 24px;\n}\n\n.panelWrap {\n    margin: 16px 16px 16px 0px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.subHeader {\n    font-family: var(--regular400);\n    font-size: 14px;\n    color: var(--black50Transparent);\n    padding: 16px;\n    border-bottom: 1px solid var(--black10Transparent);\n}\n.formRow {\n    display: flex;\n    margin: 16px;\n    justify-content: space-between;\n  }\n  \n  .formRow > * {\n    width: calc(50% - 8px);\n  }\n\n  .formRow .businessEmailRow {\n    display: flex;\n    align-items: center;\n    width: 100%;\n  }\n\n.formSectionHeader {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n    padding: 16px 16px 0px 16px;\n}\n\n.phoneNumber {\n    width: 100%;\n}\n\n.footer {\n    width: 100vw;\n    position: fixed;\n    padding: 16px;\n    bottom: 0;\n    background: var(--white);\n    padding-left: 250px;\n    left: 0;\n    border-top: 1px solid var(--black10);\n    z-index: 100;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sellerGlobalCommission": `SellerGlobalCommission_sellerGlobalCommission__rKixA`,
	"panelWrap": `SellerGlobalCommission_panelWrap__IvPCk`,
	"subHeader": `SellerGlobalCommission_subHeader__4r1Oa`,
	"formRow": `SellerGlobalCommission_formRow__cpXg3`,
	"businessEmailRow": `SellerGlobalCommission_businessEmailRow__2xVWk`,
	"formSectionHeader": `SellerGlobalCommission_formSectionHeader__kMqU+`,
	"phoneNumber": `SellerGlobalCommission_phoneNumber__9UtUQ`,
	"footer": `SellerGlobalCommission_footer__S7NNO`
};
export default ___CSS_LOADER_EXPORT___;
