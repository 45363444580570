import React from "react";
import { Controller } from "react-hook-form";
import { TextField, Box, Chip, Button } from "@mui/material";
import { pink } from "@mui/material/colors";


export default function ControlledTextArea({
  control,
  name,
  handleChange,
  label,
  rules,
  error,
  defaultValue = "",
  suggestions = [],
  ...props
}) {
  const handleSuggestionClick = (suggestion, onChange, currentValue) => {
    const newValue = currentValue ? `${currentValue} ${suggestion}` : suggestion;
    onChange(newValue);
    handleChange && handleChange(name, newValue);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => {
        const { onChange, value, ref } = field;
        const { error } = fieldState;

        return (
          <>
            <TextField
              {...field}
              inputRef={ref}
              label={label}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              error={!!error}
              helperText={error?.message}
              onChange={(e) => {
                onChange(e);
                handleChange && handleChange(name, e.target.value);
              }}
              {...props}
            />
            {suggestions.length > 0 && (
              <Box sx={{ paddingTop: '24px', paddingBottom: '24px', display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {suggestions.map((suggestion, index) => (
                  <Chip
                    key={index}
                    label={suggestion}
                    onClick={() => handleSuggestionClick(suggestion, onChange, value)}              
                    variant="outlined"
                    size="small"
                  />
                ))}
              </Box>
            )}
          </>
        );
      }}
    />
  );
}
