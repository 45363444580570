// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_sideNav__KvXVr {
    height: 100%;
    width: 265px;
    z-index: 1;
    overflow-x: hidden;
    padding-top: 20px;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,iBAAiB;EACnB","sourcesContent":[".sideNav {\n    height: 100%;\n    width: 265px;\n    z-index: 1;\n    overflow-x: hidden;\n    padding-top: 20px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideNav": `Navigation_sideNav__KvXVr`
};
export default ___CSS_LOADER_EXPORT___;
