import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { queryClient } from "common/query";
import { setToast } from "components/Toast";
import { saveAs } from "file-saver";


export function useGetBrandProductRequests(filter) {
  return useQuery([Endpoints.Product.GetBrandProductRequests, filter], () =>
    api
      .post(Endpoints.Product.GetBrandProductRequests, filter)
      .then((response) => response?.data)
  );
}

export function useApproveProductRequest() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Product.ApproveProductRequest + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useApproveProductRequest: useMutation: onError: error", error);
      },
    }
  );
}

export function useRejectProductRequest() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Product.RejectProductRequest + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useRejectProductRequest: useMutation: onError: error", error);
      },
    }
  );
}

export function useSetupProductSessionShipment() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Product.SetupProductSessionShipment, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useSetupProductSessionShipment: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetProductSessionDetail(id) {
  return useQuery(
    [Endpoints.Product.GetProductSessionDetail, id],
    () =>
      api
        .get(Endpoints.Product.GetProductSessionDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetProductSessionDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCategories(filter) {
  return useQuery(
    [Endpoints.Product.GetCategories, filter],
    () =>
      api
        .post(Endpoints.Product.GetCategories, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetCategories: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCategoryDropdown(options) {
  return useQuery(
    Endpoints.Product.GetCategoryDropdown,
    () =>
      api
        .get(Endpoints.Product.GetCategoryDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCategoryDropdown: useQuery: onError: error", error);
      },
      ...options,
    }
  );
}

export function useGetCategoryDetail(id) {
  return useQuery(
    [Endpoints.Product.GetCategoryDetail, id],
    () =>
      api
        .get(Endpoints.Product.GetCategoryDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCategoryDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useAddCategory() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Product.AddCategory, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAddCategory: useMutation: onError: error", error);
      },
    }
  );
}

export function useUpdateCategory(data) {
  return useMutation(
    () =>
      api
        .put(Endpoints.Product.UpdateCategory, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateCategory: useMutation: onError: error", error);
      },
    }
  );
}

export function useDeleteCategory() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Product.DeleteCategory + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeleteCategory: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetPriceTiers(filter) {
  return useQuery(
    [Endpoints.Product.GetPriceTiers, filter],
    () =>
      api
        .post(Endpoints.Product.GetPriceTiers, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetPriceTiers: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetPriceTierDetail(id) {
  return useQuery(
    [Endpoints.Product.GetPriceTierDetail, id],
    () =>
      api
        .get(Endpoints.Product.GetPriceTierDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetPriceTierDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetPriceTierDropdown(options) {
  return useQuery(
    Endpoints.Product.GetPriceTierDropdown,
    () =>
      api
        .get(Endpoints.Product.GetPriceTierDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetPriceTierDropdown: useQuery: onError: error", error);
      },
      ...options,
    }
  );
}

export function useAddPriceTier() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Product.AddPriceTier, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Product.GetPriceTiers);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAddPriceTier: useMutation: onError: error", error);
      },
    }
  );
}

export function useUpdatePriceTier() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Product.UpdatePriceTier, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdatePriceTier: useMutation: onError: error", error);
      },
    }
  );
}

export function useDeletePriceTier() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Product.DeletePriceTier + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeletePriceTier: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetTaxGroups(filter) {
  return useQuery([Endpoints.Product.GetTaxGroups, filter], () =>
    api
      .post(Endpoints.Product.GetTaxGroups, filter)
      .then((response) => response?.data?.result)
  );
}

export function useGetTaxGroupDetail(id) {
  return useQuery([Endpoints.Product.GetTaxGroupDetail, id], () =>
    api
      .get(Endpoints.Product.GetTaxGroupDetail + `/${id}`)
      .then((response) => response?.data?.result)
  );
}

export function useGetTaxGroupDropdown() {
  return useQuery(Endpoints.Product.GetTaxGroupDropdown, () =>
    api
      .get(Endpoints.Product.GetTaxGroupDropdown)
      .then((response) => response?.data?.result)
  );
}

export function useAddTaxGroup() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Product.AddTaxGroup, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([Endpoints.Product.GetTaxGroups, {}]);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAddTaxGroup: useMutation: onError: error", error);
      },
    }
  );
}

export function useUpdateTaxGroup() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Product.UpdateTaxGroup, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateTaxGroup: useMutation: onError: error", error);
      },
    }
  );
}

export function useDeleteTaxGroup() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Product.DeleteTaxGroup + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeleteTaxGroup: useMutation: onError: error", error);
      },
    }
  );
}

export function useAddTax() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Product.AddTax, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAddTax: useMutation: onError: error", error);
      },
    }
  );
}

export function useUpdateTax() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Product.UpdateTax, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateTax: useMutation: onError: error", error);
      },
    }
  );
}

export function useDeleteTax() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Product.DeleteTax + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeleteTax: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetProducts(filter) {
  return useQuery([Endpoints.Product.GetProducts, filter], () =>
    api
      .post(Endpoints.Product.GetProducts, filter)
      .then((response) => response?.data)
  );
}

export function useAddProducts() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Product.AddProducts, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAddProducts: useMutation: onError: error", error);
      },
    }
  );
}

export function useUpdateProducts() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Product.UpdateProducts, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (_, data) => {
        queryClient.invalidateQueries([
          Endpoints.Product.GetProductDetail,
          data.id,
        ]);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateProducts: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetProductDetail(id) {
  return useQuery(
    [Endpoints.Product.GetProductDetail, id],
    () =>
      api
        .get(Endpoints.Product.GetProductDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      staleTime: Infinity,
    }
  );
}

export function useGetProductDropdown(data) {
  return useQuery([Endpoints.Product.GetProductDropdown, data], () =>
    api
      .post(Endpoints.Product.GetProductDropdown, data)
      .then((response) => response?.data?.result)
  );
}

export function useDeleteProduct() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Product.DeleteProduct + `/${id}`)
        .then((response) => response?.data?.success),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeleteProduct: useMutation: onError: error", error);
      },
    }
  );
}

export function useAchieveProduct() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Product.AchieveProduct + `/${id}`)
        .then((response) => response?.data?.success),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAchieveProduct: useMutation: onError: error", error);
      },
    }
  );
}

export function useActivateProduct() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Product.ActivateProduct + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useActivateProduct: useMutation: onError: error", error);
      },
    }
  );
}

export function useDeactivateProduct() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Product.DeativateProduct + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeactivateProduct: useMutation: onError: error", error);
      },
    }
  );
}

export function useExportProducts() {
  return useMutation(
    (filter) =>
      api
        .post(Endpoints.Product.ExportProducts, filter, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.xlsx");
        }),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useExportProducts: useMutation: onError: error", error);
      },
    }
  );
}

export function useImportLazadaProducts() {
  return useMutation(
    (files) =>
      api
        .post(Endpoints.Product.ImportLazadaProducts, files, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useImportLazadaProducts: useMutation: onError: error", error);
      },
    }
  );
}

export function useImportProducts() {
  return useMutation(
    (files) =>
      api
        .post(Endpoints.Product.ImportProducts, files, {
          responseType: "blob",
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.xlsx");
        }),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useImportProducts: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetStateDropdown() {
  return useQuery(
    Endpoints.Product.GetStateDropdown,
    () =>
      api
        .get(Endpoints.Product.GetStateDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetStateDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetShippingGroupDropdown() {
  return useQuery(
    Endpoints.Product.GetShippingGroupDropdown,
    () =>
      api
        .get(Endpoints.Product.GetShippingGroupDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetShippingGroupDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetAmountMeasurementDropdown() {
  return useQuery(
    Endpoints.Product.GetAmountMeasurementDropdown,
    () =>
      api
        .get(Endpoints.Product.GetAmountMeasurementDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetAmountMeasurementDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetDiscountStatusDropdown() {
  return useQuery(
    Endpoints.Product.GetDiscountStatusDropdown,
    () =>
      api
        .get(Endpoints.Product.GetDiscountStatusDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetDiscountStatusDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetDiscountUsageLimitConditionDropdown() {
  return useQuery(
    Endpoints.Product.GetDiscountUsageLimitConditionDropdown,
    () =>
      api
        .get(Endpoints.Product.GetDiscountUsageLimitConditionDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetDiscountUsageLimitConditionDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetDiscountLimitPerCustomerConditionDropdown() {
  return useQuery(
    Endpoints.Product.GetDiscountLimitPerCustomerConditionDropdown,
    () =>
      api
        .get(Endpoints.Product.GetDiscountLimitPerCustomerConditionDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetDiscountLimitPerCustomerConditionDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetDiscountOrderConditionDropdown() {
  return useQuery(
    Endpoints.Product.GetDiscountOrderConditionDropdown,
    () =>
      api
        .get(Endpoints.Product.GetDiscountOrderConditionDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetDiscountOrderConditionDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetShippingTypeDropdown() {
  return useQuery(
    Endpoints.Product.GetShippingTypeDropdown,
    () =>
      api
        .get(Endpoints.Product.GetShippingTypeDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetShippingTypeDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetProductStatusDropdown() {
  return useQuery(
    Endpoints.Product.GetProductStatusDropdown,
    () =>
      api
        .get(Endpoints.Product.GetProductStatusDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetProductStatusDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}
