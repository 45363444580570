import { ReactComponent as ProductIcon } from "assets/icons/productSample.svg";

const sellerRequestData = [];

const sellerTiers = [
  {
    "id": "1",
    "name": "felipa.org",
    "defaultDiscounts": 60507,
    "noOfSellers": 76078,
    "createdDate": "2020-07-10T18:22:07.001Z"
  },
  {
    "id": "2",
    "name": "linwood.name",
    "defaultDiscounts": 72285,
    "noOfSellers": 66063,
    "createdDate": "2020-03-06T16:44:24.731Z"
  },
  {
    "id": "3",
    "name": "gennaro.net",
    "defaultDiscounts": 75934,
    "noOfSellers": 81813,
    "createdDate": "2020-07-14T20:21:41.961Z"
  },
  {
    "id": "4",
    "name": "eliezer.name",
    "defaultDiscounts": 69197,
    "noOfSellers": 368,
    "createdDate": "2020-02-12T20:04:14.365Z"
  },
  {
    "id": "5",
    "name": "ezra.net",
    "defaultDiscounts": 41354,
    "noOfSellers": 24947,
    "createdDate": "2020-11-15T15:09:42.134Z"
  },
  {
    "id": "6",
    "name": "elinore.biz",
    "defaultDiscounts": 15777,
    "noOfSellers": 92289,
    "createdDate": "2020-06-18T03:28:52.029Z"
  },
  {
    "id": "7",
    "name": "aniya.net",
    "defaultDiscounts": 13720,
    "noOfSellers": 7699,
    "createdDate": "2020-07-20T22:56:42.183Z"
  },
  {
    "id": "8",
    "name": "karson.name",
    "defaultDiscounts": 67025,
    "noOfSellers": 756,
    "createdDate": "2020-07-02T11:08:16.018Z"
  },
  {
    "id": "9",
    "name": "iliana.name",
    "defaultDiscounts": 69649,
    "noOfSellers": 24153,
    "createdDate": "2021-01-15T16:20:49.280Z"
  },
  {
    "id": "10",
    "name": "kristy.net",
    "defaultDiscounts": 23781,
    "noOfSellers": 54623,
    "createdDate": "2020-08-08T06:48:08.823Z"
  },
  {
    "id": "11",
    "name": "isadore.org",
    "defaultDiscounts": 73902,
    "noOfSellers": 16325,
    "createdDate": "2020-09-23T00:07:29.164Z"
  },
  {
    "id": "12",
    "name": "joy.name",
    "defaultDiscounts": 12612,
    "noOfSellers": 64684,
    "createdDate": "2020-04-25T12:46:50.300Z"
  },
  {
    "id": "13",
    "name": "claude.net",
    "defaultDiscounts": 41649,
    "noOfSellers": 38073,
    "createdDate": "2020-11-23T19:30:24.964Z"
  },
  {
    "id": "14",
    "name": "april.net",
    "defaultDiscounts": 36687,
    "noOfSellers": 21583,
    "createdDate": "2020-02-02T11:15:52.306Z"
  },
  {
    "id": "15",
    "name": "hans.net",
    "defaultDiscounts": 51838,
    "noOfSellers": 90836,
    "createdDate": "2020-03-10T17:08:15.440Z"
  },
  {
    "id": "16",
    "name": "vickie.net",
    "defaultDiscounts": 35611,
    "noOfSellers": 73955,
    "createdDate": "2020-04-16T14:22:32.712Z"
  },
  {
    "id": "17",
    "name": "lennie.com",
    "defaultDiscounts": 136,
    "noOfSellers": 16946,
    "createdDate": "2020-12-16T05:03:06.026Z"
  },
  {
    "id": "18",
    "name": "etha.name",
    "defaultDiscounts": 23290,
    "noOfSellers": 61001,
    "createdDate": "2020-06-29T22:18:20.606Z"
  },
  {
    "id": "19",
    "name": "justice.biz",
    "defaultDiscounts": 9213,
    "noOfSellers": 48207,
    "createdDate": "2020-12-12T23:35:19.794Z"
  },
  {
    "id": "20",
    "name": "peyton.name",
    "defaultDiscounts": 63514,
    "noOfSellers": 44533,
    "createdDate": "2021-01-08T12:20:08.910Z"
  },
  {
    "id": "21",
    "name": "tamia.net",
    "defaultDiscounts": 10924,
    "noOfSellers": 57406,
    "createdDate": "2020-09-18T00:04:02.078Z"
  },
  {
    "id": "22",
    "name": "remington.com",
    "defaultDiscounts": 64201,
    "noOfSellers": 44680,
    "createdDate": "2020-08-29T10:33:23.697Z"
  },
  {
    "id": "23",
    "name": "mellie.net",
    "defaultDiscounts": 6805,
    "noOfSellers": 12158,
    "createdDate": "2020-08-05T14:30:11.764Z"
  },
  {
    "id": "24",
    "name": "susan.info",
    "defaultDiscounts": 91816,
    "noOfSellers": 17531,
    "createdDate": "2020-08-30T07:03:43.454Z"
  },
  {
    "id": "25",
    "name": "agustina.org",
    "defaultDiscounts": 55885,
    "noOfSellers": 83580,
    "createdDate": "2020-07-13T20:54:03.024Z"
  },
  {
    "id": "26",
    "name": "taurean.info",
    "defaultDiscounts": 46874,
    "noOfSellers": 64619,
    "createdDate": "2020-09-03T10:16:45.351Z"
  },
  {
    "id": "27",
    "name": "eulah.com",
    "defaultDiscounts": 26204,
    "noOfSellers": 32343,
    "createdDate": "2020-11-30T07:12:37.077Z"
  },
  {
    "id": "28",
    "name": "colleen.biz",
    "defaultDiscounts": 96514,
    "noOfSellers": 7476,
    "createdDate": "2020-05-21T18:08:28.545Z"
  },
  {
    "id": "29",
    "name": "dwight.info",
    "defaultDiscounts": 68576,
    "noOfSellers": 9425,
    "createdDate": "2020-03-15T12:36:35.602Z"
  },
  {
    "id": "30",
    "name": "golden.com",
    "defaultDiscounts": 37909,
    "noOfSellers": 30917,
    "createdDate": "2020-10-27T13:22:36.989Z"
  },
  {
    "id": "31",
    "name": "chester.org",
    "defaultDiscounts": 2378,
    "noOfSellers": 49568,
    "createdDate": "2020-06-09T01:13:34.459Z"
  },
  {
    "id": "32",
    "name": "davion.net",
    "defaultDiscounts": 24016,
    "noOfSellers": 32980,
    "createdDate": "2020-08-05T13:17:15.797Z"
  },
  {
    "id": "33",
    "name": "leonor.net",
    "defaultDiscounts": 82208,
    "noOfSellers": 96245,
    "createdDate": "2020-01-25T03:47:37.701Z"
  },
  {
    "id": "34",
    "name": "jessyca.net",
    "defaultDiscounts": 94618,
    "noOfSellers": 78860,
    "createdDate": "2020-03-22T04:28:05.841Z"
  },
  {
    "id": "35",
    "name": "rose.biz",
    "defaultDiscounts": 39102,
    "noOfSellers": 57254,
    "createdDate": "2020-02-25T05:43:07.656Z"
  },
  {
    "id": "36",
    "name": "aiyana.com",
    "defaultDiscounts": 21961,
    "noOfSellers": 56993,
    "createdDate": "2020-12-19T21:12:57.627Z"
  },
  {
    "id": "37",
    "name": "loy.org",
    "defaultDiscounts": 59239,
    "noOfSellers": 55496,
    "createdDate": "2020-09-24T21:59:35.345Z"
  },
  {
    "id": "38",
    "name": "ines.com",
    "defaultDiscounts": 32071,
    "noOfSellers": 57812,
    "createdDate": "2020-03-27T10:54:54.991Z"
  },
  {
    "id": "39",
    "name": "lauretta.com",
    "defaultDiscounts": 89871,
    "noOfSellers": 10084,
    "createdDate": "2020-06-20T12:54:15.801Z"
  },
  {
    "id": "40",
    "name": "maurine.net",
    "defaultDiscounts": 34945,
    "noOfSellers": 73615,
    "createdDate": "2020-07-15T07:57:40.386Z"
  },
  {
    "id": "41",
    "name": "alexander.biz",
    "defaultDiscounts": 4691,
    "noOfSellers": 72533,
    "createdDate": "2020-03-18T16:13:30.398Z"
  },
  {
    "id": "42",
    "name": "zane.net",
    "defaultDiscounts": 44284,
    "noOfSellers": 78898,
    "createdDate": "2020-04-09T14:14:59.763Z"
  },
  {
    "id": "43",
    "name": "walter.org",
    "defaultDiscounts": 81228,
    "noOfSellers": 49606,
    "createdDate": "2020-03-09T08:10:16.946Z"
  },
  {
    "id": "44",
    "name": "ines.net",
    "defaultDiscounts": 76799,
    "noOfSellers": 20521,
    "createdDate": "2020-05-03T20:21:19.615Z"
  },
  {
    "id": "45",
    "name": "quentin.net",
    "defaultDiscounts": 3344,
    "noOfSellers": 55436,
    "createdDate": "2020-12-12T16:44:05.783Z"
  },
  {
    "id": "46",
    "name": "wilmer.org",
    "defaultDiscounts": 609,
    "noOfSellers": 62903,
    "createdDate": "2020-07-05T14:07:34.076Z"
  },
  {
    "id": "47",
    "name": "mose.com",
    "defaultDiscounts": 88100,
    "noOfSellers": 52190,
    "createdDate": "2020-03-11T17:52:40.376Z"
  },
  {
    "id": "48",
    "name": "leilani.com",
    "defaultDiscounts": 22347,
    "noOfSellers": 11032,
    "createdDate": "2020-05-25T20:00:57.843Z"
  },
  {
    "id": "49",
    "name": "moises.com",
    "defaultDiscounts": 35873,
    "noOfSellers": 51265,
    "createdDate": "2020-06-13T22:28:09.494Z"
  },
  {
    "id": "50",
    "name": "dave.biz",
    "defaultDiscounts": 62477,
    "noOfSellers": 47614,
    "createdDate": "2020-06-26T12:26:55.379Z"
  },
  {
    "id": "51",
    "name": "brody.biz",
    "defaultDiscounts": 36495,
    "noOfSellers": 29751,
    "createdDate": "2020-04-29T18:40:38.537Z"
  },
  {
    "id": "52",
    "name": "richie.name",
    "defaultDiscounts": 93039,
    "noOfSellers": 45239,
    "createdDate": "2020-11-03T13:52:49.613Z"
  }
];

const customersData = [
  {
    "id": "1",
    "name": "Mackenzie Raynor",
    "email": "Xavier97@gmail.com",
    "mobile": "(326) 324-2345 x33151",
    "successfultransactionCount": 90268
  },
  {
    "id": "2",
    "name": "Charlie Bashirian",
    "email": "Leone.Gleichner@gmail.com",
    "mobile": "725-853-5895 x42024",
    "successfultransactionCount": 74187
  },
  {
    "id": "3",
    "name": "Madeline Wiegand",
    "email": "Andy.Lubowitz@gmail.com",
    "mobile": "(553) 745-6282 x8692",
    "successfultransactionCount": 45316
  },
  {
    "id": "4",
    "name": "Anita Schmidt Sr.",
    "email": "Chad_Blanda55@hotmail.com",
    "mobile": "1-846-028-4575",
    "successfultransactionCount": 54032
  },
  {
    "id": "5",
    "name": "Neal Kling",
    "email": "Emmitt73@gmail.com",
    "mobile": "1-740-980-0791 x015",
    "successfultransactionCount": 85716
  },
  {
    "id": "6",
    "name": "Yvette Lind",
    "email": "Valentina49@yahoo.com",
    "mobile": "328-012-9918 x34423",
    "successfultransactionCount": 10025
  },
  {
    "id": "7",
    "name": "Aracely Hodkiewicz",
    "email": "Lester90@hotmail.com",
    "mobile": "434.856.1759",
    "successfultransactionCount": 14393
  },
  {
    "id": "8",
    "name": "Forrest Casper",
    "email": "Viviane_Wiza43@gmail.com",
    "mobile": "(134) 771-8522",
    "successfultransactionCount": 63957
  },
  {
    "id": "9",
    "name": "Marquis Nienow",
    "email": "Trenton21@yahoo.com",
    "mobile": "(286) 622-4954 x934",
    "successfultransactionCount": 51021
  },
  {
    "id": "10",
    "name": "Dr. Otis Kohler",
    "email": "Alexie.Purdy36@gmail.com",
    "mobile": "646-284-3701 x676",
    "successfultransactionCount": 80643
  },
  {
    "id": "11",
    "name": "Duncan Mohr Sr.",
    "email": "Kristofer.Moen@gmail.com",
    "mobile": "(722) 766-9634",
    "successfultransactionCount": 69199
  },
  {
    "id": "12",
    "name": "Kylee Funk DDS",
    "email": "Stewart82@gmail.com",
    "mobile": "1-478-188-6375",
    "successfultransactionCount": 19356
  },
  {
    "id": "13",
    "name": "Georgianna Heller",
    "email": "Annie_Predovic@yahoo.com",
    "mobile": "1-550-686-8100",
    "successfultransactionCount": 83615
  },
  {
    "id": "14",
    "name": "Leann Cummerata",
    "email": "Sonia.Stehr@yahoo.com",
    "mobile": "271-019-1428 x587",
    "successfultransactionCount": 58337
  },
  {
    "id": "15",
    "name": "Ivah MacGyver",
    "email": "Marlen.Watsica27@gmail.com",
    "mobile": "1-728-419-9635 x2383",
    "successfultransactionCount": 81583
  },
  {
    "id": "16",
    "name": "Grace Schmeler",
    "email": "Alanna_Sanford22@gmail.com",
    "mobile": "391-927-3726 x2209",
    "successfultransactionCount": 40949
  },
  {
    "id": "17",
    "name": "Makenzie Ward",
    "email": "Dana62@gmail.com",
    "mobile": "944.396.6948 x2869",
    "successfultransactionCount": 89423
  },
  {
    "id": "18",
    "name": "Susana Konopelski DVM",
    "email": "Jamar87@hotmail.com",
    "mobile": "332-764-5865",
    "successfultransactionCount": 73020
  },
  {
    "id": "19",
    "name": "Frankie Kihn",
    "email": "Brenda47@hotmail.com",
    "mobile": "202.018.2139 x895",
    "successfultransactionCount": 9331
  },
  {
    "id": "20",
    "name": "Chandler Jast DVM",
    "email": "Clifford.Mayer@yahoo.com",
    "mobile": "(239) 618-8072 x2084",
    "successfultransactionCount": 94177
  },
  {
    "id": "21",
    "name": "Armani Waters",
    "email": "Randal.Durgan13@gmail.com",
    "mobile": "278.792.0309",
    "successfultransactionCount": 99716
  },
  {
    "id": "22",
    "name": "Cornelius Stroman",
    "email": "Bradley47@hotmail.com",
    "mobile": "1-054-606-9160 x73747",
    "successfultransactionCount": 5948
  },
  {
    "id": "23",
    "name": "Rafaela Prosacco V",
    "email": "Cecile_Mitchell67@yahoo.com",
    "mobile": "1-139-857-3421 x51618",
    "successfultransactionCount": 3596
  },
  {
    "id": "24",
    "name": "Alayna Hills",
    "email": "Jackson_Nikolaus@gmail.com",
    "mobile": "089-582-7148",
    "successfultransactionCount": 62514
  },
  {
    "id": "25",
    "name": "Bryce Ziemann DDS",
    "email": "Favian.Greenholt@yahoo.com",
    "mobile": "431-128-6092 x7968",
    "successfultransactionCount": 82849
  },
  {
    "id": "26",
    "name": "Rosina Gorczany",
    "email": "Selena96@hotmail.com",
    "mobile": "1-763-306-9595 x16837",
    "successfultransactionCount": 34908
  },
  {
    "id": "27",
    "name": "Else Price",
    "email": "Donny_Schowalter@gmail.com",
    "mobile": "833-707-5800",
    "successfultransactionCount": 6300
  },
  {
    "id": "28",
    "name": "Whitney Harris",
    "email": "Easter_Schamberger@hotmail.com",
    "mobile": "680.666.0642",
    "successfultransactionCount": 78790
  },
  {
    "id": "29",
    "name": "Chance Sipes",
    "email": "Deon27@yahoo.com",
    "mobile": "(264) 869-1051 x9529",
    "successfultransactionCount": 45922
  },
  {
    "id": "30",
    "name": "Axel Howell",
    "email": "Emmanuel.Kertzmann@hotmail.com",
    "mobile": "1-133-993-8020",
    "successfultransactionCount": 64317
  },
  {
    "id": "31",
    "name": "Toney Gleason",
    "email": "Hubert.Bashirian@gmail.com",
    "mobile": "1-808-949-7004",
    "successfultransactionCount": 10304
  },
  {
    "id": "32",
    "name": "Everardo Bartoletti",
    "email": "Elissa.Pfannerstill@gmail.com",
    "mobile": "(745) 575-2294 x23296",
    "successfultransactionCount": 60600
  },
  {
    "id": "33",
    "name": "Dina Hermiston",
    "email": "Bernita_Abernathy92@gmail.com",
    "mobile": "(675) 452-8777 x61287",
    "successfultransactionCount": 6661
  },
  {
    "id": "34",
    "name": "Raphaelle Wuckert",
    "email": "Abdullah80@hotmail.com",
    "mobile": "330.333.8696",
    "successfultransactionCount": 3345
  },
  {
    "id": "35",
    "name": "Ron Abernathy",
    "email": "Anastacio36@hotmail.com",
    "mobile": "1-657-797-2652 x5181",
    "successfultransactionCount": 26215
  },
  {
    "id": "36",
    "name": "Dr. Hadley Ward",
    "email": "Ruben.Feil@hotmail.com",
    "mobile": "743.119.7478",
    "successfultransactionCount": 69770
  },
  {
    "id": "37",
    "name": "Ashton Konopelski",
    "email": "Sylvan_Carroll@yahoo.com",
    "mobile": "(304) 852-6424 x6869",
    "successfultransactionCount": 12776
  },
  {
    "id": "38",
    "name": "Lilla Farrell",
    "email": "Judson.Kautzer81@yahoo.com",
    "mobile": "181.662.0904 x174",
    "successfultransactionCount": 65625
  },
  {
    "id": "39",
    "name": "Elian Rau",
    "email": "Kristoffer73@yahoo.com",
    "mobile": "145-180-2883",
    "successfultransactionCount": 37262
  },
  {
    "id": "40",
    "name": "Graciela Bechtelar",
    "email": "Tanner_Hammes@hotmail.com",
    "mobile": "(978) 337-7581",
    "successfultransactionCount": 91546
  },
  {
    "id": "41",
    "name": "Diego Langosh",
    "email": "Pete85@gmail.com",
    "mobile": "(245) 059-1719 x8443",
    "successfultransactionCount": 76291
  },
  {
    "id": "42",
    "name": "Mrs. Liliana Eichmann",
    "email": "Lillie.Connelly@yahoo.com",
    "mobile": "053-128-9266 x12900",
    "successfultransactionCount": 38860
  },
  {
    "id": "43",
    "name": "Cathryn Lehner",
    "email": "Fiona_Marquardt@yahoo.com",
    "mobile": "539-737-5474 x10981",
    "successfultransactionCount": 77720
  },
  {
    "id": "44",
    "name": "Abigale Durgan",
    "email": "Brett45@gmail.com",
    "mobile": "086-657-0189",
    "successfultransactionCount": 47767
  },
  {
    "id": "45",
    "name": "Sonny Muller",
    "email": "Mandy.Mohr@yahoo.com",
    "mobile": "(699) 189-4877 x192",
    "successfultransactionCount": 24442
  },
  {
    "id": "46",
    "name": "Brandt Reichert",
    "email": "Kira26@hotmail.com",
    "mobile": "(180) 133-6905 x512",
    "successfultransactionCount": 25684
  },
  {
    "id": "47",
    "name": "Alda Eichmann",
    "email": "Edmund.Bergstrom@yahoo.com",
    "mobile": "322.663.2896",
    "successfultransactionCount": 14958
  },
  {
    "id": "48",
    "name": "Ken Marks",
    "email": "Anissa58@yahoo.com",
    "mobile": "1-522-599-7006",
    "successfultransactionCount": 30779
  },
  {
    "id": "49",
    "name": "Savanna Hodkiewicz",
    "email": "Dallin.Heidenreich@hotmail.com",
    "mobile": "035-591-6611",
    "successfultransactionCount": 46181
  },
  {
    "id": "50",
    "name": "Craig Monahan",
    "email": "Aliza.Trantow@gmail.com",
    "mobile": "1-465-939-0731 x7471",
    "successfultransactionCount": 54332
  }
];

const discountsData = [
  {
    "id": "1",
    "name": "Investor",
    "code": "invoice",
    "type": "Percentage(%)",
    "typevalue": 54,
    "startDate": "2020-11-15T04:39:38.477Z",
    "expiredDate": "2021-01-11T08:19:49.360Z",
    "usage": "usage 1",
    "status": "Active"
  },
  {
    "id": "2",
    "name": "payment",
    "code": "parsing",
    "type": "Percentage(%)",
    "typevalue": 63,
    "startDate": "2020-06-09T16:29:58.347Z",
    "expiredDate": "2021-01-07T19:19:16.204Z",
    "usage": "usage 2",
    "status": "Active"
  },
  {
    "id": "3",
    "name": "human-resource",
    "code": "Executive",
    "type": "Percentage(%)",
    "typevalue": 49,
    "startDate": "2020-09-27T00:32:27.582Z",
    "expiredDate": "2020-02-14T13:04:59.324Z",
    "usage": "usage 3",
    "status": "Disabled"
  },
  {
    "id": "4",
    "name": "Dam",
    "code": "Frozen",
    "type": "Percentage(%)",
    "typevalue": 65,
    "startDate": "2020-06-23T09:20:11.874Z",
    "expiredDate": "2021-01-15T21:56:54.194Z",
    "usage": "usage 4",
    "status": "Active"
  },
  {
    "id": "5",
    "name": "Saudi Riyal",
    "code": "Micronesia",
    "type": "Percentage(%)",
    "typevalue": 9,
    "startDate": "2020-02-02T23:52:03.766Z",
    "expiredDate": "2021-01-16T21:55:18.807Z",
    "usage": "usage 5",
    "status": "Disabled"
  },
  {
    "id": "6",
    "name": "Refined Cotton Mouse",
    "code": "enhance",
    "type": "Percentage(%)",
    "typevalue": 88,
    "startDate": "2020-12-09T14:43:02.870Z",
    "expiredDate": "2020-02-28T06:20:17.538Z",
    "usage": "usage 6",
    "status": "Active"
  },
  {
    "id": "7",
    "name": "quantifying",
    "code": "tan",
    "type": "Percentage(%)",
    "typevalue": 22,
    "startDate": "2020-11-03T03:20:53.650Z",
    "expiredDate": "2020-10-17T03:28:32.868Z",
    "usage": "usage 7",
    "status": "Disabled"
  },
  {
    "id": "8",
    "name": "card",
    "code": "Guinea Franc",
    "type": "Percentage(%)",
    "typevalue": 83,
    "startDate": "2020-05-19T06:05:48.555Z",
    "expiredDate": "2020-11-14T00:57:55.927Z",
    "usage": "usage 8",
    "status": "Disabled"
  },
  {
    "id": "9",
    "name": "Money Market Account",
    "code": "program",
    "type": "Percentage(%)",
    "typevalue": 98,
    "startDate": "2020-07-25T20:01:50.647Z",
    "expiredDate": "2020-11-23T17:01:00.468Z",
    "usage": "usage 9",
    "status": "Active"
  },
  {
    "id": "10",
    "name": "homogeneous",
    "code": "empowering",
    "type": "Percentage(%)",
    "typevalue": 7,
    "startDate": "2020-06-04T14:07:05.177Z",
    "expiredDate": "2020-03-06T16:28:45.250Z",
    "usage": "usage 10",
    "status": "Active"
  },
  {
    "id": "11",
    "name": "Trafficway",
    "code": "JBOD",
    "type": "Percentage(%)",
    "typevalue": 24,
    "startDate": "2020-04-13T17:20:17.826Z",
    "expiredDate": "2020-12-05T08:29:37.934Z",
    "usage": "usage 11",
    "status": "Disabled"
  },
  {
    "id": "12",
    "name": "Garden",
    "code": "Bedfordshire",
    "type": "Percentage(%)",
    "typevalue": 4,
    "startDate": "2020-03-19T11:51:49.839Z",
    "expiredDate": "2020-08-10T06:26:21.963Z",
    "usage": "usage 12",
    "status": "Disabled"
  },
  {
    "id": "13",
    "name": "panel",
    "code": "Practical Plastic Car",
    "type": "Percentage(%)",
    "typevalue": 8,
    "startDate": "2020-10-17T17:32:02.034Z",
    "expiredDate": "2020-04-06T06:15:40.553Z",
    "usage": "usage 13",
    "status": "Disabled"
  },
  {
    "id": "14",
    "name": "parse",
    "code": "1080p",
    "type": "Percentage(%)",
    "typevalue": 7,
    "startDate": "2021-01-20T12:58:39.711Z",
    "expiredDate": "2020-12-07T10:30:14.558Z",
    "usage": "usage 14",
    "status": "Active"
  },
  {
    "id": "15",
    "name": "Virtual",
    "code": "withdrawal",
    "type": "Percentage(%)",
    "typevalue": 86,
    "startDate": "2020-05-11T18:25:16.560Z",
    "expiredDate": "2020-08-28T22:10:25.374Z",
    "usage": "usage 15",
    "status": "Disabled"
  },
  {
    "id": "16",
    "name": "Avon",
    "code": "e-business",
    "type": "Percentage(%)",
    "typevalue": 76,
    "startDate": "2020-07-23T23:49:46.297Z",
    "expiredDate": "2020-04-14T08:38:54.281Z",
    "usage": "usage 16",
    "status": "Active"
  },
  {
    "id": "17",
    "name": "Branding",
    "code": "Small",
    "type": "Percentage(%)",
    "typevalue": 22,
    "startDate": "2021-01-04T09:58:24.470Z",
    "expiredDate": "2021-01-14T04:38:45.035Z",
    "usage": "usage 17",
    "status": "Disabled"
  },
  {
    "id": "18",
    "name": "SCSI",
    "code": "USB",
    "type": "Percentage(%)",
    "typevalue": 68,
    "startDate": "2020-09-12T21:45:54.047Z",
    "expiredDate": "2020-12-02T21:07:19.816Z",
    "usage": "usage 18",
    "status": "Active"
  },
  {
    "id": "19",
    "name": "index",
    "code": "Netherlands",
    "type": "Percentage(%)",
    "typevalue": 85,
    "startDate": "2020-06-20T13:07:28.294Z",
    "expiredDate": "2020-06-02T10:31:34.316Z",
    "usage": "usage 19",
    "status": "Disabled"
  },
  {
    "id": "20",
    "name": "background",
    "code": "Dalasi",
    "type": "Percentage(%)",
    "typevalue": 50,
    "startDate": "2020-08-11T03:09:48.337Z",
    "expiredDate": "2020-03-06T07:10:04.095Z",
    "usage": "usage 20",
    "status": "Active"
  }
];

const transactionData = [
  {
    "id": "1",
    "date": "2020-06-09T00:36:45.757Z",
    "transactionType": "invoice",
    "description": "success Function-based",
    "amount": "780.61",
    "status": "status 1"
  },
  {
    "id": "2",
    "date": "2020-10-06T22:35:15.186Z",
    "transactionType": "Credit",
    "description": "Investment Account",
    "amount": "151.52",
    "status": "status 2"
  },
  {
    "id": "3",
    "date": "2020-06-04T01:04:57.688Z",
    "transactionType": "Withdrawal",
    "description": "Toys",
    "amount": "603.91",
    "status": "status 3"
  },
  {
    "id": "4",
    "date": "2020-06-17T10:24:42.911Z",
    "transactionType": "Withdrawal",
    "description": "invoice",
    "amount": "795.82",
    "status": "status 4"
  },
  {
    "id": "5",
    "date": "2020-07-03T06:33:32.722Z",
    "transactionType": "invoice",
    "description": "generate",
    "amount": "794.42",
    "status": "status 5"
  },
  {
    "id": "6",
    "date": "2020-03-27T18:57:39.438Z",
    "transactionType": "Withdrawal",
    "description": "harness",
    "amount": "440.97",
    "status": "status 6"
  },
  {
    "id": "7",
    "date": "2020-10-14T05:05:41.014Z",
    "transactionType": "Credit",
    "description": "parsing panel",
    "amount": "460.24",
    "status": "status 7"
  },
  {
    "id": "8",
    "date": "2020-10-26T17:26:29.346Z",
    "transactionType": "Withdrawal",
    "description": "backing up Licensed Steel Bike Park",
    "amount": "837.92",
    "status": "status 8"
  },
  {
    "id": "9",
    "date": "2020-03-26T18:23:19.268Z",
    "transactionType": "invoice",
    "description": "Avon Profound compress",
    "amount": "655.57",
    "status": "status 9"
  },
  {
    "id": "10",
    "date": "2021-01-11T04:43:55.392Z",
    "transactionType": "Withdrawal",
    "description": "Tasty intangible",
    "amount": "95.71",
    "status": "status 10"
  },
  {
    "id": "11",
    "date": "2020-05-31T19:20:36.008Z",
    "transactionType": "payment",
    "description": "back-end Auto Loan Account",
    "amount": "295.89",
    "status": "status 11"
  },
  {
    "id": "12",
    "date": "2021-01-21T11:32:32.857Z",
    "transactionType": "Withdrawal",
    "description": "User-friendly",
    "amount": "389.48",
    "status": "status 12"
  },
  {
    "id": "13",
    "date": "2020-11-19T18:00:15.329Z",
    "transactionType": "payment",
    "description": "synthesize",
    "amount": "86.52",
    "status": "status 13"
  },
  {
    "id": "14",
    "date": "2020-10-24T06:09:15.349Z",
    "transactionType": "Credit",
    "description": "Refined Quality Practical Concrete Shirt",
    "amount": "87.46",
    "status": "status 14"
  },
  {
    "id": "15",
    "date": "2020-03-28T06:46:17.312Z",
    "transactionType": "Withdrawal",
    "description": "matrices",
    "amount": "484.71",
    "status": "status 15"
  },
  {
    "id": "16",
    "date": "2020-02-08T05:59:11.643Z",
    "transactionType": "invoice",
    "description": "reintermediate",
    "amount": "813.95",
    "status": "status 16"
  },
  {
    "id": "17",
    "date": "2020-09-13T16:42:45.332Z",
    "transactionType": "invoice",
    "description": "Yuan Renminbi",
    "amount": "957.60",
    "status": "status 17"
  },
  {
    "id": "18",
    "date": "2020-07-26T21:34:22.071Z",
    "transactionType": "payment",
    "description": "Assurance parallelism",
    "amount": "864.41",
    "status": "status 18"
  },
  {
    "id": "19",
    "date": "2020-07-11T11:26:22.880Z",
    "transactionType": "Withdrawal",
    "description": "Handmade Sleek Steel Ball Incredible",
    "amount": "934.89",
    "status": "status 19"
  },
  {
    "id": "20",
    "date": "2020-10-08T11:08:18.898Z",
    "transactionType": "payment",
    "description": "Music Customer",
    "amount": "599.23",
    "status": "status 20"
  },
  {
    "id": "21",
    "date": "2020-09-11T03:35:42.610Z",
    "transactionType": "Withdrawal",
    "description": "Euro Tools",
    "amount": "797.33",
    "status": "status 21"
  },
  {
    "id": "22",
    "date": "2020-11-22T11:16:27.267Z",
    "transactionType": "Credit",
    "description": "Keyboard Withdrawal Credit",
    "amount": "165.72",
    "status": "status 22"
  },
  {
    "id": "23",
    "date": "2020-04-20T12:59:55.414Z",
    "transactionType": "Withdrawal",
    "description": "Investment Account Washington",
    "amount": "899.06",
    "status": "status 23"
  },
  {
    "id": "24",
    "date": "2020-06-12T09:04:19.526Z",
    "transactionType": "invoice",
    "description": "niches fault-tolerant Concrete",
    "amount": "844.87",
    "status": "status 24"
  },
  {
    "id": "25",
    "date": "2020-02-20T22:13:15.237Z",
    "transactionType": "Credit",
    "description": "digital Djibouti Franc Graphical User Interface",
    "amount": "77.00",
    "status": "status 25"
  },
  {
    "id": "26",
    "date": "2020-03-22T12:29:49.154Z",
    "transactionType": "invoice",
    "description": "Licensed Dynamic",
    "amount": "894.57",
    "status": "status 26"
  },
  {
    "id": "27",
    "date": "2020-09-25T17:41:50.216Z",
    "transactionType": "Withdrawal",
    "description": "Handcrafted Plastic Tuna efficient PCI",
    "amount": "468.53",
    "status": "status 27"
  },
  {
    "id": "28",
    "date": "2020-09-15T23:45:08.642Z",
    "transactionType": "Withdrawal",
    "description": "Withdrawal",
    "amount": "799.32",
    "status": "status 28"
  },
  {
    "id": "29",
    "date": "2020-10-26T20:39:26.984Z",
    "transactionType": "Credit",
    "description": "Handcrafted Metal Sausages Australian Dollar didactic",
    "amount": "791.82",
    "status": "status 29"
  },
  {
    "id": "30",
    "date": "2020-08-22T13:54:53.743Z",
    "transactionType": "payment",
    "description": "Designer solid state Baby",
    "amount": "22.45",
    "status": "status 30"
  },
  {
    "id": "31",
    "date": "2020-06-22T11:24:41.411Z",
    "transactionType": "Withdrawal",
    "description": "Home",
    "amount": "130.17",
    "status": "status 31"
  },
  {
    "id": "32",
    "date": "2020-09-19T20:03:59.535Z",
    "transactionType": "Withdrawal",
    "description": "program Center",
    "amount": "24.56",
    "status": "status 32"
  },
  {
    "id": "33",
    "date": "2020-10-26T16:12:40.917Z",
    "transactionType": "invoice",
    "description": "complexity",
    "amount": "187.01",
    "status": "status 33"
  },
  {
    "id": "34",
    "date": "2020-05-28T13:58:08.514Z",
    "transactionType": "invoice",
    "description": "vortals Personal Loan Account",
    "amount": "215.55",
    "status": "status 34"
  },
  {
    "id": "35",
    "date": "2020-07-25T15:23:52.155Z",
    "transactionType": "Credit",
    "description": "Florida Granite",
    "amount": "578.52",
    "status": "status 35"
  },
  {
    "id": "36",
    "date": "2021-01-05T14:00:09.803Z",
    "transactionType": "payment",
    "description": "backing up",
    "amount": "530.04",
    "status": "status 36"
  },
  {
    "id": "37",
    "date": "2020-05-08T19:09:12.992Z",
    "transactionType": "invoice",
    "description": "robust",
    "amount": "583.24",
    "status": "status 37"
  },
  {
    "id": "38",
    "date": "2020-07-16T14:33:29.373Z",
    "transactionType": "Credit",
    "description": "Assurance best-of-breed",
    "amount": "613.35",
    "status": "status 38"
  },
  {
    "id": "39",
    "date": "2020-01-25T05:44:43.803Z",
    "transactionType": "Credit",
    "description": "Ghana",
    "amount": "553.61",
    "status": "status 39"
  }
];

const topProductSalesList = [
  {
    productId: "1",
    productName: "Product name",
    ProductImage: ProductIcon,
    totalSalesValue: "RM990.00",
    noOfItems: "32"
  },
  {
    productId: "2",
    productName: "Product name",
    ProductImage: ProductIcon,
    totalSalesValue: "RM875.50",
    noOfItems: "27"
  },
  {
    productId: "3",
    productName: "Product name",
    ProductImage: ProductIcon,
    totalSalesValue: "RM620.00",
    noOfItems: "24"
  },
  {
    productId: "4",
    productName: "Product name",
    ProductImage: ProductIcon,
    totalSalesValue: "RM600.00",
    noOfItems: "19"
  },
  {
    productId: "5",
    productName: "Product name",
    ProductImage: ProductIcon,
    totalSalesValue: "RM510.00",
    noOfItems: "15"
  }
];


const averageSalesPerSeller = [
  {
    sellerId: "1",
    sellerName: "Seller name1",
    averageSales: "RM999,999.99"
  },
  {
    sellerId: "2",
    sellerName: "Seller name2",
    averageSales: "RM999,999.99"
  },
  {
    sellerId: "3",
    sellerName: "Seller name3",
    averageSales: "RM999,999.99"
  },
  {
    sellerId: "4",
    sellerName: "Seller name4",
    averageSales: "RM999,999.99"
  },
  {
    sellerId: "5",
    sellerName: "Seller name5",
    averageSales: "RM999,999.99"
  },
];

const totalSaleReportsData = [
  {
    "id": "1",
    "date": "2020-09-20T01:19:06.681Z",
    "orders": 24172,
    "discounts": "649.17",
    "returns": "16.72",
    "shippingfees": "597.79",
    "tax": "397.81",
    "grossSales": "637.04",
    "netSales": "420.15",
    "totalSales": "817.85"
  },
  {
    "id": "2",
    "date": "2020-10-27T20:34:42.667Z",
    "orders": 33531,
    "discounts": "481.55",
    "returns": "322.35",
    "shippingfees": "226.35",
    "tax": "583.67",
    "grossSales": "115.15",
    "netSales": "593.83",
    "totalSales": "964.63"
  },
  {
    "id": "3",
    "date": "2020-08-08T10:58:46.386Z",
    "orders": 49471,
    "discounts": "503.79",
    "returns": "7.54",
    "shippingfees": "422.09",
    "tax": "985.83",
    "grossSales": "970.34",
    "netSales": "783.47",
    "totalSales": "678.96"
  },
  {
    "id": "4",
    "date": "2020-09-26T08:21:42.836Z",
    "orders": 42032,
    "discounts": "463.12",
    "returns": "977.81",
    "shippingfees": "228.92",
    "tax": "344.47",
    "grossSales": "88.97",
    "netSales": "513.53",
    "totalSales": "626.16"
  },
  {
    "id": "5",
    "date": "2020-09-24T03:22:17.497Z",
    "orders": 284,
    "discounts": "825.02",
    "returns": "225.85",
    "shippingfees": "40.94",
    "tax": "346.30",
    "grossSales": "230.01",
    "netSales": "916.61",
    "totalSales": "362.74"
  },
  {
    "id": "6",
    "date": "2020-02-23T05:45:08.848Z",
    "orders": 68170,
    "discounts": "868.95",
    "returns": "525.51",
    "shippingfees": "937.63",
    "tax": "831.23",
    "grossSales": "188.64",
    "netSales": "231.15",
    "totalSales": "532.61"
  },
  {
    "id": "7",
    "date": "2020-08-25T17:41:26.067Z",
    "orders": 69199,
    "discounts": "520.59",
    "returns": "134.48",
    "shippingfees": "579.37",
    "tax": "411.27",
    "grossSales": "257.25",
    "netSales": "369.02",
    "totalSales": "421.04"
  },
  {
    "id": "8",
    "date": "2020-03-13T19:04:04.026Z",
    "orders": 1332,
    "discounts": "33.20",
    "returns": "790.31",
    "shippingfees": "259.81",
    "tax": "656.68",
    "grossSales": "721.11",
    "netSales": "486.01",
    "totalSales": "925.97"
  },
  {
    "id": "9",
    "date": "2020-06-08T09:49:50.921Z",
    "orders": 57092,
    "discounts": "912.16",
    "returns": "111.72",
    "shippingfees": "998.84",
    "tax": "276.01",
    "grossSales": "307.53",
    "netSales": "924.99",
    "totalSales": "920.48"
  },
  {
    "id": "10",
    "date": "2020-05-24T19:49:06.286Z",
    "orders": 26174,
    "discounts": "13.61",
    "returns": "752.67",
    "shippingfees": "138.62",
    "tax": "510.50",
    "grossSales": "156.06",
    "netSales": "830.32",
    "totalSales": "812.11"
  },
  {
    "id": "11",
    "date": "2020-12-28T03:16:10.621Z",
    "orders": 80237,
    "discounts": "313.78",
    "returns": "746.28",
    "shippingfees": "769.93",
    "tax": "883.25",
    "grossSales": "252.92",
    "netSales": "194.16",
    "totalSales": "300.79"
  },
  {
    "id": "12",
    "date": "2020-09-26T23:14:06.840Z",
    "orders": 30467,
    "discounts": "4.19",
    "returns": "31.78",
    "shippingfees": "975.07",
    "tax": "951.93",
    "grossSales": "776.73",
    "netSales": "325.08",
    "totalSales": "795.92"
  },
  {
    "id": "13",
    "date": "2020-05-27T06:12:59.618Z",
    "orders": 760,
    "discounts": "56.24",
    "returns": "476.55",
    "shippingfees": "433.57",
    "tax": "951.69",
    "grossSales": "356.92",
    "netSales": "371.06",
    "totalSales": "322.21"
  },
  {
    "id": "14",
    "date": "2020-10-09T03:42:36.375Z",
    "orders": 68866,
    "discounts": "401.21",
    "returns": "697.42",
    "shippingfees": "50.80",
    "tax": "41.11",
    "grossSales": "274.93",
    "netSales": "743.76",
    "totalSales": "872.92"
  },
  {
    "id": "15",
    "date": "2020-11-10T20:46:53.325Z",
    "orders": 2294,
    "discounts": "807.49",
    "returns": "182.56",
    "shippingfees": "589.34",
    "tax": "217.67",
    "grossSales": "199.56",
    "netSales": "351.35",
    "totalSales": "872.18"
  },
  {
    "id": "16",
    "date": "2020-05-06T00:59:15.363Z",
    "orders": 10759,
    "discounts": "957.00",
    "returns": "16.04",
    "shippingfees": "494.64",
    "tax": "645.60",
    "grossSales": "338.33",
    "netSales": "139.09",
    "totalSales": "636.01"
  },
  {
    "id": "17",
    "date": "2020-09-22T13:21:59.928Z",
    "orders": 66744,
    "discounts": "717.14",
    "returns": "149.71",
    "shippingfees": "495.11",
    "tax": "734.67",
    "grossSales": "126.81",
    "netSales": "357.73",
    "totalSales": "515.87"
  },
  {
    "id": "18",
    "date": "2020-06-29T18:13:16.726Z",
    "orders": 86794,
    "discounts": "373.08",
    "returns": "98.44",
    "shippingfees": "577.86",
    "tax": "86.96",
    "grossSales": "649.65",
    "netSales": "162.38",
    "totalSales": "764.67"
  },
  {
    "id": "19",
    "date": "2020-04-17T12:14:12.492Z",
    "orders": 17845,
    "discounts": "107.30",
    "returns": "279.60",
    "shippingfees": "720.75",
    "tax": "57.98",
    "grossSales": "467.15",
    "netSales": "542.98",
    "totalSales": "783.90"
  },
  {
    "id": "20",
    "date": "2020-06-14T22:33:24.617Z",
    "orders": 83537,
    "discounts": "878.98",
    "returns": "883.22",
    "shippingfees": "872.78",
    "tax": "880.54",
    "grossSales": "765.89",
    "netSales": "188.08",
    "totalSales": "930.67"
  },
  {
    "id": "21",
    "date": "2020-12-05T01:21:41.232Z",
    "orders": 26505,
    "discounts": "301.79",
    "returns": "103.89",
    "shippingfees": "368.61",
    "tax": "876.81",
    "grossSales": "263.43",
    "netSales": "821.39",
    "totalSales": "736.11"
  },
  {
    "id": "22",
    "date": "2020-12-26T14:22:21.547Z",
    "orders": 13365,
    "discounts": "932.73",
    "returns": "626.45",
    "shippingfees": "656.55",
    "tax": "103.73",
    "grossSales": "817.25",
    "netSales": "61.65",
    "totalSales": "585.44"
  },
  {
    "id": "23",
    "date": "2020-02-15T12:22:41.205Z",
    "orders": 30011,
    "discounts": "549.50",
    "returns": "467.40",
    "shippingfees": "47.44",
    "tax": "616.20",
    "grossSales": "685.97",
    "netSales": "978.09",
    "totalSales": "968.65"
  },
  {
    "id": "24",
    "date": "2020-12-20T17:16:08.209Z",
    "orders": 8416,
    "discounts": "908.19",
    "returns": "471.58",
    "shippingfees": "985.76",
    "tax": "59.42",
    "grossSales": "771.42",
    "netSales": "329.55",
    "totalSales": "438.82"
  },
  {
    "id": "25",
    "date": "2020-03-14T05:16:40.707Z",
    "orders": 54775,
    "discounts": "277.23",
    "returns": "295.29",
    "shippingfees": "992.39",
    "tax": "36.02",
    "grossSales": "708.46",
    "netSales": "632.86",
    "totalSales": "111.32"
  },
  {
    "id": "26",
    "date": "2020-07-06T06:29:29.457Z",
    "orders": 27055,
    "discounts": "909.81",
    "returns": "374.20",
    "shippingfees": "209.81",
    "tax": "707.00",
    "grossSales": "761.80",
    "netSales": "524.04",
    "totalSales": "795.21"
  },
  {
    "id": "27",
    "date": "2020-12-18T14:02:31.346Z",
    "orders": 21338,
    "discounts": "618.79",
    "returns": "156.22",
    "shippingfees": "729.91",
    "tax": "355.91",
    "grossSales": "901.67",
    "netSales": "930.55",
    "totalSales": "487.49"
  },
  {
    "id": "28",
    "date": "2020-02-22T07:38:26.903Z",
    "orders": 52757,
    "discounts": "504.36",
    "returns": "595.90",
    "shippingfees": "767.15",
    "tax": "311.93",
    "grossSales": "471.39",
    "netSales": "88.63",
    "totalSales": "126.47"
  },
  {
    "id": "29",
    "date": "2020-11-03T04:16:06.123Z",
    "orders": 16265,
    "discounts": "535.78",
    "returns": "310.60",
    "shippingfees": "617.29",
    "tax": "682.81",
    "grossSales": "384.58",
    "netSales": "151.71",
    "totalSales": "234.03"
  },
  {
    "id": "30",
    "date": "2020-03-20T17:41:34.354Z",
    "orders": 40490,
    "discounts": "797.19",
    "returns": "112.21",
    "shippingfees": "434.61",
    "tax": "623.24",
    "grossSales": "809.72",
    "netSales": "431.35",
    "totalSales": "49.10"
  },
  {
    "id": "31",
    "date": "2020-12-06T10:41:16.200Z",
    "orders": 61444,
    "discounts": "560.64",
    "returns": "50.60",
    "shippingfees": "872.85",
    "tax": "933.02",
    "grossSales": "184.36",
    "netSales": "419.39",
    "totalSales": "351.66"
  },
  {
    "id": "32",
    "date": "2020-05-16T12:58:34.460Z",
    "orders": 26346,
    "discounts": "723.33",
    "returns": "94.11",
    "shippingfees": "203.95",
    "tax": "3.21",
    "grossSales": "879.09",
    "netSales": "440.17",
    "totalSales": "74.86"
  }
];

const shippingSettings = [
  {
    name: "Flat rate for SEA",
    countries: "3 countries",
    shippingMethods: "Flat-rate"
  },
  {
    name: "Flat rate for the rest of the worlds",
    countries: "24 countries",
    shippingMethods: "Flat-rate"
  },
  {
    name: "24 countries",
    countries: "All countries",
    shippingMethods: "Free shipping"
  },
  {
    name: "Weight-based",
    countries: "3 countries",
    shippingMethods: "Weight-based"
  }
];

const taxesSettings = [
  {
    name: "Business taxes",
    numberOfTaxes: "1"
  },
  {
    name: "Local taxes",
    numberOfTaxes: "3"
  }
];

const billingStatements = [
  {
    date: "2020-03-20T17:41:34.354Z",
    description: "Premium plan (Annually) payment for 2021",
    amount: "RM199 / Annual"
  },
  {
    date: "2020-03-20T17:41:34.354Z",
    description: "Premium plan (Annually) payment for 2020",
    amount: "RM199 / Annual"
  },
  {
    date: "2020-03-20T17:41:34.354Z",
    description: "Premium plan (Annually) payment for 2019",
    amount: "RM199 / Annual"
  }
];

const getNotifications = () => {
  return {
      'ThU, 15 Feb 2020': [{
          type: 'System notice',
          Content: 'Server will be down for maintenance for 24 hours',
          isRead: false
      }],
      'ThU, 14 Feb 2020': [
          {
              type: 'Success notice',
              Content: 'You have successfully created a new order',
              isRead: false
          },
          {
              type: 'Warning notice',
              Content: 'You have not added your bank accounts',
              isRead: true
          },
      ],
      'MON, 10 Feb 2020': [
          {
              type: 'Error notice',
              Content: 'Internal server error message.',
              isRead: true
          },
      ]
  }
};

const openOrders = [
  {
    orderId: '0001',
    transactionDate: '2020-11-03T04:16:06.123Z',
    paymentStatus: 'Payment pending',
    transactionStatus: 'Open',
    total: '99999',
    itemCount: '4'
  },
  {
    orderId: '0002',
    transactionDate: '2020-11-03T04:16:06.123Z',
    paymentStatus: 'Payment pending',
    transactionStatus: 'Open',
    total: '99999',
    itemCount: '4'
  },
  {
    orderId: '0003',
    transactionDate: '2020-11-03T04:16:06.123Z',
    paymentStatus: 'Payment pending',
    transactionStatus: 'Open',
    total: '99999',
    itemCount: '4'
  },
  {
    orderId: '0004',
    transactionDate: '2020-11-03T04:16:06.123Z',
    paymentStatus: 'Payment pending',
    transactionStatus: 'Open',
    total: '99999',
    itemCount: '4'
  },
  {
    orderId: '0005',
    transactionDate: '2020-11-03T04:16:06.123Z',
    paymentStatus: 'Payment pending',
    transactionStatus: 'Open',
    total: '99999',
    itemCount: '4'
  }
];

const profileActionsStatus = {
  addProducts: true,
  inviteSellers: true,
  setUpShippings: false,
  manageTaxes: false,
  bankAccountAdded: false
}

const vendorData = [
  {
    "id": "1",
    "name": "Mackenzie Raynor",
    "email": "Xavier97@gmail.com",
    "mobile": "(326) 324-2345 x33151",
    "successfultransactionCount": 90268
  },
  {
    "id": "2",
    "name": "Charlie Bashirian",
    "email": "Leone.Gleichner@gmail.com",
    "mobile": "725-853-5895 x42024",
    "successfultransactionCount": 74187
  },
  {
    "id": "3",
    "name": "Madeline Wiegand",
    "email": "Andy.Lubowitz@gmail.com",
    "mobile": "(553) 745-6282 x8692",
    "successfultransactionCount": 45316
  },
  {
    "id": "4",
    "name": "Anita Schmidt Sr.",
    "email": "Chad_Blanda55@hotmail.com",
    "mobile": "1-846-028-4575",
    "successfultransactionCount": 54032
  },
  {
    "id": "5",
    "name": "Neal Kling",
    "email": "Emmitt73@gmail.com",
    "mobile": "1-740-980-0791 x015",
    "successfultransactionCount": 85716
  },
  {
    "id": "6",
    "name": "Yvette Lind",
    "email": "Valentina49@yahoo.com",
    "mobile": "328-012-9918 x34423",
    "successfultransactionCount": 10025
  },
  {
    "id": "7",
    "name": "Aracely Hodkiewicz",
    "email": "Lester90@hotmail.com",
    "mobile": "434.856.1759",
    "successfultransactionCount": 14393
  },
  {
    "id": "8",
    "name": "Forrest Casper",
    "email": "Viviane_Wiza43@gmail.com",
    "mobile": "(134) 771-8522",
    "successfultransactionCount": 63957
  },
  {
    "id": "9",
    "name": "Marquis Nienow",
    "email": "Trenton21@yahoo.com",
    "mobile": "(286) 622-4954 x934",
    "successfultransactionCount": 51021
  },
  {
    "id": "10",
    "name": "Dr. Otis Kohler",
    "email": "Alexie.Purdy36@gmail.com",
    "mobile": "646-284-3701 x676",
    "successfultransactionCount": 80643
  },
  {
    "id": "11",
    "name": "Duncan Mohr Sr.",
    "email": "Kristofer.Moen@gmail.com",
    "mobile": "(722) 766-9634",
    "successfultransactionCount": 69199
  },
  {
    "id": "12",
    "name": "Kylee Funk DDS",
    "email": "Stewart82@gmail.com",
    "mobile": "1-478-188-6375",
    "successfultransactionCount": 19356
  },
  {
    "id": "13",
    "name": "Georgianna Heller",
    "email": "Annie_Predovic@yahoo.com",
    "mobile": "1-550-686-8100",
    "successfultransactionCount": 83615
  },
  {
    "id": "14",
    "name": "Leann Cummerata",
    "email": "Sonia.Stehr@yahoo.com",
    "mobile": "271-019-1428 x587",
    "successfultransactionCount": 58337
  },
  {
    "id": "15",
    "name": "Ivah MacGyver",
    "email": "Marlen.Watsica27@gmail.com",
    "mobile": "1-728-419-9635 x2383",
    "successfultransactionCount": 81583
  },
  {
    "id": "16",
    "name": "Grace Schmeler",
    "email": "Alanna_Sanford22@gmail.com",
    "mobile": "391-927-3726 x2209",
    "successfultransactionCount": 40949
  },
  {
    "id": "17",
    "name": "Makenzie Ward",
    "email": "Dana62@gmail.com",
    "mobile": "944.396.6948 x2869",
    "successfultransactionCount": 89423
  },
  {
    "id": "18",
    "name": "Susana Konopelski DVM",
    "email": "Jamar87@hotmail.com",
    "mobile": "332-764-5865",
    "successfultransactionCount": 73020
  },
  {
    "id": "19",
    "name": "Frankie Kihn",
    "email": "Brenda47@hotmail.com",
    "mobile": "202.018.2139 x895",
    "successfultransactionCount": 9331
  },
  {
    "id": "20",
    "name": "Chandler Jast DVM",
    "email": "Clifford.Mayer@yahoo.com",
    "mobile": "(239) 618-8072 x2084",
    "successfultransactionCount": 94177
  },
  {
    "id": "21",
    "name": "Armani Waters",
    "email": "Randal.Durgan13@gmail.com",
    "mobile": "278.792.0309",
    "successfultransactionCount": 99716
  },
  {
    "id": "22",
    "name": "Cornelius Stroman",
    "email": "Bradley47@hotmail.com",
    "mobile": "1-054-606-9160 x73747",
    "successfultransactionCount": 5948
  },
  {
    "id": "23",
    "name": "Rafaela Prosacco V",
    "email": "Cecile_Mitchell67@yahoo.com",
    "mobile": "1-139-857-3421 x51618",
    "successfultransactionCount": 3596
  },
  {
    "id": "24",
    "name": "Alayna Hills",
    "email": "Jackson_Nikolaus@gmail.com",
    "mobile": "089-582-7148",
    "successfultransactionCount": 62514
  },
  {
    "id": "25",
    "name": "Bryce Ziemann DDS",
    "email": "Favian.Greenholt@yahoo.com",
    "mobile": "431-128-6092 x7968",
    "successfultransactionCount": 82849
  },
  {
    "id": "26",
    "name": "Rosina Gorczany",
    "email": "Selena96@hotmail.com",
    "mobile": "1-763-306-9595 x16837",
    "successfultransactionCount": 34908
  },
  {
    "id": "27",
    "name": "Else Price",
    "email": "Donny_Schowalter@gmail.com",
    "mobile": "833-707-5800",
    "successfultransactionCount": 6300
  },
  {
    "id": "28",
    "name": "Whitney Harris",
    "email": "Easter_Schamberger@hotmail.com",
    "mobile": "680.666.0642",
    "successfultransactionCount": 78790
  },
  {
    "id": "29",
    "name": "Chance Sipes",
    "email": "Deon27@yahoo.com",
    "mobile": "(264) 869-1051 x9529",
    "successfultransactionCount": 45922
  },
  {
    "id": "30",
    "name": "Axel Howell",
    "email": "Emmanuel.Kertzmann@hotmail.com",
    "mobile": "1-133-993-8020",
    "successfultransactionCount": 64317
  },
  {
    "id": "31",
    "name": "Toney Gleason",
    "email": "Hubert.Bashirian@gmail.com",
    "mobile": "1-808-949-7004",
    "successfultransactionCount": 10304
  },
  {
    "id": "32",
    "name": "Everardo Bartoletti",
    "email": "Elissa.Pfannerstill@gmail.com",
    "mobile": "(745) 575-2294 x23296",
    "successfultransactionCount": 60600
  },
  {
    "id": "33",
    "name": "Dina Hermiston",
    "email": "Bernita_Abernathy92@gmail.com",
    "mobile": "(675) 452-8777 x61287",
    "successfultransactionCount": 6661
  },
  {
    "id": "34",
    "name": "Raphaelle Wuckert",
    "email": "Abdullah80@hotmail.com",
    "mobile": "330.333.8696",
    "successfultransactionCount": 3345
  },
  {
    "id": "35",
    "name": "Ron Abernathy",
    "email": "Anastacio36@hotmail.com",
    "mobile": "1-657-797-2652 x5181",
    "successfultransactionCount": 26215
  },
  {
    "id": "36",
    "name": "Dr. Hadley Ward",
    "email": "Ruben.Feil@hotmail.com",
    "mobile": "743.119.7478",
    "successfultransactionCount": 69770
  },
  {
    "id": "37",
    "name": "Ashton Konopelski",
    "email": "Sylvan_Carroll@yahoo.com",
    "mobile": "(304) 852-6424 x6869",
    "successfultransactionCount": 12776
  },
  {
    "id": "38",
    "name": "Lilla Farrell",
    "email": "Judson.Kautzer81@yahoo.com",
    "mobile": "181.662.0904 x174",
    "successfultransactionCount": 65625
  },
  {
    "id": "39",
    "name": "Elian Rau",
    "email": "Kristoffer73@yahoo.com",
    "mobile": "145-180-2883",
    "successfultransactionCount": 37262
  },
  {
    "id": "40",
    "name": "Graciela Bechtelar",
    "email": "Tanner_Hammes@hotmail.com",
    "mobile": "(978) 337-7581",
    "successfultransactionCount": 91546
  },
  {
    "id": "41",
    "name": "Diego Langosh",
    "email": "Pete85@gmail.com",
    "mobile": "(245) 059-1719 x8443",
    "successfultransactionCount": 76291
  },
  {
    "id": "42",
    "name": "Mrs. Liliana Eichmann",
    "email": "Lillie.Connelly@yahoo.com",
    "mobile": "053-128-9266 x12900",
    "successfultransactionCount": 38860
  },
  {
    "id": "43",
    "name": "Cathryn Lehner",
    "email": "Fiona_Marquardt@yahoo.com",
    "mobile": "539-737-5474 x10981",
    "successfultransactionCount": 77720
  },
  {
    "id": "44",
    "name": "Abigale Durgan",
    "email": "Brett45@gmail.com",
    "mobile": "086-657-0189",
    "successfultransactionCount": 47767
  },
  {
    "id": "45",
    "name": "Sonny Muller",
    "email": "Mandy.Mohr@yahoo.com",
    "mobile": "(699) 189-4877 x192",
    "successfultransactionCount": 24442
  },
  {
    "id": "46",
    "name": "Brandt Reichert",
    "email": "Kira26@hotmail.com",
    "mobile": "(180) 133-6905 x512",
    "successfultransactionCount": 25684
  },
  {
    "id": "47",
    "name": "Alda Eichmann",
    "email": "Edmund.Bergstrom@yahoo.com",
    "mobile": "322.663.2896",
    "successfultransactionCount": 14958
  },
  {
    "id": "48",
    "name": "Ken Marks",
    "email": "Anissa58@yahoo.com",
    "mobile": "1-522-599-7006",
    "successfultransactionCount": 30779
  },
  {
    "id": "49",
    "name": "Savanna Hodkiewicz",
    "email": "Dallin.Heidenreich@hotmail.com",
    "mobile": "035-591-6611",
    "successfultransactionCount": 46181
  },
  {
    "id": "50",
    "name": "Craig Monahan",
    "email": "Aliza.Trantow@gmail.com",
    "mobile": "1-465-939-0731 x7471",
    "successfultransactionCount": 54332
  }
];

export {
  sellerRequestData,
  sellerTiers,
  customersData,
  discountsData,
  transactionData,
  topProductSalesList,
  averageSalesPerSeller,
  totalSaleReportsData,
  shippingSettings,
  taxesSettings,
  billingStatements,
  getNotifications,
  openOrders,
  profileActionsStatus,
  vendorData
};