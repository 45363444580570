import clsx from "clsx";
import styles from "./index.module.css";

const types = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  p1: "p1",
  p2: "p2",
  xl: "xl", // for num 1
  l: "l", // for num 2
};

export default function SixtyText({
  type = "p1",
  weight = "regular",
  align = "left",
  color = "primary", // primary | secondary | orange | purple
  className,
  inline,
  children,
  ...rest
}) {
  const _className = clsx(
    styles[type],
    styles[weight],
    styles[color],
    styles.blackFont,
    inline && styles.inline,
    className
  );
  const renderText = () => {
    if (inline)

           return (
        (
        <span className={_className} {...rest}>
          
          {children}
        
        </span>
      )
      );
    switch (type) {
      case types.p1:
      case types.p2:
        return (
          (
          <p className={_className} {...rest}>
            
            {children}
          
          </p>
        )
        );
      case types.h1:
        return (
          (
          <h1 className={_className} {...rest}>
            
            {children}
          
          </h1>
        )
        );
      case types.h2:
        return (
          (
          <h2 className={_className} {...rest}>
            
            {children}
          
          </h2>
        )
        );
      case types.h3:
        return (
          (
          <h3 className={_className} {...rest}>
            
            {children}
          
          </h3>
        )
        );
      case types.h4:
        return (
          (
          <h4 className={_className} {...rest}>
            
            {children}
          
          </h4>
        )
        );
      case types.h5:
        return (
          (
          <h5 className={_className} {...rest}>
            
            {children}
          
          </h5>
        )
        );
      case types.xl:
      case types.l:
        return (
          (
          <div className={_className} {...rest}>
            
            {children}
          
          </div>
        )
        );

      default:
        console.error("invalid type prop passed to Text Component");
    }
  };

  return renderText();
}
