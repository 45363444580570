import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { Step } from "components/Stepper";
import { ReactComponent as IconArrowRight } from "assets/icons/Icon-Arrow-Right.svg";

const ProfileActionsProgress = ({ profileActionsStatus }) => {
  const history = useNavigate();
  return (
    <React.Fragment>
      <div>
        <ProfileActionProgressItem
          id="1"
          title="Add products"
          description="Start adding products for your creators to promote"
          isCompleted={profileActionsStatus.addProducts}
          onClick={() => history("/dashboard/products")}
        />
        {/* <ProfileActionProgressItem
          id="2"
          title="Invite sellers"
          description="Share the invitation code with the sellers to join and browse your products"
          isCompleted={profileActionsStatus.inviteSellers}
          onClick={() => history.push("/dashboard/sellers")}
        /> */}
        {/* <ProfileActionProgressItem
          id="2"
          title="Set up shippings"
          description="Choose the shipping methods on how your products get delivered to your customer"
          isCompleted={profileActionsStatus.setupShippings}
          onClick={() => history.push("/dashboard/settings/shippings")}
        /> */}
        {/* <ProfileActionProgressItem
          id="3"
          title="Manage taxes"
          description="Set up your taxes and we will generate the calculations and report for you"
          isCompleted={profileActionsStatus.manageTaxes}
          onClick={() => history.push("/dashboard/settings/taxes")}
        /> */}
        {/* <ProfileActionProgressItem
          id="3"
          title="Add bank account"
          description="Provide your bank account details to cash out on your profits"
          isCompleted={profileActionsStatus.addBankAccount}
          onClick={() => history.push("/dashboard/walletbalances")}
        /> */}
        {/* <ProfileActionProgressItem
          id="4"
          title="Add affiliate commission"
          description="Setup commission rate for your affiliate."
          isCompleted={profileActionsStatus.setupSellerCommission}
          onClick={() => history.push("/dashboard/settings/affiliateCommission")}
        /> */}
      </div>
    </React.Fragment>
  );
};

export const ProfileActionProgressItem = ({
  id,
  title,
  description,
  isCompleted,
  onClick,
}) => {
  const getStatus = () => {
    if (isCompleted) {
      return "visited";
    }
    return "notReached";
  };

  return (
    <div className={styles.profileActionContainer} onClick={onClick}>
      <div className={styles.firstColumn}>
        <Step status={getStatus()} className={styles.CheckIcon} />
      </div>
      <div className={styles.secondColumn}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div>
        <span className={styles.rightArrow}>
          <IconArrowRight />
        </span>
      </div>
    </div>
  );
};

export default ProfileActionsProgress;
