import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { TextField, Chip, Box, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import styles from "./index.module.css";
import { pink } from '@mui/material/colors';

function MultiValueInput({ 
  field,
  maxItems = Infinity, 
  placeholder = "",
  label = "Add Items",
  chipColor = "primary",
  maxItemMsg = "Max 5 support keywords",
  newItemMsg = "Click here to add your keyword",
  ...props
}) {
  const [inputValue, setInputValue] = useState("");

  // Ensure field.value is always an array
  const values = field.value ? field.value.split(',').map(item => item.trim()) : [];

  const handleAddValue = useCallback(() => {
    if (inputValue.trim() !== "" && values.length < maxItems) {
      const newValues = [...values, inputValue.trim()];
      field.onChange(newValues.join(', ')); // Join array to string
      setInputValue("");
    }
  }, [inputValue, values, field, maxItems]);

  const handleDelete = useCallback((chipToDelete) => {
    const newValues = values.filter((value) => value !== chipToDelete);
    field.onChange(newValues.join(', ')); // Join array to string
  }, [values, field]);

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      handleAddValue();
    }
  }, [handleAddValue]);

  return (
    <Box className={styles.container}>
      <TextField
        className={styles.customTextField}
        variant="outlined"
        fullWidth
        label={label}
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress} 
        placeholder={placeholder}
        disabled={values.length >= maxItems}
        size="small"
        InputProps={{
          endAdornment: (
            <>
             <Tooltip 
              title={values.length >= maxItems ? maxItemMsg: newItemMsg}
              placement="top"
              arrow
              open={inputValue.trim() || values.length >= maxItems}
            ></Tooltip>
            <span>
                <IconButton
                    onClick={handleAddValue}
                    disabled={!inputValue.trim() || values.length >= maxItems}
                    aria-label="Add item"
                    className={styles.addButton}
                    size="small"
                >
                  <AddIcon />
                </IconButton>
            </span>
            </>
          ),
          inputProps: {
            "aria-label": label,
          }
        }}
      />
      <Box className={styles.chipContainer}>
        {values.map((value, index) => (
          <Chip
            key={index}
            label={value}
            onDelete={() => handleDelete(value)}
            color={chipColor}
            style={{backgroundColor: pink[500], fontSize: 12}} 
          />
        ))}
      </Box>
    </Box>
  );
}

MultiValueInput.propTypes = {
  field: PropTypes.object.isRequired,
  maxItems: PropTypes.number,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  chipColor: PropTypes.string,
};

export default MultiValueInput;
