import { Controller } from "react-hook-form";
import ChipInput from "components/ChipInput";
import Select from 'react-select'

export default function ControlledChipInput({
  control,
  name,
  handleChange,
  getValue,
  label,
  defaultValue,
  error,
  ref,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value, ...rest }) => (
        <>
          <Select
            isMulti={true}
            isClearable={false}
            onChange={(value, options) => {
              handleChange ? onChange(handleChange(value, options)) : onChange(value);
            }}
            label={label}
            error={error}
            value={getValue ? getValue(value) : value}
            {...props}
            {...rest}
          />
        </>
      )}
    ></Controller>
  );
}
