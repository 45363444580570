import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { saveAs } from "file-saver";
import { setToast } from 'components/Toast';
import { queryClient } from "common/query";



export function useGetBlogs(filter) {
  return useQuery(
    [Endpoints.Blog.GetBlogs, filter],
    () =>
      api
        .post(Endpoints.Blog.GetBlogs, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetBlogs: useQuery: onError: error", error);
      },
    }
  );
}



