import { useEffect, useRef, useState } from "react";
import SearchFilter from "components/SixtyTable/components/SearchFilter";
import styles from "./index.module.css";
import { useGlobalSearch } from "queries/dashboard";
import { useNavigate, useLocation } from "react-router-dom";

export default function GlobalSearch({ placeholder }) {
  const [searchValue, setSearchValue] = useState("");

  const {
    data: searchData,
    isLoading: isSearching,
    isError,
    error,
  } = useGlobalSearch({ limit: 10, keyword: searchValue });

  const history = useNavigate();
  const location = useLocation();
  const goToProduct = (id) => {
    history("/dashboard/products/" + id);
    if (reset.current) reset.current();
    setSearchValue("");
  };

  useEffect(() => {
    if (reset.current) reset.current();
    setSearchValue("");
  }, [location]);

  const goToSeller = (id) => {
    history.push("/dashboard/creators/" + id);
    if (reset.current) reset.current();
    setSearchValue("");
  };

  const goToOrder = (id) => {
    history.push("/dashboard/orders/" + id);
    if (reset.current) reset.current();
    setSearchValue("");
  };

  const reset = useRef();

  return (
    <div className={styles.searchContainer}>
      <SearchFilter
        onSearchChange={(value) => setSearchValue(value)}
        search={searchValue}
        placeholder={placeholder}
        transparent
        resetCb={(cb) => {
          reset.current = cb;
        }}
      />
      {searchValue && searchData?.length > 0 && (
        <div className={styles.searchResult}>
          {searchData?.map((item) => {
            let { recordType, name, id } = item;

            if (recordType === "Product") {
              return (
                <div
                  className={styles.resultItem}
                  onClick={() => goToProduct(id)}
                >
                  {name}
                </div>
              );
            }
            if (recordType === "Seller") {
              return (
                <div
                  className={styles.resultItem}
                  onClick={() => goToSeller(id)}
                >
                  {name}
                </div>
              );
            }
            if (recordType === "Order") {
              return (
                <div
                  className={styles.resultItem}
                  onClick={() => goToOrder(id)}
                >
                  {name}
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}
