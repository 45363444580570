// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VendorSelection_vendorTable__v5DV4 {
    width: calc(95vw - 250px);
}

.VendorSelection_panelWrap__VEhNp {
    margin: 20px;
    display: block;
    min-height: unset;
    position: relative;
}

.VendorSelection_gridHeader__bwyu1 {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
}

.VendorSelection_ButtonsContainer__ECKX5 {
    display: flex;
    align-items: center;
}

.VendorSelection_exportBtn__\\+HxRe {
    font-family: var(--medium500);
    color: var(--black50Transparent);
    font-size: 14px;
}

.VendorSelection_vendorPageHeaderContainer__sTCu5 {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 8px;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
    margin-bottom: 16px;
}

.VendorSelection_modalTitle__pxvoa {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
    padding-left: 8px;
}

.VendorSelection_vendorDetailModalTitleBar__VUa9K {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}   

.VendorSelection_vendorNameContainer__pGu11 {
    display: flex;
    align-items: center;
}

.VendorSelection_closeIcon__Cy\\+be {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Blogs/VendorSelection/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".vendorTable {\n    width: calc(95vw - 250px);\n}\n\n.panelWrap {\n    margin: 20px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.gridHeader {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n}\n\n.ButtonsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.exportBtn {\n    font-family: var(--medium500);\n    color: var(--black50Transparent);\n    font-size: 14px;\n}\n\n.vendorPageHeaderContainer {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0px 8px;\n    justify-content: space-between;\n    align-items: center;\n    margin: 24px;\n    margin-bottom: 16px;\n}\n\n.modalTitle {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n    padding-left: 8px;\n}\n\n.vendorDetailModalTitleBar {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: space-between;\n}   \n\n.vendorNameContainer {\n    display: flex;\n    align-items: center;\n}\n\n.closeIcon {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vendorTable": `VendorSelection_vendorTable__v5DV4`,
	"panelWrap": `VendorSelection_panelWrap__VEhNp`,
	"gridHeader": `VendorSelection_gridHeader__bwyu1`,
	"ButtonsContainer": `VendorSelection_ButtonsContainer__ECKX5`,
	"exportBtn": `VendorSelection_exportBtn__+HxRe`,
	"vendorPageHeaderContainer": `VendorSelection_vendorPageHeaderContainer__sTCu5`,
	"modalTitle": `VendorSelection_modalTitle__pxvoa`,
	"vendorDetailModalTitleBar": `VendorSelection_vendorDetailModalTitleBar__VUa9K`,
	"vendorNameContainer": `VendorSelection_vendorNameContainer__pGu11`,
	"closeIcon": `VendorSelection_closeIcon__Cy+be`
};
export default ___CSS_LOADER_EXPORT___;
