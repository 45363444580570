// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .Tab_tabListItem__rr57U {
    display: inline-block;
    padding: 0.5rem 16px;
    padding-bottom: 0px;
    height: 40px;
    margin: 0px 4px;
    font-family: var(--medium500);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  
  .Tab_tabListActive__6elUd{
    background-color: #fff;
    border-bottom: 2px solid #EF8777;
    color: #000;
  }

  .Tab_tabAlert__xdLvs{
    color: red !important;
  }

  .Tab_recordsCount__Gs1aT {
    color: rgba(0, 0, 0, 0.25);
    padding-left: 4px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Tabs/components/Tab/index.module.css"],"names":[],"mappings":"EAAE;IACE,qBAAqB;IACrB,oBAAoB;IACpB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,6BAA6B;IAC7B,eAAe;IACf,yBAAyB;IACzB,eAAe;EACjB;;EAEA;IACE,sBAAsB;IACtB,gCAAgC;IAChC,WAAW;EACb;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;IAC1B,iBAAiB;EACnB","sourcesContent":["  .tabListItem {\n    display: inline-block;\n    padding: 0.5rem 16px;\n    padding-bottom: 0px;\n    height: 40px;\n    margin: 0px 4px;\n    font-family: var(--medium500);\n    font-size: 14px;\n    color: rgba(0, 0, 0, 0.5);\n    cursor: pointer;\n  }\n  \n  .tabListActive{\n    background-color: #fff;\n    border-bottom: 2px solid #EF8777;\n    color: #000;\n  }\n\n  .tabAlert{\n    color: red !important;\n  }\n\n  .recordsCount {\n    color: rgba(0, 0, 0, 0.25);\n    padding-left: 4px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabListItem": `Tab_tabListItem__rr57U`,
	"tabListActive": `Tab_tabListActive__6elUd`,
	"tabAlert": `Tab_tabAlert__xdLvs`,
	"recordsCount": `Tab_recordsCount__Gs1aT`
};
export default ___CSS_LOADER_EXPORT___;
