import { Link } from "react-router-dom";
import clsx from "clsx";
import Text from "../SixtyText";
import styles from "./index.module.css";

const defaultTextProps = {
  inline: true,
  color: "primary",
  type: "p1",
};

export default function SixtyLink({
  external = false,
  to,
  disableUnderline = true,
  children,
  ...rest
}) {
  return external ? (
    <a href={to} target={"_blank"} className={clsx(disableUnderline && styles.disableUnderline)}>
      <Text {...defaultTextProps} {...rest}>
        {children}
      </Text>
    </a>
  ) : (
    <Link to={to} className={clsx(disableUnderline && styles.disableUnderline)}>
      <Text {...defaultTextProps} {...rest}>
        {children}
      </Text>
    </Link>
  );
}
