import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import VendorProductTable from "../components/VendorProductTable";
import ProductPreviewDetails from "../components/ProductPreviewDetails";
import BlogProductTable from "../components/BlogProductTable";
import BlogProductDetails from "../components/BlogProductDetails";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import { setToast } from "components/Toast";
import BasicPageHeader from "../../Components/BasicHeader";
import Tabs from "components/Tabs";
import { useGetBlogProductCount, useGetBlogDetailById } from "queries/admin";
import Loader from "components/Loader";
import SixtyText from "components/SixtyText";

const BlogProducts = () => {
  
  const history = useNavigate();
  const params = useParams();

  const [isProductDetailOpen, setProductDetailOpen] = useState(false);
  const productDetailRef = useRef();
  const [selectedProduct, setSelectedProduct] = useState({});

  const [isBlogProductDetailOpen, setBlogProductDetailOpen] = useState(false);
  const blogProductDetailRef = useRef();
  const [selectedBlogProduct, setSelectedBlogProduct] = useState({});


  const {
    data: requestData,
  } = useGetBlogProductCount(params.id);

  const { 
    data: blogData, 
    isLoading: blogLoading 
  } = useGetBlogDetailById(params.id);
   
  const ProductDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedProduct.name} />
          </div>
          <div className={styles.modalTitle}>{selectedProduct.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setProductDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const BlogProductDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedBlogProduct.name} />
          </div>
          <div className={styles.modalTitle}>{selectedBlogProduct.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setBlogProductDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onCampaignProductClick = (value) => {
    setSelectedBlogProduct(value);
    setBlogProductDetailOpen(true);
  };

  const onProductClick = (value) => {  
    setSelectedProduct(value);
    setProductDetailOpen(true);
  };

  const onGoBack = () => {
    history(-1);
  };

  return (
    <div>
      {blogLoading && (
        <div style={{width:800, height:500}} >
             <Loader/>
        </div>
      )}
      {!blogLoading && blogData && (
      <>
         
          <SixtyText type="h1" color="secondary" className={styles.name}>
                {blogData?.title}
           </SixtyText>
        
          <BasicPageHeader title="Blog Products" onGoBack={onGoBack} />

            <Panel className="tabs">
              <Tabs
                activeTab={ "1" }
                tabs={[              
                  {
                    tabId: "1",
                    label: "Vendor Products",
                    TabComponent: VendorProductTable,
                    props: {
                      blogId: params.id,
                      onContentClick: onProductClick,
                      msg: "Search vendor products and add them to the blog."
                    },           
                  },
                  {
                    tabId: "2",
                    label: 'Blog Products',
                    recordsCount: requestData?.productCount,
                    TabComponent: BlogProductTable,
                    props: {
                      blogId: params.id,
                      onContentClick: onCampaignProductClick,
                      msg: "Manage blog products."                        
                    },
                  },
                ]}
              ></Tabs>
            </Panel>
        
       </>
      )}

      {isProductDetailOpen && (
        <Modal
          isOpen={isProductDetailOpen}
          onAttemptClose={() => {
            setProductDetailOpen(false);
          }}
          modalRef={productDetailRef}
          Title={ProductDetailTitle}
        >
          <ProductPreviewDetails
            selectedBlog={blogData}
            selectedProduct={selectedProduct}
            handleClose={() => setProductDetailOpen(false)}
          />
        </Modal>
      )}

      {isBlogProductDetailOpen && (
        <Modal
          isOpen={isBlogProductDetailOpen}
          onAttemptClose={() => {
            setBlogProductDetailOpen(false);
          }}
          modalRef={blogProductDetailRef}
          Title={BlogProductDetailTitle}
        >
          <BlogProductDetails
            selectedProduct={selectedBlogProduct}
            handleClose={() => setBlogProductDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default BlogProducts;
