import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import GridHeader from "components/GridHeader";
import Button from "components/SixtyButton";
import Input from "components/FormComponents/ControlledInput";
import { setToast } from "components/Toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    useUpdateCommission,
    useGetCommission,
} from "queries/setting";

import { format } from "date-fns";
import { reportError } from "crashlytics";
import _isEmpty from "lodash/isEmpty"; 
import CheckBox from "components/SixtyCheckBox";
  
const schema = yup.object().shape({
    
    commissionAmount: yup.number()
    .required("required")
    .min(0, "commission should be valid")
    .nullable()
  });

const SellerGlobalCommission = () =>{
    const { data: commissionData } = useGetCommission();
    
    const formMethods = useForm({
        resolver: yupResolver(schema),
      });
    const { control,setValue, errors } = formMethods;
    const [applied, setApply] = useState(true);

    const onAppliedToggle = (ev) => {
      if (ev.target.checked) { 
        setApply(false);       
      } else {
        setApply(true);
      }
    };

    useEffect(() => {
        if (commissionData) {
         
          for (const [key, value] of Object.entries({
            commissionAmount: commissionData?.result?.commissionAmount,
          })) {
            setValue(key, value);
          }
        }
      }, [setValue, commissionData]);
    const { mutateAsync: updateCommission, isLoading } = useUpdateCommission();
    const onSubmit = async (data) => {

        try {
         const result =  await updateCommission({
            commissionAmount: String(data?.commissionAmount),
            applyToProducts:applied
          });

          if (result) {
            setToast("Successfully saved seller commission amount");
          }
          
        } catch (error) {
          reportError("SetupSellerAccountInfo: onSubmit: error: ", error);
        }
      };
    return (
        <div className={styles.sellerGlobalCommission}>
      <GridHeader header="Affiliate Commission" />
      <div className={styles.accountSettingsBody}>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className={styles.form}
          >
            <Panel className={styles.panelWrap}>
            <div className={styles.formRow}>
                  <div className={styles.commissionInputContainer}>
                  
                    <div className={styles.commission}>
                      <Input
                        name="commissionAmount"
                        type="number"
                        control={control}
                        error={errors.commissionAmount}
                        defaultValue={undefined}
                        label="Commission Amount(%)"
                        step=".01"
                        min={0}
                        />
                    </div>
                  </div>
                 
            </div>
            <div className={styles.formRow}>
                    <CheckBox
                      name="isAppliedToProducts"    
                      label="Apply changes to existing products cost price"  
                      defaultChecked={applied}
                      onChange={onAppliedToggle}
                    />
             </div>
            </Panel>
            <div className={styles.footer}>
              <Button type="submit" disabled={isLoading}>Save</Button>
            </div>
            </form>
            </FormProvider> 
            </div>
            </div>
   )
}

export default SellerGlobalCommission;