// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Topbar_topbar__V9-\\+w {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1001;
  border-bottom: 1px solid #818181;
}

.Topbar_topbarCollapse__Bb4H1 {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1001;
  border-bottom: 1px solid #818181;
  padding-left: 200px;
}

.Topbar_leftContainer__jIo01 {
  display: flex;
  width: 60%;
}

.Topbar_leftContainer__jIo01 > *:last-child {
  margin-left: 96px;
}

.Topbar_rightContainer__YrDfm {
  display: flex;
  align-items: center;
}

.Topbar_rightContainer__YrDfm > *:not(:last-child) {
  margin-right: 16px;
}

.Topbar_arrowIcon__ptVcD {
  margin-left: 8px;
}

.Topbar_notificationBtn__LTj3L,
.Topbar_avatarBtn__slbbj {
  position: relative;
}

.Topbar_notificationsFlyout__jEQwp {
  position: absolute;
}

.Topbar_selectBox__13od- {
  font-family: var(--medium500);
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Topbar_selectBox__13od- span:first-child {
  padding-right: 0px;
}

.Topbar_optionBoxText__kG6U7,
.Topbar_selectBoxText__AKnn8 {
  font-family: var(--medium500);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.Topbar_optionsBox__MWLP9 {
  min-width: 160px;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Topbar/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,MAAM;EACN,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,MAAM;EACN,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,6BAA6B;EAC7B,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,QAAQ;AACV","sourcesContent":[".topbar {\n  padding: 16px 24px;\n  display: flex;\n  justify-content: space-between;\n  position: sticky;\n  top: 0;\n  width: 100%;\n  background-color: #fff;\n  z-index: 1001;\n  border-bottom: 1px solid #818181;\n}\n\n.topbarCollapse {\n  padding: 16px 24px;\n  display: flex;\n  justify-content: space-between;\n  position: sticky;\n  top: 0;\n  width: 100%;\n  background-color: #fff;\n  z-index: 1001;\n  border-bottom: 1px solid #818181;\n  padding-left: 200px;\n}\n\n.leftContainer {\n  display: flex;\n  width: 60%;\n}\n\n.leftContainer > *:last-child {\n  margin-left: 96px;\n}\n\n.rightContainer {\n  display: flex;\n  align-items: center;\n}\n\n.rightContainer > *:not(:last-child) {\n  margin-right: 16px;\n}\n\n.arrowIcon {\n  margin-left: 8px;\n}\n\n.notificationBtn,\n.avatarBtn {\n  position: relative;\n}\n\n.notificationsFlyout {\n  position: absolute;\n}\n\n.selectBox {\n  font-family: var(--medium500);\n  border-radius: 100px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n\n.selectBox span:first-child {\n  padding-right: 0px;\n}\n\n.optionBoxText,\n.selectBoxText {\n  font-family: var(--medium500);\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.5);\n}\n\n.optionsBox {\n  min-width: 160px;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topbar": `Topbar_topbar__V9-+w`,
	"topbarCollapse": `Topbar_topbarCollapse__Bb4H1`,
	"leftContainer": `Topbar_leftContainer__jIo01`,
	"rightContainer": `Topbar_rightContainer__YrDfm`,
	"arrowIcon": `Topbar_arrowIcon__ptVcD`,
	"notificationBtn": `Topbar_notificationBtn__LTj3L`,
	"avatarBtn": `Topbar_avatarBtn__slbbj`,
	"notificationsFlyout": `Topbar_notificationsFlyout__jEQwp`,
	"selectBox": `Topbar_selectBox__13od-`,
	"optionBoxText": `Topbar_optionBoxText__kG6U7`,
	"selectBoxText": `Topbar_selectBoxText__AKnn8`,
	"optionsBox": `Topbar_optionsBox__MWLP9`
};
export default ___CSS_LOADER_EXPORT___;
