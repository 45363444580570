import styles from "./index.module.css";
import clsx from "clsx";

const GeneralReport = ({
  title,
  currentMonthCount,
  unit,
  previousMonthCount,
  currentMonthNewRecord,
  className,
  showProfitInNewLine = false,
  hideMinusIcon = false,
  ...props
}) => {
  return (
    <div className={clsx(styles.GeneralReportContainer, className)}>
      <div className={styles.title}>{title}</div>
      <div className={clsx(styles.counts, props.countsHelper)}>
        <span className={styles.currentMonthCount}>{currentMonthCount}</span>
        {currentMonthNewRecord && (
          <span
            className={clsx({ [styles.profitInNewLine]: showProfitInNewLine })}
          >
            {currentMonthNewRecord >= 0 && (
              <span className={styles.profit}>
                {"+ " + currentMonthNewRecord}
              </span>
            )}
            {currentMonthNewRecord < 0 && (
              <span className={styles.loss}>{currentMonthNewRecord}</span>
            )}
            {!currentMonthNewRecord && !hideMinusIcon && <span>-</span>}
            {(currentMonthNewRecord ?? unit) && (
              <span
                className={clsx(styles.unit, {
                  [styles.profit]: currentMonthNewRecord >= 0,
                  [styles.loss]: currentMonthNewRecord < 0,
                })}
              >
                {" "}
                {unit}
              </span>
            )}
          </span>
        )}
      </div>
      {previousMonthCount && (
        <div className={styles.previousMonthCountText}>
          Compared to {previousMonthCount} last month
        </div>
      )}
      {props.children && <div>{props.children}</div>}
    </div>
  );
};

export default GeneralReport;
