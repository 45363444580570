import { useEffect } from 'react';

function useOnEscape(handler) {
    useEffect(
        () => {
            const handleEsc = event => {
                if(event.key === 'Esc' || event.key === 'Escape') {
                    handler(event);
                }
                return;
            };

            window.addEventListener('keydown', handleEsc);

            return () => {
                window.removeEventListener('keydown', handleEsc);
            };
        },
        [handler]
    );
}

export default useOnEscape;
