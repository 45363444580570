import React, { useEffect, useState } from "react";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import GridHeader from "components/GridHeader";
import PanelHeader from "components/PanelHeader";
import OrderNotificationSettings from "./components/OrderNotifications";
import SellerNotifications from "./components/SellerNotifications";
import { useGetNotificationSettings } from "queries/setting";
import { useImmer } from "utils/hooks/useImmer";

const NotificationSettings = () => {
  const { data } = useGetNotificationSettings();

  const [settingsState, setSettingsState] = useState({
    orderReceived: false,
    orderConfirmed: false,
    orderShipped: false,
    orderRefunded: false,
    sellerApplicationSubmitted: false,
    sellerApplicationApproved: false,
    sellerApplicationRejected: false,
  });

  useEffect(() => {
    if (data) setSettingsState(data);
  }, [data]);

  

  return (
    <div>
      <GridHeader header="Notification settings" />
      <div className={styles.billingPanel}>
        <Panel className={styles.panelWrap}>
          <PanelHeader title="Orders" />
          <OrderNotificationSettings
            state={settingsState}
            setState={setSettingsState}
          />
        </Panel>
        <Panel className={styles.panelWrap}>
          <PanelHeader title="Sellers" />
          <SellerNotifications
            state={settingsState}
            setState={setSettingsState}
          />
        </Panel>
      </div>
    </div>
  );
};

export default NotificationSettings;
