// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileSetupLayout_page__Dnbuc {
    --margin-top: 64px;
    width: 100%;
    min-height: calc(100vh - var(--margin-top));
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var( --margin-top );
    padding-top: 80px;
    background-color: #F9F8FA;
}

.ProfileSetupLayout_navbar__diZz5 {
    position: fixed;
    top: 0;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid var(--black10);
    padding: 16px 24px;
}`, "",{"version":3,"sources":["webpack://./src/routes/layouts/ProfileSetupLayout/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,2CAA2C;IAC3C,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,+BAA+B;IAC/B,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,MAAM;IACN,WAAW;IACX,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,uCAAuC;IACvC,kBAAkB;AACtB","sourcesContent":[".page {\n    --margin-top: 64px;\n    width: 100%;\n    min-height: calc(100vh - var(--margin-top));\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: var( --margin-top );\n    padding-top: 80px;\n    background-color: #F9F8FA;\n}\n\n.navbar {\n    position: fixed;\n    top: 0;\n    width: 100%;\n    height: 64px;\n    display: flex;\n    justify-content: space-between;\n    background-color: #fff;\n    border-bottom: 1px solid var(--black10);\n    padding: 16px 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `ProfileSetupLayout_page__Dnbuc`,
	"navbar": `ProfileSetupLayout_navbar__diZz5`
};
export default ___CSS_LOADER_EXPORT___;
