// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationSettings_billingPanel__EzMs1 {
    width: calc(95vw - 250px);
}

.NotificationSettings_panelWrap__5HUf8 {
    margin: 8px 8px;
    display: block;
    min-height: unset;
    position: relative;
}

.NotificationSettings_firstRow__j\\+L-X {
    padding: 8px;
    justify-content: space-between;
}

.NotificationSettings_firstRow__j\\+L-X .NotificationSettings_panelWrap__5HUf8  {
    margin: 0px;
}

.NotificationSettings_billingPanel__EzMs1 {
    display: flex;
    flex-direction: column;
    margin: 16px;
    margin-top: 12px;
}

.NotificationSettings_row__vOQ5h {  
    display: flex;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/screens/Settings/NotificationSettings/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".billingPanel {\n    width: calc(95vw - 250px);\n}\n\n.panelWrap {\n    margin: 8px 8px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.firstRow {\n    padding: 8px;\n    justify-content: space-between;\n}\n\n.firstRow .panelWrap  {\n    margin: 0px;\n}\n\n.billingPanel {\n    display: flex;\n    flex-direction: column;\n    margin: 16px;\n    margin-top: 12px;\n}\n\n.row {  \n    display: flex;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billingPanel": `NotificationSettings_billingPanel__EzMs1`,
	"panelWrap": `NotificationSettings_panelWrap__5HUf8`,
	"firstRow": `NotificationSettings_firstRow__j+L-X`,
	"row": `NotificationSettings_row__vOQ5h`
};
export default ___CSS_LOADER_EXPORT___;
