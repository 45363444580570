import React from "react";
import styles from "./index.module.css";
import clsx from "clsx";
import { ReactComponent as CheckIcon } from "../../assets/images/Icon-Success.svg";
import { ReactComponent as ArrowIcon } from "assets/images/Icon-Arrow.svg";

export const Step = ({ status, label, className }) => {
  const getBgStyle = () => {
    if (status === "active") {
      return "active";
    }
    if (status === "visited") {
      return "visited";
    }
    return "notReached";
  };
  return (
    <div className={clsx(styles.item, className)}>
      <div className={clsx(styles.progressIcon, styles[getBgStyle()])}>
        <span className={styles.checkIcon}>
          <CheckIcon />
        </span>
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

export default function Stepper({ status, labels }) {
  if (status.length !== labels.length) {
    console.error(
      "Stepper component Props mismatch, please pass correct status and labels"
    );
    return null;
  }

  return (
    <div className={styles.stepupContainer}>
      {status.map((step, index) => (
        <React.Fragment key={`${index} ${labels[index]}`}>
          <Step status={step} label={labels[index]} />
          {index < status.length - 1 && (
            <span
              style={{
                alignSelf: "center",
                width: 8,
                height: 12,
              }}
            >
              <ArrowIcon />
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
