import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SubscribeForm from "../components/SubscriptionForm";
import { useNavigate } from "react-router-dom";
import ProfileSetupLayout from "routes/layouts/ProfileSetupLayout";
import GridHeader from "components/GridHeader";
import BasicHeader from "../../../Components/BasicHeader";
import SixtyText from "components/SixtyText";
import SixtyLink from "components/SixtyLink";
import Button from "components/SixtyButton";
import styles from "./index.module.css";
import { useParams } from "react-router-dom";
import {
    useProceedCreditCardSubscription,
    useGetSubscriptionDetail,
  } from "queries/subscription";
import Panel from "components/SixtyPanel";
import Loader from "components/Loader";

export default function CreditCardSubscribe(){

    const { id } = useParams();
    const history = useNavigate();
    const { data: subscriptionDetail, isSuccess, isLoading } = useGetSubscriptionDetail(id);

    const redirectDashboard = () => {
        history("/dashboard");
    };

    const onGoBack = () => {
      history(-1);
    };
    
    const stripePromise = loadStripe('pk_live_wr06OHB57FwcvMuF1lCjltWn00gU7Y48bk');
    const headerName = subscriptionDetail?.freeTrial ? subscriptionDetail?.name + ' Plan Free Trial' :
                       subscriptionDetail?.name + ' Plan';

    return (
          <>
          <ProfileSetupLayout className={styles.profileLayoutHelper}>
            <div className={styles.container}>
            {isLoading && (
                <Loader />
             )}
              {isSuccess &&(
                <>
                  <BasicHeader
                    title={headerName}
                    onGoBack={onGoBack} 
                  />
                  <div className={styles.templateContainer}>
                  <Elements stripe={stripePromise}>
                    {subscriptionDetail && (
                      <SubscribeForm 
                        handleSuccess={redirectDashboard} 
                        id={id}
                        subscriptionReference={subscriptionDetail?.referenceSubscription}
                        data={subscriptionDetail} />
                    )}
                 </Elements>
                  </div>
                </>
              )}
              <footer className={styles.footer}>
                <hr className={styles.footerHr} />
                <div>
                  <SixtyText>
                    Need help?{" "}
                    <SixtyLink
                      color="orange"
                      inline
                      external
                      to={"mailto:hello@60sec.cc"}
                    >
                      Contact us
                    </SixtyLink>
                  </SixtyText>
                </div>
              </footer>
            </div>
          </ProfileSetupLayout>
      </>
    );
}



