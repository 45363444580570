import axios from "axios";
import { QueryKeys } from "../common/constants";
import { log } from "../utils/common";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../utils/storage";
import { queryClient } from "common/query";
import { setTokens } from "utils/auth";

//Create default axios instance
const api = axios.create();
api.defaults.baseURL = process.env.REACT_APP_API_URL;
api.defaults.timeout = 1200000;
// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Request interceptor
api.interceptors.request.use(
  function (config) {
    // const token = localStorage.getItem("token");
    // const tempTokens = queryClient.getQueryData(QueryKeys.tempTokens);
    // const authorization = tempTokens?.token || token;

    // //Set headers
    // if (authorization) config.headers.Authorization = `Bearer ${authorization}`;
    // if (!config.headers["Content-Type"])
    //   config.headers["Content-Type"] = "application/json";

    // if (config.headers["Content-Type"] === null) {
    //   delete config.headers["Content-Type"];
    // }

    // log("🙏 API Request", config.baseURL + config.url, "green", config);
    return config;
  },
  function (error) {
    log("💩 API Request Error", "Oops! Something went wrong.", "red", error);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  function (response) {
    log("🤘 API Response", response?.config?.url, "green", response);
    return response;
  },
  function (error) {
    const status = error?.response?.status;
    log("💀 API Response Error", error.request._url, "red", {
      error,
      request: error.request,
      response: error.response,
    });

    // const originalRequest = error.config;
    // const refreshToken = window.localStorage.getItem("refreshToken");

    // if (status === 401 && !originalRequest._retry && refreshToken) {
    //   console.log("#####", status);
    //   if (isRefreshing) {
    //     return new Promise(function (resolve, reject) {
    //       failedQueue.push({ resolve, reject });
    //     })
    //       .then((token) => {
    //         originalRequest.headers["Authorization"] = "Bearer " + token;
    //         return axios(originalRequest);
    //       })
    //       .catch((err) => {
    //         return Promise.reject(err);
    //       });
    //   }

    //   originalRequest._retry = true;
    //   isRefreshing = true;

    //   return new Promise(function (resolve, reject) {
    //     api
    //       .post("Account/RefreshToken", { refreshToken })
    //       .then(({ data }) => {
    //         setTokens(data?.result);
    //         api.defaults.headers.common["Authorization"] =
    //           "Bearer " + data.token;
    //         originalRequest.headers["Authorization"] = "Bearer " + data.token;
    //         processQueue(null, data.token);
    //         resolve(axios(originalRequest));
    //       })
    //       .catch((err) => {
    //         processQueue(err, null);
    //         reject(err);
    //       })
    //       .finally(() => {
    //         isRefreshing = false;
    //       });
    //   });
    // }
    return Promise.reject(error);
  }
);

export default api;
