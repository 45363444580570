// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SellersReport_row__E1g4x {
  display: flex;
  flex-wrap: wrap;
}

.SellersReport_viewRequestBtn__vR9ZO {
  /* border-top: 1px solid var(--black10Transparent); */
  color: var(--danger);
  font-size: 12px;
  font-family: var(--medium500);
  display: flex;
  justify-content: flex-end;
  padding: 12px 12px 12px 0px;
}

.SellersReport_sellersGeneralReport__Dg-P7 {
  width: 50%;
}

.SellersReport_totalApplied__lbd3g,
.SellersReport_totalConversionRate__Ey5IL {
  border-right: 1px solid var(--black10Transparent);
  border-bottom: 1px solid var(--black10Transparent);
}

.SellersReport_chartWrapper__\\+Z9GW {
  height: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Reports/components/SellersReport/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,qDAAqD;EACrD,oBAAoB;EACpB,eAAe;EACf,6BAA6B;EAC7B,aAAa;EACb,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,UAAU;AACZ;;AAEA;;EAEE,iDAAiD;EACjD,kDAAkD;AACpD;;AAEA;EACE,YAAY;AACd","sourcesContent":[".row {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.viewRequestBtn {\n  /* border-top: 1px solid var(--black10Transparent); */\n  color: var(--danger);\n  font-size: 12px;\n  font-family: var(--medium500);\n  display: flex;\n  justify-content: flex-end;\n  padding: 12px 12px 12px 0px;\n}\n\n.sellersGeneralReport {\n  width: 50%;\n}\n\n.totalApplied,\n.totalConversionRate {\n  border-right: 1px solid var(--black10Transparent);\n  border-bottom: 1px solid var(--black10Transparent);\n}\n\n.chartWrapper {\n  height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `SellersReport_row__E1g4x`,
	"viewRequestBtn": `SellersReport_viewRequestBtn__vR9ZO`,
	"sellersGeneralReport": `SellersReport_sellersGeneralReport__Dg-P7`,
	"totalApplied": `SellersReport_totalApplied__lbd3g`,
	"totalConversionRate": `SellersReport_totalConversionRate__Ey5IL`,
	"chartWrapper": `SellersReport_chartWrapper__+Z9GW`
};
export default ___CSS_LOADER_EXPORT___;
