// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SetupProfile_form__3mVqx {
  display: none;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
}

.SetupProfile_form__3mVqx > * {
  width: calc(50% - 8px);
  margin-bottom: 16px;
}

.SetupProfile_mobileInputContainer__Gfyb6 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.SetupProfile_countryCode__XOMV4 {
  width: calc(40% - 4px);
}

.SetupProfile_countryCode__XOMV4 > * {
  min-width: 100%;
}

.SetupProfile_phoneNumber__-VbIb {
  width: calc(60% - 4px);
}

.SetupProfile_fullWidth__NYDpk {
  min-width: 100%;
}

.SetupProfile_subHeader__-YW9O {
  margin-bottom: 24px;
}

.SetupProfile_footer__-g3qz {
  display: flex;
  justify-content: space-between;
}

.SetupProfile_activeForm__2Aw3x {
  display: flex;
}

.SetupProfile_dateFormat__2SoMQ {
  font-family: var(--regular400);
  font-size: 12px;
  color: var(--black50Transparent);
  padding-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Onboarding/SetupProfile/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,gCAAgC;EAChC,gBAAgB;AAClB","sourcesContent":[".form {\n  display: none;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  margin: 0;\n}\n\n.form > * {\n  width: calc(50% - 8px);\n  margin-bottom: 16px;\n}\n\n.mobileInputContainer {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n}\n\n.countryCode {\n  width: calc(40% - 4px);\n}\n\n.countryCode > * {\n  min-width: 100%;\n}\n\n.phoneNumber {\n  width: calc(60% - 4px);\n}\n\n.fullWidth {\n  min-width: 100%;\n}\n\n.subHeader {\n  margin-bottom: 24px;\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n}\n\n.activeForm {\n  display: flex;\n}\n\n.dateFormat {\n  font-family: var(--regular400);\n  font-size: 12px;\n  color: var(--black50Transparent);\n  padding-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `SetupProfile_form__3mVqx`,
	"mobileInputContainer": `SetupProfile_mobileInputContainer__Gfyb6`,
	"countryCode": `SetupProfile_countryCode__XOMV4`,
	"phoneNumber": `SetupProfile_phoneNumber__-VbIb`,
	"fullWidth": `SetupProfile_fullWidth__NYDpk`,
	"subHeader": `SetupProfile_subHeader__-YW9O`,
	"footer": `SetupProfile_footer__-g3qz`,
	"activeForm": `SetupProfile_activeForm__2Aw3x`,
	"dateFormat": `SetupProfile_dateFormat__2SoMQ`
};
export default ___CSS_LOADER_EXPORT___;
