import GeneralReport from "../GeneralReport";
import styles from "./index.module.css";
import clsx from "clsx";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import SixtyLink from "components/SixtyLink";

const data = [];

//Total applied sellers
data.push({
  data: [
    {
      label: "Dec 20",
      value: 52,
    },
    {
      label: "Nov 20",
      value: 37,
    },
  ],
});
//Total approved sellers
data.push({
  data: [
    {
      label: "Dec 20",
      value: 24,
    },
    {
      label: "Nov 20",
      value: 21,
    },
  ],
});
//Total conversion rate
data.push({
  data: [
    {
      label: "Dec 20",
      value: 40,
    },
    {
      label: "Nov 20",
      value: 30,
    },
  ],
});
//Total new seller
data.push({
  data: [
    {
      label: "Dec 20",
      value: 98,
    },
    {
      label: "Nov 20",
      value: 89,
    },
  ],
});

const SellersReport = ({ data }) => {
  return (
    <div className={styles.totalSellersReport}>
      <div className={styles.row}>
        {Array.isArray(data) &&
          data?.map((item) => (
            <GeneralReport
              key={item.position}
              title={item.title}
              currentMonthCount={item.total}
              previousMonthCount={item.previousMonthTotal}
              currentMonthNewRecord={item.currentMonthNewRecord}
              className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
            >
              {/* <div className={styles.chartWrapper}>
                        <HorizontalBarChart
                            data={data[0].data}
                            color="#3E517A"
                            tooltipText="applied sellers"
                        />
                    </div> */}
            </GeneralReport>
          ))}
      </div>
      <div className={styles.viewRequestBtn}>
        <SixtyLink
          type="p2"
          color="orange"
          weight="medium"
          inline
          to="/dashboard/creatorRequest"
        >
          View all requests
        </SixtyLink>
      </div>
    </div>
  );
};

export default SellersReport;
