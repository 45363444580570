import React, { useState, useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";

const RedirectUrlForm = () => {

  const { control, watch, errors } = useFormContext();

  return (
    <div className={styles.form}>

      <div style={{height:15}} ></div>
      <div className={styles.section}>Traffic Redirect</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
        <Input
          name="redirectUrl"
          error={errors.redirectUrl}
          control={control}
          label={"Redirect Url"}
          placeholder={"e.g https://shopee.com.my/"}
          defaultValue={null}
        />
      </div>

    </div>
  );
};

export default RedirectUrlForm;
