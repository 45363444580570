import React from "react";
import clsx from "clsx";
import { useGetVendorCreditBalance } from "queries/wallet";

import Panel from "components/SixtyPanel";
import BalanceDetails from "./components/BalanceDetails";
import BankAccountDetails from "./components/BankAccountDetails";
import RecentTransactions from "./components/RecentTransactions";
import GridHeader from "components/GridHeader";

import styles from "./index.module.css";

const WalletBalances = (props) => {
  const { data: wallet } = useGetVendorCreditBalance();

  return (
    <div>
      <GridHeader header="Wallet balances" />
      <div className={styles.walletBalancesPanel}>
        <div className={clsx(styles.row, styles.firstRow)}>
          <Panel className={styles.panelWrap}>
            <BalanceDetails balance={wallet?.credit} />
          </Panel>
          {/* <Panel className={styles.panelWrap}>
            <BankAccountDetails bankDetails={bankDetails} />
          </Panel> */}
        </div>
        <div className={styles.row}>
          <Panel className={styles.panelWrap}>
            <div className={styles.transactionTable}>
              <RecentTransactions />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default WalletBalances;
