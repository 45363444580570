import React, { useState, useEffect, useRef  } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { useProgressUploads, useUploads } from "queries/media";
import { Editor } from '@tinymce/tinymce-react';
import Button from "components/SixtyButton";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";

const Form = ({selectedBlog, setContent}) => {

  const editorRef = useRef(null);
  
  const [replaceImage, setReplaceImage] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState([]);
  const { control, watch, errors } = useFormContext();
 
  const onProfilePhotoDelete = () => {
    setProfilePhoto([]);
  };

  const {
    mutateAsync: uploadFiles,
    isLoading: uploadingFiles,
  } = useUploads();

  async function onUploadImage(blobInfo, progress){
    const images = new FormData();

    images.append(`files[0].fileName`, blobInfo.filename());
    images.append("files[0].file", blobInfo.blob());

    var response = await uploadFiles(images);

    return response[0].path;
  }

  const onReplace = async () => {
    setReplaceImage(true);
  };


  return (
    <div className={styles.form}>
      <div className={styles.section}>Media</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
      <div className={styles.subHeader}>
        {!replaceImage && (
              <>
                <span> Uploaded Image </span>
                <div className={styles.profPhotoContainer}>
                  <div className={styles.profPhotoDropzone}>
                  <img
                    src={selectedBlog?.imageUrl}
                    className={styles.img}
                    alt="No preview"
                  />
                  </div>
                  <div className={styles.actionButtonsContainer}>
                      <div onClick={onReplace}>
                        <Button label="Replace" variant="secondary" />
                      </div>
                  </div>
                </div>
              </>
          )}
          {replaceImage && (
              <>
                <span> Main image </span>
                <div className={styles.profPhotoContainer}>
                  <div className={styles.profPhotoDropzone}>
                    <ImageDropzone
                      name="replaceImageUrl"
                      control={control}
                      noClick
                      showButton
                      buttonLabel="Upload"
                      DefaultThumb={IconSingleImageDrop}
                      maximumFiles={1}
                      onDropAccepted={(name, selectedFiles) => {
                        setProfilePhoto(selectedFiles);
                      }}
                      selectedFiles={profilePhoto}
                    />
                  </div>
                  {profilePhoto.length > 0 && (
                    <div className={styles.actionButtonsContainer}>
                      <div onClick={onProfilePhotoDelete}>
                        <Button label="Delete" variant="secondary" />
                      </div>
                    </div>
                  )}
                </div>
              </>
          )}
      </div>
      </div>
      <div style={{height:15}} ></div>
      <div className={styles.section}>Blog Title</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
         <Input
           name="title"
           label="Title"
           defaultValue=""
         />
      </div>

      <div style={{height:15}} ></div>
      <div className={styles.section}>Seo Content</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
         <Input
           name="keywords"
           label="Keywords e.g 60sec,nike,shoe"
           defaultValue=""
         />
      </div>
      <div className={styles.fullFormRow}>
        <Input
           name="description"
           label="Preview Description"
           lines={6}
           defaultValue=""
         />
      </div>

      <div style={{height:15}} ></div>
      <div className={styles.section}>Content</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
      <Editor
         apiKey='wsg1700p2ol4tgi8trs4h0zn4p5j8804yg2b6hk2t9ehbqn5'
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue={selectedBlog?.content}
         init={{
             height:700,
             plugins: 'image lists link paste help wordcount table charmap print preview anchor advlist autolink' +
                       'searchreplace visualblocks code fullscreen' +
                       'insertdatetime media code help',
             toolbar:  'undo redo | formatselect | ' +
                       'bold italic backcolor | alignleft aligncenter ' +
                       'alignright alignjustify | bullist numlist outdent indent | ' +
                       'removeformat | help',
             images_file_types: 'jpg,svg,webp',
             images_upload_handler: (blobInfo, progress) => onUploadImage(blobInfo, progress)
         }}
         onEditorChange={(newText) => setContent(newText)}
       />
      </div>

    </div>
  );
};

export default Form;
