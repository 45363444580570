// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionPlan_container__aka2\\+ {
    width: 80%;
    padding-bottom: 40px;
  }
  .SubscriptionPlan_templateContainer__Zjg-i {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .SubscriptionPlan_pricingPlanHeader__0mWSt {
    width: 100%;
    margin: 24px 8px 16px 8px;
  }
  
  .SubscriptionPlan_profileLayoutHelper__kY1Cw {
    padding-top: 0px;
  }
  
  .SubscriptionPlan_footerHr__axfwc {
    width: 100%;
    margin: 24px 16px;
  }
  
  .SubscriptionPlan_footer__kUUNJ {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

@media (max-width:800px) { 
   .SubscriptionPlan_templateContainer__Zjg-i {
     display:block;
    }
    .SubscriptionPlan_footer__kUUNJ{
      display: none;
    }
}
  
  `, "",{"version":3,"sources":["webpack://./src/screens/Onboarding/SubscriptionPlan/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oBAAoB;EACtB;EACA;IACE,aAAa;IACb,WAAW;IACX,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;AAEF;GACG;KACE,aAAa;IACd;IACA;MACE,aAAa;IACf;AACJ","sourcesContent":[".container {\n    width: 80%;\n    padding-bottom: 40px;\n  }\n  .templateContainer {\n    display: flex;\n    width: 100%;\n    justify-content: center;\n  }\n  \n  .pricingPlanHeader {\n    width: 100%;\n    margin: 24px 8px 16px 8px;\n  }\n  \n  .profileLayoutHelper {\n    padding-top: 0px;\n  }\n  \n  .footerHr {\n    width: 100%;\n    margin: 24px 16px;\n  }\n  \n  .footer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n@media (max-width:800px) { \n   .templateContainer {\n     display:block;\n    }\n    .footer{\n      display: none;\n    }\n}\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SubscriptionPlan_container__aka2+`,
	"templateContainer": `SubscriptionPlan_templateContainer__Zjg-i`,
	"pricingPlanHeader": `SubscriptionPlan_pricingPlanHeader__0mWSt`,
	"profileLayoutHelper": `SubscriptionPlan_profileLayoutHelper__kY1Cw`,
	"footerHr": `SubscriptionPlan_footerHr__axfwc`,
	"footer": `SubscriptionPlan_footer__kUUNJ`
};
export default ___CSS_LOADER_EXPORT___;
