// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecentTransactions_transactionTableHeader__KL1IN {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.RecentTransactions_gridHeader__r1\\+gX {
  font-family: var(--medium500);
  font-size: 16px;
  color: var(--black);
}

.RecentTransactions_ButtonsContainer__tyPBx {
  display: flex;
  align-items: center;
}

.RecentTransactions_ButtonsContainer__tyPBx > div {
  margin: 4px;
}

.RecentTransactions_exportBtn__NA4k-,
.RecentTransactions_addDiscountBtn__XATP7 {
  font-family: var(--medium500);
  color: var(--black50Transparent);
  font-size: 14px;
}

.RecentTransactions_firstColumn__KBfrK {
  padding: 0px 16px;
}

.RecentTransactions_credit__Rs0hD {
  color: var(--success);
}

.RecentTransactions_calendarIcon__dcKye {
  margin-right: 5px;
}

.RecentTransactions_downloadAsPdf__W7yIq {
  color: var(--success);
}
`, "",{"version":3,"sources":["webpack://./src/screens/WalletBalances/components/RecentTransactions/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;;EAEE,6BAA6B;EAC7B,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".transactionTableHeader {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n  padding: 16px;\n}\n\n.gridHeader {\n  font-family: var(--medium500);\n  font-size: 16px;\n  color: var(--black);\n}\n\n.ButtonsContainer {\n  display: flex;\n  align-items: center;\n}\n\n.ButtonsContainer > div {\n  margin: 4px;\n}\n\n.exportBtn,\n.addDiscountBtn {\n  font-family: var(--medium500);\n  color: var(--black50Transparent);\n  font-size: 14px;\n}\n\n.firstColumn {\n  padding: 0px 16px;\n}\n\n.credit {\n  color: var(--success);\n}\n\n.calendarIcon {\n  margin-right: 5px;\n}\n\n.downloadAsPdf {\n  color: var(--success);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionTableHeader": `RecentTransactions_transactionTableHeader__KL1IN`,
	"gridHeader": `RecentTransactions_gridHeader__r1+gX`,
	"ButtonsContainer": `RecentTransactions_ButtonsContainer__tyPBx`,
	"exportBtn": `RecentTransactions_exportBtn__NA4k-`,
	"addDiscountBtn": `RecentTransactions_addDiscountBtn__XATP7`,
	"firstColumn": `RecentTransactions_firstColumn__KBfrK`,
	"credit": `RecentTransactions_credit__Rs0hD`,
	"calendarIcon": `RecentTransactions_calendarIcon__dcKye`,
	"downloadAsPdf": `RecentTransactions_downloadAsPdf__W7yIq`
};
export default ___CSS_LOADER_EXPORT___;
