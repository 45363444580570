// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Reports_reports__8OSNL {
    border-bottom: 1px solid var(--black10Transparent);
}

.Reports_countsHelper__flhvm {
    display: flex;
    align-items: center;
    padding-bottom: 0px;
}

.Reports_subValue__mQGZr {
    font-family: var(--regular400);
    font-size: 12px;
    color: var(--black50Transparent);
}`, "",{"version":3,"sources":["webpack://./src/screens/AdminScreens/Dashboard/components/Reports/index.module.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;AACtD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;AACpC","sourcesContent":[".reports {\n    border-bottom: 1px solid var(--black10Transparent);\n}\n\n.countsHelper {\n    display: flex;\n    align-items: center;\n    padding-bottom: 0px;\n}\n\n.subValue {\n    font-family: var(--regular400);\n    font-size: 12px;\n    color: var(--black50Transparent);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reports": `Reports_reports__8OSNL`,
	"countsHelper": `Reports_countsHelper__flhvm`,
	"subValue": `Reports_subValue__mQGZr`
};
export default ___CSS_LOADER_EXPORT___;
