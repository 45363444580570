// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .BasicReports_row__F3I3x {
    display: flex;
    flex-wrap: wrap;
  }
  
  .BasicReports_viewRequestBtn__3jHet {
    /* border-top: 1px solid var(--black10Transparent); */
    color: var(--danger);
    font-size: 12px;
    font-family: var(--medium500);
    display: flex;
    justify-content: flex-end;
    padding: 12px 12px 12px 0px;
  }
  
  .BasicReports_sellersGeneralReport__w2zqN {
    width: 50%;
  }
  
  .BasicReports_totalApplied__Ua1HU,
  .BasicReports_totalConversionRate__USouz {
    border-right: 1px solid var(--black10Transparent);
    border-bottom: 1px solid var(--black10Transparent);
  }
  
  .BasicReports_chartWrapper__P5QGe {
    height: 60px;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/AdminScreens/Dashboard/components/BasicReports/index.module.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,qDAAqD;IACrD,oBAAoB;IACpB,eAAe;IACf,6BAA6B;IAC7B,aAAa;IACb,yBAAyB;IACzB,2BAA2B;EAC7B;;EAEA;IACE,UAAU;EACZ;;EAEA;;IAEE,iDAAiD;IACjD,kDAAkD;EACpD;;EAEA;IACE,YAAY;EACd","sourcesContent":["  .row {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  \n  .viewRequestBtn {\n    /* border-top: 1px solid var(--black10Transparent); */\n    color: var(--danger);\n    font-size: 12px;\n    font-family: var(--medium500);\n    display: flex;\n    justify-content: flex-end;\n    padding: 12px 12px 12px 0px;\n  }\n  \n  .sellersGeneralReport {\n    width: 50%;\n  }\n  \n  .totalApplied,\n  .totalConversionRate {\n    border-right: 1px solid var(--black10Transparent);\n    border-bottom: 1px solid var(--black10Transparent);\n  }\n  \n  .chartWrapper {\n    height: 60px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `BasicReports_row__F3I3x`,
	"viewRequestBtn": `BasicReports_viewRequestBtn__3jHet`,
	"sellersGeneralReport": `BasicReports_sellersGeneralReport__w2zqN`,
	"totalApplied": `BasicReports_totalApplied__Ua1HU`,
	"totalConversionRate": `BasicReports_totalConversionRate__USouz`,
	"chartWrapper": `BasicReports_chartWrapper__P5QGe`
};
export default ___CSS_LOADER_EXPORT___;
