import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import StripeCreditCard from "./components/StripeCreditCard";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import SixtyButton from "components/SixtyButton";
import { ReactComponent as LockIcon } from "assets/icons/Icon-Lock.svg";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import { ReactComponent as OptionalIcon } from "assets/icons/Icon-Optional.svg";
import {
    useGetMemberBilling,
    useGetCustomerDefaultPaymentMethod,
    useActivateCancelledSubscription,
    useChangeSubscription,
    useGetSubscriptionDetail
} from "queries/subscription";
import Loader from "components/Loader";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ReactComponent as IconAmex } from "assets/icons/Icon-amex-v2.svg";
import { ReactComponent as IconMaster } from "assets/icons/Icon-mastercard-v2.svg";
import { ReactComponent as IconVisa } from "assets/icons/Icon-visa-v3.svg";
import { ReactComponent as IconLock } from "assets/icons/Icon-lockv3.svg";

let defaultValues = {
    creditCardNumber: "",
    expirationDate: "",
};

export default function AddEditCardDetailsForm({
    handleCancel,
}) {

    const stripePromise = loadStripe('pk_live_wr06OHB57FwcvMuF1lCjltWn00gU7Y48bk');
    const { data: paymentData, refetch, isLoading: isPaymentLoad } = useGetCustomerDefaultPaymentMethod();

    const formMethods = useForm({
        defaultValues,
    });

    const { setValue } = formMethods;

    useEffect(() => {

      if (paymentData) {   
        for (const [key, value] of Object.entries({
            creditCardNumber: paymentData.name,
            expirationDate: paymentData.expired,
        })) {
          setValue(key, value);
        }
      }
        
    }, [paymentData]);

    const onSubmit = (data) => {
      console.log("data from form", data);
    };

    const onClose = (data) => {
        refetch();
        handleCancel();
    };

    return (
        <>
        {isPaymentLoad && (
           <Loader/>
        )}
         {!isPaymentLoad && (
            <div className={styles.container}>
                <FormProvider {...formMethods}>
                   <form
                       onSubmit={formMethods.handleSubmit(onSubmit)}
                       className={styles.form}
                   >
                       <div className={styles.formHeader}>
                           <div className={styles.leftHeader}>Card details</div>
                           <div className={styles.rightHeader}>
                               <span className={styles.lockIcon}><LockIcon /></span>
                               <span>Secure credit card payment</span>
                           </div>
                       </div>
                       <div className={styles.formHeader}>
                           <div className={styles.leftHeader}>
                            <IconMaster className={styles.icon} />   
                            <IconVisa className={styles.icon} />   
                            <IconAmex className={styles.icon} /> 
                           </div>
                       </div>
                       <div className={styles.formHeader}>
                           <div className={styles.leftHeader}>
                             Active Card details
                           </div>
                       </div>
                       <div className={styles.formRow}>
                           <Input
                               name="creditCardNumber"
                               rules={{ required: "required" }}
                               label="Credit card number"
                               defaultValue=""
                               disabled={true}
                           />
                           <Input
                               name="expirationDate"
                               label="Expiration date"
                               defaultValue=""
                               disabled={true}
                           />
                       </div>
                       <div className={styles.formHeader}>
                           <div className={styles.leftHeader}>
                             New Card details
                           </div>
                       </div>
                       <Elements stripe={stripePromise}>
                         <StripeCreditCard onClose={onClose} />
                       </Elements>
                       {/* <footer className={styles.footer}>
                           <SixtyButton type="submit">Update</SixtyButton>
                           <SixtyButton variant="secondary" className={styles.cancelButton} onClick={handleCancel}>Cancel</SixtyButton>
                       </footer> */}
                   </form>
               </FormProvider>
               </div>
           )}
     </>
    );
}
