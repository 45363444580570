// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddBankAccountForm_form__VDyvt {
    padding: 16px;
}

.AddBankAccountForm_footer__4RxVu {
    padding: 16px;
    border-top: 1px solid var(--black10);
}

.AddBankAccountForm_addAccountBtn__VnX4Z {
    margin-right: 8px;
}

.AddBankAccountForm_fullName__SkdWq, .AddBankAccountForm_accountNumber__ShuAF {
    padding-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/screens/WalletBalances/components/AddBankAccountForm/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".form {\n    padding: 16px;\n}\n\n.footer {\n    padding: 16px;\n    border-top: 1px solid var(--black10);\n}\n\n.addAccountBtn {\n    margin-right: 8px;\n}\n\n.fullName, .accountNumber {\n    padding-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `AddBankAccountForm_form__VDyvt`,
	"footer": `AddBankAccountForm_footer__4RxVu`,
	"addAccountBtn": `AddBankAccountForm_addAccountBtn__VnX4Z`,
	"fullName": `AddBankAccountForm_fullName__SkdWq`,
	"accountNumber": `AddBankAccountForm_accountNumber__ShuAF`
};
export default ___CSS_LOADER_EXPORT___;
