// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionPlan_container__3Evcf {
    width: 80%;
    padding-bottom: 40px;
  }
  .SubscriptionPlan_templateContainer__CDS2L {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .SubscriptionPlan_pricingPlanHeader__v7xmp {
    width: 100%;
    margin: 24px 8px 16px 8px;
  }
  
  .SubscriptionPlan_profileLayoutHelper__Vwd5c {
    padding-top: 0px;
  }
  
  .SubscriptionPlan_footerHr__-UiQk {
    width: 100%;
    margin: 24px 16px;
  }
  
  .SubscriptionPlan_footer__uQEwJ {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .SubscriptionPlan_modalTitleBar__jG2qV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
} 
  `, "",{"version":3,"sources":["webpack://./src/screens/Settings/Billing/SubscriptionPlan/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oBAAoB;EACtB;EACA;IACE,aAAa;IACb,WAAW;IACX,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".container {\n    width: 80%;\n    padding-bottom: 40px;\n  }\n  .templateContainer {\n    display: flex;\n    width: 100%;\n    justify-content: center;\n  }\n  \n  .pricingPlanHeader {\n    width: 100%;\n    margin: 24px 8px 16px 8px;\n  }\n  \n  .profileLayoutHelper {\n    padding-top: 0px;\n  }\n  \n  .footerHr {\n    width: 100%;\n    margin: 24px 16px;\n  }\n  \n  .footer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .modalTitleBar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n} \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SubscriptionPlan_container__3Evcf`,
	"templateContainer": `SubscriptionPlan_templateContainer__CDS2L`,
	"pricingPlanHeader": `SubscriptionPlan_pricingPlanHeader__v7xmp`,
	"profileLayoutHelper": `SubscriptionPlan_profileLayoutHelper__Vwd5c`,
	"footerHr": `SubscriptionPlan_footerHr__-UiQk`,
	"footer": `SubscriptionPlan_footer__uQEwJ`,
	"modalTitleBar": `SubscriptionPlan_modalTitleBar__jG2qV`
};
export default ___CSS_LOADER_EXPORT___;
