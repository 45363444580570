// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PanelHeader_panelTitle__6SGYS {
    font-family: var(--medium500);
    font-size: 18px;
    color: #000;
    padding: 12px 16px;
    border-bottom: 1px solid var(--black10Transparent);
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PanelHeader_panelSubTitle__ccWCf {
    font-family: var(--regular400);
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/PanelHeader/index.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,kDAAkD;IAClD,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".panelTitle {\n    font-family: var(--medium500);\n    font-size: 18px;\n    color: #000;\n    padding: 12px 16px;\n    border-bottom: 1px solid var(--black10Transparent);\n    min-height: 48px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.panelSubTitle {\n    font-family: var(--regular400);\n    font-size: 12px;\n    color: rgba(0, 0, 0, 0.5);\n    padding-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelTitle": `PanelHeader_panelTitle__6SGYS`,
	"panelSubTitle": `PanelHeader_panelSubTitle__ccWCf`
};
export default ___CSS_LOADER_EXPORT___;
