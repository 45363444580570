import {produce, setAutoFreeze } from "immer";
import { useState, useReducer, useCallback, useMemo } from "react";

export function useImmer(initialValue) {
  setAutoFreeze(false);
  const [val, updateValue] = useState(initialValue);
  return [
    val,
    useCallback((updater) => {
      updateValue(produce(updater));
    }, []),
  ];
}

export function useImmerReducer(reducer, initialState, initialAction) {
  const cachedReducer = useMemo(() => produce(reducer), [reducer]);
  return useReducer(cachedReducer, initialState, initialAction);
}
