// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyGrid_emptyGrid__NfR9Q {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 177px;
  justify-content: center;
  padding: 24px;
}

.EmptyGrid_emptyText__ivveV {
  padding-top: 16px;
  font-family: var(--medium500);
  font-size: 14px;
  color: #000;
}

.EmptyGrid_icon__Yffh\\+ {
  min-height: 48px;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E6E6E6;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmptyGrid/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".emptyGrid {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  min-height: 177px;\n  justify-content: center;\n  padding: 24px;\n}\n\n.emptyText {\n  padding-top: 16px;\n  font-family: var(--medium500);\n  font-size: 14px;\n  color: #000;\n}\n\n.icon {\n  min-height: 48px;\n  min-width: 48px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #E6E6E6;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyGrid": `EmptyGrid_emptyGrid__NfR9Q`,
	"emptyText": `EmptyGrid_emptyText__ivveV`,
	"icon": `EmptyGrid_icon__Yffh+`
};
export default ___CSS_LOADER_EXPORT___;
