import clsx from "clsx";
import { useGetShippingRegionDropdown } from "queries/shipping";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";

import Select from "components/SixtySelect";

import styles from "./index.module.css";
import SixtyButton from "components/SixtyButton";

export default function RegionFields({ formMethods }) {
  const [selectedRegion, setSelectedRegion] = useState("");

  const { control, watch } = formMethods;

  const {
    fields: regionFields,
    append: appendRegion,
    remove: removeRegion,
  } = useFieldArray({
    control,
    name: "shippingRegions",
  });

  const shippingRegions = watch("shippingRegions");

  const {
    data: shippingRegionsOptions,
    isLoading: regionsLoading,
  } = useGetShippingRegionDropdown({});

  const regionOptions = () => {
    if (shippingRegionsOptions?.length > 0) {
      if (shippingRegions?.length > 0) {
        let options = shippingRegionsOptions.filter((option) => {
          let present = false;
          shippingRegions.forEach((region) => {
            if (region?.regionId === option?.id) {
              present = true;
            }
          });
          return !present;
        }, []);
        return (
          options.map((option) => ({
            label: option.name,
            value: option.id,
          })) || []
        );
      } else {
        return (
          shippingRegionsOptions.map((option) => ({
            label: option.name,
            value: option.id,
          })) || []
        );
      }
    } else {
      return [];
    }
  };

  const onAddRegion = () => {
    if (!!selectedRegion) {
      appendRegion({
        regionId: selectedRegion,
      });
      setSelectedRegion("");
    }
  };
  return (
    <>
      <div className={clsx(styles.subForm, styles.countries)}>
        <div className={styles.regionField}>
          <Select
            label={regionsLoading ? "Loading..." : "Select Region"}
            options={regionOptions()}
            value={selectedRegion}
            onChange={(value) => {
              setSelectedRegion(value);
            }}
            fullWidth
          />
        </div>
        <SixtyButton
          label="Add Region"
          variant="secondary"
          className={styles.addCountryBtn}
          onClick={onAddRegion}
        />
      </div>
      {regionFields?.length > 0 &&
        regionFields?.map((region, index) => {
          let regionOption = shippingRegionsOptions?.find(
            (option) => option.id === region?.regionId
          );
          return (
            index < shippingRegions.length && (
              <div key={region.id} className={styles.selectedCountryContainer}>
                <div className={styles.leftContainer}>
                  <div className={styles.countryName}>{regionOption?.name}</div>
                </div>
                <div>
                  <SixtyButton
                    variant="text"
                    label="Remove"
                    onClick={() => removeRegion(index)}
                  />
                </div>
              </div>
            )
          );
        })}
    </>
  );
}
