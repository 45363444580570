import { setToast } from "components/Toast";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";

export function useGetCompanyBankAccount() {
  return useQuery(
    Endpoints.BankAccount.GetCompanyBankAccount,
    () =>
      api
        .get(Endpoints.BankAccount.GetCompanyBankAccount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetCompanyBankAccount: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useSetupCompanyBankAccount() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.BankAccount.SetupCompanyBankAccount, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useSetupCompanyBankAccount: useQuery: onError: error", error);
      },
    }
  );
}
