// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminTopbar_topbar__ysw3N {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 1001;
  border-bottom: 1px solid var(--black10);
}

.AdminTopbar_leftContainer__0n7cS {
  display: flex;
  width: 60%;
}

.AdminTopbar_leftContainer__0n7cS > *:last-child {
  margin-left: 96px;
}

.AdminTopbar_rightContainer__jvoWZ {
  display: flex;
  align-items: center;
}

.AdminTopbar_rightContainer__jvoWZ > *:not(:last-child) {
  margin-right: 16px;
}

.AdminTopbar_arrowIcon__lT0bz {
  margin-left: 8px;
}

.AdminTopbar_notificationBtn__HUaKJ,
.AdminTopbar_avatarBtn__GgXAc {
  position: relative;
}

.AdminTopbar_notificationsFlyout__JdhpM {
  position: absolute;
}

.AdminTopbar_selectBox__XGc\\+c {
  font-family: var(--medium500);
  border-radius: 100px;
  border: 1px solid var(--black10Transparent);
}

.AdminTopbar_selectBox__XGc\\+c span:first-child {
  padding-right: 0px;
}

.AdminTopbar_optionBoxText__X5do4,
.AdminTopbar_selectBoxText__D7fyq {
  font-family: var(--medium500);
  font-size: 14px;
  color: var(--black50Transparent);
}

.AdminTopbar_optionsBox__XZQhV {
  min-width: 160px;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminTopbar/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,MAAM;EACN,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,6BAA6B;EAC7B,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,QAAQ;AACV","sourcesContent":[".topbar {\n  padding: 16px 24px;\n  display: flex;\n  justify-content: space-between;\n  position: sticky;\n  top: 0;\n  width: 100%;\n  background: #fff;\n  z-index: 1001;\n  border-bottom: 1px solid var(--black10);\n}\n\n.leftContainer {\n  display: flex;\n  width: 60%;\n}\n\n.leftContainer > *:last-child {\n  margin-left: 96px;\n}\n\n.rightContainer {\n  display: flex;\n  align-items: center;\n}\n\n.rightContainer > *:not(:last-child) {\n  margin-right: 16px;\n}\n\n.arrowIcon {\n  margin-left: 8px;\n}\n\n.notificationBtn,\n.avatarBtn {\n  position: relative;\n}\n\n.notificationsFlyout {\n  position: absolute;\n}\n\n.selectBox {\n  font-family: var(--medium500);\n  border-radius: 100px;\n  border: 1px solid var(--black10Transparent);\n}\n\n.selectBox span:first-child {\n  padding-right: 0px;\n}\n\n.optionBoxText,\n.selectBoxText {\n  font-family: var(--medium500);\n  font-size: 14px;\n  color: var(--black50Transparent);\n}\n\n.optionsBox {\n  min-width: 160px;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topbar": `AdminTopbar_topbar__ysw3N`,
	"leftContainer": `AdminTopbar_leftContainer__0n7cS`,
	"rightContainer": `AdminTopbar_rightContainer__jvoWZ`,
	"arrowIcon": `AdminTopbar_arrowIcon__lT0bz`,
	"notificationBtn": `AdminTopbar_notificationBtn__HUaKJ`,
	"avatarBtn": `AdminTopbar_avatarBtn__GgXAc`,
	"notificationsFlyout": `AdminTopbar_notificationsFlyout__JdhpM`,
	"selectBox": `AdminTopbar_selectBox__XGc+c`,
	"optionBoxText": `AdminTopbar_optionBoxText__X5do4`,
	"selectBoxText": `AdminTopbar_selectBoxText__D7fyq`,
	"optionsBox": `AdminTopbar_optionsBox__XZQhV`
};
export default ___CSS_LOADER_EXPORT___;
