// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogProducts_vendorTable__3qPsY {
    width: calc(95vw - 250px);
}

.BlogProducts_panelWrap__668RN {
    margin: 20px;
    display: block;
    min-height: unset;
    position: relative;
}

.BlogProducts_gridHeader__Q3cnK {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
}

.BlogProducts_ButtonsContainer__4lEXH {
    display: flex;
    align-items: center;
}

.BlogProducts_exportBtn__97Q5O {
    font-family: var(--medium500);
    color: var(--black50Transparent);
    font-size: 14px;
}

.BlogProducts_vendorPageHeaderContainer__mIc7a {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 8px;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
    margin-bottom: 16px;
}

.BlogProducts_modalTitle__obcvy {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
    padding-left: 8px;
}

.BlogProducts_vendorDetailModalTitleBar__9Mao\\+ {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}   

.BlogProducts_vendorNameContainer__ZlsQI {
    display: flex;
    align-items: center;
}

.BlogProducts_closeIcon__HSSZc {
    cursor: pointer;
}

.BlogProducts_name__RFknl{
    padding: 15px;
    width: 100%;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Blogs/BlogProducts/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".vendorTable {\n    width: calc(95vw - 250px);\n}\n\n.panelWrap {\n    margin: 20px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.gridHeader {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n}\n\n.ButtonsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.exportBtn {\n    font-family: var(--medium500);\n    color: var(--black50Transparent);\n    font-size: 14px;\n}\n\n.vendorPageHeaderContainer {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0px 8px;\n    justify-content: space-between;\n    align-items: center;\n    margin: 24px;\n    margin-bottom: 16px;\n}\n\n.modalTitle {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n    padding-left: 8px;\n}\n\n.vendorDetailModalTitleBar {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: space-between;\n}   \n\n.vendorNameContainer {\n    display: flex;\n    align-items: center;\n}\n\n.closeIcon {\n    cursor: pointer;\n}\n\n.name{\n    padding: 15px;\n    width: 100%;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vendorTable": `BlogProducts_vendorTable__3qPsY`,
	"panelWrap": `BlogProducts_panelWrap__668RN`,
	"gridHeader": `BlogProducts_gridHeader__Q3cnK`,
	"ButtonsContainer": `BlogProducts_ButtonsContainer__4lEXH`,
	"exportBtn": `BlogProducts_exportBtn__97Q5O`,
	"vendorPageHeaderContainer": `BlogProducts_vendorPageHeaderContainer__mIc7a`,
	"modalTitle": `BlogProducts_modalTitle__obcvy`,
	"vendorDetailModalTitleBar": `BlogProducts_vendorDetailModalTitleBar__9Mao+`,
	"vendorNameContainer": `BlogProducts_vendorNameContainer__ZlsQI`,
	"closeIcon": `BlogProducts_closeIcon__HSSZc`,
	"name": `BlogProducts_name__RFknl`
};
export default ___CSS_LOADER_EXPORT___;
