// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BusinessInfoForm_subHeader__Ih\\+kN {
  font-family: var(--regular400);
  font-size: 14px;
  color: var(--black50Transparent);
  padding: 16px;
  border-bottom: 1px solid var(--black10Transparent);
}

.BusinessInfoForm_companyLogoContainer__siWvU {
  display: flex;
  padding-top: 9px;
}

.BusinessInfoForm_companyLogoDropzone__r4IDp {
  width: fit-content;
  padding-right: 24px;
}

.BusinessInfoForm_actionButtonsContainer__nEVjZ {
  padding: 24px 0px;
  display: flex;
  align-items: center;
}

.BusinessInfoForm_actionButtonsContainer__nEVjZ > div {
  padding-right: 8px;
  position: relative;
  top: 7px;
}

.BusinessInfoForm_formRow__Xzsjx {
  display: flex;
  margin: 16px;
  justify-content: space-between;
}

.BusinessInfoForm_formRow__Xzsjx > * {
  width: calc(50% - 8px);
}

.BusinessInfoForm_avatar__hVBaG {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Settings/components/BusinessInfoForm/index.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,eAAe;EACf,gCAAgC;EAChC,aAAa;EACb,kDAAkD;AACpD;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".subHeader {\n  font-family: var(--regular400);\n  font-size: 14px;\n  color: var(--black50Transparent);\n  padding: 16px;\n  border-bottom: 1px solid var(--black10Transparent);\n}\n\n.companyLogoContainer {\n  display: flex;\n  padding-top: 9px;\n}\n\n.companyLogoDropzone {\n  width: fit-content;\n  padding-right: 24px;\n}\n\n.actionButtonsContainer {\n  padding: 24px 0px;\n  display: flex;\n  align-items: center;\n}\n\n.actionButtonsContainer > div {\n  padding-right: 8px;\n  position: relative;\n  top: 7px;\n}\n\n.formRow {\n  display: flex;\n  margin: 16px;\n  justify-content: space-between;\n}\n\n.formRow > * {\n  width: calc(50% - 8px);\n}\n\n.avatar {\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeader": `BusinessInfoForm_subHeader__Ih+kN`,
	"companyLogoContainer": `BusinessInfoForm_companyLogoContainer__siWvU`,
	"companyLogoDropzone": `BusinessInfoForm_companyLogoDropzone__r4IDp`,
	"actionButtonsContainer": `BusinessInfoForm_actionButtonsContainer__nEVjZ`,
	"formRow": `BusinessInfoForm_formRow__Xzsjx`,
	"avatar": `BusinessInfoForm_avatar__hVBaG`
};
export default ___CSS_LOADER_EXPORT___;
