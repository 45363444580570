// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationRow_row__J7Z59 {
    display: flex;
    padding: 16px 16px 0px 16px;
}

.NotificationRow_firstCol__Pz2Y2, .NotificationRow_thirdCol__XcWEa {
    flex-basis: 25%;
}

.NotificationRow_secondCol__0k8jD {
    flex-basis: 50%;
    font-family: var(--regular400);
    color: var(--black50Transparent);
}

.NotificationRow_thirdCol__XcWEa {
    display: flex;
    justify-content: flex-end;
}

.NotificationRow_firstCol__Pz2Y2, .NotificationRow_secondCol__0k8jD, .NotificationRow_thirdCol__XcWEa {
    font-size: 14px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--black10Transparent);
    display: flex;
    align-items: center;
}

.NotificationRow_firstCol__Pz2Y2 {
    font-family: var(--medium500);
    color: var(--black);
}`, "",{"version":3,"sources":["webpack://./src/screens/Settings/NotificationSettings/components/NotificationRow/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,8BAA8B;IAC9B,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,kDAAkD;IAClD,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".row {\n    display: flex;\n    padding: 16px 16px 0px 16px;\n}\n\n.firstCol, .thirdCol {\n    flex-basis: 25%;\n}\n\n.secondCol {\n    flex-basis: 50%;\n    font-family: var(--regular400);\n    color: var(--black50Transparent);\n}\n\n.thirdCol {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.firstCol, .secondCol, .thirdCol {\n    font-size: 14px;\n    padding-bottom: 16px;\n    border-bottom: 1px solid var(--black10Transparent);\n    display: flex;\n    align-items: center;\n}\n\n.firstCol {\n    font-family: var(--medium500);\n    color: var(--black);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `NotificationRow_row__J7Z59`,
	"firstCol": `NotificationRow_firstCol__Pz2Y2`,
	"thirdCol": `NotificationRow_thirdCol__XcWEa`,
	"secondCol": `NotificationRow_secondCol__0k8jD`
};
export default ___CSS_LOADER_EXPORT___;
