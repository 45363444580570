import React, { useState } from "react";
import styles from "./index.module.css";

import ChipInput from "components/ChipInput";
import { useGetProductDropdown } from "queries/product";

export default function Components() {
  const [value, setValue] = useState([]);
  const [keyword, setKeyword] = useState("");
  // product dropdown option query
  const { data: productdata } = useGetProductDropdown({
    keyword,
    limit: 10,
  });

  const productOptions =
    productdata &&
    productdata.map((item) => {
      return { label: item.name, value: item.id };
    });

  return (
    <div className={styles.container}>
      <ChipInput
        options={productOptions}
        value={value}
        onChange={(val) => setValue(val)}
        onSearchChange={setKeyword}
        handleChange={(value) =>
          value.map((id) => {
            const name = productOptions.find(
              (option) => option.value === id
            ).label;
            return { productId: id, name };
          })
        }
        getValue={(value) => value.map((item) => item.productId)}
      />
    </div>
  );
}
