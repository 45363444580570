import React, { useState, useEffect, useRef, useCallback, useMemo  } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import SixtyButton from "components/SixtyButton";
import Select from "components/FormComponents/ControlledSelect";
import Checkbox from "components/SixtyCheckBox";
import MultiValueInput from "components/FormComponents/ControlledMultiTextInput"; 
import { Editor } from '@tinymce/tinymce-react';
import { Grid2, Box, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createEditor, Transforms, Text } from 'slate';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import { htmlToSlate, slateToHtml  } from "utils/htmlParser";
import TextArea from "components/FormComponents/ControlledTextArea";



const CustomEditor = {
  isBoldMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: n => n.bold === true,
      universal: true,
    });
    return !!match;
  },

  toggleBoldMark(editor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: n => Text.isText(n), split: true }
    );
  },
};

const BoldButton = () => {
  const editor = useSlate();
  return (
    <SixtyButton
      onMouseDown={event => {
        event.preventDefault();
        CustomEditor.toggleBoldMark(editor);
      }}
    >
      Bold
    </SixtyButton>
  );
};

const Form = ({setContent, content = null, generateArticleLoading = false, editorRef}) => {

  // const editorRef = useRef(null);
  
  const [profilePhoto, setProfilePhoto] = useState([]);
  const [customAudience, setCustomAudience] = useState(false);
  const [customLanguage, setCustomLanguage] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [targetAudience, setTargetAudience] = useState("");
  const [voiceTone, setVoiceTone] = useState("");
  const [languageStyle, setLanguageStyle] = useState("");
  const [language, setLanguage] = useState("");
  const [wordCount, setWordCount] = useState("");
  const [loadingText, setLoadingText] = useState('AI is thinking');
  const { control, reset, watch, errors } = useFormContext();

  useEffect(() => {
    let interval;
    if (generateArticleLoading) {
      let dots = 0;
      interval = setInterval(() => {
        setLoadingText(prevText => {
          dots = (dots + 1) % 4;
          return `AI is thinking${'.'.repeat(dots)}`;
        });
      }, 500);
    } else {
      setLoadingText('AI is thinking');
    }
    return () => clearInterval(interval);
  }, [generateArticleLoading]);

  const [expandedAccordion, setExpandedAccordion] = useState({
    topicKeywords: false,
    styleLanguage: false,
    targetAudience: false
  });

  const collapseAllAccordions = () => {
    setExpandedAccordion({
      topicKeywords: false,
      styleLanguage: false,
      targetAudience: false
    });
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion({ ...expandedAccordion, [panel]: isExpanded });
  };

  const [editor] = useState(() => withReact(createEditor()));

  // const initialValue = useMemo(() => {
    
  //   if (!content) {
  //     return [{ type: 'paragraph', children: [{ text: '' }] }];
  //   }

  //   var newContent = htmlToSlate(content);

  //   const processedContent = newContent.map(node => {
  //     if (typeof node === 'string') {
  //       return { type: 'paragraph', children: [{ text: node }] };
  //     }
  //     return node;
  //   });

  //   return processedContent.filter(node => 
  //     typeof node === 'object' && node.type && node.children
  //   );
  // }, [content]);

  const handleResetForm = () => {
    reset({
      website: "",
      mainFocusKeywords: "",
      supporKeywords: "",
      targetAudience: "",
      toneOfVoice: "",
      languageStyle: "",
      language: "",
      title: "",
      revise: ""
   });
   setLanguage("");
   setLanguageStyle("");
   setVoiceTone("");
   setTargetAudience("");
   setContent("");
  };


  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case 'heading-one':
        return <h1 {...props.attributes}>{props.children}</h1>;
      case 'heading-two':
        return <h2 {...props.attributes}>{props.children}</h2>;
      case 'link':
        return <a href={props.element.url} {...props.attributes}>{props.children}</a>;
      default:
        return <p {...props.attributes}>{props.children}</p>;
    }
  }, []);



  const handleEditorChange = useCallback((value) => {
    const isAstChange = editor.operations.some(
      op => 'set_selection' !== op.type
    );
    if (isAstChange) {
      // Save the value to Local Storage.
      const content = JSON.stringify(value);
      setContent(content);
    }
  }, [editor, setContent]);


  const renderLeaf = useCallback((props) => {
    let { attributes, children, leaf } = props;
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
      children = <em>{children}</em>;
    }
    return <span {...attributes}>{children}</span>;
  }, []);

 
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Grid2 container spacing={2} columns={16} sx={{ flexGrow: 1 }}> 
    <Grid2 item size={5} sx={{ height:'100%', overflowY: 'auto' }}>
    <Box className={styles.form}>

    {content && (
              <>
                <div className={styles.section}>Tell your thought to our AI</div>
                <hr className={styles.hr} />
                <div >
                  <TextArea
                   control={control}
                    name="revise"
                    label="Revise the article"
                    defaultValue=""
                    suggestions={["Expand on the ideas in this article to provide more value to the reader", "Improve the flow and structure of this article while keeping the original meaning intact", "Make this article more conversational while maintaining professionalism"]}
                  />
                </div>
                <div className={styles.btnActionContainer}>
                    <SixtyButton
                      fullWidth
                      type="submit"
                      variant="warning"
                      className={styles.approveBtn}
                      disabled={generateArticleLoading}
                      onClick={() => {
                        collapseAllAccordions();
                      }}
                      >
                      {"Revise Article"}
                    </SixtyButton>

                 </div>
              </>
    )}

    <Accordion 
        expanded={expandedAccordion.topicKeywords} 
        onChange={handleAccordionChange('topicKeywords')} 
        elevation={0} 
        className={styles.accordion} 
     >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}>Topic & Keywords </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
        
        <div className={styles.section}>Website to focus</div>
        <hr className={styles.hr} />
        <div className={styles.fullFormRow}>
          <Input
            name="website"
            label="website"
            defaultValue=""
          />
        </div>

        <div style={{height:15}} ></div>
        <div className={styles.section}>Topic of article</div>
        <hr className={styles.hr} />
        <div className={styles.fullFormRow}>
          <Input
            name="title"
            label="Topic (Optional)"
            defaultValue=""
          />
        </div>
 
        <div style={{height:15}} ></div>
        <div className={styles.section}>Seed keyword & support keywords</div>
        <hr className={styles.hr} />
        <div className={styles.formRow}>
          <Input
            name="mainFocusKeywords"
            label="Seed keywords"
            defaultValue=""
            size="small"
          />
          <div>
          <MultiValueInput
            control={control}
            maxItems={5}
            name="supporKeywords"
            label="Support keywords"
            defaultValue=""
          />
          </div>

        </div>
        </AccordionDetails>
    </Accordion>

    <Accordion 
       expanded={expandedAccordion.styleLanguage} 
       onChange={handleAccordionChange('styleLanguage')} 
       elevation={0} 
       className={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}>Style & Language </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
        
            <div className={styles.section}>Language settings</div>
            <hr className={styles.hr} />
            <div className={styles.fullFormRow}>
                <Checkbox
                    label="Custom language"
                    checked={customLanguage}
                    onChange={(e) => setCustomLanguage(e.target.checked)}
                    uncheckStyleVariant="greyedOut"
                  />
                  <Input
                    name={`isCustomLanguage`}
                    control={control}
                    type="hidden"
                    label="Visibility"
                    defaultValue={'false'}
                    value={'false'}
                    className={styles.hidden}
                  />
            </div>
            <div className={styles.fullFormRow}>
                {!customLanguage && (
                  <Select
                      name="language"
                      options={[
                        {
                            label: "English",
                            selectedLabel: "English",
                            value: "English",
                        },
                        {
                            label: "Malay",
                            selectedLabel: "Malay",
                            value: "Malay",
                        },
                        {
                            label: "Chinese",
                            selectedLabel: "Chinese",
                            value: "Chinese",
                        }
                      ]}
                      fullWidth
                      defaultOption="Language"
                      handleChange={(name, value) => {
                        setLanguage(value);
                      }}
                      value={language}
                      defaultValue={language}
                />
                )}
                {customLanguage && (
                    <Input
                      name="language"
                      label="Write your prefered language"
                      defaultValue=""
                    />
                )} 
            </div>  

            <div style={{height:15}} ></div>
          <div className={styles.section}>Tone & style settings</div>
          <hr className={styles.hr} />
          <div className={styles.formRow}>
          <Select
                name="toneOfVoice"
                options={[
                  {
                      label: "How-to-Guide",
                      selectedLabel: "How-to-Guide",
                      value: "How-to-Guide",
                  },
                  {
                      label: "Tutorial",
                      selectedLabel: "Tutorial",
                      value: "Tutorial",
                  },
                  {
                      label: "Case-study",
                      selectedLabel: "Case-study",
                      value: "Case-study",
                  },
                  {
                      label: "Comparison",
                      selectedLabel: "Comparison",
                      value: "Comparison",
                  },
                  {
                      label: "Opinion Piece",
                      selectedLabel: "Opinion Piece",
                      value: "Opinion Piece",
                  },
                  {
                      label: "FaQ",
                      selectedLabel: "FaQ",
                      value: "FaQ",
                  },
                  {
                      label: "Infographic",
                      selectedLabel: "Infographic",
                      value: "Infographic",
                  },
                  {
                    label: "Storytelling",
                    selectedLabel: "Storytelling",
                    value: "Storytelling",
                  },
                  {
                      label: "News",
                      selectedLabel: "News",
                      value: "News",
                  },
                  {
                      label: "Pros and Cons",
                      selectedLabel: "Pros and Cons",
                      value: "Pros and Cons",
                  },
                ]}
                fullWidth
                defaultOption="Tone of voice"
                handleChange={(name, value) => {
                  setVoiceTone(value);
                }}
                value={voiceTone}
                defaultValue={voiceTone}
            />
            <Select
                name="languageStyle"
                options={[
                  {
                      label: "Professional",
                      selectedLabel: "Professional",
                      value: "Professional",
                  },
                  {
                      label: "Conversational",
                      selectedLabel: "Conversational",
                      value: "Conversational",
                  },
                  {
                      label: "Formal",
                      selectedLabel: "Formal",
                      value: "Formal",
                  },
                  {
                      label: "Neutral",
                      selectedLabel: "Neutral",
                      value: "Neutral",
                  },
                  {
                      label: "Persuasive",
                      selectedLabel: "Persuasive",
                      value: "Persuasive",
                  },
                  {
                      label: "Authoritative",
                      selectedLabel: "Authoritative",
                      value: "Authoritative",
                  },
                  {
                      label: "Humorous",
                      selectedLabel: "Humorous",
                      value: "Humorous",
                  },
                  {
                    label: "Technical",
                    selectedLabel: "Technical",
                    value: "Technical",
                  },
                  {
                      label: "Emotive",
                      selectedLabel: "Emotive",
                      value: "Emotive",
                  },
                  {
                      label: "Playful",
                      selectedLabel: "Playful",
                      value: "Playful",
                  },
                ]}
                fullWidth
                defaultOption="Language style"
                handleChange={(name, value) => {
                  setLanguageStyle(value);
                }}
                value={languageStyle}
                defaultValue={languageStyle}
            />
          </div>  
        </AccordionDetails>
    </Accordion>


    <Accordion 
      expanded={expandedAccordion.targetAudience} 
      onChange={handleAccordionChange('targetAudience')} 
      elevation={0} 
      className={styles.accordion}
    >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}> Target Audience</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
        
          <div className={styles.section}>Select Target Audience</div>
          <hr className={styles.hr} />
          <div className={styles.fullFormRow}>
          <Checkbox
              label="Customize own audience"
              checked={customAudience}
              onChange={(e) => setCustomAudience(e.target.checked)}
              uncheckStyleVariant="greyedOut"
            />
            <Input
              name={`isCustomAudience`}
              control={control}
              type="hidden"
              label="Visibility"
              defaultValue={'false'}
              value={'false'}
              className={styles.hidden}
            />
          </div>
          <div className={styles.fullFormRow}>
          {!customAudience && (
            <Select
                name="targetAudience"
                options={[
                  {
                      label: "General audience",
                      selectedLabel: "General audience",
                      value: "General audience",
                  },
                  {
                      label: "Eco-concious consumers",
                      selectedLabel: "Eco-concious consumers",
                      value: "Eco-concious consumers",
                  },
                  {
                      label: "Small business Owners",
                      selectedLabel: "Small business Owners",
                      value: "Small business Owners",
                  },
                  {
                      label: "Corporate professional",
                      selectedLabel: "Corporate professional",
                      value: "Corporate professional",
                  },
                  {
                      label: "Parents",
                      selectedLabel: "Parents",
                      value: "Parents",
                  },
                  {
                      label: "Students",
                      selectedLabel: "Students",
                      value: "Students",
                  },
                  {
                      label: "Retirees",
                      selectedLabel: "Retirees",
                      value: "Retirees",
                  },
                  {
                      label: "Freelancer",
                      selectedLabel: "Freelancer",
                      value: "Freelancer",
                  },
                  {
                      label: "Travel Enthusiasts",
                      selectedLabel: "Travel Enthusiasts",
                      value: "Travel Enthusiasts",
                  },
                  {
                      label: "Fashion Enthusiasts",
                      selectedLabel: "Fashion Enthusiasts",
                      value: "Fashion Enthusiasts",
                  },
                  {
                      label: "Artists and Creatives",
                      selectedLabel: "Artists and Creatives",
                      value: "Artists and Creatives",
                  },
                  {
                      label: "Foodies",
                      selectedLabel: "Foodies",
                      value: "Foodies",
                  },
                  {
                      label: "Sports Fans",
                      selectedLabel: "Sports Fans",
                      value: "Sports Fans",
                  },
                  {
                      label: "Fitness Beginner",
                      selectedLabel: "Fitness Beginner",
                      value: "Fitness Beginner",
                  },
                ]}
                fullWidth
                defaultOption="Target audience"
                handleChange={(name, value) => {
                  setTargetAudience(value);
                }}
                value={targetAudience}
                defaultValue={targetAudience}
            />
          )}  
            {customAudience && (
              <Input
                name="targetAudience"
                label="Write your target audience"
                defaultValue=""
              />
            )}  
          </div>  
  
        </AccordionDetails>
    </Accordion>



      <div className={styles.btnActionContainer}>
            <SixtyButton
               type="submit"
               variant="primary"
               className={styles.approveBtn}
               disabled={generateArticleLoading}
               onClick={() => {
                collapseAllAccordions();
              }}
            >
              {"Generate Article"}
            </SixtyButton>
            <SixtyButton
               variant="warning"
               className={styles.approveBtn}
               disabled={generateArticleLoading}
               onClick={() => {
                handleResetForm();
              }}
            >
              {"Reset"}
            </SixtyButton>
       </div>
      </Box>
      </Grid2>
      <Grid2 item size={11} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ paddingTop: '16px', paddingRight: '16px', flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>

              {generateArticleLoading && (
                  <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                      }}
                  >
                    <CircularProgress size={60} />
                      <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                        {loadingText}
                      </Typography>
                  </Box>
                )}
                <Editor
                apiKey='wsg1700p2ol4tgi8trs4h0zn4p5j8804yg2b6hk2t9ehbqn5'
                value={content}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=""
                init={{
                    height:1000,
                    width: '100%',
                    // resize: false,
                    plugins: 'lists link paste help wordcount table charmap print preview anchor advlist autolink' +
                              'searchreplace visualblocks code fullscreen' +
                              'insertdatetime media code help',
                    toolbar:  'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                    setup: (editor) => {
                                editor.on('init', () => {
                                  if (generateArticleLoading) {
                                    editor.setMode('readonly');
                                  }
                                });
                              }
                }}
                onEditorChange={(newText) => setContent(newText)}
                disabled={generateArticleLoading}
              />  
            {/* <Slate 
              editor={editor} 
              initialValue={initialValue}
              value={initialValue} 
              onChange={handleEditorChange}
            >
              <Editable className={styles.slateEditor} renderElement={renderElement} renderLeaf={renderLeaf} />
            </Slate> */}
          </Box> 

    </Grid2>
    </Grid2>
    </Box>
  );
};

const Leaf = props => {
  return (
    <span
      {...props.attributes}
      style={{ fontWeight: props.leaf.bold ? 'bold' : 'normal' }}
    >
      {props.children}
    </span>
  );
};

export default Form;
