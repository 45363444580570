import React from "react";
import { formatCurrency } from "utils/common";
import styles from "./index.module.css";

const OpenOrders = ({ openOrders }) => {
  return (
    <React.Fragment>
      <div>
        {openOrders?.length > 0 && (
          <ul>
            {openOrders.slice(0, 5).map((order) => (
              <li key={order.id}>
                <OpenOrdersItem order={order} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </React.Fragment>
  );
};

export const OpenOrdersItem = ({ order }) => {
  const {
    transactionCode,
    orderStatus,
    totalAmount,
    transactionDate,
    paymentStatus,
    soldQuantity,
  } = order;

  return (
    transactionCode && (
      <div className={styles.orderReqContainer}>
        <div className={styles.firstColumn}>
          <div className={styles.orderId}>{"#" + transactionCode}</div>
          <div className={styles.dateCell}>{transactionDate}</div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.statusColumns}>
            {/* {paymentStatus && (
              <div className={styles.paymentStatusCell}>{paymentStatus}</div>
            )} */}
            {orderStatus && (
              <div className={styles.transactionStatusCell}>{orderStatus}</div>
            )}
          </div>

          <div className={styles.orderValueContainer}>
            <div className={styles.totalAmount}>
              {formatCurrency(totalAmount) || 0}
            </div>
            <div className={styles.totalItems}>{soldQuantity + " items"}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default OpenOrders;
