import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import SixtyButton from "components/SixtyButton";
import Input from "components/FormComponents/ControlledInput";
import GroupFields from "./GroupFields";
import RegionFields from "./RegionFields";

import styles from "./index.module.css";
import { useAddShipping } from "queries/shipping";
import Loader from "components/Loader";
import { setToast } from "components/Toast";

const defaultValues = {
  name: "",
  shippingRegions: [],
  shippingGroups: [],
};

const AddShippingZoneForm = ({ AddShippingZoneFormRef, handleClose }) => {
  const schema = yup.object().shape({
    name: yup.string().required("Add a name"),
    shippingRegions: yup.array(
      yup.object().shape({
        regionId: yup.string(),
      })
    ),
    shippingGroups: yup.array(
      yup.object().shape({
        name: yup.string().required("Add a name"),
        firstWeight: yup.number().nullable(),
        firstWeightUnit: yup.string().nullable(),
        price: yup.number().nullable(),
        subsequentWeight: yup.number().nullable(),
        subsequentWeightUnit: yup.string().nullable(),
        subsequentPrice: yup.number().nullable(),
        shippingType: yup.string(),
        minimumOrderAmount: yup.number().nullable(),
      })
    ),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const {
    mutateAsync: addShipping,
    isLoading: isAddingShipping,
    isSuccess: isAddingSuccess,
    isError: isAddingFailed,
  } = useAddShipping();

  useEffect(() => {
    if (isAddingSuccess) {
      setToast("Successfully added shipping");
      handleClose();
    }
  }, [handleClose, isAddingSuccess]);

  useEffect(() => {
    if (isAddingFailed) {
      setToast("Failed adding shipping", "error");
      handleClose();
    }
  }, [handleClose, isAddingFailed]);

  const onSubmit = (data) => {
    addShipping(data);
  };

  return (
    <>
      {isAddingShipping ? (
        <Panel className={styles.panelWrap}>
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        </Panel>
      ) : (
        <FormProvider {...formMethods}>
          <form
            id="add-shipping-zone-form"
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className={styles.form}
          >
            <div className={styles.itemsContainer}>
              <Panel className={styles.panelWrap}>
                <PanelHeader title="1. General" />
                <div className={styles.subForm}>
                  <Input
                    name="name"
                    error={formMethods.errors.naem}
                    control={formMethods.control}
                    label="Name"
                    defaultValue=""
                  />
                </div>
              </Panel>
              <Panel className={styles.panelWrap}>
                <PanelHeader title="2. Countries" />
                <RegionFields formMethods={formMethods} />
              </Panel>
              <Panel className={styles.panelWrap}>
                <GroupFields formMethods={formMethods} />
              </Panel>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default AddShippingZoneForm;
