// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Confirmation_contentVoidForm__EmW2s {
    padding: 16px;
    width: 400px;
    height: auto;
}

.Confirmation_footer__CoVf6 {
    padding: 16px;
    border-top: 1px solid var(--black10);
}

.Confirmation_voidBtn__Kh58D {
    margin-right: 8px;
}

.Confirmation_container__bxK9H{
    min-height: 100px;
    padding-top: 50px;
}


`, "",{"version":3,"sources":["webpack://./src/permissions/Confirmation/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".contentVoidForm {\n    padding: 16px;\n    width: 400px;\n    height: auto;\n}\n\n.footer {\n    padding: 16px;\n    border-top: 1px solid var(--black10);\n}\n\n.voidBtn {\n    margin-right: 8px;\n}\n\n.container{\n    min-height: 100px;\n    padding-top: 50px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentVoidForm": `Confirmation_contentVoidForm__EmW2s`,
	"footer": `Confirmation_footer__CoVf6`,
	"voidBtn": `Confirmation_voidBtn__Kh58D`,
	"container": `Confirmation_container__bxK9H`
};
export default ___CSS_LOADER_EXPORT___;
