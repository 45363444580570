import Report from "../Report";
import styles from "./index.module.css";
import clsx from "clsx";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import SixtyLink from "components/SixtyLink";

const CampaignReport = ({ data }) => {

  {["UGC"].includes(data.campaignType) && (
    <>
      
    </>
  )}

  return (
    <>
      {["UGC"].includes(data.campaignType) && (
        <>
          <div className={styles.totalSellersReport}>
            <div className={styles.row}>
                  <Report
                    title={'Assigned Creators'}
                    unit={data?.recruitedCount}
                    // desc={'Our Assigned creators will create your contents.'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
                  {/* <Report
                    title={'Ongoing Shipment'}
                    unit={data?.pendingShipmentCount}
                    desc={'Update shipment information, so our team can keep track your sample product shipment status,' +
                          ' you can skip this progress if not required sample products for the content creation.'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  /> */}
                  <Report
                    title={'Submited Job'}
                    unit={data?.postCount}
                    // desc={'Review our submitted content.'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
                  <Report
                    title={'My Order Progress'}
                    unit={data?.progressStatus}
                    // desc={'Progress status of your order.'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
                  <Report
                    title={'Top-up Status'}
                    unit={'Click Manage to check the status'}
                    // desc={'Status of your top-up.'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
                  {/* <Report
                    title={''}
                    unit={''}
                    desc={''}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  /> */}
            </div>
            <div className={styles.viewRequestBtn}>
              {data?.id && (
                <SixtyLink
                  type="p2"
                  color="orange"
                  weight="medium"
                  inline
                  to={"/dashboard/services/ugc/" + data?.id}
                >
                  Manage
                </SixtyLink>
              )} 
            </div>
          </div>
        </>
      )}
      {!["UGC"].includes(data.campaignType) && (
        <>
          <div className={styles.totalSellersReport}>
            <div className={styles.row}>
                  <Report
                    title={'Pending Approval Recruitment'}
                    unit={data?.pendingRecruitmentCount}
                    desc={'Review and recruit creators that interested to your campaign.'}
                    redirectLink={data?.id ? `/dashboard/campaign/recruitment/` + data?.id : null}
                    redirectTitle={'Recruit Creators'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
                  <Report
                    title={'Recruited Creators'}
                    unit={data?.recruitedCount}
                    desc={'Creators will create contents and share through social media.'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
                  <Report
                    title={'Voucher & Shipment'}
                    unit={data?.pendingShipmentCount}
                    desc={'Update voucher information, so that creators able to redeem product with voucher code,' +
                          ' you can skip this progress if creators not required sample products.'}
                    redirectLink={data?.id ? `/dashboard/campaign/shipment/` + data?.id : null}
                    redirectTitle={'Manage Voucher'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
                  {/* <Report
                    title={'Redeemed voucher codes'}
                    unit={data?.redeemStatus}
                    desc={'Creators who redeem voucher code through 60sec app.'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  /> */}
                  <Report
                    title={'Submitted Job'}
                    unit={data?.postCount}
                    desc={'Review creator social media posts, mark post as completed so creators able to claim reward.'}
                    redirectLink={data?.id ? `/dashboard/campaign/post/` + data?.id : null}
                    redirectTitle={'Review Social Media Posts'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
                  {/* <Report
                    title={'Analytics'}
                    unit={null}
                    desc={'Campaign basic information.'}
                    redirectLink={data?.id ? "/dashboard/campaigns/AnalyticDetails/" + data?.id : null}
                    redirectTitle={'View Campaign Analytics'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  /> */}
                  <Report
                    title={'Status'}
                    unit={data?.progressStatus}
                    desc={'Progress status of campaign.'}
                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                  />
            </div>
            <div className={styles.viewRequestBtn}>
            </div>
          </div>
        </>
      )}

    </>
  );
};

export default CampaignReport;
