// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary: #4CAF50;
    --primary10: #86c886;
    --primary50: rgba(76, 175, 80, 0.5);
    --secondary: #2A234E;
    --secondary50: #9591A7;
    --tertiary: #FF8072;
    --danger: #EF8777;
    --danger10: #FFF1EF;
    --success: #4FCE82;
    --success10: #E7FAF0;
    --yellow: #F5B755;
    --green: #4FCE82;
    --lightgreen: #EDFAF2;
    --purple:#2a234e;
    --purple3: #F9F8FA;
    --orange: #EF8777;
    --defaultBackground: #EDFAF2;
    --black: #000000;
    --black75: #404040;
    --black50: #808080;
    --black50Transparent: rgba(0, 0, 0, 0.5);
    --black25Transparent: rgba(0, 0, 0, 0.25);
    --black25: #BFBFBF;
    --black10: #818181;
    --black10Transparent: rgba(0, 0, 0, 0.1);
    --black5Transparent: rgba(0, 0, 0, 0.05);
    --black3Transparent: rgba(0, 0, 0, 0.03);
    --black5: #F2F2F2;
    --white: rgba(255,255,255, 1);
    --white25: rgba(255, 255, 255, 0.25);
    --white75: rgba(255, 255, 255, 0.75);
    --transparent: 'transparent';
    --orange50: rgba(239, 135, 119, 0.5);
    --grey: #C4C4C4;
    --blue: #4F82CE;
    --newTransparent: 'transparent';
}`, "",{"version":3,"sources":["webpack://./src/theme/color.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,mCAAmC;IACnC,oBAAoB;IACpB,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,4BAA4B;IAC5B,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,wCAAwC;IACxC,yCAAyC;IACzC,kBAAkB;IAClB,kBAAkB;IAClB,wCAAwC;IACxC,wCAAwC;IACxC,wCAAwC;IACxC,iBAAiB;IACjB,6BAA6B;IAC7B,oCAAoC;IACpC,oCAAoC;IACpC,4BAA4B;IAC5B,oCAAoC;IACpC,eAAe;IACf,eAAe;IACf,+BAA+B;AACnC","sourcesContent":[":root {\n    --primary: #4CAF50;\n    --primary10: #86c886;\n    --primary50: rgba(76, 175, 80, 0.5);\n    --secondary: #2A234E;\n    --secondary50: #9591A7;\n    --tertiary: #FF8072;\n    --danger: #EF8777;\n    --danger10: #FFF1EF;\n    --success: #4FCE82;\n    --success10: #E7FAF0;\n    --yellow: #F5B755;\n    --green: #4FCE82;\n    --lightgreen: #EDFAF2;\n    --purple:#2a234e;\n    --purple3: #F9F8FA;\n    --orange: #EF8777;\n    --defaultBackground: #EDFAF2;\n    --black: #000000;\n    --black75: #404040;\n    --black50: #808080;\n    --black50Transparent: rgba(0, 0, 0, 0.5);\n    --black25Transparent: rgba(0, 0, 0, 0.25);\n    --black25: #BFBFBF;\n    --black10: #818181;\n    --black10Transparent: rgba(0, 0, 0, 0.1);\n    --black5Transparent: rgba(0, 0, 0, 0.05);\n    --black3Transparent: rgba(0, 0, 0, 0.03);\n    --black5: #F2F2F2;\n    --white: rgba(255,255,255, 1);\n    --white25: rgba(255, 255, 255, 0.25);\n    --white75: rgba(255, 255, 255, 0.75);\n    --transparent: 'transparent';\n    --orange50: rgba(239, 135, 119, 0.5);\n    --grey: #C4C4C4;\n    --blue: #4F82CE;\n    --newTransparent: 'transparent';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
