// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BusinessAddressForm_subHeader__g66hk {
    font-family: var(--regular400);
    font-size: 14px;
    color: var(--black50Transparent);
    padding: 16px;
    border-bottom: 1px solid var(--black10Transparent);
}

.BusinessAddressForm_formRow__pHex5 {
    display: flex;
    margin: 16px;
    justify-content: space-between;
  }
  
  .BusinessAddressForm_formRow__pHex5 > * {
    width: calc(50% - 8px);
  }

  .BusinessAddressForm_postalCode__5YK2k {
    padding: 0px 16px;
  }`, "",{"version":3,"sources":["webpack://./src/screens/Settings/components/BusinessAddressForm/index.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;IAChC,aAAa;IACb,kDAAkD;AACtD;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".subHeader {\n    font-family: var(--regular400);\n    font-size: 14px;\n    color: var(--black50Transparent);\n    padding: 16px;\n    border-bottom: 1px solid var(--black10Transparent);\n}\n\n.formRow {\n    display: flex;\n    margin: 16px;\n    justify-content: space-between;\n  }\n  \n  .formRow > * {\n    width: calc(50% - 8px);\n  }\n\n  .postalCode {\n    padding: 0px 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeader": `BusinessAddressForm_subHeader__g66hk`,
	"formRow": `BusinessAddressForm_formRow__pHex5`,
	"postalCode": `BusinessAddressForm_postalCode__5YK2k`
};
export default ___CSS_LOADER_EXPORT___;
