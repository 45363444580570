// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_container__U8JUv {
  min-height: 160px;
  min-width: 360px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Error_message__rmdj5 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Error_actions__FqD39 > *:first-child {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Error/index.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  min-height: 160px;\n  min-width: 360px;\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.message {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.actions > *:first-child {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Error_container__U8JUv`,
	"message": `Error_message__rmdj5`,
	"actions": `Error_actions__FqD39`
};
export default ___CSS_LOADER_EXPORT___;
