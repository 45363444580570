// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Stepper_item__bebQ3 {
  display: flex;
  margin: 0 24px;
  align-items: center;
  font-size: 14px;
}

.Stepper_active__QpEnG {
  background-color: #F5B755;
}

.Stepper_visited__LdhZD {
  background-color: #4FCE82;
}

.Stepper_notReached__96ZSV {
  background-color: #F2F2F2;
}

.Stepper_notReached__96ZSV ~ .Stepper_label__PEKG2 {
  color: rgba(0, 0, 0, 0.5);
}

.Stepper_progressIcon__bEew9 {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-right: 16px;
}

.Stepper_checkIcon__-C9g7 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Stepper_notReached__96ZSV .Stepper_checkIcon__-C9g7 svg,
.Stepper_active__QpEnG svg {
  opacity: 0;
}

.Stepper_visited__LdhZD .Stepper_checkIcon__-C9g7 svg {
  stroke: #fff;
  position: relative;
  stroke-width: 0px;
}

.Stepper_label__PEKG2 {
  font-family: var(--medium500);
}

.Stepper_stepupContainer__UUeYU {
  display: flex;
  align-items: flex-start;
  padding: 12px 24px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  width: fit-content;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Stepper/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;;EAEE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,sBAAsB;EACtB,qCAAqC;EACrC,sBAAsB;EACtB,2CAA2C;EAC3C,oBAAoB;EAEpB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".item {\n  display: flex;\n  margin: 0 24px;\n  align-items: center;\n  font-size: 14px;\n}\n\n.active {\n  background-color: #F5B755;\n}\n\n.visited {\n  background-color: #4FCE82;\n}\n\n.notReached {\n  background-color: #F2F2F2;\n}\n\n.notReached ~ .label {\n  color: rgba(0, 0, 0, 0.5);\n}\n\n.progressIcon {\n  width: 24px;\n  height: 24px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 50%;\n  margin-right: 16px;\n}\n\n.checkIcon {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.notReached .checkIcon svg,\n.active svg {\n  opacity: 0;\n}\n\n.visited .checkIcon svg {\n  stroke: #fff;\n  position: relative;\n  stroke-width: 0px;\n}\n\n.label {\n  font-family: var(--medium500);\n}\n\n.stepupContainer {\n  display: flex;\n  align-items: flex-start;\n  padding: 12px 24px;\n  background-color: #fff;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n  box-sizing: border-box;\n  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);\n  border-radius: 100px;\n  width: -moz-fit-content;\n  width: fit-content;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `Stepper_item__bebQ3`,
	"active": `Stepper_active__QpEnG`,
	"visited": `Stepper_visited__LdhZD`,
	"notReached": `Stepper_notReached__96ZSV`,
	"label": `Stepper_label__PEKG2`,
	"progressIcon": `Stepper_progressIcon__bEew9`,
	"checkIcon": `Stepper_checkIcon__-C9g7`,
	"stepupContainer": `Stepper_stepupContainer__UUeYU`
};
export default ___CSS_LOADER_EXPORT___;
