// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BasicHeader_basicHeader__S3n2W {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 8px 8px 8px;
    justify-content: space-between;
    align-items: center;
}

.BasicHeader_titleBox__1ZM\\+6 {
    display: flex;
    align-items: center;
}

.BasicHeader_titleContent__KilHp {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
    padding-left: 8px;
}

.BasicHeader_ButtonsContainer__qHfEr {
    display: flex;
    align-items: center;
}

.BasicHeader_ButtonsContainer__qHfEr > div {
    margin: 4px;
}

.BasicHeader_moreActionsSelectBox__S9xr9 {
    font-family: var(--medium500);
    border-radius: 100px;
    border: 1px solid var(--black10Transparent);
}

.BasicHeader_moreActionsSelectBox__S9xr9 span:first-child {
    padding-right: 0px;
}

.BasicHeader_optionBoxText__BJHUI {
    font-family: var(--medium500);
    font-size: 14px;
    color: var(--black50Transparent);
}

.BasicHeader_selectBoxText__\\+geV7, .BasicHeader_actionButtons__OAAlJ {
    font-family: var(--medium500);
    color: var(--black50Transparent);
    font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Components/BasicHeader/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,wBAAwB;IACxB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,oBAAoB;IACpB,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;AACnB","sourcesContent":[".basicHeader {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 25px 8px 8px 8px;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.titleBox {\n    display: flex;\n    align-items: center;\n}\n\n.titleContent {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n    padding-left: 8px;\n}\n\n.ButtonsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.ButtonsContainer > div {\n    margin: 4px;\n}\n\n.moreActionsSelectBox {\n    font-family: var(--medium500);\n    border-radius: 100px;\n    border: 1px solid var(--black10Transparent);\n}\n\n.moreActionsSelectBox span:first-child {\n    padding-right: 0px;\n}\n\n.optionBoxText {\n    font-family: var(--medium500);\n    font-size: 14px;\n    color: var(--black50Transparent);\n}\n\n.selectBoxText, .actionButtons {\n    font-family: var(--medium500);\n    color: var(--black50Transparent);\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"basicHeader": `BasicHeader_basicHeader__S3n2W`,
	"titleBox": `BasicHeader_titleBox__1ZM+6`,
	"titleContent": `BasicHeader_titleContent__KilHp`,
	"ButtonsContainer": `BasicHeader_ButtonsContainer__qHfEr`,
	"moreActionsSelectBox": `BasicHeader_moreActionsSelectBox__S9xr9`,
	"optionBoxText": `BasicHeader_optionBoxText__BJHUI`,
	"selectBoxText": `BasicHeader_selectBoxText__+geV7`,
	"actionButtons": `BasicHeader_actionButtons__OAAlJ`
};
export default ___CSS_LOADER_EXPORT___;
