// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Flyout_flyout__8qwmn {
    position: absolute;
    right: 1px;
    top: 34px;
    border: 1px solid var(--black10Transparent);
    box-shadow: 0px 2px 5px var(--black5Transparent);
    border-radius: 4px;
}

.Flyout_flyout__8qwmn .Flyout_flyoutHidden__oz-w4 {
    opacity: 0;
    transform: translate3d(-50%, 100%, 0) scale(0.5) rotateX(-90deg);
}

.Flyout_flyoutContent__7lTUf {
    color: #000;
    background-color: #fff;
    overflow: auto;
    max-height: 80vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/Flyout/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,2CAA2C;IAC3C,gDAAgD;IAChD,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,gEAAgE;AACpE;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".flyout {\n    position: absolute;\n    right: 1px;\n    top: 34px;\n    border: 1px solid var(--black10Transparent);\n    box-shadow: 0px 2px 5px var(--black5Transparent);\n    border-radius: 4px;\n}\n\n.flyout .flyoutHidden {\n    opacity: 0;\n    transform: translate3d(-50%, 100%, 0) scale(0.5) rotateX(-90deg);\n}\n\n.flyoutContent {\n    color: #000;\n    background-color: #fff;\n    overflow: auto;\n    max-height: 80vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flyout": `Flyout_flyout__8qwmn`,
	"flyoutHidden": `Flyout_flyoutHidden__oz-w4`,
	"flyoutContent": `Flyout_flyoutContent__7lTUf`
};
export default ___CSS_LOADER_EXPORT___;
