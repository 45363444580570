import React from 'react';
import styles from './index.module.css';
import useOnClickOutside from '../../utils/hooks/useOnClickOutside';
import useOnEscape from '../../utils/hooks/useOnEscape';
import PropTypes from 'prop-types';

const Modal = ({
    isOpen,
    modalRef,
    onAttemptClose,
    helperClass,
    Title,
    Actions,
    loading,
    loadingContent,
    ...props
}) => {
    const handleEsc =
        () => {
            if (
                isOpen &&
                onAttemptClose
            ) {
                onAttemptClose();
            }
        };

    useOnClickOutside(modalRef, () => onAttemptClose());
    useOnEscape((e) => handleEsc());

    return (
        <div
            className={[
                styles.overlay,
                !isOpen ? styles.staticDialog : '',
                !isOpen ? styles.overlayHidden : '',
            ].join(' ')}
        >
            <div
                ref={modalRef}
                role="dialog"
                aria-modal="true"
                className={[
                    styles.modal,
                    helperClass || '',
                    !isOpen ? styles.modalHidden : '',
                ].join(' ')}
            >
                <div className={styles.modalContainer}>
                    {loading && <div className={styles.loader}>{loadingContent}</div>}
                    {Title && <div className={styles.modalTitle}>
                        <Title />
                    </div>
                    }
                    <div className={styles.modalContent}>
                        {props.children}
                    </div>
                    {Actions && (<div className={styles.modalButtonPlace}>
                        <Actions />
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    isOpen: PropTypes.bool,
    modalRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    onAttemptClose: PropTypes.func,
    helperClass: PropTypes.string,
    Title: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    Actions: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

export default Modal;
