import React, { memo, useRef, useLayoutEffect } from "react";
import usePrevious from "../../utils/hooks/usePrevious";
import clsx from "clsx";
import styles from "./index.module.css";

function SingleOTPInputComponent({ focus, autoFocus, error, ...rest }) {
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <input
      ref={inputRef}
      {...rest}
      className={clsx(
        styles.otpInput,
        error && styles.error,
        (focus || rest.value !== "") && styles.activeInput
      )}
    />
  );
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
