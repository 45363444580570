import styles from "./index.module.css";
import Navigation from "components/Navigation";
import Topbar from "components/Topbar";

import CustomToastContainer from "components/Toast";
import { useGetSideMenuRecordCount } from "queries/dashboard";
import { useEffect } from "react";
import React, { useState, useRef } from "react";
import { useNavigate, Outlet } from "react-router";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { queryClient } from "common/query";
import { setToast } from "components/Toast";
import { clearStorage, writeToStorage } from "../../../utils/storage";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InterestsIcon from '@mui/icons-material/Interests';
import Collapse from '@mui/material/Collapse';
import TagIcon from '@mui/icons-material/Tag';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import MovieIcon from '@mui/icons-material/Movie';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DescriptionIcon from '@mui/icons-material/Description';

const Navbar = ({open = false, setOpen}) => {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openSocialMediaNested, setSocialMediaNested] = useState(false);
  const [openProductNested, setProductNested] = useState(false);

  const theme = useTheme();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  
  const handleDrawer= () => {
    if(open){
      setOpen(false);
    }
    else{
      setOpen(true);
    }  
  };

  const handleSocialMedia = () => {
    setSocialMediaNested(!openSocialMediaNested);
  };

  const handleProduct = () => {
    setProductNested(!openProductNested);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  height: '75px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

const getProductPath = (text) => {
  switch (text) {
    case 'Product Description':
      return '/product-description';
    default:
      return '/dashboard';
  }
};

const getProductIcon = (text) => {
  switch (text) {
    case 'Product Description':
      return <DescriptionIcon />;
    default:
      return <DashboardIcon />;
  }
};

  const getSocialMediaPath = (text) => {
    switch (text) {
      case 'Hashtag Analysis':
        return '/dashboard/hashtag';
      case 'Caption':
        return '/dashboard/caption';
      case 'Video Script':
        return '/dashboard/ShortVideoScript';
      default:
        return '/dashboard';
    }
  };

  const getSocialMediaIcon = (text) => {
    switch (text) {
      case 'Hashtag Analysis':
        return <TagIcon />;
      case 'Caption':
        return <ClosedCaptionIcon />;
      case 'Video Script':
        return <MovieIcon />;
      default:
        return <DashboardIcon />;
    }
  };

  const defaultSettings = [
    {
      itemId: "9.1",
      title: "Company",
      path: "/dashboard",
    },
    // {
    //   itemId: "9.2",
    //   title: "Company",
    //   path: "/dashboard/settings/company",
    // },
  ];

  return (
    <div>
       {/* <div className={styles.dashboardBtnContainer}>
          <SixtyButton
              variant="primary"
              onClick={onOpenCampaignGoal}
              className={"create-campaign-btn"}
              disabled={true}
          >
              New Article
          </SixtyButton>
       </div> */}

      {/* <Navigation
        items={[
          {
            itemId: "1",
            title: "Home",
            ElemBefore: IconHome,
            path: "/dashboard",
          },
          {
            itemId: "9",
            title: "Settings",
            ElemBefore: IconSettings,
            subNav: defaultSettings           
          },
        ]}
      /> */}

        <Drawer 
          variant="permanent" open={open}
          className={`${styles.drawer} ${open ? styles.drawerOpen : styles.drawerClose}`}
          classes={{
            paper: open ? styles.drawerOpen : styles.drawerClose,
          }}
        >
        <DrawerHeader>
          <div className={styles.drawerHeader}>
              <IconButton onClick={handleDrawer}>
                {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
          </div>
        </DrawerHeader>
        <Divider />
        <List>
          {['SEO'].map((text, index) => (
            <ListItem 
               button 
               component={Link} 
               to="/dashboard" 
               key={text} 
               disablePadding 
               className={styles.listItem}
               onClick={(event) => handleListItemClick(event, index)}>
              <ListItemButton 
                selected={selectedIndex === index}
                className={`${styles.listItemButton} ${!open && styles.listItemButtonClose}`}
              >
                <ListItemIcon
                  className={`${styles.listItemIcon} ${open ? styles.listItemIconOpen : styles.listItemIconClose}`}
                  style={{ color: selectedIndex === index ? '#FF007FFF': 'default' }}
                >
                  {<DashboardIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  className={`${styles.listItemText} ${!open && styles.listItemTextClose}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
        <ListItemButton 
           onClick={handleSocialMedia}         
           className={`${styles.listItemButton} ${!open && styles.listItemButtonClose}`}
        >
          <ListItemIcon       
                className={`${styles.listItemIcon} ${open ? styles.listItemIconOpen : styles.listItemIconClose}`}
          >
            <InterestsIcon />
          </ListItemIcon>
          <ListItemText 
             primary="Social Media"       
             className={`${styles.listItemText} ${!open && styles.listItemTextClose}`} 
          />
          {open ? openSocialMediaNested ? <ExpandLess /> : <ExpandMore /> : ""}
        </ListItemButton>
          <Collapse in={openSocialMediaNested} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={styles.nestedList}>
            {['Hashtag Analysis', 'Caption', 'Video Script'].map((text, index) => (
              <ListItem 
                 button 
                 component={Link} 
                 to={getSocialMediaPath(text)} 
                 key={text} 
                 disablePadding 
                 className={styles.listItem}
                 onClick={(event) => handleListItemClick(event, index + 2)}>
                <ListItemButton
                  selected={selectedIndex === index + 2}
                  className={`${styles.listItemButton} ${!open && styles.listItemButtonClose}`}
                >
                  <ListItemIcon
                    className={`${styles.listItemIcon} ${open ? styles.listItemIconOpen : styles.listItemIconClose}`}
                    style={{ color: selectedIndex === index + 2 ? '#FF007FFF': 'default' }}
                  >
                    {getSocialMediaIcon(text)}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    className={`${styles.listItemText} ${!open && styles.listItemTextClose}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
           </List>
          </Collapse>
          {/* <ListItemButton onClick={handleProduct}         
              sx={[
                  {
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                        justifyContent: 'initial',
                      }
                    : {
                        justifyContent: 'center',
                      },
                ]}>
          <ListItemIcon       
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: 'auto',
                        },
                  ]}>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Product"       
                  sx={[
                    {
                     color: 'black',
                    },
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}/>
          {open ? openProductNested ? <ExpandLess /> : <ExpandMore /> : ""}
        </ListItemButton>
          <Collapse in={openProductNested} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {['Product Description'].map((text, index) => (
              <ListItem button component={Link} to={getProductPath(text)} key={text} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={[
                    {
                      minHeight: 48,
                      pl: 4,
                    },
                    open
                      ? {
                          justifyContent: 'initial',
                        }
                      : {
                          justifyContent: 'center',
                        },
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: 'center',
                      },
                      open
                        ? {
                            mr: 3,
                          }
                        : {
                            mr: 'auto',
                          },
                    ]}
                  >
                    {getProductIcon(text)}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={[
                      {
                      color: 'black',
                      },
                      open
                        ? {
                            opacity: 1,
                          }
                        : {
                            opacity: 0,
                          },
                    ]}
                  />
                </ListItemButton>
              </ListItem>
            ))}
           </List>
          </Collapse> */}
        </List>
      </Drawer>
    </div>
  );
};

export default function DashboardLayout() {

  const [open, setOpen] = React.useState(false);
  const history = useNavigate();

  const user = queryClient.getQueryData("user");


  return (
    <>
      <Topbar open={open} />
      <div className={styles.page}>
        <Navbar open={open} setOpen={setOpen} />
        <Outlet />
        <div>
          <CustomToastContainer />
        </div>
      </div>
    </>
  );
}
