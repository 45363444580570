import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { queryClient } from "../common/query";
import { reportError } from "../crashlytics";
import { setToast } from 'components/Toast';

export function useGetSellerRegistrationDetail(code) {
  return useQuery(
    [Endpoints.Seller.GetSellerRegistration, code],
    () =>
      api
        .get(Endpoints.Seller.GetSellerRegistration + `/${code}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerRegistrationDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerRequests(filter) {
  return useQuery(
    [Endpoints.Seller.GetSellerRequests, filter],
    () =>
      api
        .post(Endpoints.Seller.GetSellerRequests, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellerRequests: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellers(filter) {
  return useQuery(
    [Endpoints.Seller.GetSellers, filter],
    () =>
      api
        .post(Endpoints.Seller.GetSellers, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellers: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetTopSellers() {
  return useQuery(
    Endpoints.Seller.GetTopSellers,
    () =>
      api
        .get(Endpoints.Seller.GetTopSellers)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetTopSellers: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerDropdown(filter) {
  return useQuery(
    Endpoints.Seller.GetSellerDropdown,
    () =>
      api
        .post(Endpoints.Seller.GetSellerDropdown, filter)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerDropdown: useQuery: onError: error", error);
      },
      staleTime: Infinity,
    }
  );
}

export function useGetSellerDetail(id) {
  return useQuery(
    [Endpoints.Seller.GetSellerDetail, id],
    () =>
      api
        .get(Endpoints.Seller.GetSellerDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useUpdateSellerStatus() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Seller.UpdateSellerStatus, data)
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
      queryClient.refetchQueries(Endpoints.Seller.GetSellerRequests);
    },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateSellerStatus: useMutation: onError: error", error);
      },
    }
  );
}

export function useApproveSellerApplications() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Seller.ApproveSellerApplications, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useApproveSellerApplications: usemutation: onError: error", error);
      },
    }
  );
}

export function useRejectSellerApplications() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Seller.RejectSellerApplications, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useRejectSellerApplications: usemutation: onError: error", error);
      },
    }
  );
}

export function useUpdateServiceAgreement() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Seller.UpdateServiceAgreement, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateServiceAgreement: usemutation: onError: error", error);
      },
    }
  );
}

export function useGetGetServiceAgreement() {
  return useQuery(
    Endpoints.Seller.GetServiceAgreement,
    () =>
      api
        .get(Endpoints.Seller.GetServiceAgreement)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetGetServiceAgreement: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerRejectionDropdown() {
  return useQuery(
    Endpoints.Seller.GetSellerRejectReasonDropdown,
    () =>
      api
        .get(Endpoints.Seller.GetSellerRejectReasonDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("GetSellerRejectReasonDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerLatestOrders(id) {
  return useQuery(
    [Endpoints.Seller.GetSellerLatestOrders, id],
    () =>
      api
        .get(Endpoints.Seller.GetSellerLatestOrders + `/${id}`)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellerLatestOrders: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerProductSales(filter) {
  return useQuery(
    [Endpoints.Seller.GetSellerProductSales, filter],
    () =>
      api
        .post(Endpoints.Seller.GetSellerProductSales, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellerProductSales: useQuery: onError: error", error);
      },
    }
  );
}

export function useAssignSellerPriceTier() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Seller.AssignSellerPriceTier, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAssignSellerPriceTier: useMutation: onError: error", error);
      },
    }
  );
}