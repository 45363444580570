// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loaderContainer__AkN-Y {
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Loader_loadingCard__bpfEH {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E6E6E6;
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.Loader_icon__y7JHh {
  animation-name: Loader_rotate-s-loader__56jig;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
}

.Loader_loadingText__6z7\\+X {
  margin-top: 8px;
}

@keyframes Loader_rotate-s-loader__56jig {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/index.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,6CAA+B;EAC/B,mCAAmC;EACnC,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,oBAAoB;EACtB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loaderContainer {\n  min-height: 180px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.loadingCard {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #E6E6E6;\n  height: 48px;\n  width: 48px;\n  border-radius: 50%;\n}\n\n.icon {\n  animation-name: rotate-s-loader;\n  animation-iteration-count: infinite;\n  animation-duration: 1s;\n  animation-timing-function: linear;\n}\n\n.loadingText {\n  margin-top: 8px;\n}\n\n@keyframes rotate-s-loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `Loader_loaderContainer__AkN-Y`,
	"loadingCard": `Loader_loadingCard__bpfEH`,
	"icon": `Loader_icon__y7JHh`,
	"rotate-s-loader": `Loader_rotate-s-loader__56jig`,
	"loadingText": `Loader_loadingText__6z7+X`
};
export default ___CSS_LOADER_EXPORT___;
