import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { queryClient } from "../common/query";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";

export function useGetNotificationSettings() {
  return useQuery(
    Endpoints.Setting.GetNotificationSettings,
    () =>
      api
        .get(Endpoints.Setting.GetNotificationSettings)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetNotificationSettings: useQuery: onError: error",
          error
        );
      },
      staleTime: 10 * 60 * 1000,
    }
  );
}

export function useUpdateNotificationSettings() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Setting.UpdateNotificationSettings, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateNotificationSettings: useMutatiosn: onError: error",
          error
        );
      },
    }
  );
}


export function useUpdateCommission() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Setting.UpdateSellerGlobalCommission, data)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateSellerGlobalCommission: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetCommission() {
  return useQuery(
    [Endpoints.Setting.GetSellerGlobalCommission],
    () =>
      api
        .get(Endpoints.Setting.GetSellerGlobalCommission)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellerGlobalCommission: useQuery: onError: error", error);
      },
    }
  );
}