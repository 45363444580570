// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginContainer_loginPaper__hUlLs {
    width: 100%;
    max-width: 360px;
    align-items: center;
    padding-top: 40px;
    margin-bottom: 16px;
}

.LoginContainer_largePaper__gqNOg {
    max-width: 560px;
}

.LoginContainer_header__13IaK {
    margin-bottom: 24px;
    text-align: center;
}

.LoginContainer_form__FUu-N {
    width: 100%;
    margin-bottom: 8px;
}

.LoginContainer_formBody__xa5pF > * {
    margin-bottom: 16px;
}

.LoginContainer_showPassword__4aS3f {
    cursor: pointer
}

.LoginContainer_formFooter__DqG1E {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.LoginContainer_formFooter__DqG1E *:first-child {
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/screens/Onboarding/components/LoginContainer/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;AACJ;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".loginPaper {\n    width: 100%;\n    max-width: 360px;\n    align-items: center;\n    padding-top: 40px;\n    margin-bottom: 16px;\n}\n\n.largePaper {\n    max-width: 560px;\n}\n\n.header {\n    margin-bottom: 24px;\n    text-align: center;\n}\n\n.form {\n    width: 100%;\n    margin-bottom: 8px;\n}\n\n.formBody > * {\n    margin-bottom: 16px;\n}\n\n.showPassword {\n    cursor: pointer\n}\n\n.formFooter {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.formFooter *:first-child {\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginPaper": `LoginContainer_loginPaper__hUlLs`,
	"largePaper": `LoginContainer_largePaper__gqNOg`,
	"header": `LoginContainer_header__13IaK`,
	"form": `LoginContainer_form__FUu-N`,
	"formBody": `LoginContainer_formBody__xa5pF`,
	"showPassword": `LoginContainer_showPassword__4aS3f`,
	"formFooter": `LoginContainer_formFooter__DqG1E`
};
export default ___CSS_LOADER_EXPORT___;
