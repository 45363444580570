// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shippings_table__NulYI {
  width: calc(95vw - 250px);
}

.Shippings_gridHeader__8kpEk {
  font-family: var(--medium500);
  font-size: 20px;
  color: var(--black);
}

.Shippings_ButtonsContainer__dzu75 {
  display: flex;
  align-items: center;
}

.Shippings_ButtonsContainer__dzu75 > div {
  margin: 4px;
}

.Shippings_exportBtn__-e4qN,
.Shippings_addShippingzoneBtn__0nGi\\+ {
  font-family: var(--medium500);
  color: var(--black50Transparent);
  font-size: 14px;
}

.Shippings_addShippingzoneBtn__0nGi\\+ {
  color: var(--white);
}

.Shippings_headerContainer__cc8S\\+ {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 8px;
  justify-content: space-between;
  align-items: center;
  margin: 24px;
  margin-bottom: 16px;
}

.Shippings_modalTitleContainer__jhggB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Shippings_modalTitle__9FPYt {
  font-family: var(--medium500);
  font-size: 16px;
  color: var(--black);
}

.Shippings_closeIcon__Px97n {
  cursor: pointer;
}

.Shippings_cancelButton__Rhd8E {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Settings/Shippings/index.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;;EAEE,6BAA6B;EAC7B,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".table {\n  width: calc(95vw - 250px);\n}\n\n.gridHeader {\n  font-family: var(--medium500);\n  font-size: 20px;\n  color: var(--black);\n}\n\n.ButtonsContainer {\n  display: flex;\n  align-items: center;\n}\n\n.ButtonsContainer > div {\n  margin: 4px;\n}\n\n.exportBtn,\n.addShippingzoneBtn {\n  font-family: var(--medium500);\n  color: var(--black50Transparent);\n  font-size: 14px;\n}\n\n.addShippingzoneBtn {\n  color: var(--white);\n}\n\n.headerContainer {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0px 8px;\n  justify-content: space-between;\n  align-items: center;\n  margin: 24px;\n  margin-bottom: 16px;\n}\n\n.modalTitleContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.modalTitle {\n  font-family: var(--medium500);\n  font-size: 16px;\n  color: var(--black);\n}\n\n.closeIcon {\n  cursor: pointer;\n}\n\n.cancelButton {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Shippings_table__NulYI`,
	"gridHeader": `Shippings_gridHeader__8kpEk`,
	"ButtonsContainer": `Shippings_ButtonsContainer__dzu75`,
	"exportBtn": `Shippings_exportBtn__-e4qN`,
	"addShippingzoneBtn": `Shippings_addShippingzoneBtn__0nGi+`,
	"headerContainer": `Shippings_headerContainer__cc8S+`,
	"modalTitleContainer": `Shippings_modalTitleContainer__jhggB`,
	"modalTitle": `Shippings_modalTitle__9FPYt`,
	"closeIcon": `Shippings_closeIcon__Px97n`,
	"cancelButton": `Shippings_cancelButton__Rhd8E`
};
export default ___CSS_LOADER_EXPORT___;
