import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGenerateArticle } from "queries/writer";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import DOMPurify from 'dompurify';
import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { useUploads } from "queries/media";
import styles from "./index.module.css";
import Loader from "components/Loader";
import Form from "./components/Form";
import EditorForm from "./components/EditorForm";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import BasicPageHeader from "../../Components/BasicHeader";
import { isEmpty, isArray } from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import Stepper from "components/Stepper";
import Paper from "components/SixtyPaper";
import { htmlToSlate, slateToHtml  } from "utils/htmlParser";


const CreateBlog = () => {

const history = useNavigate();
const params = useParams();
const contentRef = useRef(null);

const [currentProgress, setCurrentProgress] = useState(0);
const [progress, setProgress] = useState(["active", "notReached"]);
const [newBlog, setCreatedBlog] = useState();
const [blogModel, setBlogModel] = useState();
const [blogCreated, setBlog] = useState(false);
const [completeLoading, setCompleteLoading] = useState(false);
const [content, setContent] = useState("");

// const [content, setContent] = useState([
//   {
//     type: 'paragraph',
//     children: [{ text: '' }],
//   },
// ]);


const defaultValues = {
    website: '',
    title: '',
    mainFocusKeywords: '',
    contentFormat: '',
    targetAudience: '',
    toneOfVoice: '',
    languageStyle: '',
    language: '',
    wordCount: '',
 };


 const formMethods = useForm({
    defaultValues,
 });
  
  const { mutateAsync: createArticle, 
          isLoading : generateArticleLoading 
        } = useGenerateArticle();

  const onSubmit = async (data) => {
    try {

        if(currentProgress == 0){

          // if(!data.website){
          //   setToast("WEBSITE IS REQUIRED", "error");
          //   return;
          // }

          if(!data.mainFocusKeywords){
            setToast("SEED KEYWORD IS REQUIRED", "error");
            return;
          }

          // goToContent();
        }

        let model = {
            website: data.website,
            mainFocusKeywords: data.mainFocusKeywords,
            supporKeywords: data.supporKeywords,
            contentFormat: 'Html body with text, exclude header and footer',
            targetAudience: data.targetAudience,
            toneOfVoice: data.toneOfVoice,
            languageStyle: data.languageStyle,
            language: data.language,
            title: data.title
         };

         if(currentProgress == 1 && blogModel){
            model.website = blogModel?.website;
            model.mainFocusKeywords = blogModel?.mainFocusKeywords;
            model.supporKeywords = blogModel?.supporKeywords;
            model.targetAudience = blogModel?.targetAudience;
            model.toneOfVoice = blogModel?.toneOfVoice;
            model.languageStyle = blogModel?.languageStyle;
            model.language = blogModel?.language;
            model.title = blogModel?.title;
         }

         if(data.revise){
            model.remark = data.revise;
            model.article = content;
         }

         setBlogModel(model);
         setCompleteLoading(true);
        //  console.log(model);
        //  const response = await fetch('https://uat-vendor.60sec.asia/SeoWriterAPI/api/Writer/GenerateStreamArticle', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(model),
        // });
  
        //  const reader = response.body.getReader();
        //  const decoder = new TextDecoder('utf-8');

        //  contentRef.current = "";
        //  let partialContent = ''; 
        //  let buffer = ''; // Partial content (for periodic updates)
     
        //  while (true) {
        //     const { done, value } = await reader.read();

        //     if (done) {
        //       setCompleteLoading(false);
        //       break;
        //     } 
  
        //     const chunk = decoder.decode(value, { stream: true });

        //     partialContent += chunk;

        //     const lines = partialContent.split('\n');
        //     partialContent = lines.pop();

        //     lines.forEach((line) => {
            
        //       if(chunk.includes('```html')){
        //         line = line.replace(/```html|```/g, '');
        //       }

        //       const sanitizedLine = DOMPurify.sanitize(line);
 
        //       contentRef.current += sanitizedLine;
        //       setContent(contentRef.current);
        //     });
          
        //  }

        //  if (partialContent) {
        //     const finalSanitized = DOMPurify.sanitize(partialContent);

        //     contentRef.current += finalSanitized;
        //     setContent(contentRef.current);
        // }

         var response = await createArticle(model);

         if(response){
            if(response.includes('```html')){
              response = response.replace(/```html|```/g, '');
            }

             setCompleteLoading(false);
             setCreatedBlog(response);
             setContent(response);
             setBlog(true);
             setToast("New article generated");
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignForm: onSubmit, error: ",
        e
      );
      setCompleteLoading(false);
      setToast("OPS, CONNECTION ERROR", "error");
    }
  };


  const onTriggerSubmit = () => {    
    formMethods.handleSubmit(onSubmit);
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (content){
        innerText = `${content}`;
    }
        
    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Article copied to clipboard");
    else setToast("No content to copy", "error");
  };

  const onGoBack = () => {
    history(-1);
  };

  function GetCurrentProgress(targetProgress){
    let totalProgress = 1;
    let result = [];

    for(let i = 0; i <= totalProgress; i++){
       if(i == targetProgress){
        result.push("active")
       }
       else if(i != targetProgress && i <= currentProgress){
        result.push("visited")
       }
       else if(i != targetProgress && i > currentProgress){
        result.push("notReached")
       }
    }

    return result;
  }

  const goToSetup = () => {
    const newProgress = 0;
    setCurrentProgress(newProgress);
  };

  const goToContent = () => {
    const newProgress = 1;
    setCurrentProgress(newProgress);
  };

  const renderForm = () => {

    return (
      <>
        {progress[0] === "active" && (
          <>
            <Form setContent={setContent} 
                  content={content} 
                  generateArticleLoading={completeLoading}
                  editorRef={contentRef}
            />   

          </>
        )}
        {progress[1] === "active" && (
          <>        
             {/* <EditorForm setContent={setContent} 
                  content={content} 
                  generateArticleLoading={completeLoading}
                  editorRef={contentRef}
                  copy={copyToClipboard}
                  back={goToSetup}
             />   */}
          </>
        )}             
      </>
    );
  };

  // useEffect(() => {
  //   const updatedProgress = GetCurrentProgress(currentProgress);
  //   setProgress(updatedProgress);
  // }, [currentProgress]);

  return (
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
      <BasicPageHeader title="Create New Article" onGoBack={onGoBack} disableBack={true} />
      <div className={styles.campaignRequestForm}>
        <div>
        <Paper className={clsx(styles.stepPaper)}>

         {/* <div className={styles.margin}></div>
         <Stepper
              status={progress}
              labels={["Setup", "Content"]}
         />
         <div className={styles.margin}></div>
         <div className={styles.margin}></div> */}

      
        <Panel className={styles.customWrap}>
                  <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Setup your SEO requirement"
                  />
                  <FormProvider {...formMethods}>
                  <form
                      onSubmit={formMethods.handleSubmit(onSubmit)}
                      className={styles.form}
                  >    
                    {renderForm()}   
                  </form>
              </FormProvider>
        </Panel>
      </Paper>
        </div>
      </div>

        <footer className={styles.footer}>
          {/* {blogCreated == false && (
            <>
            <SixtyButton
              type="submit"
              variant="tertiary"
              onClick={formMethods.handleSubmit(onSaveAsDraft)}
              className={styles.approveBtn}
              disabled={blogLoading || uploadingFiles}
              >
              {"Save as draft"}
            </SixtyButton>
            <SixtyButton
              type="submit"
              onClick={formMethods.handleSubmit(onSubmit)}
              className={styles.approveBtn}
              disabled={blogLoading || uploadingFiles}
              >
              {"Publish content"}
            </SixtyButton>
            </>
          )} */}

        </footer>
      </div>
    </React.Fragment>
  );
};

export default CreateBlog;
