import React, { useState, useEffect, useRef  } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { useProgressUploads, useUploads } from "queries/media";
import { Editor } from '@tinymce/tinymce-react';
import Button from "components/SixtyButton";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";

const UpdateForm = () => {


  const { control, watch, errors } = useFormContext();
 

  return (
    <div className={styles.form}> 
      <div style={{height:15}} ></div>
      <div className={styles.section}>Video Story</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
         <Input
           name="name"
           label="Name"
           defaultValue=""
         />
      </div>
      <div className={styles.fullFormRow}>
        <Input
           name="instruction"
           label="Story"
           lines={20}
           defaultValue=""
         />
      </div>
    </div>
  );
};

export default UpdateForm;
