// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginLayout_page__nP9oH {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    background-color: #1C1F4D;
}

.LoginLayout_logo__r1Sso {
    height: 200px;
    margin-bottom: 20px;
}

/* Fade-in animation */
.LoginLayout_fadeIn__ZSUvU {
    animation: LoginLayout_fadeIn__ZSUvU 3s ease-in-out;
}

/* Fade-out animation */
.LoginLayout_fadeOut__DrD7q {
    animation: LoginLayout_fadeOut__DrD7q 3s ease-in-out;
}

/* Keyframes for fade-in */
@keyframes LoginLayout_fadeIn__ZSUvU {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Keyframes for fade-out */
@keyframes LoginLayout_fadeOut__DrD7q {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/routes/layouts/LoginLayout/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA,sBAAsB;AACtB;IACI,mDAAgC;AACpC;;AAEA,uBAAuB;AACvB;IACI,oDAAiC;AACrC;;AAEA,0BAA0B;AAC1B;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ;;AAEA,2BAA2B;AAC3B;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".page {\n    width: 100%;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 80px;\n    background-color: #1C1F4D;\n}\n\n.logo {\n    height: 200px;\n    margin-bottom: 20px;\n}\n\n/* Fade-in animation */\n.fadeIn {\n    animation: fadeIn 3s ease-in-out;\n}\n\n/* Fade-out animation */\n.fadeOut {\n    animation: fadeOut 3s ease-in-out;\n}\n\n/* Keyframes for fade-in */\n@keyframes fadeIn {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n}\n\n/* Keyframes for fade-out */\n@keyframes fadeOut {\n    0% {\n        opacity: 1;\n    }\n    100% {\n        opacity: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `LoginLayout_page__nP9oH`,
	"logo": `LoginLayout_logo__r1Sso`,
	"fadeIn": `LoginLayout_fadeIn__ZSUvU`,
	"fadeOut": `LoginLayout_fadeOut__DrD7q`
};
export default ___CSS_LOADER_EXPORT___;
