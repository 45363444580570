// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalSalesDetails_totalSalesDetailsPage__8Bw-e {
    width: calc(100vw - 250px);
    padding: 24px 24px 40px 82px;
}

.TotalSalesDetails_row__cAKVo {  
    display: flex;
    flex-wrap: wrap;
  }

.TotalSalesDetails_panelWrap__wnrFD {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 8px 8px;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Reports/components/TotalSalesDetails/index.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,eAAe;EACjB;;AAEF;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf","sourcesContent":[".totalSalesDetailsPage {\n    width: calc(100vw - 250px);\n    padding: 24px 24px 40px 82px;\n}\n\n.row {  \n    display: flex;\n    flex-wrap: wrap;\n  }\n\n.panelWrap {\n    display: block;\n    overflow-x: auto;\n    overflow-y: hidden;\n    margin: 8px 8px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalSalesDetailsPage": `TotalSalesDetails_totalSalesDetailsPage__8Bw-e`,
	"row": `TotalSalesDetails_row__cAKVo`,
	"panelWrap": `TotalSalesDetails_panelWrap__wnrFD`
};
export default ___CSS_LOADER_EXPORT___;
