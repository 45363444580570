// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalSalesTable_header__nfmpJ {
  display: flex;
  padding: 16px;
  justify-content: space-between;
}

.TotalSalesTable_headerOptions__e5kA4 {
  display: flex;
}

.TotalSalesTable_headerOptions__e5kA4 > * {
  margin-right: 8px;
}

.TotalSalesTable_headerOptions__e5kA4 > *:last-child {
  margin-right: 0;
}

.TotalSalesTable_panelWrap__ObgaG {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 8px 8px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Reports/components/TotalSalesDetails/components/TotalSalesTable/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb","sourcesContent":[".header {\n  display: flex;\n  padding: 16px;\n  justify-content: space-between;\n}\n\n.headerOptions {\n  display: flex;\n}\n\n.headerOptions > * {\n  margin-right: 8px;\n}\n\n.headerOptions > *:last-child {\n  margin-right: 0;\n}\n\n.panelWrap {\n  display: flex;\n  overflow-x: auto;\n  overflow-y: hidden;\n  margin: 8px 8px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `TotalSalesTable_header__nfmpJ`,
	"headerOptions": `TotalSalesTable_headerOptions__e5kA4`,
	"panelWrap": `TotalSalesTable_panelWrap__ObgaG`
};
export default ___CSS_LOADER_EXPORT___;
