import { Controller } from "react-hook-form";
import Select from "components/SixtySelect";

export default function ControlledSelect({
  control,
  name,
  handleChange,
  label,
  defaultValue,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { onChange, value } = field;
        const { error } = fieldState;
    
        return (
          <>
            <Select
              onChange={(value) => {
                onChange(value);
                handleChange && handleChange(name, value);
              }}
              label={label}
              defaultValue={defaultValue || value}
              value={value}
              {...props}
            />
          </>
        );
      }}
    ></Controller>
  );
}
