// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BillingPlan_container__EZUFE {
    width: 100%;
    height: 100%;
}

.BillingPlan_row__Cg0Eb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
}

.BillingPlan_leftContainer__lDN-4, .BillingPlan_rightContainer__kEFv9 {
    display: flex;
    width: 50%; 
    padding: 16px;
}

.BillingPlan_leftContainer__lDN-4 {
    align-items: flex-start;
    flex-direction: column;
}

.BillingPlan_rightContainer__kEFv9 {
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
}

.BillingPlan_rowHeader__9TFQa, .BillingPlan_rowValue__4MwHI, .BillingPlan_rowValueDetails__h4RAV {
    font-size: 14px;
}

.BillingPlan_rowHeader__9TFQa {
    font-family: var(--regular400);
    color: var(--black50Transparent);
}

.BillingPlan_rowValue__4MwHI {
    font-family: var(--medium500);
    color: var(--black);
    padding: 8px 0px;
}

.BillingPlan_rowValueDetails__h4RAV {
    font-family: var(--regular400);
    color: var(--danger);
}

.BillingPlan_planUpgradeBtn__siug1 {
    margin-right: 8px;
}

.BillingPlan_modalTitleBar__wx4rH {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
} `, "",{"version":3,"sources":["webpack://./src/screens/Settings/Billing/components/BillingPlan/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n}\n\n.row {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    height: 100%;\n}\n\n.leftContainer, .rightContainer {\n    display: flex;\n    width: 50%; \n    padding: 16px;\n}\n\n.leftContainer {\n    align-items: flex-start;\n    flex-direction: column;\n}\n\n.rightContainer {\n    align-items: flex-end;\n    flex-direction: row;\n    justify-content: flex-end;\n}\n\n.rowHeader, .rowValue, .rowValueDetails {\n    font-size: 14px;\n}\n\n.rowHeader {\n    font-family: var(--regular400);\n    color: var(--black50Transparent);\n}\n\n.rowValue {\n    font-family: var(--medium500);\n    color: var(--black);\n    padding: 8px 0px;\n}\n\n.rowValueDetails {\n    font-family: var(--regular400);\n    color: var(--danger);\n}\n\n.planUpgradeBtn {\n    margin-right: 8px;\n}\n\n.modalTitleBar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BillingPlan_container__EZUFE`,
	"row": `BillingPlan_row__Cg0Eb`,
	"leftContainer": `BillingPlan_leftContainer__lDN-4`,
	"rightContainer": `BillingPlan_rightContainer__kEFv9`,
	"rowHeader": `BillingPlan_rowHeader__9TFQa`,
	"rowValue": `BillingPlan_rowValue__4MwHI`,
	"rowValueDetails": `BillingPlan_rowValueDetails__h4RAV`,
	"planUpgradeBtn": `BillingPlan_planUpgradeBtn__siug1`,
	"modalTitleBar": `BillingPlan_modalTitleBar__wx4rH`
};
export default ___CSS_LOADER_EXPORT___;
