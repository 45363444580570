import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import clsx from "clsx";
import GridHeader from "components/GridHeader";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import Avatar from "components/Avatar";
import Button from "components/SixtyButton";
import Select from "components/FormComponents/ControlledSelect";
import Input from "components/FormComponents/ControlledInput";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import { setToast } from "components/Toast";
import { RegexExpressions } from "common/constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useGetVendorUserDetails,
  useGetGenderDropdown,
  useUpdateVendorProfile,
} from "queries/vendor";
import { ReactComponent as EyeIcon } from "assets/icons/passwordView.svg";
import { ReactComponent as EyeIconActive } from "assets/icons/passwordViewActive.svg";

import { format } from "date-fns";
import { reportError } from "crashlytics";
import _isEmpty from "lodash/isEmpty";

// const defaultValues = {
//   profileImage: "",
//   email: "",
//   name: "",
//   userName: "",
//   countryCode: "",
//   mobile: "",
//   dob: new Date(),
// };

const schema = yup.object().shape({
  name: yup.string().required("Enter full name"),
  userName: yup.string().required("Enter a user name"),
  email: yup.string().email().required("Enter business email address"),
  countryCode: yup.string().required("*"),
  mobile: yup.string().required("Enter contact"),
  currentPassword: yup.string().nullable(),
  newPassword: yup.string().when("currentPassword", {
    is: (val) => !_isEmpty(val),
    then: yup.string().required("Enter new password"),
    otherwise: yup.string().nullable(),
  }),
  dob: yup
    .string()
    .required("Enter date of birth")
    .test("validDob", "Enter a valid date", (val) => {
      return true;
    }),
  gender: yup.string().required("Enter gender"),
});

const AccountSettings = () => {
  const [profilePhoto, setProfilePhoto] = useState([]);

  const { data: genderData } = useGetGenderDropdown();
  const { data: vendorData } = useGetVendorUserDetails();

  const genderOptions = genderData?.length
    ? genderData.map((option) => ({
        label: option.name,
        value: option.key,
      }))
    : [];

  const formMethods = useForm({
    resolver: yupResolver(schema),
  });
  const { setValue, errors } = formMethods;

  const onProfilePhotoDelete = () => {
    setProfilePhoto([]);
  };

  const { mutateAsync: updateVendorProfile } = useUpdateVendorProfile();

  const onSubmit = async (data) => {
    console.log("data from form", data);

    try {
     const result =  await updateVendorProfile({
        name: data?.name,
        gender: data?.gender,

        dateOfBirth: data?.dob && format(new Date(data?.dob), "dd/MM/yyyy"),

        detail: {
          username: data?.userName,
          contact: data?.mobile,
          email: data?.email,
          currentPassword: data?.currentPassword,
          newPassword: data?.newPassword,
        },
      });
      if (result) {
        setToast("Successfully saved account settings");
      }
      
    } catch (error) {
      reportError("SetupVendorAccountInfo: onSubmit: error: ", error);
    }
  };

  useEffect(() => {
    if (vendorData) {
      let newDate = "";
      if (vendorData?.dateOfBirth) {
        let myDate = vendorData?.dateOfBirth.split("/");
        newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
      }

      for (const [key, value] of Object.entries({
        name: vendorData?.name,
        userName: vendorData?.detail?.username,
        email: vendorData?.detail?.email,
        mobile: vendorData?.detail?.contact,
        dob: vendorData?.dateOfBirth && newDate,
        gender: vendorData?.gender,
      })) {
        setValue(key, value);
      }
    }
  }, [setValue, vendorData]);

  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = (e) => setShowPassword((show) => !show);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const handleShowRepeatClick = (e) => setShowRepeatPassword((show) => !show);

  return (
    <div className={styles.accountSettingsPage}>
      <GridHeader header="Account settings" />
      <div className={styles.accountSettingsBody}>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className={styles.form}
          >
            <Panel className={styles.panelWrap}>
              <div className={styles.subHeader}>
                <span> Profile photo </span>
                <div className={styles.profPhotoContainer}>
                  <div className={styles.profPhotoDropzone}>
                    <ImageDropzone
                      name="profileImage"
                      showButton
                      buttonLabel="Upload"
                      DefaultThumb={() => (
                        <Avatar
                          name={vendorData?.name || "AA"}
                          className={styles.avatarHelper}
                        />
                      )}
                      maximumFiles={1}
                      onDropAccepted={(name, selectedFiles) => {
                        setProfilePhoto(selectedFiles);
                      }}
                      selectedFiles={profilePhoto}
                    />
                  </div>
                  {profilePhoto.length > 0 && (
                    <div className={styles.actionButtonsContainer}>
                      <div onClick={onProfilePhotoDelete}>
                        <Button label="Delete" variant="secondary" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.personalDetailsSection}>
                <div className={styles.formSectionHeader}>Personal details</div>
                <div>
                  <div className={styles.formRow}>
                    <Input
                      name="name"
                      error={errors?.name}
                      label="Full name"
                      defaultValue=""
                    />
                    <Input
                      name="userName"
                      error={errors?.userName}
                      label="Username"
                      defaultValue=""
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.businessEmailRow}>
                    <Input
                      name="email"
                      error={errors?.email}
                      label="Business email address"
                      defaultValue=""
                      className={styles.businessEmailInput}
                    />
                    {/* <Button label="Change business email address" variant="text" className={styles.changeEmailBtn} /> */}
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.mobileInputContainer}>
                    <div className={styles.countryCode}>
                      <Select
                        name="countryCode"
                        defaultValue="+60"
                        disabled={true}
                        options={[
                          {
                            label: "Malaysia +60",
                            selectedLabel: "+60",
                            value: "+60",
                          },
                        ]}
                        handleChange={(name, value) => {
                          setValue("countryCode", value);
                        }}
                      />
                    </div>
                    <div className={styles.phoneNumber}>
                      <Input
                        name="mobile"
                        error={errors?.mobile}
                        label="Mobile number"
                        defaultValue=""
                      />
                    </div>
                  </div>
                  <div>
                    <ControlledDatepicker
                      name="dob"
                      label="Date of Birth"
                      error={errors?.dob}
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                    />
                    <div className={styles.dateFormat}>DD / MM / YYYY</div>
                  </div>
                </div>
                <div className={styles.formRow}>
                  <Select
                    name="gender"
                    label="Gender"
                    defaultValue=""
                    error={errors?.gender}
                    options={genderOptions}
                    handleChange={(name, value) => {
                      setValue("gender", value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.changePasswordSection}>
                <div className={styles.formSectionHeader}>Change password</div>
                <div>
                  <div className={styles.formRow}>
                    <Input
                      name="currentPassword"
                      rules={{ required: "required" }}
                      label="Current password"
                      type={showPassword ? "text" : "password"}
                      endIcon={
                        showPassword ? (
                          <EyeIconActive onClick={handleShowClick} />
                        ) : (
                          <EyeIcon onClick={handleShowClick} />
                        )
                      }
                      defaultValue=""
                      error={errors?.currentPassword}
                      className={styles.passwordInput}
                    />
                  </div>
                  <div
                    className={clsx(styles.formRow, styles.newPasswordField)}
                  >
                    <Input
                      name="newPassword"
                      rules={{
                        required: "required",
                        pattern: {
                          value: RegexExpressions.password,
                          message: "Password not strong",
                        },
                      }}
                      type={showRepeatPassword ? "text" : "password"}
                      endIcon={
                        showRepeatPassword ? (
                          <EyeIconActive onClick={handleShowRepeatClick} />
                        ) : (
                          <EyeIcon onClick={handleShowRepeatClick} />
                        )
                      }
                      label="New password"
                      defaultValue=""
                      className={styles.passwordInput}
                      error={errors?.newPassword}
                    />
                    <div className={styles.passwordHelper}>
                      At least 10 characters including a number, uppercase, and
                      lowercase letter.
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
            <div className={styles.footer}>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AccountSettings;
