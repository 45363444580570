import styles from "./index.module.css";
import { useRef } from "react";
import Flyout from "components/Flyout";
import { getNotifications } from "assets/fakeData/fakeData";
import { ReactComponent as BaseIcon } from "assets/images/Base.svg";
import clsx from "clsx";

const NotificationsFlyOut = ({ isOpen, onAttemptClose, notificationData }) => {
  const createDiscountRef = useRef();
  return (
    <Flyout
      isOpen={isOpen}
      onAttemptClose={onAttemptClose}
      flyoutRef={createDiscountRef}
      helperClass={styles.notificationsFlyout}
    >
      <div className={styles.notificationContainer}>
        {notificationData.map((notification) => {
          return (
            <div>
              <div className={styles.title}>{notification?.date}</div>
              <div className={styles.content}>
                <div
                  className={clsx(styles.notificationContentContainer, {
                    [styles.contentIsRead]: notification.isRead,
                  })}
                >
                  <div
                    className={clsx(styles.elipse, styles.elipseSmall, {
                      [styles.elipseBlue]:
                        notification?.notificationType === "Notice",
                      [styles.elipseGreen]:
                        notification?.notificationType === "Success",
                      [styles.elipseYellow]:
                        notification?.notificationType === "Warning",
                      [styles.elipseOrange]:
                        notification?.notificationType === "Error",
                    })}
                  >
                    <BaseIcon />
                  </div>
                  <div>
                    <span className={styles.notificationType}>
                      {notification?.notificationType}:&nbsp;
                    </span>
                    <span>{notification.title}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Flyout>
  );
};

export default NotificationsFlyOut;
