import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./index.module.css";
import { ReactComponent as IconMultiImageDrop } from "assets/icons/Icon-MultiImageDrop.svg";
import { ReactComponent as IconAddMore } from "assets/icons/Icon-Plus.svg";
import { map } from "lodash";
import Button from "components/SixtyButton";
import clsx from "clsx";

const ImageDropzone = React.forwardRef(
  (
    {
      title = "Drag your images here",
      subTitle = "or Click to browse for files",
      DefaultThumb = IconMultiImageDrop,
      selectedFiles = [],
      maximumFiles,
      onDropAccepted,
      onDropRejected,
      onRemoveFile = () => {},
      error,
      setOpenDropzonehook,
      showButton = false,
      buttonLabel = "",
      noClick,
      imageClassName,
      rounded,
      noName
    },
    ref
  ) => {
    const [filesPresent, setFilesPresent] = useState(false);

    const { getRootProps, getInputProps, open } = useDropzone({
      maxFiles: maximumFiles,
      multiple: maximumFiles !== 1,
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        const selectedFiles = map(acceptedFiles, (file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        onDropAccepted(selectedFiles);
        if (onDropAccepted) {
          onDropAccepted(selectedFiles);
        }
      },
      onDropRejected: (fileRejections) => {
        if (onDropRejected) {
          onDropRejected(fileRejections);
        }
      },
      noClick: filesPresent || noClick,
    });

    useEffect(() => {
      if (setOpenDropzonehook) setOpenDropzonehook(open);
    }, [open, setOpenDropzonehook]);

    useEffect(() => {
      if (selectedFiles.length > 0 && !filesPresent) {
        setFilesPresent(true);
      }
      if (selectedFiles.length === 0 && filesPresent) {
        setFilesPresent(false);
      }
    }, [selectedFiles, filesPresent]);

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        return () =>
          selectedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
      },
      [selectedFiles]
    );

    const truncateFilename = (fileName) => {
      if (!fileName.length) {
        return "";
      }
      if (fileName.length <= 12) {
        return fileName;
      }
      const maximumAllowedCharacters = 9;
      const nameArray = fileName.split(".");
      const extension = nameArray[nameArray.length - 1];
      let result = "";
      let requiredRemainingCharacter =
        maximumAllowedCharacters - extension.length;
      for (let i = 0; i < nameArray.length - 1; i++) {
        if (nameArray[i].length < requiredRemainingCharacter) {
          result = result + nameArray[i];
          requiredRemainingCharacter -= nameArray[i].length;
        } else {
          result =
            result + nameArray[i].substr(0, requiredRemainingCharacter - 1);
          break;
        }
      }
      result = result + " ...." + extension;
      return result;
    };

    const mainImageRef = useRef();
    const [dimensions, setDimensions] = useState();
    useEffect(() => {
      if (!dimensions)
        if (mainImageRef?.current?.naturalWidth) {
          let width = mainImageRef.current.naturalWidth;
          let height = mainImageRef.current.naturalHeight;
          let dimension = `${width} x ${height}`;
          setDimensions(dimension);
        } else {
          setDimensions();
        }
    }, [setDimensions, dimensions]);

    const singleFileSelectedRenderer = map(selectedFiles, (file, index) => (
      <div className={styles.singleSelectContainer} key={file.name || index}>
        <div className={clsx(styles.thumbInner)}>
          <img
            src={file?.preview || file?.path}
            className={clsx(styles.img, imageClassName, rounded && styles.rounded)}
            alt="No preview"
            ref={mainImageRef}
          />
          {!noName && file.name && (
            <div className={styles.fileDetails}>
              <span className={styles.fileName}>
                {truncateFilename(file.name)}
              </span>
              <span className={styles.fileDimensions}>{dimensions}</span>
            </div>
          )}
        </div>
      </div>
    ));

    const multiFileSelectedRenderer = () => {
      if (!selectedFiles.length) {
        return;
      }
      return (
        <div className={styles.multiSelectContainer}>
          {selectedFiles.map((file, index) => (
            <div className={styles.multiSelectImage} key={file?.name + index}>
              <div className={styles.thumbInner}>
                <div className={styles.imgContainer}>
                  {file?.preview ? (
                    <img
                      src={file.preview}
                      className={styles.img}
                      alt="preview"
                    />
                  ) : (
                    <img src={file.path} className={styles.img} alt="preview" />
                  )}
                  <div
                    className={styles.removeImage}
                    onClick={() => onRemoveFile(file)}
                  >
                    Remove
                  </div>
                </div>
                <span className={styles.fileName}>
                  {truncateFilename(file.name)}
                </span>
              </div>
            </div>
          ))}
          <div className={styles.multiSelectImage} onClick={open}>
            <div className={styles.addMore}>
              <IconAddMore />
            </div>
            <div className={styles.addMoreText}>Add More</div>
          </div>
        </div>
      );
    };

    const thumbs = () => {
      if (maximumFiles === 1) {
        return singleFileSelectedRenderer;
      } else {
        return multiFileSelectedRenderer();
      }
    };

    return (
      <>
        <section ref={ref} className={styles.container}>
          <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            {!selectedFiles.length && (
              <div
                className={clsx(styles.defaultThumbContainer, {
                  [styles.showInlineBtns]: showButton,
                })}
              >
                <div className={styles.defaultThumb}>
                  {DefaultThumb && <DefaultThumb />}
                </div>
                {!showButton && (
                  <div>
                    {title && <p className={styles.title}>{title}</p>}
                    {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
                  </div>
                )}
                {showButton && (
                  <Button
                    label={buttonLabel}
                    variant="secondary"
                    onClick={() => open()}
                  />
                )}
              </div>
            )}
            <aside
              className={clsx(styles.thumbsContainer, {
                [styles.inineBtnThumbs]: showButton,
              })}
            >
              {thumbs()}
            </aside>
          </div>
        </section>
        {error && <div className={styles.errorMessage}>{error.message}</div>}
      </>
    );
  }
);
export default ImageDropzone;
