// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalSalesReport_totalSalesReport__PHYY9 {
    /* height: 315px; */
    /* border-bottom: 1px solid var(--black10Transparent); */
}
`, "",{"version":3,"sources":["webpack://./src/screens/Reports/components/TotalSalesReport/index.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,wDAAwD;AAC5D","sourcesContent":[".totalSalesReport {\n    /* height: 315px; */\n    /* border-bottom: 1px solid var(--black10Transparent); */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalSalesReport": `TotalSalesReport_totalSalesReport__PHYY9`
};
export default ___CSS_LOADER_EXPORT___;
