import styles from './index.module.css';
import clsx from "clsx";

const PanelHeader = ({
    title,
    subTitle,
    className,
    ...props
}) => {
    return (
        <div className={clsx(styles.panelTitle, className)}>
            <div>
                <div>{title}</div>
                {subTitle && (
                    <div className={styles.panelSubTitle}>
                        {subTitle}
                    </div>
                )}
            </div>
            {props.children && <div >{props.children}</div>}
        </div>
    )
}

export default PanelHeader;