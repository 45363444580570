// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BalanceDetails_balanceDetailsTitleBar__HVwoS {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}  

.BalanceDetails_container__WQhjn {
    width: 100%;
    height: 100%;
}

.BalanceDetails_row__WV4\\+U {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
}

.BalanceDetails_leftContainer__pCyHJ, .BalanceDetails_rightContainer__BZUJS {
    display: flex;
    flex-direction: column;
    width: 50%; 
    padding: 16px;
}

.BalanceDetails_leftContainer__pCyHJ {
    align-items: flex-start;
}

.BalanceDetails_rightContainer__BZUJS {
    align-items: flex-end;
}

.BalanceDetails_rowHeader__2Eg3i {
    font-family: var(--regular400);
    font-size: 14px;
    color: var(--black50Transparent);
    padding-bottom: 4px;
}

.BalanceDetails_rowValue__TKDT- {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
}`, "",{"version":3,"sources":["webpack://./src/screens/WalletBalances/components/BalanceDetails/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".balanceDetailsTitleBar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}  \n\n.container {\n    width: 100%;\n    height: 100%;\n}\n\n.row {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    height: 100%;\n}\n\n.leftContainer, .rightContainer {\n    display: flex;\n    flex-direction: column;\n    width: 50%; \n    padding: 16px;\n}\n\n.leftContainer {\n    align-items: flex-start;\n}\n\n.rightContainer {\n    align-items: flex-end;\n}\n\n.rowHeader {\n    font-family: var(--regular400);\n    font-size: 14px;\n    color: var(--black50Transparent);\n    padding-bottom: 4px;\n}\n\n.rowValue {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balanceDetailsTitleBar": `BalanceDetails_balanceDetailsTitleBar__HVwoS`,
	"container": `BalanceDetails_container__WQhjn`,
	"row": `BalanceDetails_row__WV4+U`,
	"leftContainer": `BalanceDetails_leftContainer__pCyHJ`,
	"rightContainer": `BalanceDetails_rightContainer__BZUJS`,
	"rowHeader": `BalanceDetails_rowHeader__2Eg3i`,
	"rowValue": `BalanceDetails_rowValue__TKDT-`
};
export default ___CSS_LOADER_EXPORT___;
