// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserAuthenticationContainer_userAuthenticationPaper__\\+kgQl {
  width: 100%;
  max-width: 360px;
  align-items: center;
  padding-top: 40px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 5px var(--black5Transparent);
  border: 1px solid var(--black10Transparent);
}

.UserAuthenticationContainer_largePaper__vFij- {
  max-width: 560px;
}

.UserAuthenticationContainer_header__00RIr {
  margin-bottom: 24px;
  text-align: center;
}

.UserAuthenticationContainer_form__X2kd8 {
  width: 100%;
  margin-bottom: 8px;
}

.UserAuthenticationContainer_formBody__bQIGP > * {
  margin-bottom: 16px;
}

.UserAuthenticationContainer_showPassword__8AbRV {
  cursor: pointer;
}

.UserAuthenticationContainer_formFooter__HIK\\+a {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.UserAuthenticationContainer_formFooter__HIK\\+a *:first-child {
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Onboarding/components/UserAuthenticationContainer/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,gDAAgD;EAChD,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".userAuthenticationPaper {\n  width: 100%;\n  max-width: 360px;\n  align-items: center;\n  padding-top: 40px;\n  margin-bottom: 16px;\n  box-shadow: 0px 2px 5px var(--black5Transparent);\n  border: 1px solid var(--black10Transparent);\n}\n\n.largePaper {\n  max-width: 560px;\n}\n\n.header {\n  margin-bottom: 24px;\n  text-align: center;\n}\n\n.form {\n  width: 100%;\n  margin-bottom: 8px;\n}\n\n.formBody > * {\n  margin-bottom: 16px;\n}\n\n.showPassword {\n  cursor: pointer;\n}\n\n.formFooter {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.formFooter *:first-child {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userAuthenticationPaper": `UserAuthenticationContainer_userAuthenticationPaper__+kgQl`,
	"largePaper": `UserAuthenticationContainer_largePaper__vFij-`,
	"header": `UserAuthenticationContainer_header__00RIr`,
	"form": `UserAuthenticationContainer_form__X2kd8`,
	"formBody": `UserAuthenticationContainer_formBody__bQIGP`,
	"showPassword": `UserAuthenticationContainer_showPassword__8AbRV`,
	"formFooter": `UserAuthenticationContainer_formFooter__HIK+a`
};
export default ___CSS_LOADER_EXPORT___;
