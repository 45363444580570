// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopupCreditForm_form__dbREe {
    padding: 16px;
}

.TopupCreditForm_formContainer__FOraO{
    min-width: 500px;
}

.TopupCreditForm_footer__IlJZH {
    padding: 16px;
    border-top: 1px solid var(--black10);
}

.TopupCreditForm_withdrawBtn__yS57t {
    margin-right: 8px;
}

.TopupCreditForm_fieldHeader__-EaLp {
    font-family: var(--regular400);
    font-size: 14px;
    color: var(--black50Transparent);
    padding-bottom: 8px;
}

.TopupCreditForm_fieldValue__49np6 {
    font-family: var(--regular400);
    padding-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/screens/WalletBalances/components/TopupCreditForm/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,oBAAoB;AACxB","sourcesContent":[".form {\n    padding: 16px;\n}\n\n.formContainer{\n    min-width: 500px;\n}\n\n.footer {\n    padding: 16px;\n    border-top: 1px solid var(--black10);\n}\n\n.withdrawBtn {\n    margin-right: 8px;\n}\n\n.fieldHeader {\n    font-family: var(--regular400);\n    font-size: 14px;\n    color: var(--black50Transparent);\n    padding-bottom: 8px;\n}\n\n.fieldValue {\n    font-family: var(--regular400);\n    padding-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `TopupCreditForm_form__dbREe`,
	"formContainer": `TopupCreditForm_formContainer__FOraO`,
	"footer": `TopupCreditForm_footer__IlJZH`,
	"withdrawBtn": `TopupCreditForm_withdrawBtn__yS57t`,
	"fieldHeader": `TopupCreditForm_fieldHeader__-EaLp`,
	"fieldValue": `TopupCreditForm_fieldValue__49np6`
};
export default ___CSS_LOADER_EXPORT___;
