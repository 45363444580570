import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export default function ControlledInput({
  control,
  name,
  handleChange,
  label,
  rules,
  error,
  defaultValue = "",
  type = "text",
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => {
        const { onChange, value, ref } = field;
        const { error } = fieldState;

        if (type === "hidden") {
          return (
            <input
              type="hidden"
              {...field}
              ref={ref}
            />
          );
        }

        return (
          <TextField
            {...field}
            inputRef={ref}
            label={label}
            // fullWidth
            size="small"
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            onChange={(e) => {
              onChange(e);
              handleChange && handleChange(name, e.target.value);
            }}
            {...props}
          />
        );
      }}
    />
  );
}
