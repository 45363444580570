// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Report_GeneralReportContainer__PeOJq {
    display: flex;
    flex-direction: column;
    padding: 16px
}

.Report_title__WCvU7, .Report_previousMonthCountText__seQ6A {
    color: var(--black50Transparent);
    font-size: 14px;
    font-family: var(--regular400);
}

.Report_counts__woLUP {
    padding: 4px 0px;
}

.Report_currentMonthCount__Txe1r {
    color: var(--black);
    font-family: var(--medium500);
    font-size: 24px;
    margin-right: 8px;
}

.Report_previousMonthCountText__seQ6A {
    font-size: 12px;
    margin-bottom: 10px;
}

.Report_profit__M0I0t, .Report_loss__XCwbg, .Report_unit__zgIza {
    font-family: var(--regular400);
    font-size: 14px;
}

.Report_unit__zgIza {
    color: var(--black50Transparent)
}

.Report_profit__M0I0t {
    color: var(--success);
}

.Report_loss__XCwbg {
    color: var(--danger);
}

.Report_profitInNewLine__iHtRJ {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/screens/AdminScreens/Dashboard/components/Report/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB;AACJ;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI;AACJ;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".GeneralReportContainer {\n    display: flex;\n    flex-direction: column;\n    padding: 16px\n}\n\n.title, .previousMonthCountText {\n    color: var(--black50Transparent);\n    font-size: 14px;\n    font-family: var(--regular400);\n}\n\n.counts {\n    padding: 4px 0px;\n}\n\n.currentMonthCount {\n    color: var(--black);\n    font-family: var(--medium500);\n    font-size: 24px;\n    margin-right: 8px;\n}\n\n.previousMonthCountText {\n    font-size: 12px;\n    margin-bottom: 10px;\n}\n\n.profit, .loss, .unit {\n    font-family: var(--regular400);\n    font-size: 14px;\n}\n\n.unit {\n    color: var(--black50Transparent)\n}\n\n.profit {\n    color: var(--success);\n}\n\n.loss {\n    color: var(--danger);\n}\n\n.profitInNewLine {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GeneralReportContainer": `Report_GeneralReportContainer__PeOJq`,
	"title": `Report_title__WCvU7`,
	"previousMonthCountText": `Report_previousMonthCountText__seQ6A`,
	"counts": `Report_counts__woLUP`,
	"currentMonthCount": `Report_currentMonthCount__Txe1r`,
	"profit": `Report_profit__M0I0t`,
	"loss": `Report_loss__XCwbg`,
	"unit": `Report_unit__zgIza`,
	"profitInNewLine": `Report_profitInNewLine__iHtRJ`
};
export default ___CSS_LOADER_EXPORT___;
