// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TaxesTable_loaderContainer__v0LJr {
    min-height: 180px;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Settings/Taxes/components/TaxesTable/index.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;EACnB","sourcesContent":[".loaderContainer {\n    min-height: 180px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `TaxesTable_loaderContainer__v0LJr`
};
export default ___CSS_LOADER_EXPORT___;
