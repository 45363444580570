// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SixtyPanel_panel__OfDJm {
    min-width: 30vw;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.SixtyPanel_actionButton__li3DL {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/components/SixtyPanel/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,sBAAsB;IACtB,oCAAoC;IACpC,kBAAkB;IAClB,sBAAsB;IACtB,2CAA2C;IAC3C,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,oBAAoB;EACtB","sourcesContent":[".panel {\n    min-width: 30vw;\n    background-color: #fff;\n    border: 1px solid rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n    box-sizing: border-box;\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);\n    margin: 20px;\n    display: flex;\n    flex-direction: column;\n}\n\n.actionButton {\n    display: flex;\n    justify-content: center;\n    padding-bottom: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `SixtyPanel_panel__OfDJm`,
	"actionButton": `SixtyPanel_actionButton__li3DL`
};
export default ___CSS_LOADER_EXPORT___;
