// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoStories_blogTable__TNXR6 {
    width: calc(95vw - 250px);
}

.VideoStories_panelWrap__3NmBg {
    margin: 20px;
    display: block;
    min-height: unset;
    position: relative;
}

.VideoStories_gridHeader__Hl86h {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
}

.VideoStories_ButtonsContainer__xdG5g {
    display: flex;
    align-items: center;
}

.VideoStories_exportBtn__pvnSo {
    font-family: var(--medium500);
    color: var(--black50Transparent);
    font-size: 14px;
}

.VideoStories_blogPageHeaderContainer__0Jr0Y {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 8px;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
    margin-bottom: 16px;
}

.VideoStories_modalTitle__OyqVw {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
    padding-left: 8px;
}

.VideoStories_modalTitleBar__X3\\+JZ {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}   

.VideoStories_modalContainer__6u4ao {
    display: flex;
    align-items: center;
}

.VideoStories_closeIcon__YenFI {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/screens/AdminScreens/VideoStories/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".blogTable {\n    width: calc(95vw - 250px);\n}\n\n.panelWrap {\n    margin: 20px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.gridHeader {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n}\n\n.ButtonsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.exportBtn {\n    font-family: var(--medium500);\n    color: var(--black50Transparent);\n    font-size: 14px;\n}\n\n.blogPageHeaderContainer {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0px 8px;\n    justify-content: space-between;\n    align-items: center;\n    margin: 24px;\n    margin-bottom: 16px;\n}\n\n.modalTitle {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n    padding-left: 8px;\n}\n\n.modalTitleBar {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: space-between;\n}   \n\n.modalContainer {\n    display: flex;\n    align-items: center;\n}\n\n.closeIcon {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blogTable": `VideoStories_blogTable__TNXR6`,
	"panelWrap": `VideoStories_panelWrap__3NmBg`,
	"gridHeader": `VideoStories_gridHeader__Hl86h`,
	"ButtonsContainer": `VideoStories_ButtonsContainer__xdG5g`,
	"exportBtn": `VideoStories_exportBtn__pvnSo`,
	"blogPageHeaderContainer": `VideoStories_blogPageHeaderContainer__0Jr0Y`,
	"modalTitle": `VideoStories_modalTitle__OyqVw`,
	"modalTitleBar": `VideoStories_modalTitleBar__X3+JZ`,
	"modalContainer": `VideoStories_modalContainer__6u4ao`,
	"closeIcon": `VideoStories_closeIcon__YenFI`
};
export default ___CSS_LOADER_EXPORT___;
