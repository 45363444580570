import { queryClient } from "common/query";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";
import { $CombinedState } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";


  export function useGenerateArticle() {
    return useMutation(
      (data) =>
        api
          .post(Endpoints.Writer.GenerateArticle, data)
          .then((response) => response?.data?.result),
      {
        onSuccess: () => {
        //   queryClient.refetchQueries(Endpoints.Admin.GetVideoStories);
        },
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useGenerateArticle: useMutation: onError: error",
            error
          );
        }
      }
    );
  }

  export function useGenerateHashtag() {
    return useMutation(
      (data) =>
        api
          .post(Endpoints.SocialMedia.GenerateHashtag, data)
          .then((response) => response?.data?.result),
      {
        onSuccess: () => {
        //   queryClient.refetchQueries(Endpoints.Admin.GetVideoStories);
        },
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useGenerateHashtag: useMutation: onError: error",
            error
          );
        }
      }
    );
  }

  export function useGenerateCaption() {
    return useMutation(
      (data) =>
        api
          .post(Endpoints.SocialMedia.GenerateCaption, data)
          .then((response) => response?.data?.result),
      {
        onSuccess: () => {
        //   queryClient.refetchQueries(Endpoints.Admin.GetVideoStories);
        },
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useGenerateCaption: useMutation: onError: error",
            error
          );
        }
      }
    );
  }

  export function useGenerateShortVideoScript() {
    return useMutation(
      (data) =>
        api
          .post(Endpoints.SocialMedia.GenerateShortVideoScript, data)
          .then((response) => response?.data?.result),
      {
        onSuccess: () => {
        //   queryClient.refetchQueries(Endpoints.Admin.GetVideoStories);
        },
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useGenerateShortVideoScript: useMutation: onError: error",
            error
          );
        }
      }
    );
  }
