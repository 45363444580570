// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreditCard_container__6SVih {
    width: 80%;
    padding-bottom: 40px;
  }
  .CreditCard_templateContainer__J5lsT {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .CreditCard_pricingPlanHeader__JW88F {
    width: 100%;
    margin: 24px 8px 16px 8px;
    text-align: center !important;
  }
  
  .CreditCard_profileLayoutHelper__XMvAR {
    padding-top: 0px;
  }
  
  .CreditCard_footerHr__RH3dg {
    width: 100%;
    margin: 24px 16px;
  }
  
  .CreditCard_footer__GX-el {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  `, "",{"version":3,"sources":["webpack://./src/screens/Onboarding/SubscriptionPlan/CreditCard/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oBAAoB;EACtB;EACA;IACE,aAAa;IACb,WAAW;IACX,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,6BAA6B;EAC/B;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".container {\n    width: 80%;\n    padding-bottom: 40px;\n  }\n  .templateContainer {\n    display: flex;\n    width: 100%;\n    justify-content: center;\n  }\n  \n  .pricingPlanHeader {\n    width: 100%;\n    margin: 24px 8px 16px 8px;\n    text-align: center !important;\n  }\n  \n  .profileLayoutHelper {\n    padding-top: 0px;\n  }\n  \n  .footerHr {\n    width: 100%;\n    margin: 24px 16px;\n  }\n  \n  .footer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CreditCard_container__6SVih`,
	"templateContainer": `CreditCard_templateContainer__J5lsT`,
	"pricingPlanHeader": `CreditCard_pricingPlanHeader__JW88F`,
	"profileLayoutHelper": `CreditCard_profileLayoutHelper__XMvAR`,
	"footerHr": `CreditCard_footerHr__RH3dg`,
	"footer": `CreditCard_footer__GX-el`
};
export default ___CSS_LOADER_EXPORT___;
