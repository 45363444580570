// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Blogs_blogTable__Diq49 {
    width: calc(95vw - 250px);
}

.Blogs_panelWrap__1Kf\\+i {
    margin: 20px;
    display: block;
    min-height: unset;
    position: relative;
}

.Blogs_gridHeader__O\\+vIt {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
}

.Blogs_ButtonsContainer__SzaBM {
    display: flex;
    align-items: center;
}

.Blogs_exportBtn__bBfNi {
    font-family: var(--medium500);
    color: var(--black50Transparent);
    font-size: 14px;
}

.Blogs_blogPageHeaderContainer__5PA4y {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 8px;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
    margin-bottom: 16px;
}

.Blogs_modalTitle__4iGPR {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
    padding-left: 8px;
}

.Blogs_blogDetailModalTitleBar__TnmS1 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}   

.Blogs_blogNameContainer__Eh9-y {
    display: flex;
    align-items: center;
}

.Blogs_closeIcon__wpsNm {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Blogs/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".blogTable {\n    width: calc(95vw - 250px);\n}\n\n.panelWrap {\n    margin: 20px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.gridHeader {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n}\n\n.ButtonsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.exportBtn {\n    font-family: var(--medium500);\n    color: var(--black50Transparent);\n    font-size: 14px;\n}\n\n.blogPageHeaderContainer {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0px 8px;\n    justify-content: space-between;\n    align-items: center;\n    margin: 24px;\n    margin-bottom: 16px;\n}\n\n.modalTitle {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n    padding-left: 8px;\n}\n\n.blogDetailModalTitleBar {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: space-between;\n}   \n\n.blogNameContainer {\n    display: flex;\n    align-items: center;\n}\n\n.closeIcon {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blogTable": `Blogs_blogTable__Diq49`,
	"panelWrap": `Blogs_panelWrap__1Kf+i`,
	"gridHeader": `Blogs_gridHeader__O+vIt`,
	"ButtonsContainer": `Blogs_ButtonsContainer__SzaBM`,
	"exportBtn": `Blogs_exportBtn__bBfNi`,
	"blogPageHeaderContainer": `Blogs_blogPageHeaderContainer__5PA4y`,
	"modalTitle": `Blogs_modalTitle__4iGPR`,
	"blogDetailModalTitleBar": `Blogs_blogDetailModalTitleBar__TnmS1`,
	"blogNameContainer": `Blogs_blogNameContainer__Eh9-y`,
	"closeIcon": `Blogs_closeIcon__wpsNm`
};
export default ___CSS_LOADER_EXPORT___;
