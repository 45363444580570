// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../theme/theme.module.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../theme/font.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Avatar_avatar__vXxtQ {
  background-color: #1C1F4D;
  color: #fff;
  height: 30px;
  width: 30px;
  border: none;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/components/Avatar/index.module.css"],"names":[],"mappings":"AAAA;EAGE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".avatar {\n  composes: buttonBase from \"theme/theme.module.css\";\n  composes: medium from \"theme/font.css\";\n  background-color: #1C1F4D;\n  color: #fff;\n  height: 30px;\n  width: 30px;\n  border: none;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `Avatar_avatar__vXxtQ ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonBase"]} ${___CSS_LOADER_ICSS_IMPORT_1___.locals["medium"]}`
};
export default ___CSS_LOADER_EXPORT___;
