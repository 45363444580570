// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BankAccountDetails_modalTitleBar__MCUQ2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}  

.BankAccountDetails_container__6jmFj {
    width: 100%;
}

.BankAccountDetails_row__\\+pZEu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.BankAccountDetails_leftContainer__cnrZ7, .BankAccountDetails_rightContainer__svqlY {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.BankAccountDetails_leftContainer__cnrZ7 {
    align-items: flex-start;
    width: 70%; 
}

.BankAccountDetails_rightContainer__svqlY {
    align-items: flex-end;
    width: 30%; 
}

.BankAccountDetails_rowHeader__EOlIy {
    font-family: var(--medium500);
    font-size: 14px;
    color: var(--black);
    padding-bottom: 4px;
}

.BankAccountDetails_rowValue__-55kV {
    font-family: var(--regular400);
    font-size: 14px;
    color: var(--black50Transparent);
}

.BankAccountDetails_bankAccountDetails__KzDMs {
    display: flex;
    align-items: center;
}

.BankAccountDetails_bankAccountIcon__jRi0u {
    padding-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/screens/WalletBalances/components/BankAccountDetails/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".modalTitleBar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}  \n\n.container {\n    width: 100%;\n}\n\n.row {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n}\n\n.leftContainer, .rightContainer {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n}\n\n.leftContainer {\n    align-items: flex-start;\n    width: 70%; \n}\n\n.rightContainer {\n    align-items: flex-end;\n    width: 30%; \n}\n\n.rowHeader {\n    font-family: var(--medium500);\n    font-size: 14px;\n    color: var(--black);\n    padding-bottom: 4px;\n}\n\n.rowValue {\n    font-family: var(--regular400);\n    font-size: 14px;\n    color: var(--black50Transparent);\n}\n\n.bankAccountDetails {\n    display: flex;\n    align-items: center;\n}\n\n.bankAccountIcon {\n    padding-right: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitleBar": `BankAccountDetails_modalTitleBar__MCUQ2`,
	"container": `BankAccountDetails_container__6jmFj`,
	"row": `BankAccountDetails_row__+pZEu`,
	"leftContainer": `BankAccountDetails_leftContainer__cnrZ7`,
	"rightContainer": `BankAccountDetails_rightContainer__svqlY`,
	"rowHeader": `BankAccountDetails_rowHeader__EOlIy`,
	"rowValue": `BankAccountDetails_rowValue__-55kV`,
	"bankAccountDetails": `BankAccountDetails_bankAccountDetails__KzDMs`,
	"bankAccountIcon": `BankAccountDetails_bankAccountIcon__jRi0u`
};
export default ___CSS_LOADER_EXPORT___;
