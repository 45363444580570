// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletBalances_transactionTable__NkWKc {
    width: calc(95vw - 250px);
}

.WalletBalances_gridHeader__i1KB6 {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
}
.WalletBalances_walletPageHeaderContainer__xPLbj {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 8px;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
    margin-bottom: 12px;
}

.WalletBalances_modalTitle__P2m3q {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
}

.WalletBalances_panelWrap__zIWdo {
    margin: 8px 8px;
    display: block;
    min-height: unset;
    position: relative;
}

.WalletBalances_firstRow__DNfd9 {
    padding: 8px;
    justify-content: space-between;
}

.WalletBalances_firstRow__DNfd9 .WalletBalances_panelWrap__zIWdo  {
    margin: 0px;
    flex-basis: 49%;
}

.WalletBalances_walletBalancesPanel__wZj02 {
    display: flex;
    flex-direction: column;
    margin: 16px;
    margin-top: 12px;
}

.WalletBalances_row__sVqvR {  
    display: flex;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/screens/WalletBalances/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".transactionTable {\n    width: calc(95vw - 250px);\n}\n\n.gridHeader {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n}\n.walletPageHeaderContainer {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0px 8px;\n    justify-content: space-between;\n    align-items: center;\n    margin: 24px;\n    margin-bottom: 12px;\n}\n\n.modalTitle {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n}\n\n.panelWrap {\n    margin: 8px 8px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.firstRow {\n    padding: 8px;\n    justify-content: space-between;\n}\n\n.firstRow .panelWrap  {\n    margin: 0px;\n    flex-basis: 49%;\n}\n\n.walletBalancesPanel {\n    display: flex;\n    flex-direction: column;\n    margin: 16px;\n    margin-top: 12px;\n}\n\n.row {  \n    display: flex;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionTable": `WalletBalances_transactionTable__NkWKc`,
	"gridHeader": `WalletBalances_gridHeader__i1KB6`,
	"walletPageHeaderContainer": `WalletBalances_walletPageHeaderContainer__xPLbj`,
	"modalTitle": `WalletBalances_modalTitle__P2m3q`,
	"panelWrap": `WalletBalances_panelWrap__zIWdo`,
	"firstRow": `WalletBalances_firstRow__DNfd9`,
	"walletBalancesPanel": `WalletBalances_walletBalancesPanel__wZj02`,
	"row": `WalletBalances_row__sVqvR`
};
export default ___CSS_LOADER_EXPORT___;
