import { dehydrate , hydrate } from 'react-query/hydration';
import { StorageKey } from '../common/constants';
import { queryClient } from '../common/query';

const writeToStorage = async (queryKey, data) => {
    const dehydratedState = dehydrate(queryClient, {});
    localStorage.setItem(StorageKey, JSON.stringify(dehydratedState));
};

const readFromStorage = async () => {
    const storageData = await localStorage.getItem(StorageKey);
    if (storageData) {
        const dehydratedState = JSON.parse(storageData);
        if (dehydratedState) {
            hydrate(queryClient, dehydratedState, {});
        }
    }
    return true;
};

const clearStorage = async () => {
    await localStorage.clear();
    queryClient.invalidateQueries();
    queryClient.cancelQueries();
    queryClient.resetQueries();
};

export { readFromStorage, writeToStorage, clearStorage };
