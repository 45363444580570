import React, { useState, useRef } from "react";
import Button from "components/SixtyButton";
import styles from "./index.module.css";
import Modal from "components/Modal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BankAccountIcon } from "assets/icons/Icon-BankAccount.svg";
import AddBankAccountForm from "../AddBankAccountForm";

const BankAccountDetails = ({ bankDetails }) => {
  const [isAddbankAccountModalOpen, setAddbankAccountModalOpen] = useState(
    false
  );
  const history = useNavigate();
  const balanceDetailRef = useRef();

  const Title = () => {
    return (
      <div className={styles.modalTitleBar}>
        <div className={styles.modalTitle}>Add bank account</div>
      </div>
    );
  };

  const onAddBankAccount = () => {
    setAddbankAccountModalOpen(true);
  };

  const onAddBankAccountFormSubmit = () => {
    setAddbankAccountModalOpen(false);
  };

  const onAddBankAccountFormCancel = () => {
    setAddbankAccountModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.leftContainer}>
          <div className={styles.bankAccountDetails}>
            <div className={styles.bankAccountIcon}>
              <BankAccountIcon />
            </div>
            <div>
              {bankDetails ? (
                <div>
                  <div className={styles.rowHeader}>{bankDetails.bankName}</div>
                  <div className={styles.rowValue}>
                    {bankDetails.bankAccountNo}
                  </div>
                </div>
              ) : (
                <div>
                  <div className={styles.rowHeader}>No bank account found</div>
                  <div className={styles.rowValue}>
                    Add a bank account to withdraw your earnings
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          {bankDetails ? (
            <Button
              className={styles.inviteSellerBtn}
              variant="secondary"
              label="Update"
              onClick={onAddBankAccount}
            />
          ) : (
            <Button
              className={styles.inviteSellerBtn}
              label="Add bank account"
              onClick={onAddBankAccount}
            />
          )}
        </div>
      </div>
      {isAddbankAccountModalOpen && (
        <Modal
          isOpen={isAddbankAccountModalOpen}
          onAttemptClose={() => {
            history("/dashboard/walletbalances");
            setAddbankAccountModalOpen(false);
          }}
          modalRef={balanceDetailRef}
          Title={Title}
        >
          <AddBankAccountForm
            update={!!bankDetails}
            handleClose={onAddBankAccountFormCancel}
          />
        </Modal>
      )}
    </div>
  );
};

export default BankAccountDetails;
