import React, { useState, useEffect, useRef, useCallback, useMemo  } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import SixtyButton from "components/SixtyButton";
import Select from "components/FormComponents/ControlledSelect";
import Checkbox from "components/SixtyCheckBox";
import MultiValueInput from "components/FormComponents/ControlledMultiTextInput"; 
import { Editor } from '@tinymce/tinymce-react';
import { Grid2, Box, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createEditor, Transforms, Text } from 'slate';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import { htmlToSlate, slateToHtml  } from "utils/htmlParser";
import TextArea from "components/FormComponents/ControlledTextArea";




const Form = ({setContent, content = null, generateArticleLoading = false, editorRef}) => {

  // const editorRef = useRef(null);
  
  const [profilePhoto, setProfilePhoto] = useState([]);
  const [customAudience, setCustomAudience] = useState(false);
  const [customLanguage, setCustomLanguage] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [targetAudience, setTargetAudience] = useState("");
  const [voiceTone, setVoiceTone] = useState("");
  const [languageStyle, setLanguageStyle] = useState("");
  const [language, setLanguage] = useState("");
  const [videoLength, setVideolength] = useState("");
  const [cta, setCta] = useState("");
  const [duration, setDuration] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [purpose, setPurpose] = useState("");
  const [loadingText, setLoadingText] = useState('AI is thinking');
  const { control, reset, watch, errors } = useFormContext();

  useEffect(() => {
    let interval;
    if (generateArticleLoading) {
      let dots = 0;
      interval = setInterval(() => {
        setLoadingText(prevText => {
          dots = (dots + 1) % 4;
          return `AI is thinking${'.'.repeat(dots)}`;
        });
      }, 500);
    } else {
      setLoadingText('AI is thinking');
    }
    return () => clearInterval(interval);
  }, [generateArticleLoading]);

  const [expandedAccordion, setExpandedAccordion] = useState({
    socialMedia: false,
    styleLanguage: false,
    captionStyle: false,
    purpose: false,
    targetAudience: false
  });

  const collapseAllAccordions = () => {
    setExpandedAccordion({
      socialMedia: false,
      styleLanguage: false,
      captionStyle: false,
      purpose: false,
      targetAudience: false
    });
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion({ ...expandedAccordion, [panel]: isExpanded });
  };

  const handleResetForm = () => {
    reset({
      socialMedia: "",
      productOrServiceName: "",
      goal: "",
      keyMessage: "",
      language: "",
      videoLength: "",
      purpose: "",
      targetAudience: "",
      toneOfVoice: "",
      cta: "",
      revise: ""
   });
   setSocialMedia("");
   setVideolength("");
   setPurpose("");
   setLanguage("");
   setVoiceTone("");
   setTargetAudience("");
   setContent("");
  };

 
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Grid2 container spacing={2} columns={16} sx={{ flexGrow: 1 }}> 
    <Grid2 item size={5} sx={{ height: '100%', overflowY: 'auto' }}>
    <Box className={styles.form}>

    {content && (
              <>
                <div className={styles.section}>Tell your thought to our AI</div>
                <hr className={styles.hr} />
                <div >
                  <TextArea
                   control={control}
                    name="revise"
                    label="Revise the article"
                    defaultValue=""
                  />
                </div>
                <div className={styles.btnActionContainer}>
                    <SixtyButton
                      fullWidth
                      type="submit"
                      variant="warning"
                      className={styles.approveBtn}
                      disabled={generateArticleLoading}
                      onClick={() => {
                        collapseAllAccordions();
                      }}
                      >
                      {"Revise Article"}
                    </SixtyButton>

                 </div>
              </>
    )}

    <Accordion 
        expanded={expandedAccordion.socialMedia} 
        onChange={handleAccordionChange('socialMedia')} 
        elevation={0} 
        className={styles.accordion} 
     >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}>Social Media & Highlight </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>

        <div className={styles.section}>Social media to focus</div>
        <hr className={styles.hr} />
        <div className={styles.fullFormRow}>
            <Select
                    name="socialMedia"
                    options={[
                      {
                          label: "Facebook",
                          selectedLabel: "Facebook",
                          value: "Facebook",
                      },
                      {
                          label: "Instagram",
                          selectedLabel: "Instagram",
                          value: "Instagram",
                      },
                      {
                          label: "Tiktok",
                          selectedLabel: "Tiktok",
                          value: "Tiktok",
                      },
                      {
                          label: "Youtube",
                          selectedLabel: "Youtube",
                          value: "Youtube",
                      },
                      {
                          label: "X",
                          selectedLabel: "X",
                          value: "X",
                      },
                      {
                          label: "LinkedIn",
                          selectedLabel: "LinkedIn",
                          value: "LinkedIn",
                      }
                    ]}
                    fullWidth
                    defaultOption="Social Media"
                    handleChange={(name, value) => {
                      setSocialMedia(value);
                    }}
                    value={socialMedia}
                    defaultValue={socialMedia}
            />
          </div>
          <div style={{height:15}} ></div>
          <div className={styles.section}>Message to Highlight</div>
          <hr className={styles.hr} />
        
            <TextArea
              control={control}
              name="keyMessage"
              label="Message to Highlight"
              defaultValue=""
              suggestions={["Create a short story video that showcases a day in the life of a customer using [Product Name]", "Create a festive, upbeat video to promote [Product Name] during the upcoming [Holiday/Season]", "Create a video story that explains how to use [Product Name] effectively"]}
            />
          
          <div style={{height:15}} ></div>
          <div className={styles.section}>Preference Video Length</div>
          <hr className={styles.hr} />
          <div className={styles.fullFormRow}>
            <Select
                  name="videoLength"
                  options={[
                    {
                        label: "Under 30 seconds",
                        selectedLabel: "Under 30 seconds",
                        value: "Under 30 seconds",
                    },
                    {
                        label: "1-2 minutes",
                        selectedLabel: "1-2 minutes",
                        value: "1-2 minutes",
                    },
                  ]}
                  fullWidth
                  defaultOption="Preference Video Length"
                  handleChange={(name, value) => {
                    setVideolength(value);
                  }}
                  value={videoLength}
                  defaultValue={videoLength}
            />
          </div>
        </AccordionDetails>
    </Accordion>

    <Accordion 
       expanded={expandedAccordion.styleLanguage} 
       onChange={handleAccordionChange('styleLanguage')} 
       elevation={0} 
       className={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}>Language & Tone </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
        
            <div className={styles.section}>Language settings</div>
            <hr className={styles.hr} />
            <div className={styles.fullFormRow}>
                <Checkbox
                    label="Custom language"
                    checked={customLanguage}
                    onChange={(e) => setCustomLanguage(e.target.checked)}
                    uncheckStyleVariant="greyedOut"
                  />
                  <Input
                    name={`isCustomLanguage`}
                    control={control}
                    type="hidden"
                    label="Visibility"
                    defaultValue={'false'}
                    value={'false'}
                    className={styles.hidden}
                  />
            </div>
            <div className={styles.fullFormRow}>
                {!customLanguage && (
                  <Select
                      name="language"
                      options={[
                        {
                            label: "English",
                            selectedLabel: "English",
                            value: "English",
                        },
                        {
                            label: "Malay",
                            selectedLabel: "Malay",
                            value: "Malay",
                        },
                        {
                            label: "Chinese",
                            selectedLabel: "Chinese",
                            value: "Chinese",
                        }
                      ]}
                      fullWidth
                      defaultOption="Language"
                      handleChange={(name, value) => {
                        setLanguage(value);
                      }}
                      value={language}
                      defaultValue={language}
                />
                )}
                {customLanguage && (
                    <Input
                      name="language"
                      label="Write your prefered language"
                      defaultValue=""
                    />
                )} 
            </div>  

          <div style={{height:15}} ></div>
          <div className={styles.section}>Tone settings</div>
          <hr className={styles.hr} />
          <div className={styles.fullFormRow}>
          <Select
                name="toneOfVoice"
                options={[
                  {
                      label: "Professional",
                      selectedLabel: "Professional",
                      value: "Professional",
                  },
                  {
                      label: "Conversational",
                      selectedLabel: "Conversational",
                      value: "Conversational",
                  },
                  {
                      label: "Formal",
                      selectedLabel: "Formal",
                      value: "Formal",
                  },
                  {
                      label: "Neutral",
                      selectedLabel: "Neutral",
                      value: "Neutral",
                  },
                  {
                      label: "Persuasive",
                      selectedLabel: "Persuasive",
                      value: "Persuasive",
                  },
                  {
                      label: "Authoritative",
                      selectedLabel: "Authoritative",
                      value: "Authoritative",
                  },
                  {
                      label: "Humorous",
                      selectedLabel: "Humorous",
                      value: "Humorous",
                  },
                  {
                    label: "Technical",
                    selectedLabel: "Technical",
                    value: "Technical",
                  },
                  {
                      label: "Emotive",
                      selectedLabel: "Emotive",
                      value: "Emotive",
                  },
                  {
                      label: "Playful",
                      selectedLabel: "Playful",
                      value: "Playful",
                  },
                ]}
                fullWidth
                defaultOption="Tone of language"
                handleChange={(name, value) => {
                  setVoiceTone(value);
                }}
                value={voiceTone}
                defaultValue={voiceTone}
            />
          </div>  
        </AccordionDetails>
    </Accordion>


    <Accordion 
      expanded={expandedAccordion.purpose} 
      onChange={handleAccordionChange('purpose')} 
      elevation={0} 
      className={styles.accordion}
    >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}> Purpose & CTA</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>

        <div className={styles.section}>Purpose </div>
        <hr className={styles.hr} />
        <div className={styles.fullFormRow}>
            <Select
                name="purpose"
                options={[
                  {
                      label: "Education",
                      selectedLabel: "Education",
                      value: "Education",
                  },
                  {
                      label: "Promotion",
                      selectedLabel: "Promotion",
                      value: "Promotion",
                  },
                  {
                      label: "Entertainment",
                      selectedLabel: "Entertainment",
                      value: "Entertainment",
                  },
                  {
                      label: "Engagement",
                      selectedLabel: "Engagement",
                      value: "Engagement",
                  },
                  {
                      label: "Storytelling",
                      selectedLabel: "Storytelling",
                      value: "Storytelling",
                  },
                ]}
                fullWidth
                defaultOption="Purpose"
                handleChange={(name, value) => {
                  setPurpose(value);
                }}
                value={purpose}
                defaultValue={purpose}
            />
    
          </div> 

        <div className={styles.section}>Call to action</div>
        <hr className={styles.hr} />
        <div className={styles.fullFormRow}>
           <Select
                name="cta"
                options={[
                  {
                    label: "Visit our website",
                    selectedLabel: "Visit our website",
                    value: "Visit our website",
                  },
                  {
                      label: "Shop now",
                      selectedLabel: "Shop now",
                      value: "Shop now",
                  },
                  {
                      label: "Subscribe",
                      selectedLabel: "Subscribe",
                      value: "Subscribe",
                  }
                ]}
                fullWidth
                defaultOption="Call to action"
                handleChange={(name, value) => {
                  setCta(value);
                }}
                value={cta}
                defaultValue={cta}
            />    
          </div>
        </AccordionDetails>
    </Accordion>

    <Accordion 
      expanded={expandedAccordion.targetAudience} 
      onChange={handleAccordionChange('targetAudience')} 
      elevation={0} 
      className={styles.accordion}
    >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}> Target Audience</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
        
          <div className={styles.section}>Select Target Audience</div>
          <hr className={styles.hr} />
          <div className={styles.fullFormRow}>
          <Checkbox
              label="Customize own audience"
              checked={customAudience}
              onChange={(e) => setCustomAudience(e.target.checked)}
              uncheckStyleVariant="greyedOut"
            />
            <Input
              name={`isCustomAudience`}
              control={control}
              type="hidden"
              label="Visibility"
              defaultValue={'false'}
              value={'false'}
              className={styles.hidden}
            />
          </div>
          <div className={styles.fullFormRow}>
          {!customAudience && (
            <Select
                name="targetAudience"
                options={[
                  {
                      label: "General audience",
                      selectedLabel: "General audience",
                      value: "General audience",
                  },
                  {
                      label: "Eco-concious consumers",
                      selectedLabel: "Eco-concious consumers",
                      value: "Eco-concious consumers",
                  },
                  {
                      label: "Small business Owners",
                      selectedLabel: "Small business Owners",
                      value: "Small business Owners",
                  },
                  {
                      label: "Corporate professional",
                      selectedLabel: "Corporate professional",
                      value: "Corporate professional",
                  },
                  {
                      label: "Parents",
                      selectedLabel: "Parents",
                      value: "Parents",
                  },
                  {
                      label: "Students",
                      selectedLabel: "Students",
                      value: "Students",
                  },
                  {
                      label: "Retirees",
                      selectedLabel: "Retirees",
                      value: "Retirees",
                  },
                  {
                      label: "Freelancer",
                      selectedLabel: "Freelancer",
                      value: "Freelancer",
                  },
                  {
                      label: "Travel Enthusiasts",
                      selectedLabel: "Travel Enthusiasts",
                      value: "Travel Enthusiasts",
                  },
                  {
                      label: "Fashion Enthusiasts",
                      selectedLabel: "Fashion Enthusiasts",
                      value: "Fashion Enthusiasts",
                  },
                  {
                      label: "Artists and Creatives",
                      selectedLabel: "Artists and Creatives",
                      value: "Artists and Creatives",
                  },
                  {
                      label: "Foodies",
                      selectedLabel: "Foodies",
                      value: "Foodies",
                  },
                  {
                      label: "Sports Fans",
                      selectedLabel: "Sports Fans",
                      value: "Sports Fans",
                  },
                  {
                      label: "Fitness Beginner",
                      selectedLabel: "Fitness Beginner",
                      value: "Fitness Beginner",
                  },
                ]}
                fullWidth
                defaultOption="Target audience"
                handleChange={(name, value) => {
                  setTargetAudience(value);
                }}
                value={targetAudience}
                defaultValue={targetAudience}
            />
          )}  
            {customAudience && (
              <Input
                name="targetAudience"
                label="Write your Target Audience"
                defaultValue=""
              />
            )}  
          </div>  
  
        </AccordionDetails>
    </Accordion>



      <div className={styles.btnActionContainer}>
            <SixtyButton
               type="submit"
               variant="primary"
               className={styles.approveBtn}
               disabled={generateArticleLoading}
               onClick={() => {
                collapseAllAccordions();
              }}
            >
              {"Generate Short Video Script"}
            </SixtyButton>
            <SixtyButton
               variant="warning"
               className={styles.approveBtn}
               disabled={generateArticleLoading}
               onClick={() => {
                handleResetForm();
              }}
            >
              {"Reset"}
            </SixtyButton>
       </div>
      </Box>
      </Grid2>
      <Grid2 item size={11} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ paddingTop: '16px', paddingRight: '16px', flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>

 
              {generateArticleLoading && (
                  <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                      }}
                  >
                    <CircularProgress size={60} />
                      <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                        {loadingText}
                      </Typography>
                  </Box>
                )}
                <Editor
                apiKey='wsg1700p2ol4tgi8trs4h0zn4p5j8804yg2b6hk2t9ehbqn5'
                value={content}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=""
                init={{
                    height:1000,
                    width: '100%',
                    // resize: false,
                    plugins: 'lists link paste help wordcount table charmap print preview anchor advlist autolink' +
                              'searchreplace visualblocks code fullscreen' +
                              'insertdatetime media code help',
                    toolbar:  'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                    setup: (editor) => {
                                editor.on('init', () => {
                                  if (generateArticleLoading) {
                                    editor.setMode('readonly');
                                  }
                                });
                              }
                }}
                onEditorChange={(newText) => setContent(newText)}
                disabled={generateArticleLoading}
              />  
            {/* <Slate 
              editor={editor} 
              initialValue={initialValue}
              value={initialValue} 
              onChange={handleEditorChange}
            >
              <Editable className={styles.slateEditor} renderElement={renderElement} renderLeaf={renderLeaf} />
            </Slate> */}
          </Box> 

    </Grid2>
    </Grid2>
    </Box>
  );
};

const Leaf = props => {
  return (
    <span
      {...props.attributes}
      style={{ fontWeight: props.leaf.bold ? 'bold' : 'normal' }}
    >
      {props.children}
    </span>
  );
};

export default Form;
