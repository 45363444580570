import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGenerateShortVideoScript } from "queries/writer";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import DOMPurify from 'dompurify';
import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { useUploads } from "queries/media";
import styles from "./index.module.css";
import Loader from "components/Loader";
import Form from "./components/Form";
import EditorForm from "./components/EditorForm";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import BasicPageHeader from "../../Components/BasicHeader";
import { isEmpty, isArray } from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import Stepper from "components/Stepper";
import Paper from "components/SixtyPaper";

const CreateShortVideoScript = () => {

const history = useNavigate();
const params = useParams();
const contentRef = useRef(null);

const [currentProgress, setCurrentProgress] = useState(0);
const [progress, setProgress] = useState(["active", "notReached"]);
const [newBlog, setCreatedBlog] = useState();
const [dataModel, setDataModel] = useState();
const [blogCreated, setBlog] = useState(false);
const [completeLoading, setCompleteLoading] = useState(false);
const [content, setContent] = useState("");


const defaultValues = {
    company: "",
    productOrServiceName: "",
    socialMedia: "",
    goal: "",
    cta: "",
    duration: "",
    targetAudience: "",
    toneOfVoice: "",
    languageStyle: "",
    language: "",
 };


 const formMethods = useForm({
    defaultValues,
 });
  
  const { mutateAsync: createScript, 
          isLoading : generatescriptLoading 
        } = useGenerateShortVideoScript();

  const onSubmit = async (data) => {
    try {

        if(currentProgress == 0){

          if(!data.socialMedia){
            setToast("WHAT IS YOUR TARGET SOCIAL MEDIA ?", "error");
            return;
          }

          if(!data.language){
            setToast("WHAT IS YOUR TARGET LANGUAGE ?", "error");
            return;
          }

          // goToContent();
        }

        let model = {
            socialMedia: data.socialMedia,
            keyMessage: data.keyMessage,
            language: data.language,
            videoLength: data.videoLength,
            purpose: data.purpose,
            targetAudience: data.targetAudience,
            toneOfVoice: data.toneOfVoice,
            cta: data.cta,
         };

         if(currentProgress == 1 && dataModel){
            model.socialMedia = dataModel?.socialMedia;
            model.keyMessage = dataModel?.keyMessage;
            model.language = dataModel?.language;
            model.videoLength = dataModel.videoLength;
            model.purpose = dataModel.purpose;
            model.targetAudience = dataModel.targetAudience;
            model.toneOfVoice = dataModel?.toneOfVoice;
            model.cta = dataModel?.cta;
         }

         if(data.revise){
            model.remark = data.revise;
            model.script = content;
         }

         setDataModel(model);
         setCompleteLoading(true);
    
         var response = await createScript(model);

         if(response){
            if(response.includes('```html')){
              response = response.replace(/```html|```/g, '');
            }

             setCompleteLoading(false);
             setCreatedBlog(response);
             setContent(response);
             setBlog(true);
             setToast("New video script generated");
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignForm: onSubmit, error: ",
        e
      );
      setCompleteLoading(false);
      setToast("OPS, CONNECTION ERROR", "error");
    }
  };


  const onTriggerSubmit = () => {    
    formMethods.handleSubmit(onSubmit);
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    let innerText = ``;
    if (content){
        innerText = `${content}`;
    }
        
    textField.innerText = innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (innerText !== "") setToast("Script copied to clipboard");
    else setToast("No content to copy", "error");
  };

  const onGoBack = () => {
    history(-1);
  };

  function GetCurrentProgress(targetProgress){
    let totalProgress = 1;
    let result = [];

    for(let i = 0; i <= totalProgress; i++){
       if(i == targetProgress){
        result.push("active")
       }
       else if(i != targetProgress && i <= currentProgress){
        result.push("visited")
       }
       else if(i != targetProgress && i > currentProgress){
        result.push("notReached")
       }
    }

    return result;
  }

  const goToSetup = () => {
    const newProgress = 0;
    setCurrentProgress(newProgress);
  };

  const goToContent = () => {
    const newProgress = 1;
    setCurrentProgress(newProgress);
  };

  const renderForm = () => {

    return (
      <>
        {progress[0] === "active" && (
          <>
            <Form setContent={setContent} 
                  content={content} 
                  generateArticleLoading={completeLoading}
                  editorRef={contentRef}
            />   

          </>
        )}
        {progress[1] === "active" && (
          <>        
             {/* <EditorForm setContent={setContent} 
                  content={content} 
                  generateArticleLoading={completeLoading}
                  editorRef={contentRef}
                  copy={copyToClipboard}
                  back={goToSetup}
             />   */}
          </>
        )}             
      </>
    );
  };

  useEffect(() => {
    const updatedProgress = GetCurrentProgress(currentProgress);
    setProgress(updatedProgress);
  }, [currentProgress]);

  return (
    <React.Fragment>
      <div className={styles.campaignRequestFormContainer}>
      <BasicPageHeader title="Create New Short Video Script" onGoBack={onGoBack} disableBack={true} />
      <div className={styles.campaignRequestForm}>
        <div>
        <Paper className={clsx(styles.stepPaper)}>

         {/* <div className={styles.margin}></div>
         <Stepper
              status={progress}
              labels={["Setup", "Content"]}
         />
         <div className={styles.margin}></div>
         <div className={styles.margin}></div> */}

      
        <Panel className={styles.panelWrap}>
                  <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Setup your Script requirement"
                  />
                  <FormProvider {...formMethods}>
                  <form
                      onSubmit={formMethods.handleSubmit(onSubmit)}
                      className={styles.form}
                  >    
                    {renderForm()}   
                  </form>
              </FormProvider>
        </Panel>
      </Paper>
        </div>
      </div>

        <footer className={styles.footer}>
  
        </footer>
      </div>
    </React.Fragment>
  );
};

export default CreateShortVideoScript;
