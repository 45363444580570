// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Taxes_table__jQIDE {
    width: calc(95vw - 250px);
}

.Taxes_gridHeader__TEDAr {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
}

.Taxes_ButtonsContainer__\\+5TMY {
    display: flex;
    align-items: center;
}

.Taxes_ButtonsContainer__\\+5TMY > div {
    margin: 4px;
}

.Taxes_exportBtn__joDNR, .Taxes_addShippingzoneBtn__zgw65 {
    font-family: var(--medium500);
    color: var(--black50Transparent);
    font-size: 14px;
}

.Taxes_addShippingzoneBtn__zgw65 {
    color: var(--white);
}

.Taxes_headerContainer__mxKjX {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 8px;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
    margin-bottom: 16px;
}

.Taxes_modalTitleContainer__Iek3E {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}   

.Taxes_modalTitle__8lTB\\+ {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
}

.Taxes_closeIcon__Ko0OO {
    cursor: pointer;
}

.Taxes_panelWrap__UMy4e {
    min-height: unset;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Settings/Taxes/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;EACnB","sourcesContent":[".table {\n    width: calc(95vw - 250px);\n}\n\n.gridHeader {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n}\n\n.ButtonsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.ButtonsContainer > div {\n    margin: 4px;\n}\n\n.exportBtn, .addShippingzoneBtn {\n    font-family: var(--medium500);\n    color: var(--black50Transparent);\n    font-size: 14px;\n}\n\n.addShippingzoneBtn {\n    color: var(--white);\n}\n\n.headerContainer {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0px 8px;\n    justify-content: space-between;\n    align-items: center;\n    margin: 24px;\n    margin-bottom: 16px;\n}\n\n.modalTitleContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}   \n\n.modalTitle {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n}\n\n.closeIcon {\n    cursor: pointer;\n}\n\n.panelWrap {\n    min-height: unset;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Taxes_table__jQIDE`,
	"gridHeader": `Taxes_gridHeader__TEDAr`,
	"ButtonsContainer": `Taxes_ButtonsContainer__+5TMY`,
	"exportBtn": `Taxes_exportBtn__joDNR`,
	"addShippingzoneBtn": `Taxes_addShippingzoneBtn__zgw65`,
	"headerContainer": `Taxes_headerContainer__mxKjX`,
	"modalTitleContainer": `Taxes_modalTitleContainer__Iek3E`,
	"modalTitle": `Taxes_modalTitle__8lTB+`,
	"closeIcon": `Taxes_closeIcon__Ko0OO`,
	"panelWrap": `Taxes_panelWrap__UMy4e`
};
export default ___CSS_LOADER_EXPORT___;
