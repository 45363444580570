import { queryClient } from "common/query";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";
import { $CombinedState } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

export function useGetVideoStories(filter) {
    return useQuery(
      [Endpoints.Admin.GetVideoStories, filter],
      () =>
        api
          .post(Endpoints.Admin.GetVideoStories, filter)
          .then((response) => response?.data),
      {
        onError: (error) => {
          reportError("useGetVideoStories: useQuery: onError: error", error);
        },
      }
    );
  }

export function useGetVideoStoryDetail(id) {
    return useQuery(
      [Endpoints.Admin.GetVideoStoryDetail, id],
      () =>
        api
          .get(Endpoints.Admin.GetVideoStoryDetail + `/${id}`)
          .then((response) => response?.data?.result),
      {
        onError: (error) => {
          reportError("useGetVideoStoryDetail: useQuery: onError: error", error);
        },
      }
    );
  }

  export function useCreateVideoStory() {
    return useMutation(
      (data) =>
        api
          .post(Endpoints.Admin.CreateVideoStory, data)
          .then((response) => response?.data?.result),
      {
        onSuccess: () => {
          queryClient.refetchQueries(Endpoints.Admin.GetVideoStories);
        },
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useCreateVideoStory: useMutation: onError: error",
            error
          );
        }
      }
    );
  }

  export function useUpdateVideoStory() {
    return useMutation(
      (data) =>
        api
          .put(Endpoints.Admin.UpdateVideoStory, data)
          .then((response) => response?.data?.result),
      {
        onSuccess: () => {
          queryClient.refetchQueries(Endpoints.Admin.GetVideoStories);
        },
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError(
            "useUpdateVideoStory: useMutation: onError: error",
            error
          );
        }
      }
    );
  }