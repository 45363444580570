import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import api from "../common/axios";
import { Endpoints, QueryKeys, unknown_Error } from "../common/constants";
import { queryClient } from "../common/query";
import { reportError } from "../crashlytics";
import { clearStorage, writeToStorage } from "../utils/storage";
import { setTokens } from "utils/auth";
import { setToast } from 'components/Toast';
import { replace } from "lodash";

export function useRegisterUserEmail() {
  return useMutation(
    (email) =>
      api
        .post(Endpoints.Account.RegisterUserEmail, {
          email: email,
          userType: "Supplier",
        })
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(QueryKeys.user, data?.result);
        writeToStorage(QueryKeys.user, data?.result);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useRegisterUserEmail: useMutation: onError: error", error);
      },
    }
  );
}

export function useResendEmailVerificationCode() {
  const user = queryClient.getQueryData(QueryKeys.user);
  return useMutation(() =>
    api
      .post(Endpoints.Account.ResendEmailVerificationCode, {
        guid: user?.guid,
      })
      .then((response) => response?.data),
      {
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError("useResendEmailVerificationCode: useMutation: onError: error", error);
        },
      }
  );
}

export function useEmailOtpVerification() {
  const user = queryClient.getQueryData(QueryKeys.user);
  return useMutation(
    (otp) =>
      api
        .put(Endpoints.Account.EmailOtpVerification, {
          guid: user?.guid,
          otp: otp,
        })
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(QueryKeys.tempTokens, data?.result);
        writeToStorage(QueryKeys.tempTokens, data?.result);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useRegisterUserEmail: useMutation: onError: error", error);
      },
    }
  );
}

export function useLogin() {
  return useMutation(
    ({ usernameOrEmail, password }) =>
      api
        .post(Endpoints.Account.Login, {
          usernameOrEmail: usernameOrEmail,
          password: password,
        })
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(QueryKeys.tokens, data?.result);
        writeToStorage(QueryKeys.tokens, data?.result);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useLogin: useMutation: onError: error", error);
      },
    }
  );
}

export function useSetupUserAuthentication() {
  return useMutation(
    ({ username, password }) =>
      api
        .post(Endpoints.Account.SetupUserAuthentication, {
          username: username,
          password: password,
        })
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        const tempTokens = queryClient.getQueryData(QueryKeys.tempTokens);
        queryClient.setQueryData(QueryKeys.tokens, tempTokens);
        writeToStorage(QueryKeys.tokens, tempTokens);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useSetupUserAuthentication: useMutation: onError: error", error);
      },
    }
  );
}

export function useLogout() {
  const history = useNavigate();
  const refreshToken = localStorage.getItem("refreshToken");
  return useMutation(
    () =>
      api
        .put(Endpoints.Account.Logout, {
          refreshToken,
        })
        .then((response) => response?.data),
    {
      onSettled: async () => {
        await clearStorage();
        history("/", {replace: true});
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useLogout: useMutation: onError: error", error);
      },
    }
  );
}

export function useForgotPassword() {
  return useMutation((email) =>
    api
      .post(Endpoints.Account.ForgotPassword, {
        email: email,
      })
      .then((response) => response.data),
      {
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError("useForgotPassword: useMutation: onError: error", error);
        }
      }
  );
}

export function useResetPassword() {
  return useMutation((password) =>
    api
      .post(Endpoints.Account.ResetPassword, {
        password,
      })
      .then((response) => response.data),
      {
        onError: (error) => {
          setToast(error.response?.data?.message || unknown_Error, "error");
          reportError("useResetPassword: useMutation: onError: error", error);
        }
      }
  );
}

export function useRefreshToken() {
  return useMutation(
    ({token, refreshToken}) =>
      api
        .post(Endpoints.Account.RefreshToken, {
          token: token,
          refreshToken: refreshToken,
        })
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(QueryKeys.tokens, data?.result);
        writeToStorage(QueryKeys.tokens, data?.result);
        setTokens(data?.result);
      },

      onError: (error) => {
        clearStorage();
        window.location.href = '/';
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useRefreshToken: useMutation: onError: error", error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(null, {
          refetchInactive: true,
        });
      },
    }
  );
}
