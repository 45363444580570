import React, { useState } from "react";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import GeneralReport from "./components/GeneralReport";
import TotalSalesReport from "./components/TotalSalesReport";
import SellersReport from "./components/SellersReport";
import clsx from "clsx";
import {
  useGetTotalSalesSummary,
  useGetAnalyticSummary,
  useGetSellerAnalyticSummary,
} from "queries/report";
import format from "date-fns/format";
import { useNavigate } from "react-router";
import { ButtonDatePicker } from "components/SixtyDatePicker";
import Loader from "components/Loader";

const Reports = (props) => {
  const history = useNavigate();
  const [month, setMonth] = useState(new Date());

  const {
    data: totalSalesSummary,
    isLoading: isLoadingSales,
    isSuccess: totalSalesSummarySuccess,
  } = useGetTotalSalesSummary(format(month, "MM/yyyy"));

  const {
    data: analyticsSummary,
    isLoading: isLoadingAnalytics,
    isSuccess: analyticsSummarySuccess,
  } = useGetAnalyticSummary(format(month, "MM/yyyy"));

  const {
    data: sellerAnalyticsSummary,
    isLoading: isLoadingSellerAnalytics,
    isSuccess: sellerAnalyticsSummarySuccess,
  } = useGetSellerAnalyticSummary(format(month, "MM/yyyy"));
  return (
    <div>
      <div className={styles.walletPageHeaderContainer}>
        <div>
          <span className={styles.gridHeader}>Reports</span>
        </div>
        <div>
          <ButtonDatePicker
            value={month}
            maxDate={new Date()}
            handleChange={(date) => setMonth(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        </div>
      </div>
      <div className={styles.walletBalancesPanel}>
        <div className={clsx(styles.row, styles.firstRow)}>
          {analyticsSummarySuccess &&
            analyticsSummary?.map((analytic) => (
              <Panel className={styles.panelWrap}>
                <GeneralReport
                  key={analytic.position}
                  title={analytic.title}
                  currentMonthCount={analytic.total}
                  previousMonthCount={analytic.previousMonthTotal}
                  currentMonthNewRecord={analytic.currentMonthNewRecord}
                />
              </Panel>
            ))}
        </div>
        <div className={clsx(styles.row, styles.secondRow)}>
          {totalSalesSummarySuccess && (
            <Panel className={clsx(styles.panelWrap, styles.totalSalesReport)}>
              <TotalSalesReport data={totalSalesSummary} />
              <div
                className={styles.fullReportBtn}
                onClick={() =>
                  history("/dashboard/reports/totalSalesReport")
                }
              >
                View full report
              </div>
            </Panel>
          )}
          {sellerAnalyticsSummarySuccess && (
            <Panel className={styles.panelWrap}>
              <SellersReport data={sellerAnalyticsSummary} />
            </Panel>
          )}
        </div>
        {/* <div className={clsx(styles.row, styles.thirdRow)}>
          <Panel className={clsx(styles.panelWrap, styles.totalSalesReport)}>
            <TotalOrdersReport />
          </Panel>
          <Panel className={styles.panelWrap}>
            <PanelHeader title="Products" className={styles.productPanelHeader} />
            <TopProductSales />
          </Panel>
          <Panel className={styles.panelWrap}>
            <GeneralReport
              title="Average sales per seller"
              currentMonthCount="RM250,000"
              previousMonthCount="RM210,000"
              profit="6.50%"
            />
            <PanelHeader title="Top sellers" className={styles.sellerPanelHeader} />
            <AverageSalesPerSeller />
          </Panel>
        </div> */}
      </div>
      {(isLoadingSales || isLoadingAnalytics || isLoadingSellerAnalytics) && (
        <Panel className={styles.panelWrap}>
          <Loader />
        </Panel>
      )}
    </div>
  );
};

export default Reports;
