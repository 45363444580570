// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .MultiValueInput_container__GX0Qc {
    width: 100%;
  }
  
  .MultiValueInput_chipContainer__in\\+P1 {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
  }

  .MuiTooltip-tooltip {
    background-color: rgba(97, 97, 97, 0.9) !important;
    font-size: 0.75rem !important;
  }

  .MultiValueInput_addButton__O77Yj {
    color:white !important;
    background-color: #FF007FFF !important;
    opacity: 0.7 !important;
    width: 15px !important;
    height: 15px !important;
    padding: 0 !important;
  }

  .MultiValueInput_addButton__O77Yj:hover {
    color:white !important;
    background-color: #FF007FFF !important;
    opacity: 1 !important;
  }
  
  .MultiValueInput_addButton__O77Yj:disabled {
    color:white !important;
    background-color: rgba(128, 128, 128, 0.467) !important;
    opacity: 0.5 !important;
  }

  .MultiValueInput_addButton__O77Yj svg {
    font-size: 14px !important;
  }

  .MultiValueInput_customTextField__-Qjqd input {
    font-size: 14px;
  }
  
  .MultiValueInput_customTextField__-Qjqd label {
    font-size: 14px;
  }`, "",{"version":3,"sources":["webpack://./src/components/MultiValueInput/index.module.css"],"names":[],"mappings":"EAAE;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,eAAe;IACf,QAAQ;IACR,gBAAgB;EAClB;;EAEA;IACE,kDAAkD;IAClD,6BAA6B;EAC/B;;EAEA;IACE,sBAAsB;IACtB,sCAAsC;IACtC,uBAAuB;IACvB,sBAAsB;IACtB,uBAAuB;IACvB,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;IACtB,sCAAsC;IACtC,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;IACtB,uDAAuD;IACvD,uBAAuB;EACzB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB","sourcesContent":["  .container {\n    width: 100%;\n  }\n  \n  .chipContainer {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 8px;\n    margin-top: 16px;\n  }\n\n  :global(.MuiTooltip-tooltip) {\n    background-color: rgba(97, 97, 97, 0.9) !important;\n    font-size: 0.75rem !important;\n  }\n\n  .addButton {\n    color:white !important;\n    background-color: #FF007FFF !important;\n    opacity: 0.7 !important;\n    width: 15px !important;\n    height: 15px !important;\n    padding: 0 !important;\n  }\n\n  .addButton:hover {\n    color:white !important;\n    background-color: #FF007FFF !important;\n    opacity: 1 !important;\n  }\n  \n  .addButton:disabled {\n    color:white !important;\n    background-color: rgba(128, 128, 128, 0.467) !important;\n    opacity: 0.5 !important;\n  }\n\n  .addButton svg {\n    font-size: 14px !important;\n  }\n\n  .customTextField input {\n    font-size: 14px;\n  }\n  \n  .customTextField label {\n    font-size: 14px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MultiValueInput_container__GX0Qc`,
	"chipContainer": `MultiValueInput_chipContainer__in+P1`,
	"addButton": `MultiValueInput_addButton__O77Yj`,
	"customTextField": `MultiValueInput_customTextField__-Qjqd`
};
export default ___CSS_LOADER_EXPORT___;
