import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import VendorsTable from "../components/VendorSelectionTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useNavigate } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import { setToast } from "components/Toast";
import BasicPageHeader from "../../Components/BasicHeader";
import { Editor } from '@tinymce/tinymce-react';

const VendorSelection = () => {
  const [isVendorDetailOpen, setVendorDetailOpen] = useState(false);
  const history = useNavigate();
  const vendorDetailRef = useRef();
  const [selectedVendor, setSelectedVendor] = useState({});

  const VendorDetailTitle = () => {
    return (
      <div className={styles.vendorDetailModalTitleBar}>
        <div className={styles.vendorNameContainer}>
          <div>
            <Avatar name={selectedVendor.vendorName} />
          </div>
          <div className={styles.modalTitle}>{selectedVendor.vendorName}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setVendorDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onVendorClick = (value) => {
    // setSelectedVendor(value);
    // setVendorDetailOpen(true);
    history("/admin/blogs/create-blog/" + value.id)
  };

  const onGoBack = () => {
    history(-1);
  };

  return (
    <div>
      <BasicPageHeader title="Vendor Selection" onGoBack={onGoBack} />
      <Panel className={styles.panelWrap}>
        <div className={styles.vendorTable}>
          <VendorsTable onVendorClick={onVendorClick} />
        </div>
      </Panel>
    </div>
  );
};

export default VendorSelection;
