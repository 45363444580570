// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Components_container__ET4Bj {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 900px;
    padding: 30px;
}
.Components_buttons__5eB5q {
    display: flex;
    flex-direction: column;
}

.Components_buttonContainer__brxfF {
    display: flex;
    padding: 10px 0;
    /* flex-direction: column; */
}

.Components_inputs__rIXQO, .Components_radioBtn__sWRqX, .Components_checkbox__exiTk {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 40px;
}

.Components_summary__6TLI9 {
    margin: 10px 0;
}

.Components_summary__6TLI9:focus{
    outline: none;
}

.Components_pre__BIRdT {
    padding: 20px;
    background: var(--black5);
}

.Components_stepupContainer__cU0t6 {
    display: flex;
    align-items: flex-start;
    padding: 12px 24px;
    background: var(--white);;
    border: 1px solid var(--black5Transparent);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px var(--black5Transparent);
    border-radius: 100px;
    width: fit-content;
    margin: 0 auto;
}

.Components_stepPage__BI7Yj{
    text-align: center;
    padding: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Components/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,wBAAwB;IACxB,0CAA0C;IAC1C,sBAAsB;IACtB,gDAAgD;IAChD,oBAAoB;IAEpB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 900px;\n    padding: 30px;\n}\n.buttons {\n    display: flex;\n    flex-direction: column;\n}\n\n.buttonContainer {\n    display: flex;\n    padding: 10px 0;\n    /* flex-direction: column; */\n}\n\n.inputs, .radioBtn, .checkbox {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 40px;\n    margin-top: 40px;\n}\n\n.summary {\n    margin: 10px 0;\n}\n\n.summary:focus{\n    outline: none;\n}\n\n.pre {\n    padding: 20px;\n    background: var(--black5);\n}\n\n.stepupContainer {\n    display: flex;\n    align-items: flex-start;\n    padding: 12px 24px;\n    background: var(--white);;\n    border: 1px solid var(--black5Transparent);\n    box-sizing: border-box;\n    box-shadow: 0px 2px 5px var(--black5Transparent);\n    border-radius: 100px;\n    width: -moz-fit-content;\n    width: fit-content;\n    margin: 0 auto;\n}\n\n.stepPage{\n    text-align: center;\n    padding: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Components_container__ET4Bj`,
	"buttons": `Components_buttons__5eB5q`,
	"buttonContainer": `Components_buttonContainer__brxfF`,
	"inputs": `Components_inputs__rIXQO`,
	"radioBtn": `Components_radioBtn__sWRqX`,
	"checkbox": `Components_checkbox__exiTk`,
	"summary": `Components_summary__6TLI9`,
	"pre": `Components_pre__BIRdT`,
	"stepupContainer": `Components_stepupContainer__cU0t6`,
	"stepPage": `Components_stepPage__BI7Yj`
};
export default ___CSS_LOADER_EXPORT___;
