import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import UgcOrderTable from "./components/UgcOrderTable";
import EditOrder from "./components/EditOrder";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useNavigate } from "react-router-dom";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import GridHeader from "components/GridHeader";
import Tabs from "components/Tabs";
import { useGetUgcOrderStatusCount } from "queries/admin";

const UgcOrders = () => {

  const [isCampaignDetailOpen, setCampaignDetailOpen] = useState(false);
  const history = useNavigate();
  const campaignDetailRef = useRef();
  const [selectedCampaign, setSelectedCampagin] = useState({});

  const {
    data: campaignData,
  } = useGetUgcOrderStatusCount();

  const InfluencerDetailTitle = () => {
    return (
      <div className={styles.campaignDetailModalTitleBar}>
        <div className={styles.campaignNameContainer}>
          <div>
            <Avatar name={selectedCampaign.name} />
          </div>
          <div className={styles.modalTitle}>{selectedCampaign.name}</div>
        </div>
        <div>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setCampaignDetailOpen(false)}
          />
        </div>
      </div>
    );
  };

  const onOpenOrder = (value) => {
    try {
   
      window.open(`/admin/ugcOrder/${value?.id}`, "_blank")
        
    } catch (e) {
      reportError(
        "error in : UgcOders: onOpenOrder, error: ",
        e
      );
    }
  };

  const onCampaignClick = (value) => {
    setSelectedCampagin(value);
    setCampaignDetailOpen(true);
  };

  return (
    <div>
      <GridHeader header="UGC Orders" 
         renderButtons={() => (
          <>
             
          </>
         )}
      />
      <Panel className="tabs">
        <Tabs
          activeTab={"1"}
          tabs={[
            {
              tabId: "1",
              label: "Awaiting processing",
              recordsCount: campaignData?.pendingCount,
              TabComponent: UgcOrderTable,
              props: {
                status: "Pending",
                onOrderClick: onCampaignClick
              },
            },
            {
              tabId: "2",
              label: "Awaiting Top-up",
              recordsCount: campaignData?.awaitingTopupCount,
              TabComponent: UgcOrderTable,
              props: {
                status: "AwaitingTopup",
                onOrderClick: onCampaignClick
              },
            },
            {
              tabId: "3",
              label: "Ongoing",
              recordsCount: campaignData?.onGoingCount,
              TabComponent: UgcOrderTable,
              props: {
                status: "Ongoing",
                onOrderClick: onOpenOrder
              },
            },
            {
              tabId: "4",
              label: "Close",
              recordsCount: campaignData?.closeCount,
              TabComponent: UgcOrderTable,
              props: {
                status: "Close",
                onOrderClick: onOpenOrder
              },              
            },
          ]}
        ></Tabs>
      </Panel>
      {isCampaignDetailOpen && (
        <Modal
          isOpen={isCampaignDetailOpen}
          onAttemptClose={() => {
            history("/admin/campaigns");
            setCampaignDetailOpen(false);
          }}
          modalRef={campaignDetailRef}
          Title={InfluencerDetailTitle}
        >
          <EditOrder
            selectedOrder={selectedCampaign}
            handleClose={() => setCampaignDetailOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default UgcOrders;
