import React, { useState } from "react";
import Tab from "./components/Tab";
import styles from "./index.module.css";

const Tabs = ({ activeTab, tabs, onChange }) => {
  const [selectedTab, setSelectedTab] = useState(activeTab);

  const onClickTabItem = (tabId) => {
    setSelectedTab(tabId);
    if (onChange) {
      onChange(tabId);
    }
  };

  return (
    <div className={styles.tabs}>
      <div className={styles.tabList}>
        {tabs.map((tab, i) => {
          const { tabId, label, recordsCount, alert } = tab;
          console.log(alert);
          return (
            <Tab
              key={i}
              tabId={tabId}
              activeTab={selectedTab}
              label={label}
              onClick={onClickTabItem}
              recordsCount={recordsCount}
              alert={alert}
            />
          );
        })}
      </div>
      <div className={styles.tabContainer}>
        {tabs.map((tab, i) => {
          const { tabId, TabComponent, props } = tab;
          if (tabId !== selectedTab) return undefined;
          return <TabComponent key={i} {...(props ? props : {})} />;
        })}
      </div>
    </div>
  );
};

export default Tabs;
