import { queryClient } from "common/query";
import { QueryClientProvider } from "react-query";
import StartupHelper from "helpers/StartupHelper";
import Router from "./routes";
import CustomToastContainer from 'components/Toast';
import TagManager from 'react-gtm-module'
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'transparent',
          borderRadius: '10px',
          '&:first-of-type': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          },
          '&:last-of-type': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: 'transparent',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          '&.Mui-expanded': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          background: 'transparent',
          borderBottomLeftRadius: '10x',
          borderBottomRightRadius: '10px',
          padding: '16px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            fontSize: '12px',
          },
          '& .MuiInputLabel-root': {
            fontSize: '12px',
          },
        },
      },
    },
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-TD5GLPKJ'
}

// TagManager.initialize(tagManagerArgs)

function App() {
  // window.dataLayer.push({
  //   event: 'pageview'
  // });
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <StartupHelper />
        <Router />
        <div>
            <CustomToastContainer
              position="bottom-left"
            />
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
