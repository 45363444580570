import { Controller } from "react-hook-form";
import SixtyDatePicker from "components/SixtyDatePicker";

export default function ControlledDatepicker({
  control,
  name,
  handleChange,
  label,
  error,
  defaultValue,
  dateformat,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, ...rest }) => (
        <>
          <SixtyDatePicker
            onChange={(date) => {
              onChange(date);
              handleChange && handleChange(name, date);
            }}
            label={label}
            error={error}
            dateformat={dateformat}
            {...props}
            {...rest}
          />
        </>
      )}
    ></Controller>
  );
}
