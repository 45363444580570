import LoginContainer from "./components/LoginContainer";
import Link from "components/SixtyLink";
import Button from "components/SixtyButton";
import Input from "components/SixtyInput";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useForgotPassword } from "queries/account";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";

export default function ForgotPassword() {
  const { control, handleSubmit, errors } = useForm();
  const history = useNavigate();
  const { mutateAsync: forgotPassword, isLoading } = useForgotPassword();
  const onSubmit = async (data) => {
    try {
      await forgotPassword(data.email);
      setToast("new password sent to email");
      setTimeout(() => {
        history("/auth/login");
      }, 2000);
    } catch (error) {
      reportError("ForgotPassword: onSubmit: error: ", error);
    }
  };
  return (
    <LoginContainer
      header="Reset your password"
      subHeader="Enter your email and we will send you instructions for resetting your password."
      form={
        <Controller
          as={Input}
          label="Email address"
          name="email"
          control={control}
          rules={{ required: "email address required" }}
          error={errors.email}
          defaultValue=""
        />
      }
      onFormSubmit={handleSubmit(onSubmit)}
      footer={
        <Button type="submit" size="large" fullWidth disabled={isLoading}>
          Send instruction
        </Button>
      }
      footerLink={
        <Link to="/auth/login" type="p1" color="orange">
          Return to Login
        </Link>
      }
      guidelinesMessage ={"** You may check the email on spam folder if not found on inbox"}
      guidelinesLink={
        <Link external={true} to="https://60sec.asia/email-troubleshoot/" type="p1" color="orange">
          Not received email?
        </Link>
      }
    />
  );
}
