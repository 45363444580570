import styles from "./index.module.css";
import clsx from "clsx";
import SixtyButton from "components/SixtyButton";

const ActionBox = ({
  title,
  desc,
  onTriggered,
  className,
  actionTitle = null,
  triggredLoading = false,
  isAllowed = false,
  isCompleted = false,
  ...props
}) => {
  {/* clsx(styles.GeneralReport, styles.totalApplied */}
  return (
    <div className={clsx(styles.ActionBoxContainer)}>
      <div className={styles.title}>{title}</div>
      {desc && (
        <div className={styles.descText}>
           {desc}
        </div>
      )}
      {actionTitle && (
        <>
           <SixtyButton
            variant="tertiary"
            disabled={triggredLoading || !isAllowed || isCompleted}
            onClick={onTriggered}
           >
             {actionTitle}
           </SixtyButton>
        </>
      )}
      {props.children && <div>{props.children}</div>}
    </div>
  );
};

export default ActionBox;
