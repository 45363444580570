// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SixtyText_h1__OoKGV {
    font-size: 20px;
    line-height: 32px;
}

.SixtyText_h2__FJNH8 {
    font-size: 16px;
    line-height: 24px;
}

.SixtyText_h3__fQ6fF {
    font-size: 14px;
    line-height: 20px;
}

.SixtyText_h4__Z-vJd {
    font-size: 12px;
    line-height: 14px;
}

.SixtyText_h5__5dXLi {
    font-size: 10px;
    line-height: 11px;
}

.SixtyText_p1__XjILP {
    font-size: 14px;
    line-height: 20px;
}

.SixtyText_p2__k9jpz {
    font-size: 12px;
    line-height: 14px;
}

.SixtyText_xl__c0XAb {
    font-size: 56px;
    line-height: 66px;
}

.SixtyText_l__2A6hd {
    font-size: 32px;
    line-height: 40px;
}

.SixtyText_bold__zN1gb {
    font-family: var(--bold700);
    font-weight: 700;
}

.SixtyText_medium__qvtHJ {
    font-family: var(--medium500);
    font-weight: 500;
}

.SixtyText_regular__KbAcP {
    font-family: var(--regular400);
    font-weight: 400;
}

.SixtyText_primary__ciXAA {
    color: #000;
}

.SixtyText_secondary__YxLe6 {
    color: #808080;
}

.SixtyText_orange__XbH\\+E {
    color: #EF8777;
}

.SixtyText_green__Rp4ev{
    color: #4CAF50;
}

.SixtyText_purple__eVcbx {
    color: #2a234e;
}

.SixtyText_inline__VTRcf {
    display: inline;
}`, "",{"version":3,"sources":["webpack://./src/components/SixtyText/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".h1 {\n    font-size: 20px;\n    line-height: 32px;\n}\n\n.h2 {\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.h3 {\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.h4 {\n    font-size: 12px;\n    line-height: 14px;\n}\n\n.h5 {\n    font-size: 10px;\n    line-height: 11px;\n}\n\n.p1 {\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.p2 {\n    font-size: 12px;\n    line-height: 14px;\n}\n\n.xl {\n    font-size: 56px;\n    line-height: 66px;\n}\n\n.l {\n    font-size: 32px;\n    line-height: 40px;\n}\n\n.bold {\n    font-family: var(--bold700);\n    font-weight: 700;\n}\n\n.medium {\n    font-family: var(--medium500);\n    font-weight: 500;\n}\n\n.regular {\n    font-family: var(--regular400);\n    font-weight: 400;\n}\n\n.primary {\n    color: #000;\n}\n\n.secondary {\n    color: #808080;\n}\n\n.orange {\n    color: #EF8777;\n}\n\n.green{\n    color: #4CAF50;\n}\n\n.purple {\n    color: #2a234e;\n}\n\n.inline {\n    display: inline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h1": `SixtyText_h1__OoKGV`,
	"h2": `SixtyText_h2__FJNH8`,
	"h3": `SixtyText_h3__fQ6fF`,
	"h4": `SixtyText_h4__Z-vJd`,
	"h5": `SixtyText_h5__5dXLi`,
	"p1": `SixtyText_p1__XjILP`,
	"p2": `SixtyText_p2__k9jpz`,
	"xl": `SixtyText_xl__c0XAb`,
	"l": `SixtyText_l__2A6hd`,
	"bold": `SixtyText_bold__zN1gb`,
	"medium": `SixtyText_medium__qvtHJ`,
	"regular": `SixtyText_regular__KbAcP`,
	"primary": `SixtyText_primary__ciXAA`,
	"secondary": `SixtyText_secondary__YxLe6`,
	"orange": `SixtyText_orange__XbH+E`,
	"green": `SixtyText_green__Rp4ev`,
	"purple": `SixtyText_purple__eVcbx`,
	"inline": `SixtyText_inline__VTRcf`
};
export default ___CSS_LOADER_EXPORT___;
