// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionBox_ActionBoxContainer__BSxal {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.ActionBox_title__sooLj, .ActionBox_descText__RFs28 {
    color: var(--black50Transparent);
    font-size: 14px;
    font-family: var(--regular400);
}

.ActionBox_counts__INBJW {
    padding: 4px 0px;
}


.ActionBox_descText__RFs28 {
    font-size: 12px;
    margin-bottom: 10px;
    min-height: 30px;
    margin-top: 10px;
}

.ActionBox_profitInNewLine__jrRIQ {
    display: block;
}

.ActionBox_GeneralReport__leQNW {
    width: 50%;
}
  
.ActionBox_totalApplied__bx\\+kV,
.ActionBox_totalConversionRate__nO0F7 {
    border-right: 1px solid var(--black10Transparent);
    border-bottom: 1px solid var(--black10Transparent);
 }
`, "",{"version":3,"sources":["webpack://./src/screens/Dashboard/components/ActionBox/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;;IAEI,iDAAiD;IACjD,kDAAkD;CACrD","sourcesContent":[".ActionBoxContainer {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n}\n\n.title, .descText {\n    color: var(--black50Transparent);\n    font-size: 14px;\n    font-family: var(--regular400);\n}\n\n.counts {\n    padding: 4px 0px;\n}\n\n\n.descText {\n    font-size: 12px;\n    margin-bottom: 10px;\n    min-height: 30px;\n    margin-top: 10px;\n}\n\n.profitInNewLine {\n    display: block;\n}\n\n.GeneralReport {\n    width: 50%;\n}\n  \n.totalApplied,\n.totalConversionRate {\n    border-right: 1px solid var(--black10Transparent);\n    border-bottom: 1px solid var(--black10Transparent);\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActionBoxContainer": `ActionBox_ActionBoxContainer__BSxal`,
	"title": `ActionBox_title__sooLj`,
	"descText": `ActionBox_descText__RFs28`,
	"counts": `ActionBox_counts__INBJW`,
	"profitInNewLine": `ActionBox_profitInNewLine__jrRIQ`,
	"GeneralReport": `ActionBox_GeneralReport__leQNW`,
	"totalApplied": `ActionBox_totalApplied__bx+kV`,
	"totalConversionRate": `ActionBox_totalConversionRate__nO0F7`
};
export default ___CSS_LOADER_EXPORT___;
