// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .VendorSelectionTable_vendorNameCell__4yBMS {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .VendorSelectionTable_vendorNameValue__bgSgm {
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  s
  .VendorSelectionTable_loaderContainer__PK0eK {
    min-height: 180px;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Blogs/components/VendorSelectionTable/index.module.css"],"names":[],"mappings":"EAAE;IACE,eAAe;IACf,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;;IAEE,iBAAiB;EACnB","sourcesContent":["  .vendorNameCell {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n  }\n  \n  .vendorNameValue {\n    padding-left: 8px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n  s\n  .loaderContainer {\n    min-height: 180px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vendorNameCell": `VendorSelectionTable_vendorNameCell__4yBMS`,
	"vendorNameValue": `VendorSelectionTable_vendorNameValue__bgSgm`,
	"loaderContainer": `VendorSelectionTable_loaderContainer__PK0eK`
};
export default ___CSS_LOADER_EXPORT___;
