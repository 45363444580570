// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.AdminDropdown_profileFlyout__gts1t {
    position: absolute;
}

.AdminDropdown_profileFlyout__gts1t {
    width: 143px;
}

.AdminDropdown_profileDropdown__K06Jn {
    padding: 8px;
}

.AdminDropdown_row__LQR5Y {
    display: flex;
    flex-direction: column;
    padding: 8px;
    font-family: var(--medium500);
    font-size: 14px;
    color: var(--black50Transparent);
}

.AdminDropdown_row__LQR5Y:hover {
    background-color: #F9F8FA;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminDropdown/index.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":["\n.profileFlyout {\n    position: absolute;\n}\n\n.profileFlyout {\n    width: 143px;\n}\n\n.profileDropdown {\n    padding: 8px;\n}\n\n.row {\n    display: flex;\n    flex-direction: column;\n    padding: 8px;\n    font-family: var(--medium500);\n    font-size: 14px;\n    color: var(--black50Transparent);\n}\n\n.row:hover {\n    background-color: #F9F8FA;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileFlyout": `AdminDropdown_profileFlyout__gts1t`,
	"profileDropdown": `AdminDropdown_profileDropdown__K06Jn`,
	"row": `AdminDropdown_row__LQR5Y`
};
export default ___CSS_LOADER_EXPORT___;
