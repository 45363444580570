// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StripeCreditCard_paymentForm__Kd3Sh {
    width: 100%;
    padding: 15px;
    margin-bottom: 30px;
}

.StripeCreditCard_cardElement__es1wE{
  margin-bottom: 50px;
  min-height: 40px;
  border-style: none;
  will-change: opacity, transform;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 15px;
}

.StripeCreditCard_form__6\\+FDn {
  padding: 16px;
}

.StripeCreditCard_label__tQ8Xd{
  width: 100%;
  text-align: center;
}

.StripeCreditCard_icon__qWTSs {
  height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.StripeCreditCard_iconV2__TRncc {
  height: 40px;
}

.StripeCreditCard_iconV3__nhnyj {
  margin-left: 10px;
  margin-top: 8px;
  height: 15px;
}

.StripeCreditCard_formRowAlign__JbYLr {
  display: flex;
  align-items: stretch;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.StripeCreditCard_formRowHeader__MO09O {
  display: flex;
  align-items: stretch;
  height: 50px;
}

.StripeCreditCard_formRowFooter__VWKW1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.StripeCreditCard_formRow__cHgfn {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
}

.StripeCreditCard_text__i6n\\+C{
    text-align: center;
}

@media only screen and (max-width: 600px){
  .StripeCreditCard_paymentForm__Kd3Sh {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/Settings/Billing/components/AddEditCardDetailsForm/components/StripeCreditCard/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,+BAA+B;EAC/B,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".paymentForm {\n    width: 100%;\n    padding: 15px;\n    margin-bottom: 30px;\n}\n\n.cardElement{\n  margin-bottom: 50px;\n  min-height: 40px;\n  border-style: none;\n  will-change: opacity, transform;\n  border: 1px solid #E6E6E6;\n  border-radius: 4px;\n  padding: 15px;\n}\n\n.form {\n  padding: 16px;\n}\n\n.label{\n  width: 100%;\n  text-align: center;\n}\n\n.icon {\n  height: 30px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.iconV2 {\n  height: 40px;\n}\n\n.iconV3 {\n  margin-left: 10px;\n  margin-top: 8px;\n  height: 15px;\n}\n\n.formRowAlign {\n  display: flex;\n  align-items: stretch;\n  height: 50px;\n  margin-top: 30px;\n  margin-bottom: 20px;\n}\n\n.formRowHeader {\n  display: flex;\n  align-items: stretch;\n  height: 50px;\n}\n\n.formRowFooter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n.formRow {\n  display: flex;\n  margin-bottom: 16px;\n  justify-content: space-between;\n}\n\n.text{\n    text-align: center;\n}\n\n@media only screen and (max-width: 600px){\n  .paymentForm {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentForm": `StripeCreditCard_paymentForm__Kd3Sh`,
	"cardElement": `StripeCreditCard_cardElement__es1wE`,
	"form": `StripeCreditCard_form__6+FDn`,
	"label": `StripeCreditCard_label__tQ8Xd`,
	"icon": `StripeCreditCard_icon__qWTSs`,
	"iconV2": `StripeCreditCard_iconV2__TRncc`,
	"iconV3": `StripeCreditCard_iconV3__nhnyj`,
	"formRowAlign": `StripeCreditCard_formRowAlign__JbYLr`,
	"formRowHeader": `StripeCreditCard_formRowHeader__MO09O`,
	"formRowFooter": `StripeCreditCard_formRowFooter__VWKW1`,
	"formRow": `StripeCreditCard_formRow__cHgfn`,
	"text": `StripeCreditCard_text__i6n+C`
};
export default ___CSS_LOADER_EXPORT___;
