import styles from "./index.module.css";
import clsx from 'clsx';

export default function GridHeader({ header, renderButtons, className, PreHeaderBtn }) {
  return (
    <div className={clsx(styles.pageHeaderContainer, className)}>
      <div>
        {PreHeaderBtn && PreHeaderBtn()}
        <span className={styles.gridHeader}>{header}</span>
      </div>

      <div className={styles.ButtonsContainer}>
        {renderButtons && renderButtons()}
      </div>
    </div>
  );
}
