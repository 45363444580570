// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RedirectUrlForm_form__vCuy4 {
    padding: 16px;
  }
  
  .RedirectUrlForm_formRow__26Dk\\+ {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
  }
  
  .RedirectUrlForm_formRow__26Dk\\+ > * {
    width: calc(50% - 8px);
  }

  .RedirectUrlForm_fullFormRow__6MXL4 {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
  }

  .RedirectUrlForm_fullFormRow__6MXL4 > * {
    width: calc(100% - 8px);
  }
  
  .RedirectUrlForm_section__0yb6G {
    margin-bottom: 12px;
    color: var(--black50);
    font-size: 14px;
    line-height: 16px;
  }

 
 .RedirectUrlForm_input__zZr91 {
    width: 100%;
    padding: 8px;
  }

  .RedirectUrlForm_halfinput__abx\\+W {
    width: 50%;
    padding: 8px;
  }

  .RedirectUrlForm_hr__5akyO {
    margin-bottom: 16px;
    border: none;
    border-bottom: 1px solid var(--black10);
  }

  .RedirectUrlForm_hidden__7zGrw{
    display: none;;
  }`, "",{"version":3,"sources":["webpack://./src/screens/Blogs/components/BlogProductDetails/components/RedirectUrlForm/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,iBAAiB;EACnB;;;CAGD;IACG,WAAW;IACX,YAAY;EACd;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,uCAAuC;EACzC;;EAEA;IACE,aAAa;EACf","sourcesContent":[".form {\n    padding: 16px;\n  }\n  \n  .formRow {\n    display: flex;\n    margin-bottom: 16px;\n    justify-content: space-between;\n  }\n  \n  .formRow > * {\n    width: calc(50% - 8px);\n  }\n\n  .fullFormRow {\n    display: flex;\n    margin-bottom: 16px;\n    justify-content: space-between;\n  }\n\n  .fullFormRow > * {\n    width: calc(100% - 8px);\n  }\n  \n  .section {\n    margin-bottom: 12px;\n    color: var(--black50);\n    font-size: 14px;\n    line-height: 16px;\n  }\n\n \n .input {\n    width: 100%;\n    padding: 8px;\n  }\n\n  .halfinput {\n    width: 50%;\n    padding: 8px;\n  }\n\n  .hr {\n    margin-bottom: 16px;\n    border: none;\n    border-bottom: 1px solid var(--black10);\n  }\n\n  .hidden{\n    display: none;;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `RedirectUrlForm_form__vCuy4`,
	"formRow": `RedirectUrlForm_formRow__26Dk+`,
	"fullFormRow": `RedirectUrlForm_fullFormRow__6MXL4`,
	"section": `RedirectUrlForm_section__0yb6G`,
	"input": `RedirectUrlForm_input__zZr91`,
	"halfinput": `RedirectUrlForm_halfinput__abx+W`,
	"hr": `RedirectUrlForm_hr__5akyO`,
	"hidden": `RedirectUrlForm_hidden__7zGrw`
};
export default ___CSS_LOADER_EXPORT___;
