import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { setToast } from 'components/Toast';
import Button from "components/SixtyButton";
import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { useMediaQuery } from "react-responsive";
import {
    useUpdateCustomerPaymentMethod
} from "queries/subscription";
import Input from "components/SixtyInput";
import { useForm, Controller } from "react-hook-form";
import Text from "components/SixtyText";
import Checkbox from "components/SixtyCheckBox";
import { ReactComponent as IconStripe } from "assets/icons/Icon-stripe.svg";

const StripeCreditCard = ({ onClose }) => {

    const [buttonStatus, setButtonStatus] = useState("Update");
    const [days, setDays] = useState("0");
    const [isHandleSubscribe, setSubscribe] = useState(false);
    const isMobileOrTablet = useMediaQuery({ maxWidth: 768 });
    const [toggleAgreement, setAgreement] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const { handleSubmit, control, errors } = useForm();

    const CARD_OPTIONS = {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#00000",
          color: "#00000",
          fontWeight: 500,
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883"
          },
          "::placeholder": {
            color: "gray"
          }
        },
        invalid: {
          iconColor: "red",
          color: "red"
        }
      }
    };

    if(isMobileOrTablet){
      CARD_OPTIONS.style.base.fontSize = "15px";
    }

    const {
      mutateAsync: ProceedCreditCardUpdate,
    } = useUpdateCustomerPaymentMethod();

    function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
    }
  
    const onSubmit = async (event) => {

      if (!stripe || !elements) {
        return;
      }

      setSubscribe(true);
      const cardElement = elements.getElement(CardElement);
      
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setToast(error.message, "error");
        setSubscribe(false);
      } else {

        await ProceedCreditCardUpdate(
        {
          token: paymentMethod.id
        });

        await timeout(500); 
        setToast("CREDIT CARD UPDATED");                   
        setSubscribe(false);
        onClose();
      }
    };
  
    return (
      <>
      <form className={styles.paymentForm}>

      <div className={styles.cardElement}>
         <CardElement 
           options={CARD_OPTIONS}
        /> 
      </div>
             
       <div className={styles.formRow}>
         <Button onClick={handleSubmit(onSubmit)} size="large" fullWidth disabled={!stripe || isHandleSubscribe}>
           {buttonStatus}
         </Button>
        </div>

        <Text color="secondary" type="p2" className={styles.text}>
             You hereby authorise 60sec to charge you automatically until you cancel your subscription.
        </Text>

        <div className={styles.formRowFooter}>
          <IconStripe className={styles.iconV2} />
        </div>

      </form>
      </>
    );
};

export default StripeCreditCard;