// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Advertisements_advertisementContainer__WnxJu{
    width: 100%;
    padding-top: 24px;
    padding-left: 10%;
    padding-right: 10%;
}

.Advertisements_panelWrap__Nkg\\+k {
    margin: 16px 16px 16px 0px;
    display: block;
    min-height: unset;
    position: relative;
}

.Advertisements_subHeader__gtIXg {
    font-family: var(--regular400);
    font-size: 14px;
    color: var(--black50Transparent);
    padding: 16px;
    border-bottom: 1px solid var(--black10Transparent);
}
.Advertisements_formRow__\\+g\\+12 {
    display: flex;
    margin: 16px;
    justify-content: space-between;
  }
  
  .Advertisements_formRow__\\+g\\+12 > * {
    width: calc(100% - 8px);
  }

  .Advertisements_formRow__\\+g\\+12 .Advertisements_businessEmailRow__ZoOgQ {
    display: flex;
    align-items: center;
    width: 100%;
  }

.Advertisements_formSectionHeader__nxDSi {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
    padding: 16px 16px 0px 16px;
}

.Advertisements_phoneNumber__3HERp {
    width: 100%;
}

.Advertisements_footer__LXnIH {
    width: 100vw;
    position: fixed;
    padding: 16px;
    bottom: 0;
    background: var(--white);
    padding-left: 250px;
    left: 0;
    border-top: 1px solid var(--black10);
    z-index: 100;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/AdminScreens/Advertisements/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;IAChC,aAAa;IACb,kDAAkD;AACtD;AACA;IACI,aAAa;IACb,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;EACb;;AAEF;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,SAAS;IACT,wBAAwB;IACxB,mBAAmB;IACnB,OAAO;IACP,oCAAoC;IACpC,YAAY;EACd","sourcesContent":[".advertisementContainer{\n    width: 100%;\n    padding-top: 24px;\n    padding-left: 10%;\n    padding-right: 10%;\n}\n\n.panelWrap {\n    margin: 16px 16px 16px 0px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.subHeader {\n    font-family: var(--regular400);\n    font-size: 14px;\n    color: var(--black50Transparent);\n    padding: 16px;\n    border-bottom: 1px solid var(--black10Transparent);\n}\n.formRow {\n    display: flex;\n    margin: 16px;\n    justify-content: space-between;\n  }\n  \n  .formRow > * {\n    width: calc(100% - 8px);\n  }\n\n  .formRow .businessEmailRow {\n    display: flex;\n    align-items: center;\n    width: 100%;\n  }\n\n.formSectionHeader {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n    padding: 16px 16px 0px 16px;\n}\n\n.phoneNumber {\n    width: 100%;\n}\n\n.footer {\n    width: 100vw;\n    position: fixed;\n    padding: 16px;\n    bottom: 0;\n    background: var(--white);\n    padding-left: 250px;\n    left: 0;\n    border-top: 1px solid var(--black10);\n    z-index: 100;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advertisementContainer": `Advertisements_advertisementContainer__WnxJu`,
	"panelWrap": `Advertisements_panelWrap__Nkg+k`,
	"subHeader": `Advertisements_subHeader__gtIXg`,
	"formRow": `Advertisements_formRow__+g+12`,
	"businessEmailRow": `Advertisements_businessEmailRow__ZoOgQ`,
	"formSectionHeader": `Advertisements_formSectionHeader__nxDSi`,
	"phoneNumber": `Advertisements_phoneNumber__3HERp`,
	"footer": `Advertisements_footer__LXnIH`
};
export default ___CSS_LOADER_EXPORT___;
