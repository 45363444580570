import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useImmer } from "utils/hooks/useImmer";

import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import SixtyButton from "components/SixtyButton";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/SixtySelect";

import SixtyInput from "components/SixtyInput";

import styles from "./index.module.css";
import { ReactComponent as BaseIcon } from "assets/images/Base.svg";
import SixtySelectField from "components/SixtySelect";
import { setToast } from "components/Toast";
import Loader from "components/Loader";
import {
  useDeleteTaxGroup,
  useGetAmountMeasurementDropdown,
  useUpdateTaxGroup,
} from "queries/product";
import { useGetTaxGroupDetail } from "queries/product";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "components/Modal";

const EditTax = () => {
  const { id } = useParams();

  const [modalIsOpen, setModalIsOpen] = useState(true);
  const editRef = useRef();

  const {
    mutateAsync: updateTaxGroup,
    isLoading: updating,
  } = useUpdateTaxGroup();

  const {
    data: taxGroupDetail,
    isLoading: isTaxGroupLoading,
    isFetchedAfterMount,
  } = useGetTaxGroupDetail(id);

  const history = useNavigate();

  const handleClose = useCallback(() => {
    history("/dashboard/settings/taxes");
    setModalIsOpen(false);
  }, [history]);

  const Title = () => {
    return (
      <div className={styles.modalTitleContainer}>
        <div className={styles.modalTitle}>Edit tax group</div>
      </div>
    );
  };

  const schema = yup.object().shape({
    name: yup.string().required("Tax group  name is required"),
    taxes: yup.array(),
  });
  const defaultValues = {
    name: "",
    taxes: [],
  };
  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const { control, watch, setValue, errors, reset } = formMethods;

  useEffect(() => {
    if (taxGroupDetail && isFetchedAfterMount) reset(taxGroupDetail);
  }, [reset, taxGroupDetail, isFetchedAfterMount]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "taxes",
  });

  const taxes = watch("taxes");

  const { data: amountMeasurementOptions } = useGetAmountMeasurementDropdown();

  const measurementUnitOptions = amountMeasurementOptions?.length
    ? amountMeasurementOptions.map((option) => ({
        label: option.name,
        value: option.key,
      }))
    : [];

  const onSubmit = async (data) => {
    try {
      const result = await updateTaxGroup(data);
      if (result) {
        setToast("Updated tax group succesfully");
        handleClose();
      }
    } catch (error) {
      setToast("Error while updating tax group", "error");
    }
  };

  const [editOpen, setEditOpen] = useState(null);

  const handleAddTaxGroup = (value) => {
    if (value === "SST") {
      append({
        taxCode: "SST",
        name: "SST",
        amount: 10.0,
        amountUom: "Fixed",
      });
    }
    if (value === "GST") {
      append({
        taxCode: "GST",
        name: "GST",
        amount: 10.0,
        amountUom: "Fixed",
      });
    }
    if (value === "Service Fees") {
      append({
        taxCode: "Service Fees",
        name: "Service Fees",
        amount: 10.0,
        amountUom: "Percentage",
      });
    }
  };

  const taxOptions = [
    {
      label: "SST",
      value: "SST",
    },
    {
      label: "GST",
      value: "GST",
    },
    {
      label: "Service Fees",
      value: "Service Fees",
    },
  ];

  const getTaxOptions = () => {
    let options = [...taxOptions];
    if (fields.find((field) => field.taxCode === "GST")) {
      options = options.filter((option) => option.value !== "GST");
    }
    if (fields.find((field) => field.taxCode === "SST")) {
      options = options.filter((option) => option.value !== "SST");
    }
    if (fields.find((field) => field.taxCode === "Service Fees")) {
      options = options.filter((option) => option.value !== "Service Fees");
    }
    return options;
  };

  const {
    mutateAsync: deleteTaxGroup,
    isSuccess: deleteSuccess,
    isLoading: deleting,
  } = useDeleteTaxGroup();

  const onDelete = async () => {
    try {
      await deleteTaxGroup(id);
    } catch (e) {
      handleClose();
    }
  };

  useEffect(() => {
    if (deleteSuccess) {
      setToast("Successfully deleted");
      handleClose();
    }
  }, [deleteSuccess, handleClose]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onAttemptClose={() => {
        history("/dashboard/settings/taxes");
        setModalIsOpen(false);
      }}
      modalRef={editRef}
      Title={Title}
    >
      {updating || isTaxGroupLoading || deleting ? (
        <Panel>
          <Loader
            text={
              updating
                ? "Adding tax group"
                : deleting
                ? "Deleting tax group"
                : "Loading data"
            }
          />
        </Panel>
      ) : (
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className={styles.form}
          >
            <div className={styles.itemsContainer}>
              <Panel className={styles.panelWrap}>
                <PanelHeader title="Tax group name" />
                <div className={styles.subForm}>
                  <div className={styles.row}>
                    <Input
                      name="name"
                      label="Tax group name"
                      control={control}
                      error={errors.name}
                      defaultValue=""
                      className={styles.nameInput}
                    />
                  </div>
                </div>
              </Panel>
              <Panel className={styles.panelWrap}>
                <PanelHeader title="Taxes">
                  {fields.length > 0 && (
                    <Select
                      variant="secondary"
                      options={getTaxOptions()}
                      defaultOption={"Add new tax"}
                      name="newTax"
                      onChange={handleAddTaxGroup}
                      // fullWidth
                      selectBoxHelperClass={styles.shippingMethodSelectBox}
                      selectBoxFontHelper={styles.selectBoxText}
                      optionBoxFontHelper={styles.selectBoxText}
                    />
                  )}
                </PanelHeader>
                {fields.length === 0 && (
                  <div className={styles.addTaxBody}>
                    <div className={styles.addTaxHelperText}>
                      Click on the button below to add new taxes to this group
                    </div>
                    <Select
                      variant="secondary"
                      options={getTaxOptions()}
                      defaultOption={"Add new tax"}
                      name="newTax"
                      onChange={handleAddTaxGroup}
                      // fullWidth
                      selectBoxHelperClass={styles.shippingMethodSelectBox}
                      selectBoxFontHelper={styles.selectBoxText}
                      optionBoxFontHelper={styles.selectBoxText}
                    />
                  </div>
                )}

                {fields.length > 0 &&
                  fields.map((field, index) => (
                    <>
                      <div>
                        <div className={styles.methodContainer}>
                          <div className={styles.leftContainer}>
                            <div className={styles.methodHeader}>
                              {taxes[index].name}
                            </div>
                          </div>
                          <div>
                            <SixtyButton
                              variant="text"
                              label="Edit"
                              onClick={() => setEditOpen(index)}
                            />
                            <span className={styles.elipseIcon}>
                              <BaseIcon />
                            </span>
                            <SixtyButton
                              variant="text"
                              label="Remove"
                              onClick={() => remove(index)}
                            />
                          </div>
                        </div>
                      </div>
                      {editOpen === index && (
                        <div>
                          <div
                            className={clsx(
                              styles.addTaxSubForm,
                              styles.bottomBorder
                            )}
                          >
                            <AddTaxForm
                              taxes={taxes}
                              setValue={setValue}
                              index={index}
                              onClose={() => setEditOpen(null)}
                              measurementUnitOptions={measurementUnitOptions}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </Panel>
            </div>

            <footer className={styles.footer}>
              <SixtyButton type="submit" disabled={updating || deleting}>
                Update tax group
              </SixtyButton>
              <SixtyButton
                variant="warning"
                className={styles.cancelButton}
                onClick={onDelete}
                disabled={deleting || updating}
              >
                Delete
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={handleClose}
                disabled={updating || deleting}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

export default EditTax;

const AddTaxForm = ({
  taxes,
  setValue,
  index,
  onClose,
  measurementUnitOptions,
}) => {
  const [state, setState] = useImmer(() => taxes[index]);

  function handleSave() {
    setValue(`taxes[${index}].name`, state.name);
    setValue(`taxes[${index}].amount`, parseFloat(state.amount) || 0);
    setValue(`taxes[${index}].amountUom`, state.amountUom);
    onClose();
  }
  return (
    <Panel className={clsx(styles.panelWrap, styles.addTaxSubFormPanel)}>
      <div className={styles.subForm}>
        <div className={styles.row}>
          <SixtyInput
            label="Name"
            className={styles.nameInput}
            value={state.name}
            onChange={(e) => {
              setState((draft) => {
                draft.name = e.target.value;
              });
            }}
          />
        </div>
        <div className={clsx(styles.row, styles.measurementunitRow)}>
          <SixtySelectField
            options={measurementUnitOptions}
            fullWidth={true}
            label={"Unit of measurement"}
            value={state.amountUom}
            onChange={(value) => {
              setState((draft) => {
                draft.amountUom = value;
              });
            }}
          />
          <SixtyInput
            type="number"
            label="Cost price"
            step=".01"
            value={state.amount}
            onChange={(e) =>
              setState((draft) => {
                draft.amount = e.target.value;
              })
            }
            endAdornment={
              <>
                {state.amountUom === "Fixed" ? "RM" : null}
                {state.amountUom === "Percentage" ? "%" : null}
              </>
            }
          />
        </div>
        <div className={styles.subFormBtnContainer}>
          <SixtyButton onClick={handleSave}>Save</SixtyButton>
          <SixtyButton
            variant="secondary"
            className={styles.cancelButton}
            onClick={onClose}
          >
            Cancel
          </SixtyButton>
        </div>
      </div>
    </Panel>
  );
};
