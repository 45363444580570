import clsx from "clsx";
import _debounce from "lodash/debounce";

import styles from "./index.module.css";
import { ReactComponent as SearchIcon } from "assets/icons/search-icon.svg";
import { useCallback, useEffect, useState } from "react";

export default function SearchFilter({
  onSearchChange,
  search = "",
  placeholder = "",
  transparent = false,
  resetCb,
}) {
  const [searchValue, setSearchValue] = useState(search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    _debounce((nextValue) => onSearchChange(nextValue), 500),
    []
  );

  if (resetCb) {
    resetCb(() => setSearchValue(""));
  }

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [debouncedSearch, searchValue]);
  return (
    <div
      className={clsx(
        styles.searchContainer,
        transparent && styles.transparent
      )}
    >
      <span className={styles.searchIcon}>
        <SearchIcon />
      </span>
      <input
        className={styles.searchInput}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        autoFocus={!!searchValue}
        placeholder={placeholder}
      />
    </div>
  );
}
