// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UgcOrderTable_sellerNameCell__C3tfg {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .UgcOrderTable_sellerNameValue__qXn8A {
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .UgcOrderTable_loaderContainer__Bjst6 {
    min-height: 180px;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/AdminScreens/UgcOrders/components/UgcOrderTable/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".sellerNameCell {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n  }\n  \n  .sellerNameValue {\n    padding-left: 8px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n  \n  .loaderContainer {\n    min-height: 180px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sellerNameCell": `UgcOrderTable_sellerNameCell__C3tfg`,
	"sellerNameValue": `UgcOrderTable_sellerNameValue__qXn8A`,
	"loaderContainer": `UgcOrderTable_loaderContainer__Bjst6`
};
export default ___CSS_LOADER_EXPORT___;
