import React, { useState, useEffect, useRef, useCallback, useMemo  } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import SixtyButton from "components/SixtyButton";
import Select from "components/FormComponents/ControlledSelect";
import Checkbox from "components/SixtyCheckBox";
import MultiValueInput from "components/FormComponents/ControlledMultiTextInput"; 
import { Editor } from '@tinymce/tinymce-react';
import { Grid2, Box, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createEditor, Transforms, Text } from 'slate';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import { htmlToSlate, slateToHtml  } from "utils/htmlParser";
import TextArea from "components/FormComponents/ControlledTextArea";




const Form = ({setContent, content = null, AILoading = false, editorRef}) => {

  // const editorRef = useRef(null);
  
  const [profilePhoto, setProfilePhoto] = useState([]);
  const [customAudience, setCustomAudience] = useState(false);
  const [customLanguage, setCustomLanguage] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [targetAudience, setTargetAudience] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [region, setRegion] = useState("");
  const [language, setLanguage] = useState("");
  const [wordCount, setWordCount] = useState("");

  const [loadingText, setLoadingText] = useState('AI is thinking');
  const { control, reset, watch, errors } = useFormContext();

  useEffect(() => {
    let interval;
    if (AILoading) {
      let dots = 0;
      interval = setInterval(() => {
        setLoadingText(prevText => {
          dots = (dots + 1) % 4;
          return `AI is thinking${'.'.repeat(dots)}`;
        });
      }, 500);
    } else {
      setLoadingText('AI is thinking');
    }
    return () => clearInterval(interval);
  }, [AILoading]);

  const [expandedAccordion, setExpandedAccordion] = useState({
    socialMedia: false,
    styleLanguage: false,
    company: false,
    targetAudience: false
  });

  const collapseAllAccordions = () => {
    setExpandedAccordion({
      socialMedia: false,
      styleLanguage: false,
      company: false,
      targetAudience: false
    });
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion({ ...expandedAccordion, [panel]: isExpanded });
  };
  
  const handleResetForm = () => {
    reset({
      company: "",
      productOrServiceName: "",
      socialMedia: "",
      targetAudience: "",
      region: "",
      language: "",
      revise: ""
   });
   setLanguage("");
   setRegion("");
   setSocialMedia("");
   setTargetAudience("");
   setContent("");
  };
 
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Grid2 container spacing={2} columns={16} sx={{ flexGrow: 1 }}> 
    <Grid2 item size={5} sx={{ height: '100%', overflowY: 'auto' }}>
    <Box className={styles.form}>

    {content && (
              <>
                <div className={styles.section}>Tell your thought to our AI</div>
                <hr className={styles.hr} />
                <div >
                  <TextArea
                   control={control}
                    name="revise"
                    label="Revise the article"
                    defaultValue=""
                    suggestions={["Add 10 more hashtags for analysis ", "Priority on search volume"]}
                  />
                </div>
                <div className={styles.btnActionContainer}>
                    <SixtyButton
                      fullWidth
                      type="submit"
                      variant="warning"
                      className={styles.approveBtn}
                      disabled={AILoading}
                      onClick={() => {
                        collapseAllAccordions();
                      }}
                      >
                      {"Revise Article"}
                    </SixtyButton>

                 </div>
              </>
    )}

    <Accordion 
        expanded={expandedAccordion.company} 
        onChange={handleAccordionChange('company')} 
        elevation={0} 
        className={styles.accordion} 
     >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}>Company & Product </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>

          <div style={{height:15}} ></div>
          <div className={styles.section}>Company Name</div>
          <hr className={styles.hr} />
          <div className={styles.fullFormRow}>
            <Input
              name="company"
              label="Company Name"
              defaultValue=""
            />
          </div>
      
          <div style={{height:15}} ></div>
          <div className={styles.section}>Product or Service</div>
          <hr className={styles.hr} />
          <div className={styles.fullFormRow}>
            <Input
              name="productOrServiceName"
              label="Product or Service"
              defaultValue=""
            />
          </div>
        </AccordionDetails>
    </Accordion>

    <Accordion 
       expanded={expandedAccordion.styleLanguage} 
       onChange={handleAccordionChange('styleLanguage')} 
       elevation={0} 
       className={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}>Language & Target Region </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
        
            <div className={styles.section}>Language settings</div>
            <hr className={styles.hr} />
            <div className={styles.fullFormRow}>
                <Checkbox
                    label="Custom language"
                    checked={customLanguage}
                    onChange={(e) => setCustomLanguage(e.target.checked)}
                    uncheckStyleVariant="greyedOut"
                  />
                  <Input
                    name={`isCustomLanguage`}
                    control={control}
                    type="hidden"
                    label="Visibility"
                    defaultValue={'false'}
                    value={'false'}
                    className={styles.hidden}
                  />
            </div>
            <div className={styles.fullFormRow}>
                {!customLanguage && (
                  <Select
                      name="language"
                      options={[
                        {
                            label: "English",
                            selectedLabel: "English",
                            value: "English",
                        },
                        {
                            label: "Malay",
                            selectedLabel: "Malay",
                            value: "Malay",
                        },
                        {
                            label: "Chinese",
                            selectedLabel: "Chinese",
                            value: "Chinese",
                        }
                      ]}
                      fullWidth
                      defaultOption="Language"
                      handleChange={(name, value) => {
                        setLanguage(value);
                      }}
                      value={language}
                      defaultValue={language}
                />
                )}
                {customLanguage && (
                    <Input
                      name="language"
                      label="Write your prefered language"
                      defaultValue=""
                    />
                )} 
            </div>  

          <div style={{height:15}} ></div>
          <div className={styles.section}>Target region</div>
          <hr className={styles.hr} />
          <div className={styles.fullFormRow}>
            <Select
                name="region"
                options={[
                  {
                      label: "Malaysia",
                      selectedLabel: "Malaysia",
                      value: "Malaysia",
                  },
                  {
                      label: "Global",
                      selectedLabel: "Global",
                      value: "Global",
                  }
                ]}
                fullWidth
                defaultOption="Region"
                handleChange={(name, value) => {
                    setRegion(value);
                }}
                value={region}
                defaultValue={region}
            />
          </div>  
        </AccordionDetails>
    </Accordion>


    <Accordion 
      expanded={expandedAccordion.socialMedia} 
      onChange={handleAccordionChange('socialMedia')} 
      elevation={0} 
      className={styles.accordion}
    >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}> Target Social Media</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>

        <div className={styles.section}>Social media </div>
        <hr className={styles.hr} />
        <div className={styles.fullFormRow}>
           <Select
                name="socialMedia"
                options={[
                  {
                      label: "Facebook",
                      selectedLabel: "Facebook",
                      value: "Facebook",
                  },
                  {
                      label: "Instagram",
                      selectedLabel: "Instagram",
                      value: "Instagram",
                  },
                  {
                      label: "Tiktok",
                      selectedLabel: "Tiktok",
                      value: "Tiktok",
                  },
                  {
                      label: "Youtube",
                      selectedLabel: "Youtube",
                      value: "Youtube",
                  },
                ]}
                fullWidth
                defaultOption="Social Media"
                handleChange={(name, value) => {
                    setSocialMedia(value);
                }}
                value={socialMedia}
                defaultValue={socialMedia}
            />
          </div> 

        </AccordionDetails>
    </Accordion>

    <Accordion 
      expanded={expandedAccordion.targetAudience} 
      onChange={handleAccordionChange('targetAudience')} 
      elevation={0} 
      className={styles.accordion}
    >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            <Typography className={styles.typographyFont}> Target Audience</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
        
          <div className={styles.section}>Select Target Audience</div>
          <hr className={styles.hr} />
          <div className={styles.fullFormRow}>
          <Checkbox
              label="Customize own audience"
              checked={customAudience}
              onChange={(e) => setCustomAudience(e.target.checked)}
              uncheckStyleVariant="greyedOut"
            />
            <Input
              name={`isCustomAudience`}
              control={control}
              type="hidden"
              label="Visibility"
              defaultValue={'false'}
              value={'false'}
              className={styles.hidden}
            />
          </div>
          <div className={styles.fullFormRow}>
          {!customAudience && (
            <Select
                name="targetAudience"
                options={[
                  {
                      label: "General audience",
                      selectedLabel: "General audience",
                      value: "General audience",
                  },
                  {
                      label: "Eco-concious consumers",
                      selectedLabel: "Eco-concious consumers",
                      value: "Eco-concious consumers",
                  },
                  {
                      label: "Small business Owners",
                      selectedLabel: "Small business Owners",
                      value: "Small business Owners",
                  },
                  {
                      label: "Corporate professional",
                      selectedLabel: "Corporate professional",
                      value: "Corporate professional",
                  },
                  {
                      label: "Parents",
                      selectedLabel: "Parents",
                      value: "Parents",
                  },
                  {
                      label: "Students",
                      selectedLabel: "Students",
                      value: "Students",
                  },
                  {
                      label: "Retirees",
                      selectedLabel: "Retirees",
                      value: "Retirees",
                  },
                  {
                      label: "Freelancer",
                      selectedLabel: "Freelancer",
                      value: "Freelancer",
                  },
                  {
                      label: "Travel Enthusiasts",
                      selectedLabel: "Travel Enthusiasts",
                      value: "Travel Enthusiasts",
                  },
                  {
                      label: "Fashion Enthusiasts",
                      selectedLabel: "Fashion Enthusiasts",
                      value: "Fashion Enthusiasts",
                  },
                  {
                      label: "Artists and Creatives",
                      selectedLabel: "Artists and Creatives",
                      value: "Artists and Creatives",
                  },
                  {
                      label: "Foodies",
                      selectedLabel: "Foodies",
                      value: "Foodies",
                  },
                  {
                      label: "Sports Fans",
                      selectedLabel: "Sports Fans",
                      value: "Sports Fans",
                  },
                  {
                      label: "Fitness Beginner",
                      selectedLabel: "Fitness Beginner",
                      value: "Fitness Beginner",
                  },
                ]}
                fullWidth
                defaultOption="Target audience"
                handleChange={(name, value) => {
                  setTargetAudience(value);
                }}
                value={targetAudience}
                defaultValue={targetAudience}
            />
          )}  
            {customAudience && (
              <Input
                name="targetAudience"
                label="Write your Target Audience"
                defaultValue=""
              />
            )}  
          </div>  
  
        </AccordionDetails>
    </Accordion>



      <div className={styles.btnActionContainer}>
            <SixtyButton
               type="submit"
               variant="primary"
               className={styles.approveBtn}
               disabled={AILoading}
               onClick={() => {
                collapseAllAccordions();
              }}
            >
              {"Analyse Hashtags"}
            </SixtyButton>
            <SixtyButton
               variant="warning"
               className={styles.approveBtn}
               disabled={AILoading}
               onClick={() => {
                handleResetForm();
              }}
            >
              {"Reset"}
            </SixtyButton>
       </div>
      </Box>
      </Grid2>
      <Grid2 item size={11} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ paddingTop: '16px', paddingRight: '16px', flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>

              {AILoading && (
                  <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                      }}
                  >
                    <CircularProgress size={60} />
                      <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                        {loadingText}
                      </Typography>
                  </Box>
                )}
                <Editor
                apiKey='wsg1700p2ol4tgi8trs4h0zn4p5j8804yg2b6hk2t9ehbqn5'
                value={content}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=""
                init={{
                    height:1000,
                    width: '100%',
                    // resize: false,
                    plugins: 'lists link paste help wordcount table charmap print preview anchor advlist autolink' +
                              'searchreplace visualblocks code fullscreen' +
                              'insertdatetime media code help',
                    toolbar:  'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                    setup: (editor) => {
                                editor.on('init', () => {
                                  if (AILoading) {
                                    editor.setMode('readonly');
                                  }
                                });
                              }
                }}
                onEditorChange={(newText) => setContent(newText)}
                disabled={AILoading}
              />  
            {/* <Slate 
              editor={editor} 
              initialValue={initialValue}
              value={initialValue} 
              onChange={handleEditorChange}
            >
              <Editable className={styles.slateEditor} renderElement={renderElement} renderLeaf={renderLeaf} />
            </Slate> */}
          </Box> 

    </Grid2>
    </Grid2>
    </Box>
  );
};

const Leaf = props => {
  return (
    <span
      {...props.attributes}
      style={{ fontWeight: props.leaf.bold ? 'bold' : 'normal' }}
    >
      {props.children}
    </span>
  );
};

export default Form;
