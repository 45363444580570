// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VendorProductTable_sellerNameCell__3a8JZ {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .VendorProductTable_sellerNameValue__8r60N {
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .VendorProductTable_loaderContainer__61C1w {
    min-height: 180px;
  }

  .VendorProductTable_imageIconBox__dC3HI {
    border-radius: 4px;
    background: var(--black10);
    padding: 9px 8px;
    width: 32px;
    height: 32px;
    overflow: hidden;
  }
  
  .VendorProductTable_noPadding__BJJgM {
    padding: 0;
  }
  
  .VendorProductTable_imageIconBox__dC3HI img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .VendorProductTable_variantContainer__LdI4W {
    padding: 16px;
  }

  .VendorProductTable_collapsedCell__a7Lf1 {
    width: 0.1%
  }

  .VendorProductTable_message__sZoaD{
    padding: 15px;
    color: #808080;
  }

  .VendorProductTable_center__MaBom{
    text-align: center;
    min-width: 100px;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Blogs/components/VendorProductTable/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,0BAA0B;IAC1B,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE;EACF;;EAEA;IACE,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".sellerNameCell {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n  }\n  \n  .sellerNameValue {\n    padding-left: 8px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n  \n  .loaderContainer {\n    min-height: 180px;\n  }\n\n  .imageIconBox {\n    border-radius: 4px;\n    background: var(--black10);\n    padding: 9px 8px;\n    width: 32px;\n    height: 32px;\n    overflow: hidden;\n  }\n  \n  .noPadding {\n    padding: 0;\n  }\n  \n  .imageIconBox img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  \n  .variantContainer {\n    padding: 16px;\n  }\n\n  .collapsedCell {\n    width: 0.1%\n  }\n\n  .message{\n    padding: 15px;\n    color: #808080;\n  }\n\n  .center{\n    text-align: center;\n    min-width: 100px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sellerNameCell": `VendorProductTable_sellerNameCell__3a8JZ`,
	"sellerNameValue": `VendorProductTable_sellerNameValue__8r60N`,
	"loaderContainer": `VendorProductTable_loaderContainer__61C1w`,
	"imageIconBox": `VendorProductTable_imageIconBox__dC3HI`,
	"noPadding": `VendorProductTable_noPadding__BJJgM`,
	"variantContainer": `VendorProductTable_variantContainer__LdI4W`,
	"collapsedCell": `VendorProductTable_collapsedCell__a7Lf1`,
	"message": `VendorProductTable_message__sZoaD`,
	"center": `VendorProductTable_center__MaBom`
};
export default ___CSS_LOADER_EXPORT___;
