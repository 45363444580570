// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CampaignReport_row__fu7fW {
    display: flex;
    flex-wrap: wrap;
  }
  
  .CampaignReport_viewRequestBtn__I5kX\\+ {
    /* border-top: 1px solid var(--black10Transparent); */
    color: var(--danger);
    font-size: 12px;
    font-family: var(--medium500);
    display: flex;
    justify-content: flex-end;
    padding: 12px 12px 12px 0px;
  }
  
  .CampaignReport_sellersGeneralReport__cSrVL {
    width: 50%;
  }
  
  .CampaignReport_totalApplied__6pr39,
  .CampaignReport_totalConversionRate__a5k1v {
    border-right: 1px solid var(--black10Transparent);
    border-bottom: 1px solid var(--black10Transparent);
  }
  
  .CampaignReport_chartWrapper__CCzpS {
    height: 60px;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Dashboard/components/CampaignReport/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,qDAAqD;IACrD,oBAAoB;IACpB,eAAe;IACf,6BAA6B;IAC7B,aAAa;IACb,yBAAyB;IACzB,2BAA2B;EAC7B;;EAEA;IACE,UAAU;EACZ;;EAEA;;IAEE,iDAAiD;IACjD,kDAAkD;EACpD;;EAEA;IACE,YAAY;EACd","sourcesContent":[".row {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  \n  .viewRequestBtn {\n    /* border-top: 1px solid var(--black10Transparent); */\n    color: var(--danger);\n    font-size: 12px;\n    font-family: var(--medium500);\n    display: flex;\n    justify-content: flex-end;\n    padding: 12px 12px 12px 0px;\n  }\n  \n  .sellersGeneralReport {\n    width: 50%;\n  }\n  \n  .totalApplied,\n  .totalConversionRate {\n    border-right: 1px solid var(--black10Transparent);\n    border-bottom: 1px solid var(--black10Transparent);\n  }\n  \n  .chartWrapper {\n    height: 60px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `CampaignReport_row__fu7fW`,
	"viewRequestBtn": `CampaignReport_viewRequestBtn__I5kX+`,
	"sellersGeneralReport": `CampaignReport_sellersGeneralReport__cSrVL`,
	"totalApplied": `CampaignReport_totalApplied__6pr39`,
	"totalConversionRate": `CampaignReport_totalConversionRate__a5k1v`,
	"chartWrapper": `CampaignReport_chartWrapper__CCzpS`
};
export default ___CSS_LOADER_EXPORT___;
