import {
  BrowserRouter as ReactRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Components from "screens/Components";
import ForgotPassword from "screens/Onboarding/ForgotPassword";
import ResetPassword from "screens/Onboarding/ResetPassword";
import SignUp from "screens/Onboarding/SignUp";
import VerifyEmail from "screens/Onboarding/VerifyEmail";
import Login from "screens/Onboarding/Login";
import SetupProfile from "screens/Onboarding/SetupProfile";
import LoginLayout from "./layouts/LoginLayout/LoginLayout";
import Reports from "screens/Reports";
import WalletBalances from "screens/WalletBalances";
import CompanySettings from "screens/Settings";
import Dashboard from "screens/Dashboard";
import DashboardLayout from "./layouts/DashboardLayout";
import AdminLayout from "./layouts/AdminLayout";
import SetupUserAuthentication from "screens/Onboarding/SetupUserAuthentication";
import TotalSalesDetailsPage from "screens/Reports/components/TotalSalesDetails";
import ShippingSettings from "screens/Settings/Shippings";
import TaxesSettings from "screens/Settings/Taxes";
import BillingSettings from "screens/Settings/Billing";
import NotificationSettings from "screens/Settings/NotificationSettings";
import AccountSettings from "screens/Settings/AccountSettings";
import SellerGlobalCommission from "screens/Settings/SellerGlobalCommission";
import EditTax from "screens/Settings/Taxes/EditTax";
import EditShipping from "screens/Settings/Shippings/components/EditShippingZone";
import CreditCardSubscribe from"screens/Onboarding/SubscriptionPlan/CreditCard";
import SubscriptionPlan from"screens/Onboarding/SubscriptionPlan";
import Advertisement from "screens/AdminScreens/Advertisements";
import VendorSubscriptionPlan from "screens/Settings/Billing/SubscriptionPlan";
import BlogVendorSelection from "screens/Blogs/VendorSelection";
import CreateBlog from "screens/Blogs/CreateBlog";
import UpdateBlog from "screens/Blogs/UpdateBlog";
import Blog from "screens/Blogs";
import BlogProducts from "screens/Blogs/BlogProducts";
import LazadaImport from "screens/Settings/LazadaImport";
import AdminDashboard from "screens/AdminScreens/Dashboard";
import VideoStories from "screens/AdminScreens/VideoStories";
import UgcOrders from "screens/AdminScreens/UgcOrders";
import Hashtag from "screens/SocialMedia/Hashtag";
import Caption from "screens/SocialMedia/Caption";
import ShortVideoScript from "screens/SocialMedia/ShortVideoScript";

// {
//   path: "/",
//   component: <Navigate to="/auth/login" />,
//   isPublic: true,
//   exact: true,
//   roles: ["Vendor"],
// },

export default function Router() {
  const routeConfig = [
    {
      path: "/",
      component:  <Navigate to="/auth/login" replace />,
      isPublic: true,
      exact: true,
      roles: ["Vendor"],
    },
    {
      path: "/components",
      component: <Components />,
      exact: true,
      roles: ["Vendor"],
    },
    {
      path: "/setup-profile",
      component: <SetupProfile />,
      exact: true,
      roles: ["Vendor"],
    },
    {
      path: "/setup-user-authentication",
      component: <SetupUserAuthentication />,
      exact: true,
      roles: ["Vendor"],
    },
    {
      path: "/reset-password",
      component: <ResetPassword />,
      exact: true,
      roles: ["Vendor"],
    },
    {
      path: "/pricing-plan",
      component: <SubscriptionPlan />,
      exact: true,
      roles: ["Vendor"],
    },
    {
      path: "/subscription/:id",
      component: <CreditCardSubscribe />,
      exact: true,
      roles: ["Vendor"],
    },
    {
      path: "/auth",
      layout: LoginLayout,
      isPublic: true,
      roles: ["Vendor"],
      routes: [
        {
          path: "/auth/login",
          component: <Login />,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/auth/forgot-password",
          component: ForgotPassword,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/auth/sign-up",
          component: SignUp,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/auth/sign-up/:id",
          component: SignUp,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/auth/verify-email",
          component: VerifyEmail,
          exact: true,
          roles: ["Vendor"],
        },
      ],
    },
    {
      path: "/dashboard",
      layout: DashboardLayout,
      roles: ["Vendor"],
      routes: [
        {
          path: "/dashboard",
          component: CreateBlog,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/hashtag",
          component: Hashtag,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/reports",
          component: Reports,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/reports/totalSalesReport",
          component: TotalSalesDetailsPage,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/walletbalances",
          component: WalletBalances,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/lazada-import",
          component: LazadaImport,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/company",
          component: CompanySettings,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/shippings",
          component: ShippingSettings,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/shippings/:id",
          component: EditShipping,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/taxes",
          component: TaxesSettings,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/taxes/:id",
          component: EditTax,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/billing",
          component: BillingSettings,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/notifications",
          component: NotificationSettings,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/accountSettings",
          component: AccountSettings,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/affiliateCommission",
          component: SellerGlobalCommission,
          exact: true,
          roles: ["Vendor"],
        },
        {
          path: "/dashboard/settings/billing/plans",
          component: VendorSubscriptionPlan,
          exact: true,
          roles: ["Vendor"],
        }
      ],
    },
    {
      path: "/admin",
      layout: AdminLayout,
      roles: ["InternalUser"],
      routes: [
        {
          path: "/admin/dashboard",
          component: AdminDashboard,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs/vendor-selection",
          component: BlogVendorSelection,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs/blog-products/:id",
          component: BlogProducts,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs/create-blog/:id",
          component: CreateBlog,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs/update-blog/:id",
          component: UpdateBlog,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/blogs",
          component: Blog,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/subscriptionPlan/Preview",
          component: SubscriptionPlan,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/announcement",
          component: Advertisement,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/videoStories",
          component: VideoStories,
          exact: true,
          roles: ["InternalUser"],
        },
        {
          path: "/admin/ugcOrders",
          component: UgcOrders,
          exact: true,
          roles: ["InternalUser"],
        },
      ],
    },
  ];
  return (
    <ReactRouter>
      <Routes>
        {/* Public route redirecting to login */}
        <Route path="/" element={<Navigate to="/auth/login" replace />} />

        {/* Auth routes */}
        <Route path="/auth" element={<LoginLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="sign-up/:id" element={<SignUp />} />
          <Route path="verify-email" element={<VerifyEmail />} />
        </Route>

        {/* Dashboard routes (private) */}
        <Route
          path="/dashboard"
          element={
            <AuthenticatedRoute roles={["Vendor"]}>
              <DashboardLayout />
            </AuthenticatedRoute>
          }
        >
          <Route path="" element={<CreateBlog />} />
          <Route path="hashtag" element={<Hashtag />} />
          <Route path="caption" element={<Caption />} />
          <Route path="ShortVideoScript" element={<ShortVideoScript />} />
          <Route path="reports" element={<Reports />} />
        </Route>

        {/* Admin routes (private) */}
        <Route
          path="/admin"
          element={
            <AuthenticatedRoute roles={["InternalUser"]}>
              <AdminLayout />
            </AuthenticatedRoute>
          }
        >
          <Route path="dashboard" element={<AdminDashboard />} />
        </Route>

        {/* Other routes */}
        <Route path="/components" element={<Components />} />
        <Route path="/setup-profile" element={<SetupProfile />} />
      </Routes>
    </ReactRouter>
  );
}

// const AuthenticatedRoute = ({ children, roles, ...rest }) => {
//   const token = localStorage.getItem("token");
//   return (
//     <>
//       <Route
//         {...rest}
//         render={() =>
//           token ? grantPermission(roles) &&  <Route {...rest} element={children} /> : <Route path="*" element={<Navigate to="/" />} />
//         }
//       ></Route>
//     </>
//   );
// };

const AuthenticatedRoute = ({ children, roles, ...rest }) => {
  const token = localStorage.getItem("token");
  return (
    token && grantPermission(roles) ? (
      children
    ) : (
      <Navigate to="/" replace />
    )
  );
};


export const grantPermission = (requestedRoles) => {
  const role = localStorage.getItem("userRole");
  if (role && requestedRoles.includes(role)) {
    return true;
  }
  return false;
};
