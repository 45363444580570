// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .LazadaImport_lazadaImport__ZVnoN {
    width: calc(100vw - 250px);
    padding: 24px;
 }

 .LazadaImport_panelWrap__U10mZ {
    margin: 16px 16px 16px 0px;
    display: block;
    min-height: unset;
    position: relative;
 }

 .LazadaImport_subHeader__Muw7k {
    font-family: var(--regular400);
    font-size: 14px;
    color: var(--black50Transparent);
    padding: 16px;
    border-bottom: 1px solid var(--black10Transparent);
 }
 .LazadaImport_formRow__MLkjt {
    display: flex;
    margin: 16px;
    justify-content: space-between;
  }
  
  .LazadaImport_formRow__MLkjt > * {
    width: calc(50% - 8px);
  }

  .LazadaImport_formRow__MLkjt .LazadaImport_businessEmailRow__0Krm0 {
    display: flex;
    align-items: center;
    width: 100%;
  }

 .LazadaImport_formSectionHeader__3jB2v {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
    padding: 16px 16px 0px 16px;
}

.LazadaImport_phoneNumber__1on4L {
    width: 100%;
}

.LazadaImport_btn__WO6n9{
  margin-top: 15px;
}

.LazadaImport_footer__GmreD {
    width: 100vw;
    position: fixed;
    padding: 16px;
    bottom: 0;
    background: var(--white);
    padding-left: 250px;
    left: 0;
    border-top: 1px solid var(--black10);
    z-index: 100;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Settings/LazadaImport/index.module.css"],"names":[],"mappings":"CAAC;IACG,0BAA0B;IAC1B,aAAa;CAChB;;CAEA;IACG,0BAA0B;IAC1B,cAAc;IACd,iBAAiB;IACjB,kBAAkB;CACrB;;CAEA;IACG,8BAA8B;IAC9B,eAAe;IACf,gCAAgC;IAChC,aAAa;IACb,kDAAkD;CACrD;CACA;IACG,aAAa;IACb,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;EACb;;CAED;IACG,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,SAAS;IACT,wBAAwB;IACxB,mBAAmB;IACnB,OAAO;IACP,oCAAoC;IACpC,YAAY;EACd","sourcesContent":[" .lazadaImport {\n    width: calc(100vw - 250px);\n    padding: 24px;\n }\n\n .panelWrap {\n    margin: 16px 16px 16px 0px;\n    display: block;\n    min-height: unset;\n    position: relative;\n }\n\n .subHeader {\n    font-family: var(--regular400);\n    font-size: 14px;\n    color: var(--black50Transparent);\n    padding: 16px;\n    border-bottom: 1px solid var(--black10Transparent);\n }\n .formRow {\n    display: flex;\n    margin: 16px;\n    justify-content: space-between;\n  }\n  \n  .formRow > * {\n    width: calc(50% - 8px);\n  }\n\n  .formRow .businessEmailRow {\n    display: flex;\n    align-items: center;\n    width: 100%;\n  }\n\n .formSectionHeader {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n    padding: 16px 16px 0px 16px;\n}\n\n.phoneNumber {\n    width: 100%;\n}\n\n.btn{\n  margin-top: 15px;\n}\n\n.footer {\n    width: 100vw;\n    position: fixed;\n    padding: 16px;\n    bottom: 0;\n    background: var(--white);\n    padding-left: 250px;\n    left: 0;\n    border-top: 1px solid var(--black10);\n    z-index: 100;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lazadaImport": `LazadaImport_lazadaImport__ZVnoN`,
	"panelWrap": `LazadaImport_panelWrap__U10mZ`,
	"subHeader": `LazadaImport_subHeader__Muw7k`,
	"formRow": `LazadaImport_formRow__MLkjt`,
	"businessEmailRow": `LazadaImport_businessEmailRow__0Krm0`,
	"formSectionHeader": `LazadaImport_formSectionHeader__3jB2v`,
	"phoneNumber": `LazadaImport_phoneNumber__1on4L`,
	"btn": `LazadaImport_btn__WO6n9`,
	"footer": `LazadaImport_footer__GmreD`
};
export default ___CSS_LOADER_EXPORT___;
