// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalSalesChart_row__YJeWE {  
    display: flex;
    flex-wrap: wrap;
  }

  .TotalSalesChart_row__YJeWE > div {
      flex-basis: 12%;
  }

  .TotalSalesChart_subText__t-6ZK {
    color: var(--black50Transparent);
    font-size: 14px;
    font-family: var(--regular400);
    font-size: 12px;
    padding: 0px 16px 8px 16px;
    border-bottom: 1px solid var(--black10Transparent);
}

.TotalSalesChart_chartHelper__cNyc1 {
    width: 100%;
    padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/screens/Reports/components/TotalSalesDetails/components/TotalSalesChart/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;EACjB;;EAEA;MACI,eAAe;EACnB;;EAEA;IACE,gCAAgC;IAChC,eAAe;IACf,8BAA8B;IAC9B,eAAe;IACf,0BAA0B;IAC1B,kDAAkD;AACtD;;AAEA;IACI,WAAW;IACX,aAAa;AACjB","sourcesContent":[".row {  \n    display: flex;\n    flex-wrap: wrap;\n  }\n\n  .row > div {\n      flex-basis: 12%;\n  }\n\n  .subText {\n    color: var(--black50Transparent);\n    font-size: 14px;\n    font-family: var(--regular400);\n    font-size: 12px;\n    padding: 0px 16px 8px 16px;\n    border-bottom: 1px solid var(--black10Transparent);\n}\n\n.chartHelper {\n    width: 100%;\n    padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `TotalSalesChart_row__YJeWE`,
	"subText": `TotalSalesChart_subText__t-6ZK`,
	"chartHelper": `TotalSalesChart_chartHelper__cNyc1`
};
export default ___CSS_LOADER_EXPORT___;
