import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetSubscriptionPlans,
  useProceedSubscriptionPayment,
  useSetupFreeTierSubscription
} from "queries/subscription";
import map from "lodash/map";

import ProfileSetupLayout from "routes/layouts/ProfileSetupLayout";
import PricingPlanTemplate from "./components/SubscriptionPlanTemplate";
import GridHeader from "components/GridHeader";
import Panel from "components/SixtyPanel";
import Loader from "components/Loader";
import SixtyText from "components/SixtyText";
import SixtyLink from "components/SixtyLink";
import styles from "./index.module.css";
import { setToast } from 'components/Toast';

export default function SubscriptionPlan({ gridHeader = "Pick a plan" }) {
  const history = useNavigate();
  const [activePanelId, setActivePanelId] = useState("60sec Premium Plan");
  const [isRedirectSubscription, setRedirect] = useState(false);

  const {
    data: subscriptionPlans,
    isLoading,
    isSuccess,
    refetch,
  } = useGetSubscriptionPlans(localStorage.getItem("AgencyReferral") ? "ExcludeFreeTier" : null);

  const {
    mutateAsync: setupFreeTier,
    isLoading: freeTierLoading
  } = useSetupFreeTierSubscription();

  const PaymentModalTitle = () => {
    return <div className={styles.modalTitle}>Payment</div>;
  };

  const sortedByPrice = subscriptionPlans?.length
    ? subscriptionPlans.sort(order)
    : [];

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const proceedSubscription = (id) => {
    setRedirect(true);
    const path = `/subscription/${id}`;
    history(path);
  };

  const proceedFreeTrial = async (id) => {

    setToast("Please be patient while we setup the account.");

    let model = {
      subscriptionId: `${id}`
    };

    var result = await setupFreeTier(model);

    await timeout(1000); 

    if(result){
      refetch();
      setRedirect(true);
      const path = `/dashboard`;
      history(path);
    }

  };

  return (
    <React.Fragment>
      <ProfileSetupLayout className={styles.profileLayoutHelper} isProceedPayment={isRedirectSubscription}>
        <div className={styles.container}>
          {isLoading && (
            <Panel>
              <Loader />
            </Panel>
          )}
          {isSuccess && (
            <>
              <GridHeader
                header={gridHeader}
                className={styles.pricingPlanHeader}
              />
              <div className={styles.templateContainer}>
                {map(sortedByPrice, (subscriptionPlan, index) => {
                  return (
                    <PricingPlanTemplate
                      key={index}
                      subscriptionPlan={subscriptionPlan}
                      activePanelId={activePanelId}
                      setActivePanel={(name) => setActivePanelId(name)}
                      handleSubmit={subscriptionPlan.freeTier ? proceedFreeTrial : proceedSubscription}
                      isLoading={isLoading || freeTierLoading}
                      isCurrentplan={subscriptionPlan.isSubscribed}
                    />
                  );
                })}
              </div>
            </>
          )}
          <footer className={styles.footer}>
            <hr className={styles.footerHr} />
            <div>
              <SixtyText>
                Need help?{" "}
                <SixtyLink
                  color="orange"
                  inline
                  external
                  to={"mailto:hello@60sec.cc"}
                >
                  Contact us
                </SixtyLink>
              </SixtyText>
            </div>
          </footer>
        </div>
      </ProfileSetupLayout>
    </React.Fragment>
  );
}

function order(a, b) {
  return a.salePrice < b.salePrice ? -1 : a.salePrice > b.salePrice ? 1 : 0;
}
