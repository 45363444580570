import Text from "components/SixtyText";
import styles from "./index.module.css";
import clsx from "clsx";

const EmptyGrid = ({ Icon, emptyMessage, className }) => {
  return (
    <div className={clsx(styles.emptyGrid, className)}>
      {Icon && (
        <div className={styles.icon}>
          <Icon />
        </div>
      )}
      {emptyMessage && <Text className={styles.emptyText}>{emptyMessage}</Text>}
    </div>
  );
};

export default EmptyGrid;
