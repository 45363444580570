// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalSearch_searchContainer__kK3Wu {
  position: relative;
  width: 100%;
  max-width: 480px;
}

.GlobalSearch_searchResult__qZ7xi {
  position: absolute;
  top: 40px;
  width: 480px;
  max-height: 540px;
  background: #fff;
  border: 1px solid var(--black10);
  box-sizing: border-box;
  box-shadow: 0px 2px 5px var(--black5);
  border-radius: 4px;
  overflow: auto;
}

.GlobalSearch_resultItem__ch3xO {
  padding: 12px 16px;
  line-height: 16.41px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid var(--black10);
}

.GlobalSearch_resultItem__ch3xO:hover {
  background-color: #F9F8FA;
}
`, "",{"version":3,"sources":["webpack://./src/components/GlobalSearch/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,gCAAgC;EAChC,sBAAsB;EACtB,qCAAqC;EACrC,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,eAAe;EACf,uCAAuC;AACzC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".searchContainer {\n  position: relative;\n  width: 100%;\n  max-width: 480px;\n}\n\n.searchResult {\n  position: absolute;\n  top: 40px;\n  width: 480px;\n  max-height: 540px;\n  background: #fff;\n  border: 1px solid var(--black10);\n  box-sizing: border-box;\n  box-shadow: 0px 2px 5px var(--black5);\n  border-radius: 4px;\n  overflow: auto;\n}\n\n.resultItem {\n  padding: 12px 16px;\n  line-height: 16.41px;\n  font-size: 14px;\n  cursor: pointer;\n  border-bottom: 1px solid var(--black10);\n}\n\n.resultItem:hover {\n  background-color: #F9F8FA;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": `GlobalSearch_searchContainer__kK3Wu`,
	"searchResult": `GlobalSearch_searchResult__qZ7xi`,
	"resultItem": `GlobalSearch_resultItem__ch3xO`
};
export default ___CSS_LOADER_EXPORT___;
