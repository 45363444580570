import React, { useState, useRef } from "react";
import Panel from "components/SixtyPanel";
import ShippingTable from "./components/ShippingTable";
import styles from "./index.module.css";
import Button from "components/SixtyButton";
import { useNavigate } from "react-router-dom";
import Modal from "components/Modal";
import AddShippingZoneForm from "./components/AddShippingZoneForm";
import CustomToastContainer from "components/Toast";
import SixtyButton from "components/SixtyButton";

const ShippingSettings = (props) => {
  const [isAddShippingZoneOpen, setIsAddShippingZoneOpen] = useState(false);
  const history = useNavigate();
  const addShippingRef = useRef();

  const Title = () => {
    return (
      <div className={styles.modalTitleContainer}>
        <div className={styles.modalTitle}>New shipping zone</div>
      </div>
    );
  };

  const Actions = () => {
    return (
      <footer>
        <SixtyButton form="add-shipping-zone-form" type="submit">
          Create shipping zone
        </SixtyButton>
        <SixtyButton
          variant="secondary"
          className={styles.cancelButton}
          onClick={() => setIsAddShippingZoneOpen(false)}
        >
          Cancel
        </SixtyButton>
      </footer>
    );
  };

  const onAddShippingZone = () => {
    setIsAddShippingZoneOpen(true);
  };

  const onExport = () => {};

  return (
    <div>
      <div className={styles.headerContainer}>
        <div>
          <span className={styles.gridHeader}>Shippings</span>
        </div>
        <div className={styles.ButtonsContainer}>
          <div>
            <Button
              className={styles.addShippingzoneBtn}
              label="Add shipping zone"
              onClick={onAddShippingZone}
            />
          </div>
          {/* <div>
            <Button
              className={styles.exportBtn}
              variant="secondary"
              label="Export"
              onClick={onExport}
            />
          </div> */}
        </div>
      </div>
      <Panel>
        <div className={styles.table}>
          <ShippingTable />
        </div>
      </Panel>
      {isAddShippingZoneOpen && (
        <Modal
          isOpen={isAddShippingZoneOpen}
          onAttemptClose={() => {
            history("/dashboard/settings/shippings");
            setIsAddShippingZoneOpen(false);
          }}
          modalRef={addShippingRef}
          Title={Title}
          Actions={Actions}
        >
          <AddShippingZoneForm
            handleClose={() => setIsAddShippingZoneOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default ShippingSettings;
