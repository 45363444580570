// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BillingStatementsTable_tableHeader__aaaPE {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}


.BillingStatementsTable_gridHeader__SHdg\\+ {
    font-family: var(--medium500);
    font-size: 16px;
    color: var(--black);
}

.BillingStatementsTable_ButtonsContainer__eaGoz {
    display: flex;
    align-items: center;
}

.BillingStatementsTable_ButtonsContainer__eaGoz > div {
    margin: 4px;
}

.BillingStatementsTable_downloadAsPdf__\\+Iwih {
    color: var(--success);
}`, "",{"version":3,"sources":["webpack://./src/screens/Settings/Billing/components/BillingStatementsTable/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;AACjB;;;AAGA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".tableHeader {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n    padding: 16px;\n}\n\n\n.gridHeader {\n    font-family: var(--medium500);\n    font-size: 16px;\n    color: var(--black);\n}\n\n.ButtonsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.ButtonsContainer > div {\n    margin: 4px;\n}\n\n.downloadAsPdf {\n    color: var(--success);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeader": `BillingStatementsTable_tableHeader__aaaPE`,
	"gridHeader": `BillingStatementsTable_gridHeader__SHdg+`,
	"ButtonsContainer": `BillingStatementsTable_ButtonsContainer__eaGoz`,
	"downloadAsPdf": `BillingStatementsTable_downloadAsPdf__+Iwih`
};
export default ___CSS_LOADER_EXPORT___;
