import { Controller } from "react-hook-form";
import MultiValueInput from "components/MultiValueInput";

export default function ControlledMultiTextInput({
  control,
  name,
  handleChange,
  label,
  rules,
  error,
  defaultValue,
  maxItemMsg = "Max 5 support keywords",
  newItemMsg = "Click here to add your keyword",
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => {
        const { onChange, value } = field;
        const { error } = fieldState;
    
        return (
          <>
            <MultiValueInput
              field={field}
              maxItemMsg={maxItemMsg}
              newItemMsg={newItemMsg}
              onChange={(e) => {
                onChange(e.target.value); // Update form value
                handleChange && handleChange(name, e.target.value); // Custom change handler, if defined
              }}
              label={label}
              error={error} // Show validation error
              {...props} // Spread any other additional props
            />
          </>
        );
      }}
    ></Controller>
  );
}
