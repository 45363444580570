// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme_buttonBase__h3ax- {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    transition: background 0.2s ease;
}

.theme_buttonBase__h3ax-:focus {
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/theme/theme.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IAInB,gCAAgC;AACpC;;AAEA;IACI,aAAa;EACf","sourcesContent":[".buttonBase {\n    position: relative;\n    cursor: pointer;\n    user-select: none;\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 25px;\n    -webkit-transition: background 0.2s ease;\n    -moz-transition: background 0.2s ease;\n    -o-transition: background 0.2s ease;\n    transition: background 0.2s ease;\n}\n\n.buttonBase:focus {\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonBase": `theme_buttonBase__h3ax-`
};
export default ___CSS_LOADER_EXPORT___;
