import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Panel from "components/SixtyPanel";
import GridHeader from "components/GridHeader";
import PanelHeader from "components/PanelHeader";
import styles from "./index.module.css";
import BasicReport from "./components/BasicReports";
import Button from "components/SixtyButton";
import { ReactComponent as CalendarIcon } from "assets/icons/Icon-Calendar.svg";
import Reports from "./components/Reports";
import EmptyGrid from "components/EmptyGrid";
import Loader from "components/Loader";
import {
  useGetAdminReporting,
  useGetAdminSummaryReport,
} from "queries/dashboard";
import format from "date-fns/format";
import { setToast } from "components/Toast";
import { reportError } from "crashlytics";
import { ButtonDatePicker } from "components/SixtyDatePicker";

const Dashboard = () => {
  const [day, setReportDay] = useState(new Date());
  const history = useNavigate();
  const { data: adminReportingData, isLoading: isAdminLoading, refetch } = useGetAdminReporting(
    format(day, "dd/MM/yyyy")
  );
  const { data: summaryData, isLoading, isFetching } = useGetAdminSummaryReport(
    format(day, "MM/yyyy")
  );

  const [orderCount, setOrderCount] = useState(0);

  // pagination
  const startFrom = 0;
  const limit = 5;

  const getFilters = useCallback(() => {
    return {
      keyword: "",
      startFrom,
      limit,
    };
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className={styles.dashBoardPage}>
      <GridHeader header="Dashboard" className={styles.dashboardHeader} />
      <div className={styles.row}>
        <div className={styles.leftContainer}>
        {isAdminLoading ? (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            ) :(
              <Panel className={styles.panelWrap}>
                <PanelHeader
                  title={'60Sec Metrics'}
                />
                <BasicReport data={adminReportingData} />
             </Panel>
            )
        }
        
        </div>
        <div className={styles.rightContainer}>
          {(summaryData) && (
            <Panel
              className={[styles.panelWrap, styles.reportsPanel].join(" ")}
            >
              <PanelHeader title="Reports">
              <div>
                  <ButtonDatePicker
                    value={day}
                    maxDate={new Date()}
                    handleChange={(date) => setReportDay(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </PanelHeader>
              {isLoading ? (
                <Loader />
              ) : (
                <Reports summaryData={summaryData} orderCount={orderCount} />
              )}
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
