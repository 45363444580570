import format from "date-fns/format";

export function toDate(dateStr) {
  var parts = dateStr.split("/");
  return new Date(parts[2], parts[1] - 1, parts[0]);
}

export function getFormattedDateString(date, requiredFormat) {
  try {
    if (!date) {
      return "";
    }
    const dateObject = toDate(date);
    return format(dateObject, requiredFormat);
  } catch (e) {
    return date;
  }
}
