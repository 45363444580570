// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GeneralReport_GeneralReportContainer__ytmUe {
    display: flex;
    flex-direction: column;
    padding: 16px
}

.GeneralReport_title__8OI0p, .GeneralReport_previousMonthCountText__4JCaz {
    color: var(--black50Transparent);
    font-size: 14px;
    font-family: var(--regular400);
}

.GeneralReport_counts__yVKNJ {
    padding: 4px 0px;
}

.GeneralReport_currentMonthCount__agZm1 {
    color: var(--black);
    font-family: var(--medium500);
    font-size: 24px;
    margin-right: 8px;
}

.GeneralReport_previousMonthCountText__4JCaz {
    font-size: 12px;
}

.GeneralReport_profit__3yOji, .GeneralReport_loss__hkzQD, .GeneralReport_unit__2EgJ5 {
    font-family: var(--regular400);
    font-size: 14px;
}

.GeneralReport_unit__2EgJ5 {
    color: var(--black50Transparent)
}

.GeneralReport_profit__3yOji {
    color: var(--success);
}

.GeneralReport_loss__hkzQD {
    color: var(--danger);
}

.GeneralReport_profitInNewLine__nXDOt {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Reports/components/GeneralReport/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB;AACJ;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI;AACJ;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".GeneralReportContainer {\n    display: flex;\n    flex-direction: column;\n    padding: 16px\n}\n\n.title, .previousMonthCountText {\n    color: var(--black50Transparent);\n    font-size: 14px;\n    font-family: var(--regular400);\n}\n\n.counts {\n    padding: 4px 0px;\n}\n\n.currentMonthCount {\n    color: var(--black);\n    font-family: var(--medium500);\n    font-size: 24px;\n    margin-right: 8px;\n}\n\n.previousMonthCountText {\n    font-size: 12px;\n}\n\n.profit, .loss, .unit {\n    font-family: var(--regular400);\n    font-size: 14px;\n}\n\n.unit {\n    color: var(--black50Transparent)\n}\n\n.profit {\n    color: var(--success);\n}\n\n.loss {\n    color: var(--danger);\n}\n\n.profitInNewLine {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GeneralReportContainer": `GeneralReport_GeneralReportContainer__ytmUe`,
	"title": `GeneralReport_title__8OI0p`,
	"previousMonthCountText": `GeneralReport_previousMonthCountText__4JCaz`,
	"counts": `GeneralReport_counts__yVKNJ`,
	"currentMonthCount": `GeneralReport_currentMonthCount__agZm1`,
	"profit": `GeneralReport_profit__3yOji`,
	"loss": `GeneralReport_loss__hkzQD`,
	"unit": `GeneralReport_unit__2EgJ5`,
	"profitInNewLine": `GeneralReport_profitInNewLine__nXDOt`
};
export default ___CSS_LOADER_EXPORT___;
