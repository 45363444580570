import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTopupVendorCredits } from "queries/wallet";

import SixtyButton from "components/SixtyButton";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import styles from "./index.module.css";
import { formatCurrency } from "utils/common";
import { setToast } from "components/Toast";

const defaultValues = {
  credit: "",
};

const TopupCreditForm = ({ handleClose, availableBalance }) => {

const [selectedTopupCredit, setTopupCredit] = useState("500");

  const schema = yup.object().shape({
    credit: yup
      .string().required("Select your amount for topup")
  });
  const formMethods = useForm({
    defaultValues,
    shouldUnregister: false,
  });

  const { 
    mutateAsync: topup, 
    isSuccess: topupSuccess, 
    isLoading: topupLoading
   } = useTopupVendorCredits();

  const onSubmit = async (data) => {
    try {
      setToast(`Generate Payment Link`);

      var model = {
        subtotal: parseFloat(selectedTopupCredit) 
      };
      var response = await topup(model);

      if(response){
        const path = `https://payment.60sec.asia/Transaction/Stripe?transactionId=${response?.id}`;
        window.open(path, "_blank");
      }
    } catch (e) {
      handleClose();
    }
  };

  useEffect(() => {
    if (topupSuccess) {
      handleClose();
    }
  }, [handleClose, topupSuccess]);

  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.formContainer}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.form}>
              <div className={styles.fieldHeader}>Available balances</div>
              <div className={styles.fieldValue}>
                {formatCurrency(availableBalance || 0)}
              </div>
              <div className={styles.fieldHeader}>10% service charges will be incur in the topup</div>
              <div style={{height:15}} ></div>
                <div className={styles.section}>Select your amount</div>
                <hr className={styles.hr} />
                <div style={{height:15}} ></div>
                <div className={styles.fullFormRow}>
                    <Select
                        name="credit"
                        error={formMethods?.errors?.credit}
                        options={[
                            {
                                label: "500",
                                selectedLabel: "500",
                                value: "500",
                            },
                            {
                                label: "1000",
                                selectedLabel: "1000",
                                value: "1000",
                            },
                            {
                                label: "2500",
                                selectedLabel: "2500",
                                value: "2500",
                            },
                            {
                                label: "5000",
                                selectedLabel: "5000",
                                value: "5000",
                            },
                        ]}
                        fullWidth
                        defaultOption="Select your credit amount"
                        handleChange={(name, value) => {
                            setTopupCredit(value);
                        }}
                        value={selectedTopupCredit}
                        defaultValue={selectedTopupCredit}
                    />
                </div>  
            </div>
            <div style={{height:50}} ></div>
            <footer className={styles.footer}>
              <SixtyButton type="submit" className={styles.withdrawBtn} disabled={topupLoading}>
                Topup Now
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() => handleClose()}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default TopupCreditForm;
