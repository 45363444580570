// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HorizontalBarChart_chartContainer__\\+ig3C {
    position: relative;
    height: 9vh;
    width: 16vw;
}

#HorizontalBarChart_chartjstooltip__Xt5KR {
    opacity: 0;
    position: absolute;
    background-color: #000000;
    color: #fff;
    padding: 3px;
    border-radius: 3px;
    transition: all .1s ease;
    pointer-events: none;
    transform: translate(-50%, 0);
    height: auto;
}

.HorizontalBarChart_tooltipHeader__aSNIu {
    font-size: 12px;
    color: var(--white);
    opacity: 0.75;
    font-family: var(--regular400);
    border: none;
}

th {
    padding: 5px 0px 5px 0px;
}

td {
    padding: 0px 0px 5px 0px;
}

.HorizontalBarChart_tooltipValue__lfJn7 {
    font-family: var(--medium500);
    font-size: 12px;
    color: #fff;
}

.HorizontalBarChart_tooltipSeperator__fGr74 {
    border-bottom: 1px solid var(--white25);
}

.HorizontalBarChart_tooltipRows__y4gRX {
    padding: 6px 0px;
}

.HorizontalBarChart_tooltipfirstRow__4VWGa {
    padding-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Charts/HorizontalBarChart/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAElB,wBAAwB;IACxB,oBAAoB;IAEpB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,WAAW;AACf;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".chartContainer {\n    position: relative;\n    height: 9vh;\n    width: 16vw;\n}\n\n#chartjstooltip {\n    opacity: 0;\n    position: absolute;\n    background-color: #000000;\n    color: #fff;\n    padding: 3px;\n    border-radius: 3px;\n    -webkit-transition: all .1s ease;\n    transition: all .1s ease;\n    pointer-events: none;\n    -webkit-transform: translate(-50%, 0);\n    transform: translate(-50%, 0);\n    height: auto;\n}\n\n.tooltipHeader {\n    font-size: 12px;\n    color: var(--white);\n    opacity: 0.75;\n    font-family: var(--regular400);\n    border: none;\n}\n\nth {\n    padding: 5px 0px 5px 0px;\n}\n\ntd {\n    padding: 0px 0px 5px 0px;\n}\n\n.tooltipValue {\n    font-family: var(--medium500);\n    font-size: 12px;\n    color: #fff;\n}\n\n.tooltipSeperator {\n    border-bottom: 1px solid var(--white25);\n}\n\n.tooltipRows {\n    padding: 6px 0px;\n}\n\n.tooltipfirstRow {\n    padding-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": `HorizontalBarChart_chartContainer__+ig3C`,
	"chartjstooltip": `HorizontalBarChart_chartjstooltip__Xt5KR`,
	"tooltipHeader": `HorizontalBarChart_tooltipHeader__aSNIu`,
	"tooltipValue": `HorizontalBarChart_tooltipValue__lfJn7`,
	"tooltipSeperator": `HorizontalBarChart_tooltipSeperator__fGr74`,
	"tooltipRows": `HorizontalBarChart_tooltipRows__y4gRX`,
	"tooltipfirstRow": `HorizontalBarChart_tooltipfirstRow__4VWGa`
};
export default ___CSS_LOADER_EXPORT___;
