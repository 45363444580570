import { jsx } from 'slate-hyperscript';
import parse from 'html-react-parser';
import { Text } from 'slate';

// Convert HTML to Slate JSON
export const htmlToSlate = (html) => {
    const parsed = parse(html);
    console.log(parsed);
    return deserializeReactElement(parsed);
};

// Convert Slate JSON to HTML
export const slateToHtml = (nodes) => {
  return nodes.map((n) => serialize(n)).join('');
};

// Helper function to deserialize HTML to Slate JSON
const deserializeReactElement = (element) => {
  if (typeof element === 'string') {
    return element;
  }

  if (Array.isArray(element)) {
    return element.map(deserializeReactElement).flat();
  }

  if (element.type === 'text') {
    return element.props.children;
  }

  let children = element.props.children
    ? Array.isArray(element.props.children)
      ? element.props.children.map(deserializeReactElement).flat()
      : [deserializeReactElement(element.props.children)]
    : [];
  console.log(element.type);
  console.log(children);
  switch (element.type) {
    case 'p':
      return jsx('element', { type: 'paragraph' }, children);
    case 'h1':
      return jsx('element', { type: 'heading-one' }, children);
    case 'h2':
      return jsx('element', { type: 'heading-two' }, children);
    case 'a':
      return jsx('element', { type: 'link', url: element.props.href }, children);
    case 'strong':
    case 'b':
      return children.map(child => jsx('text', { bold: true }, child));
    case 'em':
    case 'i':
      return children.map(child => jsx('text', { italic: true }, child));
    default:
      return children;
  }
};

// Helper function to serialize Slate JSON to HTML
const serialize = (node) => {
  if (Text.isText(node)) {
    let string = node.text;
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    return string;
  }

  const children = node.children.map((n) => serialize(n)).join('');

  switch (node.type) {
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'heading-one':
      return `<h1>${children}</h1>`;
    case 'heading-two':
      return `<h2>${children}</h2>`;
    case 'link':
      return `<a href="${node.url}">${children}</a>`;
    default:
      return children;
  }
};
