// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SearchFilter_searchContainer__BgL4W {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 0 8px;
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .SearchFilter_transparent__AGiBK {
    background-color: transparent;
  }
  
  .SearchFilter_searchInput__ENH8l {
    height: 32px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    padding-left: 8px;
    border: none;
    width: 100%;
  }
  
  .SearchFilter_searchInput__ENH8l:focus {
    outline: none;
  }

  .SearchFilter_searchInput__ENH8l::placeholder {
      color: #BFBFBF
  }

  .SearchFilter_searchIcon__41L0b{
    height: 17px;
  }`, "",{"version":3,"sources":["webpack://./src/components/SixtyTable/components/SearchFilter/index.module.css"],"names":[],"mappings":";AACA;IACI,qCAAqC;IACrC,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,aAAa;EACf;;EAEA;MACI;EACJ;;EAEA;IACE,YAAY;EACd","sourcesContent":["\n.searchContainer {\n    background-color: rgba(0, 0, 0, 0.05);\n    border-radius: 4px;\n    padding: 0 8px;\n    height: 32px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n  }\n\n  .transparent {\n    background-color: transparent;\n  }\n  \n  .searchInput {\n    height: 32px;\n    background-color: transparent;\n    font-size: 14px;\n    font-weight: 400;\n    padding-left: 8px;\n    border: none;\n    width: 100%;\n  }\n  \n  .searchInput:focus {\n    outline: none;\n  }\n\n  .searchInput::placeholder {\n      color: #BFBFBF\n  }\n\n  .searchIcon{\n    height: 17px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": `SearchFilter_searchContainer__BgL4W`,
	"transparent": `SearchFilter_transparent__AGiBK`,
	"searchInput": `SearchFilter_searchInput__ENH8l`,
	"searchIcon": `SearchFilter_searchIcon__41L0b`
};
export default ___CSS_LOADER_EXPORT___;
