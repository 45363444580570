// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionForm_paymentForm__XK\\+pu {
    width: 50%;
    padding: 15px;
    border: gray 1px solid;
    margin-top: 30px;
    margin-bottom: 30px;
}

.SubscriptionForm_cardElement__Jlr22{
  margin-bottom: 50px;
  height: 40px;
  padding-top: 10px;
  padding-left: 10px;
  border-style: none;
  will-change: opacity, transform;
}

.SubscriptionForm_form__BR0Hn {
  padding: 16px;
}

.SubscriptionForm_label__aSnKZ{
  width: 100%;
  text-align: center;
}

.SubscriptionForm_icon__8e2ZV {
  height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.SubscriptionForm_iconV2__sJSth {
  height: 40px;
}

.SubscriptionForm_iconV3__TYEQ9 {
  margin-left: 10px;
  margin-top: 8px;
  height: 15px;
}

.SubscriptionForm_formRowAlign__cnH2n {
  display: flex;
  align-items: stretch;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.SubscriptionForm_formRowHeader__Q8FB2 {
  display: flex;
  align-items: stretch;
  height: 50px;
}

.SubscriptionForm_formRowFooter__a4OMC {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.SubscriptionForm_formRow__HGhMx {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
}

@media only screen and (max-width: 600px){
  .SubscriptionForm_paymentForm__XK\\+pu {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/Onboarding/SubscriptionPlan/components/SubscriptionForm/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".paymentForm {\n    width: 50%;\n    padding: 15px;\n    border: gray 1px solid;\n    margin-top: 30px;\n    margin-bottom: 30px;\n}\n\n.cardElement{\n  margin-bottom: 50px;\n  height: 40px;\n  padding-top: 10px;\n  padding-left: 10px;\n  border-style: none;\n  will-change: opacity, transform;\n}\n\n.form {\n  padding: 16px;\n}\n\n.label{\n  width: 100%;\n  text-align: center;\n}\n\n.icon {\n  height: 30px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.iconV2 {\n  height: 40px;\n}\n\n.iconV3 {\n  margin-left: 10px;\n  margin-top: 8px;\n  height: 15px;\n}\n\n.formRowAlign {\n  display: flex;\n  align-items: stretch;\n  height: 50px;\n  margin-top: 30px;\n  margin-bottom: 20px;\n}\n\n.formRowHeader {\n  display: flex;\n  align-items: stretch;\n  height: 50px;\n}\n\n.formRowFooter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.formRow {\n  display: flex;\n  margin-bottom: 16px;\n  justify-content: space-between;\n}\n\n@media only screen and (max-width: 600px){\n  .paymentForm {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentForm": `SubscriptionForm_paymentForm__XK+pu`,
	"cardElement": `SubscriptionForm_cardElement__Jlr22`,
	"form": `SubscriptionForm_form__BR0Hn`,
	"label": `SubscriptionForm_label__aSnKZ`,
	"icon": `SubscriptionForm_icon__8e2ZV`,
	"iconV2": `SubscriptionForm_iconV2__sJSth`,
	"iconV3": `SubscriptionForm_iconV3__TYEQ9`,
	"formRowAlign": `SubscriptionForm_formRowAlign__cnH2n`,
	"formRowHeader": `SubscriptionForm_formRowHeader__Q8FB2`,
	"formRowFooter": `SubscriptionForm_formRowFooter__a4OMC`,
	"formRow": `SubscriptionForm_formRow__HGhMx`
};
export default ___CSS_LOADER_EXPORT___;
