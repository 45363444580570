import { IndeterminateCheckbox } from "components/SixtyTable";
import { useTable, useRowSelect } from "react-table";

export default function useRowSelectionTable({ data, columns, disableRowToggle }) {

  function renderHeaderCheckbox({getToggleAllRowsSelectedProps}){
    if(disableRowToggle){
      return(
        <></>
      );
    }
    else{
      return(
        <div>
           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
       </div>
      );
    }
  }

  return useTable(
    {
      data,
      columns,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          collapse: true,
          // to render a checkbox for the select all in the header
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
            // renderHeaderCheckbox({getToggleAllRowsSelectedProps})
          ),
          // to the render a checkbox to select individual rows
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
}
