import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useUpdateShipping,
  useDeleteShipping,
  useGetShippingDetail,
} from "queries/shipping";
import { setToast } from "components/Toast";

import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import Input from "components/FormComponents/ControlledInput";
import GroupFields from "../AddShippingZoneForm/GroupFields";
import RegionFields from "../AddShippingZoneForm/RegionFields";
import Loader from "components/Loader";

import styles from "../AddShippingZoneForm/index.module.css";
import Modal from "components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import SixtyButton from "components/SixtyButton";

const defaultValues = {
  name: "",
  shippingRegions: [],
  shippingGroups: [],
};

const EditShipping = ({ AddShippingZoneFormRef }) => {
  const history = useNavigate();
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(true);
  const editShippingRef = useRef();

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    history("/dashboard/settings/shippings");
  }, [history]);

  const {
    data: shippingData,
    isLoading: isLoadingData,
    isFetchedAfterMount,
  } = useGetShippingDetail(id);

  const schema = yup.object().shape({
    name: yup.string().required("Add a name"),
    shippingRegions: yup.array(
      yup.object().shape({
        regionId: yup.string(),
      })
    ),
    shippingGroups: yup.array(
      yup.object().shape({
        name: yup.string().required("Add a name"),
        firstWeight: yup.number().nullable(),
        firstWeightUnit: yup.string().nullable(),
        price: yup.number().nullable(),
        subsequentWeight: yup.number().nullable(),
        subsequentWeightUnit: yup.string().nullable(),
        subsequentPrice: yup.number().nullable(),
        shippingType: yup.string(),
        minimumOrderAmount: yup.number().nullable(),
      })
    ),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const { reset } = formMethods;

  useEffect(() => {
    if (shippingData && isFetchedAfterMount) reset(shippingData);
  }, [reset, isFetchedAfterMount, shippingData]);

  const {
    mutateAsync: updateShipping,
    isLoading: isUpdatingShipping,
    isSuccess: isUpdatingSuccess,
    isError: isUpdatingFailed,
  } = useUpdateShipping();

  useEffect(() => {
    if (isUpdatingSuccess) {
      setToast("Successfully updated shipping");
      handleClose();
    }
  }, [handleClose, isUpdatingSuccess]);

  useEffect(() => {
    if (isUpdatingFailed) {
      setToast("Failed updating shipping", "error");
      handleClose();
    }
  }, [handleClose, isUpdatingFailed]);

  const onSubmit = (data) => {
    updateShipping(data);
  };

  const {
    mutateAsync: deleteShipping,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleting,
    isError: isDeleteError,
  } = useDeleteShipping();
  const onDelete = () => {
    deleteShipping(id);
  };
  useEffect(() => {
    if (isDeleteSuccess) {
      setToast("Successfully deleted shipping");
      handleClose();
    }
  }, [handleClose, isDeleteSuccess]);

  useEffect(() => {
    if (isDeleteError) {
      setToast("Failed deleting shipping", "error");
      handleClose();
    }
  }, [handleClose, isDeleteError]);

  const Title = () => {
    return (
      <div className={styles.modalTitleContainer}>
        <div className={styles.modalTitle}>Update shipping zone</div>
      </div>
    );
  };

  const Actions = () => {
    return (
      <footer>
        <SixtyButton form="edit-shipping-zone-form" type="submit" disabled={isUpdatingShipping}>
          Save
        </SixtyButton>
        <SixtyButton
          variant="warning"
          onClick={onDelete}
          className={styles.cancelButton}
        >
          Delete
        </SixtyButton>
        <SixtyButton
          variant="secondary"
          className={styles.cancelButton}
          onClick={handleClose}
        >
          Cancel
        </SixtyButton>
      </footer>
    );
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onAttemptClose={() => {
          history("/dashboard/settings/shippings");
          setIsModalOpen(false);
        }}
        modalRef={editShippingRef}
        Title={Title}
        Actions={Actions}
      >
        {isUpdatingShipping || isDeleting || isLoadingData ? (
          <Panel className={styles.panelWrap}>
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          </Panel>
        ) : (
          <FormProvider {...formMethods}>
            <form
              id="edit-shipping-zone-form"
              onSubmit={formMethods.handleSubmit(onSubmit)}
              className={styles.form}
            >
              <div className={styles.itemsContainer}>
                <Panel className={styles.panelWrap}>
                  <PanelHeader title="1. General" />
                  <div className={styles.subForm}>
                    <Input
                      name="name"
                      error={formMethods.errors.naem}
                      control={formMethods.control}
                      label="Name"
                      defaultValue=""
                    />
                  </div>
                </Panel>
                <Panel className={styles.panelWrap}>
                  <PanelHeader title="2. Countries" />
                  <RegionFields formMethods={formMethods} />
                </Panel>
                <Panel className={styles.panelWrap}>
                  <GroupFields formMethods={formMethods} />
                </Panel>
              </div>
            </form>
          </FormProvider>
        )}
      </Modal>
    </>
  );
};

export default EditShipping;
