import { useEffect, useMemo, useRef, useState } from "react";
import Table from "components/SixtyTable";
import styles from "./index.module.css";
import { ReactComponent as IconSettings } from "assets/icons/Icon-Settings.svg";
import EmptyGrid from "components/EmptyGrid";
import Button from "components/SixtyButton";
import { usePagination, useTable } from "react-table";
import {
  useDownloadAllBillingStatement,
  useDownloadBillingStatement,
  useGetCompanyBillingHistories,
} from "queries/subscription";
import { getFormattedDateString } from "utils/date.util";
import ErrorModal from "components/Error";
import Loader from "components/Loader";
import { PanelActionButton } from "components/SixtyPanel";
import { unknown_Error } from "common/constants";
import { setToast } from "components/Toast";

export default function BillingStatementsTable() {
  const {
    data: billingHistories,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetCompanyBillingHistories({});

  const {
    mutateAsync: downloadBillingStatement
  } = useDownloadBillingStatement();

  const {
    mutateAsync: downloadAllBillingStatements,
    isLoading: DownloadAllLoading
  } = useDownloadAllBillingStatement();

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        style: { padding: "0px 16px" },
        collapse: true,
        Cell: ({ value, row }) => (
          <div>
            <div>{getFormattedDateString(value, "MMM d, y")}</div>
          </div>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        collapse: true,
      },
      {
        Header: "Amount / Frequency",
        Cell: ({ row }) =>
          `RM${row.original.amount} / ${row.original.frequency}`,
        collapse: true,
      },
      {
        id: "download",
        Cell: ({ value, row }) => (
          <div>
            <Button
              className={styles.downloadAsPdf}
              variant="text"
              label="Download as PDF"
              onClick={() => {
                downloadBillingStatement(row.original.id);
                setToast("Downloading statement");
              }}
            />
          </div>
        ),
        collapse: true,
      },
    ],
    [downloadBillingStatement]
  );

  const data = useMemo(() => (billingHistories ? billingHistories : []), [
    billingHistories,
  ]);

  const onDownloadAllStmt = async () => {
    setToast("Downloading statements, it may take some time.");
    await downloadAllBillingStatements();
  };

  const tableInstance = useTable({ columns, data }, usePagination);

  // Error modal
  const [errorOpen, setErrorOpen] = useState(false);
  const errorRef = useRef();

  const closeError = () => {
    setErrorOpen(false);
  };

  const handleAction = () => {
    refetch();
    closeError();
  };

  useEffect(() => {
    if (isError) {
      setErrorOpen(true);
    }
  }, [isError]);

  if (errorOpen) {
    return (
      <ErrorModal
        open={errorOpen}
        handleClose={closeError}
        handleCancel={closeError}
        handleAction={handleAction}
        actionLabel="Try again"
        errorRef={errorRef}
        errorMessage={error?.message ? error.message : unknown_Error}
      />
    );
  }

  return (
    <div>
      <div className={styles.tableHeader}>
        <div>
          <span className={styles.gridHeader}>Billing statements</span>
        </div>
        <div className={styles.ButtonsContainer}>
          <div>
            <Button
              className={styles.addDiscountBtn}
              variant="secondary"
              label="Download all statements"
              disabled={DownloadAllLoading}
              onClick={onDownloadAllStmt}
            />
          </div>
        </div>
      </div>
      {data?.length === 0 && !isLoading && (
        <>
          <EmptyGrid
            Icon={IconSettings}
            emptyMessage={
              isError
                ? "Error in fetching data"
                : "Your billing statements will show up here"
            }
          />

          {isError && (
            <PanelActionButton onClick={() => refetch()}>
              Try Again
            </PanelActionButton>
          )}
        </>
      )}
      {!isError && data && (
        <>
          {(isLoading) ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            <Table instance={tableInstance} />
          )}
        </>
      )}
    </div>
  );
}
