// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../theme/font.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtpInput_otpContainer__H\\+p7J {
  margin-bottom: 24px;
}

.OtpInput_otpInputs__DQpnn {
  display: flex;
  justify-content: space-between;
}

.OtpInput_otpInput__jaOAs {
  padding-left: 15px;
  background: #F2F2F2;
  width: 45px;
  height: 40px;
  margin-right: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid var(--black5);
  line-height: 24px;
}

.OtpInput_activeInput__YHsX1 {
  background-color: #fff;
}

.OtpInput_error__St5Fa {
  border: 1px solid red;
}

.OtpInput_errorMessage__qgb8Y {
  display: block;
  color: red;
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/OtpInput/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,+BAA+B;EAC/B,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,eAAe;AAEjB","sourcesContent":[".otpContainer {\n  margin-bottom: 24px;\n}\n\n.otpInputs {\n  display: flex;\n  justify-content: space-between;\n}\n\n.otpInput {\n  padding-left: 15px;\n  background: #F2F2F2;\n  width: 45px;\n  height: 40px;\n  margin-right: 8px;\n  font-size: 16px;\n  border-radius: 4px;\n  border: 1px solid var(--black5);\n  line-height: 24px;\n}\n\n.activeInput {\n  background-color: #fff;\n}\n\n.error {\n  border: 1px solid red;\n}\n\n.errorMessage {\n  display: block;\n  color: red;\n  margin-top: 4px;\n  composes: p2 from \"theme/font.css\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"otpContainer": `OtpInput_otpContainer__H+p7J`,
	"otpInputs": `OtpInput_otpInputs__DQpnn`,
	"otpInput": `OtpInput_otpInput__jaOAs`,
	"activeInput": `OtpInput_activeInput__YHsX1`,
	"error": `OtpInput_error__St5Fa`,
	"errorMessage": `OtpInput_errorMessage__qgb8Y ${___CSS_LOADER_ICSS_IMPORT_0___.locals["p2"]}`
};
export default ___CSS_LOADER_EXPORT___;
