import { queryClient } from "common/query";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";
import { saveAs } from "file-saver";

export function useDeleteCampaign() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Campaign.DeleteCampaign + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Campaign.GetCampaigns);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useDeleteCampaign: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useCampaignNotification() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Campaign.NotifyCampaign + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useCampaignNotification: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useDownloadCampaignReceipt() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Campaign.DownloadCampaignReceipt, data, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            ?.find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            ?.trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.pdf");
        }),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useDownloadCampaignReceipt: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useTopupCampaignReward() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Campaign.TopupCampaignReward, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useTopupCampaignReward: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useGetVideoStoryDropdown() {
  return useQuery(
    [Endpoints.Campaign.GetVideoStoryDropdown],
    () =>
      api
        .get(Endpoints.Campaign.GetVideoStoryDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetVideoStoryDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignDetail(id) {
  return useQuery(
    [Endpoints.Campaign.GetCampaignDetail, id],
    () =>
      api
        .get(Endpoints.Campaign.GetCampaignDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useCreateUgcCampaign() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Campaign.CreateUGCCampaign, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Campaign.GetCampaigns);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useCreateUgcCampaign: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useUpdateUgcCampaign() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Campaign.UpdateUGCCampaign, data)
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Campaign.GetCampaigns);
        queryClient.invalidateQueries([Endpoints.Campaign.GetCampaignDetail, data.id]);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateUgcCampaign: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useExportCampaignReport() {
  return useMutation(
    (id) =>
      api
        .get(Endpoints.Campaign.ExportCampaignReport + `/${id}`, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
    
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.xlsx");
        }),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useExportCampaignReport: useMutation: onError: error", error);
      },
    }
  );
}

export function useImportCampaignShipment() {
  return useMutation(
    (files) =>
      api
        .post(Endpoints.Campaign.ImportCampaignShipment, files, {
          responseType: "json",
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useImportCampaignShipment: useMutation: onError: error", error);
      },
    }
  );
}


export function useExportCampaignShipment() {
  return useMutation(
    (id) =>
      api
        .get(Endpoints.Campaign.ExportCampaignShipment + `/${id}`, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.xlsx");
        }),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useExportCampaignShipment: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetCampaignDropdown() {
  return useQuery(
    [Endpoints.Campaign.GetCampaignDropdown],
    () =>
      api
        .get(Endpoints.Campaign.GetCampaignDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetLatestCampaign() {
  return useQuery(
    [Endpoints.Campaign.GetLatestCampaign],
    () =>
      api
        .get(Endpoints.Campaign.GetLatestCampaign)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetLatestCampaign: useQuery: onError: error", error);
      },
    }
  );
}

export function useUpdateCampaignProgress() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Campaign.UpdateCampaignProgress, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateCampaignProgress: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetCampaignSellerShipments(filter) {
  return useQuery(
    [Endpoints.Campaign.GetCampaignSellerShipments, filter],
    () =>
      api
        .post(Endpoints.Campaign.GetCampaignSellerShipments, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetCampaignSellerShipments: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerShipmentDetail(id) {
  return useQuery(
    [Endpoints.Campaign.GetSellerShipmentDetail, id],
    () =>
      api
        .get(Endpoints.Campaign.GetSellerShipmentDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerShipmentDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useUpdateSellerCampaignShipment() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Campaign.UpdateSellerCampaignShipment, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Campaign.GetCampaignSellerShipments);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateSellerCampaignShipment: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetPublishContentCampaigns(filter) {
  return useQuery(
    [Endpoints.Campaign.GetPublishContentCampaigns, filter],
    () =>
      api
        .post(Endpoints.Campaign.GetPublishContentCampaigns, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetPublishContentCampaigns: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignStatusCount() {
  return useQuery(
    [Endpoints.Campaign.GetCampaignStatusCount],
    () =>
      api
        .get(Endpoints.Campaign.GetCampaignStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaigns(filter) {
  return useQuery(
    [Endpoints.Campaign.GetCampaigns, filter],
    () =>
      api
        .post(Endpoints.Campaign.GetCampaigns, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetCampaigns: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetEventCampaigns(filter) {
  return useQuery(
    [Endpoints.Campaign.GetEventCampaigns, filter],
    () =>
      api
        .post(Endpoints.Campaign.GetEventCampaigns, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetEventCampaigns: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignSellers(filter) {
    return useQuery(
      [Endpoints.Campaign.GetCampaignSellers, filter],
      () =>
        api
          .post(Endpoints.Campaign.GetCampaignSellers, filter)
          .then((response) => response?.data),
      {
        onError: (error) => {
          reportError("useGetCampaignSellers: useQuery: onError: error", error);
        },
      }
    );
  }

  export function useGetCampaignProducts(filter) {
    return useQuery(
      [Endpoints.Campaign.GetCampaignProducts, filter],
      () =>
        api
          .post(Endpoints.Campaign.GetCampaignProducts, filter)
          .then((response) => response?.data),
      {
        onError: (error) => {
          reportError("useGetCampaignProducts: useQuery: onError: error", error);
        },
      }
    );
  }

export function useGetCampaignAnalytics(id) {
  return useQuery(
    [Endpoints.Campaign.GetCampaignAnalytics, id],
    () =>
      api
        .get(Endpoints.Campaign.GetCampaignAnalytics + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignAnalytics: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignSellerDetail(id) {
    return useQuery(
      [Endpoints.Campaign.GetCampaignSellerDetail, id],
      () =>
        api
          .get(Endpoints.Campaign.GetCampaignSellerDetail + `/${id}`)
          .then((response) => response?.data?.result),
      {
        onError: (error) => {
          reportError("useGetCampaignSellerDetail: useQuery: onError: error", error);
        },
      }
    );
  }

  export function useGetCampaignProductDetail(id) {
    return useQuery(
      [Endpoints.Campaign.GetCampaignProductDetail, id],
      () =>
        api
          .get(Endpoints.Campaign.GetCampaignProductDetail + `/${id}`)
          .then((response) => response?.data?.result),
      {
        onError: (error) => {
          reportError("useGetCampaignProductDetail: useQuery: onError: error", error);
        },
      }
    );
  }

export function useRejectSellerCampaignAccess() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Campaign.useRejectSellerCampaignAccess + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Campaign.GetCampaignSellers);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useRejectSellerCampaignAccess: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetCampaignSellerRequestCount(id) {
  return useQuery(
    [Endpoints.Campaign.GetCampaignSellerRequestCount, id],
    () =>
      api
        .get(Endpoints.Campaign.GetCampaignSellerRequestCount + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignSellerRequestCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignSellerRequests(filter) {
  return useQuery(
    [Endpoints.Campaign.GetCampaignSellerRequests, filter],
    () =>
      api
        .post(Endpoints.Campaign.GetCampaignSellerRequests, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetCampaignSellerRequests: useQuery: onError: error", error);
      },
    }
  );
}

export function useRejectCampaignRequest() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Campaign.RejectCampaignRequest + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Campaign.GetCampaignSellerRequests);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useRejectCampaignRequest: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useApproveCampaignRequest() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Campaign.ApproveCampaignRequest + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Campaign.GetCampaignSellerRequests);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useApproveCampaignRequest: useMutation: onError: error",
          error
        );
      }
    }
  );
}


