// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toast_toastContainer__3Jl-f {
    width: auto;
    z-index: 200000;
}

.Toast_toastBody__M\\+6Yl {
    min-height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 16px;
}

.Toast_toastBody__M\\+6Yl button {
    align-self: auto;
    padding-left: 16px;
    opacity: 0.25;
}

.Toast_success__TZz6Z {
    background-color: #EDFAF2;
}

.Toast_error__QwRtO {
    background-color: #F2E7E6;
}

.Toast_toastText__acTYW {
    font-family: var(--medium500);
    font-size: 14px;
    color: #000000;
}

.Toast_displayContent__vpFMr {
    display: flex;
    align-items: center;
}

.Toast_displayContent__vpFMr .Toast_label__2RtS- {
    padding-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Toast/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,oCAAoC;IACpC,2CAA2C;IAC3C,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".toastContainer {\n    width: auto;\n    z-index: 200000;\n}\n\n.toastBody {\n    min-height: 56px;\n    border: 1px solid rgba(0, 0, 0, 0.1);\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);\n    border-radius: 4px;\n    padding: 16px;\n}\n\n.toastBody button {\n    align-self: auto;\n    padding-left: 16px;\n    opacity: 0.25;\n}\n\n.success {\n    background-color: #EDFAF2;\n}\n\n.error {\n    background-color: #F2E7E6;\n}\n\n.toastText {\n    font-family: var(--medium500);\n    font-size: 14px;\n    color: #000000;\n}\n\n.displayContent {\n    display: flex;\n    align-items: center;\n}\n\n.displayContent .label {\n    padding-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastContainer": `Toast_toastContainer__3Jl-f`,
	"toastBody": `Toast_toastBody__M+6Yl`,
	"success": `Toast_success__TZz6Z`,
	"error": `Toast_error__QwRtO`,
	"toastText": `Toast_toastText__acTYW`,
	"displayContent": `Toast_displayContent__vpFMr`,
	"label": `Toast_label__2RtS-`
};
export default ___CSS_LOADER_EXPORT___;
