import { queryClient } from "common/query";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";

export function useGetShippings(filter) {
  return useQuery(
    [Endpoints.Shipping.GetShippings, filter],
    () =>
      api
        .post(Endpoints.Shipping.GetShippings, filter)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetShippings: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetShippingRegionDropdown(data) {
  return useQuery(
    [Endpoints.Shipping.GetShippingRegionDropdown, data],
    () =>
      api
        .post(Endpoints.Shipping.GetShippingRegionDropdown, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetShippingRegionDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetShippingDetail(id) {
  return useQuery(
    [Endpoints.Shipping.GetShippingDetail, id],
    () =>
      api
        .get(Endpoints.Shipping.GetShippingDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetShippingDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useAddShipping() {
  return useMutation(
    (shippingData) =>
      api
        .post(Endpoints.Shipping.AddShipping, shippingData)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useAddShipping: useMutation: onError: error", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([Endpoints.Shipping.GetShippings, {}]);
        queryClient.refetchQueries(Endpoints.Shipping.GetShippings);
      },
    }
  );
}

export function useUpdateShipping() {
  return useMutation(
    (shippingData) =>
      api
        .put(Endpoints.Shipping.UpdateShipping, shippingData)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useUpdateShipping: useMutation: onError: error", error);
      },
    }
  );
}

export function useDeleteShipping() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Shipping.DeleteShipping + `/${id}`)
        .then((response) => response?.data),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useDeleteShipping: useMutation: onError: error", error);
      },
    }
  );
}
