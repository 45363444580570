import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Panel from "components/SixtyPanel";
import styles from "./index.module.css";
import PanelHeader from "components/PanelHeader";
import clsx from "clsx";
import BusinessInfoForm from "./components/BusinessInfoForm";
import BusinessAddressForm from "./components/BusinessAddressForm";
// import OtherBasicInfoForm from "./components/OtherBasicInfoForm";
import {
  useGetVendorCompanyDetails,
  useUpdateVendorCompany,
} from "queries/vendor";
import SixtyButton from "components/SixtyButton";
import Loader from "components/Loader";
import { useUploads } from "queries/media";
import { isEmpty, isArray } from 'lodash';

const defaultValues = {
  path: "",
  storeName: "",
  customerSupportEmail: "",
  companyContact: "",
  industry: "",
  companyName: "",
  country: "",
  address1: "",
  address2: "",
  city: "",
  postalCode: "",
  state: "",
};

const CompanyHeader = ({ title }) => {
  return (
    <div className={styles.header}>
      <div className={styles.titleBox}>
        <div className={styles.titleContent}>{title}</div>
      </div>
    </div>
  );
};

const CompanySettings = () => {
  const formMethods = useForm({
    defaultValues,
  });

  const { reset } = formMethods;
  const { data: companyData, isLoading } = useGetVendorCompanyDetails();

  const { mutateAsync: updateVendorCompanyDetails, isLoading: isUpdatingDetails } = useUpdateVendorCompany();
  const {
    mutateAsync: uploadFiles,
    isLoading: uploadingFiles,
  } = useUploads();

  useEffect(() => {
    if (!!companyData) {
      reset(companyData);
    }
  }, [companyData, reset]);

  const onSubmit = async (data) => {
    if (isArray(data.path) && data.path?.length > 0) {
      const images = new FormData();
      let hasNewImages = false;
      if (!data.path[0]?.id) {
        images.append(`files[0].fileName`, data.path[0].name);
        images.append("files[0].file", data.path[0]);
        hasNewImages = true;
      }

      const result = hasNewImages ? await uploadFiles(images) : [];

      const path = result?.length ? result[0].path : '';
      data.path = path;
    }
    
    updateVendorCompanyDetails(data);
  };

  return (
    <div className={styles.settingsCompanyPage}>
      {(isLoading || isUpdatingDetails || uploadingFiles) ? (
        <Panel className={styles.panelWrap}>
          <div className={styles.loader}>
            <Loader />
          </div>
        </Panel>
      ) : companyData && (
        <>
          <CompanyHeader title="Company details" />
          <FormProvider {...formMethods}>
            <form
              onSubmit={formMethods.handleSubmit(onSubmit)}
              className={styles.form}
            >
              <div className={styles.row}>
                <Panel className={clsx(styles.panelWrap, styles.firstPanel)}>
                  <PanelHeader title="Business info" />
                  <BusinessInfoForm />
                </Panel>
                <Panel className={styles.panelWrap}>
                  <PanelHeader title="Business address" />
                  <BusinessAddressForm />
                </Panel>
                {/* Hidden for phase 1 */}
                {/* <Panel className={styles.panelWrap}>
                  <PanelHeader title="Standards, formats and currencies" />
                  <OtherBasicInfoForm />
                </Panel> */}
              </div>
              <div className={styles.footer}>
                <SixtyButton type="submit">Save</SixtyButton>
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default CompanySettings;
