import styles from "./index.module.css";
import Navigation from "components/Navigation";
import AdminTopbar from "components/AdminTopbar";
import { ReactComponent as VendorIcon } from "assets/icons/Icon-Vendors.svg";
import { ReactComponent as WithdrawIcon } from "assets/icons/Icon-Withdraws.svg";
import { ReactComponent as credit } from "assets/icons/Icon-CreditBalance.svg";
import { ReactComponent as report } from "assets/icons/Icon-Reports.svg";
import { ReactComponent as seller } from "assets/icons/Icon-Sellers.svg";
import { ReactComponent as orderIcon } from "assets/icons/Icon-Orders.svg";
import { ReactComponent as creditIcon } from "assets/icons/Icon-CreditCard.svg";
import { ReactComponent as IconHome } from "assets/icons/Icon-Home.svg";

import CustomToastContainer from "components/Toast";
import { useGetAdminSideMenuCount } from "queries/admin";
import ControlledSelect from "components/FormComponents/ControlledSelect";

const Navbar = (props) => {
   const { data } = useGetAdminSideMenuCount();
   
  return (
    <div>
      <Navigation
        items={[
          {
            itemId: "1",
            title: "Home",
            ElemBefore: IconHome,
            path: "/admin/dashboard",
          },
          {
            itemId: "2",
            title: "Vendors",
            ElemBefore: VendorIcon,
            subNav:[
              {
                itemId: "2.1",
                recordCount: data?.totalVendorCount,
                title: "All Vendors",
                path: "/admin/vendors",
              },
              // {
              //   itemId: "2.2",
              //   recordCount: data?.activeAffliateProgramCount,
              //   title: "Affiliate Program",
              //   path: "/admin/affiliateProgram",
              // },
              // {
              //   itemId: "2.3",
              //   recordCount: data?.activeAffliateContentCount,
              //   title: "Affiliate Contents",
              //   path: "/admin/affiliateContents",
              // },
              // {
              //   itemId: "2.4",
              //   recordCount: data?.processingOrderCount,
              //   title: "Pending Orders",
              //   path: "/admin/orders",
              // },
              // {
              //   itemId: "2.5",
              //   title: "Product Requests",
              //   path: "/admin/product-requests",
              // }
            ] 
          },
          {
            itemId: "3",
            title: "Influencers",
            ElemBefore: seller,
            subNav:[
              {
                itemId: "3.1",
                recordCount: data?.pendingRegistrationCount,
                title: "Influencer Registrations",
                path: "/admin/influencers/registrations",
              },
              {
                itemId: "3.2",
                recordCount: data?.rejectedRegistrationCount,
                title: "Rejection Listing",
                path: "/admin/influencers/rejection",
              },
              {
                itemId: "3.3",
                recordCount: data?.activeSellerCount,
                title: "All Influencers",
                path: "/admin/influencers/approval",
              },
            ] 
          },
          // {
          //   itemId: "4",
          //   recordCount: data?.allBlogCount,
          //   title: "Blogs",
          //   ElemBefore: report,
          //   path: "/admin/blogs",
          // },
          {
             itemId: "4",
             title: "Orders",
             ElemBefore: orderIcon,
             path: "/admin/ugcOrders",
           },
          {
            itemId: "5",
            recordCount: data?.pendingWithdrawCount,
            title: "Withdraws",
            ElemBefore: WithdrawIcon,
            path: "/admin/withdraws",
          },
          {
            itemId: "6",          
            title: "Payouts",
            ElemBefore: creditIcon,
            subNav:[
              {
                itemId: "6.1",
                recordCount: data?.completedPayoutCount,
                title: "All Payouts",
                path: "/admin/payouts",
              },
              {
                itemId: "6.2",
                recordCount: data?.payoutRequestCount,
                title: "Payout Requests",
                path: "/admin/PayoutRequest",
              },
            ] 
          },
          {
            itemId: "7",          
            title: "Campaigns",
            ElemBefore: orderIcon,
            subNav:[
              {
                itemId: "7.1",
                recordCount: data?.activeCampaignCount,
                title: "All Campaigns",
                path: "/admin/campaigns",
              },
              // {
              //   itemId: "7.2",
              //   recordCount: data?.pendingCampaignRequestCount,
              //   title: "Joining Requests",
              //   path: "/admin/campaigns/requests",
              // },
              // {
              //   itemId: "7.3",
              //   recordCount: data?.allCampaignCount,
              //   title: "Posting Contents",
              //   path: "/admin/campaigns/PostingContents",
              // },
            ] 
          },
          // {
          //   itemId: "8",
          //   title: "Refunds",
          //   ElemBefore: credit,
          //   subNav:[
          //     {
          //       itemId: "8.1",
          //       recordCount: data?.pendingVendorRefundCount,
          //       title: "Vendor Refunds",
          //       path: "/admin/vendors/refunds",
          //     },
          //     {
          //       itemId: "8.2",
          //       title: "Buyer Refunds",
          //       path: "/admin/BuyerRefundRequest",
          //     },
          //   ]         
          // },
          {
            itemId: "9",
            title: "Pricing Plan",
            ElemBefore: seller,
            subNav:[
              {
                itemId: "9.1",
                title: "Promotions",
                path: "/admin/promotions",
              },
              {
                itemId: "9.2",
                title: "Pricing Plans",
                path: "/admin/pricingPlanListing",
              },
              {
                itemId: "9.3",
                title: "Pricing Plan Preview",
                path: "/admin/subscriptionPlan/Preview",
              }
            ] 
          },
          {
            itemId: "10",
            title: "Settings",
            ElemBefore: VendorIcon,
            subNav:[
              {
                itemId: "10.1",
                title: "Announcement",
                path: "/admin/announcement",
              },
              {
                itemId: "10.2",
                title: "Video Stories",
                path: "/admin/videoStories",
              },
            ] 
          },
        ]}
      />
    </div>
  );
};

export default function AdminLayout({ children }) {
  return (
    <>
      <AdminTopbar />
      <div className={styles.page}>
        <Navbar />
        {children}
        <div>
          <CustomToastContainer />
        </div>
      </div>
    </>
  );
}
