// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Billing_billingTable__8h5r7 {
    width: calc(95vw - 250px);
}

.Billing_gridHeader__lpHSB {
    font-family: var(--medium500);
    font-size: 20px;
    color: var(--black);
}

.Billing_panelWrap__ev2Kv {
    margin: 8px 8px;
    display: block;
    min-height: unset;
    position: relative;
}

.Billing_firstRow__fhynS {
    padding: 8px;
    justify-content: space-between;
}

.Billing_firstRow__fhynS .Billing_panelWrap__ev2Kv  {
    margin: 0px;
    /* flex-basis: 49%; */
    flex-basis: 100%;
}

.Billing_billingPanel__o6Gyb {
    display: flex;
    flex-direction: column;
    margin: 16px;
    margin-top: 12px;
}

.Billing_row__qQoct {  
    display: flex;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/screens/Settings/Billing/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".billingTable {\n    width: calc(95vw - 250px);\n}\n\n.gridHeader {\n    font-family: var(--medium500);\n    font-size: 20px;\n    color: var(--black);\n}\n\n.panelWrap {\n    margin: 8px 8px;\n    display: block;\n    min-height: unset;\n    position: relative;\n}\n\n.firstRow {\n    padding: 8px;\n    justify-content: space-between;\n}\n\n.firstRow .panelWrap  {\n    margin: 0px;\n    /* flex-basis: 49%; */\n    flex-basis: 100%;\n}\n\n.billingPanel {\n    display: flex;\n    flex-direction: column;\n    margin: 16px;\n    margin-top: 12px;\n}\n\n.row {  \n    display: flex;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billingTable": `Billing_billingTable__8h5r7`,
	"gridHeader": `Billing_gridHeader__lpHSB`,
	"panelWrap": `Billing_panelWrap__ev2Kv`,
	"firstRow": `Billing_firstRow__fhynS`,
	"billingPanel": `Billing_billingPanel__o6Gyb`,
	"row": `Billing_row__qQoct`
};
export default ___CSS_LOADER_EXPORT___;
