import Flyout from "components/Flyout";
import { useRef } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "utils/storage";
import { replace } from "lodash";

const ProfileDropdown = ({
    isOpen,
    onAttemptClose
}) => {
    const history = useNavigate();

    const dropdownRef = useRef();

    const onAccountSettingClick = () => {
        onAttemptClose();
        // history.push(`/dashboard/accountSettings`);
    };

    const onLogoutClick = () => {
        clearStorage();
        onAttemptClose();
        history(`/`, {replace:true});
        //call logout mutations
    }

    return (
        <div>
            <Flyout
                isOpen={isOpen}
                onAttemptClose={onAttemptClose}
                flyoutRef={dropdownRef}
                helperClass={styles.profileFlyout}
            >
                <div className={styles.profileDropdown}>
                    {/* <div className={styles.row} onClick={() => onAccountSettingClick()}>
                        Account settings
                    </div> */}
                    <div className={styles.row} onClick={() => onLogoutClick()}>
                        Logout
                    </div>
                </div>
            </Flyout>
        </div>
    )
}

export default ProfileDropdown;