import React, { useState, useEffect, useRef, useMemo  } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { useProgressUploads, useUploads } from "queries/media";
import { Editor } from '@tinymce/tinymce-react';
import Button from "components/SixtyButton";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import SixtyButton from "components/SixtyButton";
import Select from "components/FormComponents/ControlledSelect";
import Checkbox from "components/SixtyCheckBox";
import { Slate, Editable, withReact } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import Loader from "components/Loader";

const EditorForm = ({setContent, content = null, generateArticleLoading = false, editorRef, copy, back}) => {

    const initialValue = [
      ]

  // const editorRef = useRef(null);
  
  const [profilePhoto, setProfilePhoto] = useState([]);
  const [customAudience, setCustomAudience] = useState(false);
  const [customLanguage, setCustomLanguage] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [targetAudience, setTargetAudience] = useState("");
  const [voiceTone, setVoiceTone] = useState("");
  const [languageStyle, setLanguageStyle] = useState("");
  const [language, setLanguage] = useState("");
  const [wordCount, setWordCount] = useState("");
  const { control, watch, errors } = useFormContext();
 
 
  return (
    <div className={styles.form}>

     <div style={{height:15}} ></div>
      <div className={styles.section}>Tell your thought to our AI</div>
      <hr className={styles.hr} />
      <div className={styles.fullFormRow}>
         <Input
           name="revise"
           label="revise"
           defaultValue=""
           lines={4}
         />
      </div>

      <div className={styles.btnActionContainer}>
                    <SixtyButton
                      type="submit"
                      variant="warning"
                      className={styles.approveBtn}
                      disabled={generateArticleLoading}
                      >
                      {"Revise Caption"}
                    </SixtyButton>
                    <SixtyButton
                      variant="secondary"
                      className={styles.approveBtn}
                      disabled={generateArticleLoading}
                      onClick={() => back()}
                      >
                      {"Back"}
                    </SixtyButton>
      </div>
      
      {generateArticleLoading && (
        <>
          <div style={{width:950, height:400}} >
            <Loader text={"Loading Data..."}/>
         </div>
        </>
      )}

           <div style={{height:15}} ></div>
            <div className={styles.section}>Contents</div>
            <hr className={styles.hr} />
            <div className={styles.fullFormRow}>
            <Editor
                apiKey='wsg1700p2ol4tgi8trs4h0zn4p5j8804yg2b6hk2t9ehbqn5'
                value={content}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=""
                init={{
                    height:800,
                    plugins: 'lists link paste help wordcount table charmap print preview anchor advlist autolink' +
                            'searchreplace visualblocks code fullscreen' +
                            'insertdatetime media code help',
                    toolbar:  'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                }}
                onEditorChange={(newText) => setContent(newText)}
                
            />
            </div>   
  

    </div>
  );
};

export default EditorForm;
