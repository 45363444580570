import Link from "components/SixtyLink";
import Button from "components/SixtyButton";
import Input from "components/SixtyInput";
import LoginContainer from "./components/LoginContainer";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import getParameterByName from "utils/getParamsByName";
import { useRegisterUserEmail } from "queries/account";
import { reportError } from "crashlytics";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { setTokens } from "utils/auth";

export default function SignUp() {
  // when coming with link.
  const { id } = useParams();
  const prefillEmail = getParameterByName("email");
  const { control, handleSubmit, errors } = useForm();

  const history = useNavigate();

  const { mutateAsync: registerUserEmail, isLoading, isError } = useRegisterUserEmail();

  const onCreate = async (data) => {
    try {

      window.dataLayer.push({
        event: 'event',
        eventProps: {
            category: 'Onboarding',
            action: 'Signup',
            label: 'Email Register',
            value: data.email
        }
      });

      const response = await registerUserEmail(data.email);
      if (response?.success){
          //history.push(`/auth/verify-email?email=${data.email}`);
          setTokens(response?.result?.auth);
          history(`/setup-profile${data.email ? `?email=${data.email}` : ""}`);
      }
        
    } catch (error) {
      reportError("Register: onSubmit: error: ", error);
    }
  };

  useEffect(() => {
    if (id) {
      localStorage.setItem("AgencyReferral", id);
    }
  }, []);

  return (
    <LoginContainer
      header="Register for vendor account"
      subHeader="Enter your business email address and we’ll send a code for verification."
      form={
        <>
          <Controller
            as={Input}
            control={control}
            label="Business email address"
            name="email"
            defaultValue={prefillEmail || ""}
            error={errors.email || isError}
            rules={{ required: "email is required" }}
          />
        </>
      }
      footer={
        <Button
          type="submit"
          size="large"
          fullWidth
          onClick={handleSubmit(onCreate)}
          disabled={isLoading}
          className={"sign-up-btn"}
        >
          Create account
        </Button>
      }
      footerLink={
        <Link to="/auth/login" type="p1" color="orange">
          Remember your password? Try logging in
        </Link>
      }
    />
  );
}
