import styles from "./index.module.css";
import SixtyLogo from "assets/images/Logo.svg";
import { useNavigate, Outlet } from "react-router-dom";
import { useCheckVendorOnboardingStatus } from "queries/vendor";
import { useEffect, useState } from "react";
import { queryClient } from "common/query";
import clsx from "clsx";
// import { clearStorage } from "../../../utils/storage";

export default function LoginLayout({ children }) {

  const [fade, setFade] = useState(true);

  const history = useNavigate();
  const token = localStorage.getItem("token");


  const user = queryClient.getQueryData("user");
  const role = localStorage.getItem("userRole");

  useEffect(() => {
    const interval = setInterval(() => {
        setFade(prev => !prev);
    }, 30000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  useEffect(() => {
 
    if (token && role && role === "InternalUser") {
      history("/admin/dashboard");
    } else if (token) {
      history("/dashboard");
    }
    
  }, [history, token, role]);

  return (
    <div className={styles.page}>
      <img src={SixtyLogo} alt="nineten logo" className={clsx(styles.logo, fade ? styles.fadeIn : styles.fadeOut)} />
      {/* {children} */}
      <Outlet />
    </div>
  );
}
