import React, { useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as IconArrowRight } from "assets/icons/Icon-Arrow-Right.svg";
import clsx from "clsx";
import PropTypes from "prop-types";

const LeftNavigationMenuItem = ({ item, onSelect, activeItemId }) => {
  const { itemId, title, subNav, recordCount, ElemBefore, path } = item;
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = (itemId, path) => {
    if (onSelect) {
      onSelect(itemId, path);
    }
  };

  return (
    <ul key={itemId} className={styles.sideNavigationPanel}>
      <li className={styles.sideNavigationPanelSelectWrap}>
        <div
          onClick={() =>
            subNav ? setIsExpanded(!isExpanded) : handleClick(itemId, path)
          }
        >
          <span
            className={clsx({
              [styles.expanded]: isExpanded,
              [styles.active]: !subNav && itemId === activeItemId,
            })}
          >
            <span className={styles.prefixIcon}>
              {ElemBefore && <ElemBefore />}
            </span>
            <span
              className={clsx(styles.navTitle, {
                [styles.active]: !subNav && itemId === activeItemId,
              })}
            >
              {title}
            </span>
            {recordCount
              ? recordCount > 0 && (
                  <span className={styles.recordCount}>{recordCount}</span>
                )
              : null}
          </span>
          {subNav &&
            subNav.length > 0 &&
            (isExpanded ? (
              <span className={styles.upArrow}>
                <IconArrowRight />
              </span>
            ) : (
              <span className={styles.downArrow}>
                <IconArrowRight />
              </span>
            ))}
        </div>
      </li>
      {subNav && isExpanded && (
        <ul className={styles.sideNavigationPanelSelectInner}>
          {subNav.map((subNavItem) => {
            return (
              <li
                key={subNavItem.itemId}
                className={clsx(styles.sideNavigationPanelSelectInnerWrap, {
                  [styles.active]: subNavItem.itemId === activeItemId,
                })}
                onClick={() => handleClick(subNavItem.itemId, subNavItem.path)}
              >
                <span>{subNavItem.title}</span>
                {subNavItem.recordCount ? subNavItem.recordCount && (
                  <span className={styles.recordCount}>
                    {subNavItem.recordCount}
                  </span>
                ): null}
              </li>
            );
          })}
        </ul>
      )}
    </ul>
  );
};

LeftNavigationMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  activeItemId: PropTypes.string,
};

export default LeftNavigationMenuItem;
