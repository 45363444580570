// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BillingCardDetails_modalTitleBar__rqywH {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}  

.BillingCardDetails_container__gDb-B {
    width: 100%;
    min-width: 600px;
}

.BillingCardDetails_row__phSa0 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.BillingCardDetails_leftContainer__b5RJa, .BillingCardDetails_rightContainer__xVqsk {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.BillingCardDetails_leftContainer__b5RJa {
    align-items: flex-start;
    width: 70%; 
}

.BillingCardDetails_rightContainer__xVqsk {
    align-items: flex-end;
    width: 30%; 
}

.BillingCardDetails_rowHeader__uC4H1, .BillingCardDetails_rowValue__3y06g, .BillingCardDetails_rowValueDetails__dgHoR {
    font-size: 14px;
}

.BillingCardDetails_rowHeader__uC4H1 {
    font-family: var(--regular400);
    color: var(--black50Transparent);
}

.BillingCardDetails_rowValue__3y06g {
    font-family: var(--medium500);
    font-size: 14px;
    color: var(--black);
    padding: 8px 0px;
}

.BillingCardDetails_rowValueDetails__dgHoR {
    font-family: var(--regular400);
    color: var(--black50Transparent);
}

.BillingCardDetails_bankAccountDetails__AUuYC {
    display: flex;
    align-items: center;
}

.BillingCardDetails_elipseIcon__c1k3w svg{
    fill: var(--black25Transparent);
    width: 4px;
    height: 4px;
  }

  .BillingCardDetails_elipseIcon__c1k3w {
    padding-right: 8px;
    padding-left: 8px;
  }

  .BillingCardDetails_desc__2dNZa {
   padding: 30px ;
  }

  .BillingCardDetails_spacing__FeX9i {
    margin-bottom: 20px;
   }

`, "",{"version":3,"sources":["webpack://./src/screens/Settings/Billing/components/BillingCardDetails/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,+BAA+B;IAC/B,UAAU;IACV,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;GACC,cAAc;EACf;;EAEA;IACE,mBAAmB;GACpB","sourcesContent":[".modalTitleBar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}  \n\n.container {\n    width: 100%;\n    min-width: 600px;\n}\n\n.row {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n}\n\n.leftContainer, .rightContainer {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n}\n\n.leftContainer {\n    align-items: flex-start;\n    width: 70%; \n}\n\n.rightContainer {\n    align-items: flex-end;\n    width: 30%; \n}\n\n.rowHeader, .rowValue, .rowValueDetails {\n    font-size: 14px;\n}\n\n.rowHeader {\n    font-family: var(--regular400);\n    color: var(--black50Transparent);\n}\n\n.rowValue {\n    font-family: var(--medium500);\n    font-size: 14px;\n    color: var(--black);\n    padding: 8px 0px;\n}\n\n.rowValueDetails {\n    font-family: var(--regular400);\n    color: var(--black50Transparent);\n}\n\n.bankAccountDetails {\n    display: flex;\n    align-items: center;\n}\n\n.elipseIcon svg{\n    fill: var(--black25Transparent);\n    width: 4px;\n    height: 4px;\n  }\n\n  .elipseIcon {\n    padding-right: 8px;\n    padding-left: 8px;\n  }\n\n  .desc {\n   padding: 30px ;\n  }\n\n  .spacing {\n    margin-bottom: 20px;\n   }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitleBar": `BillingCardDetails_modalTitleBar__rqywH`,
	"container": `BillingCardDetails_container__gDb-B`,
	"row": `BillingCardDetails_row__phSa0`,
	"leftContainer": `BillingCardDetails_leftContainer__b5RJa`,
	"rightContainer": `BillingCardDetails_rightContainer__xVqsk`,
	"rowHeader": `BillingCardDetails_rowHeader__uC4H1`,
	"rowValue": `BillingCardDetails_rowValue__3y06g`,
	"rowValueDetails": `BillingCardDetails_rowValueDetails__dgHoR`,
	"bankAccountDetails": `BillingCardDetails_bankAccountDetails__AUuYC`,
	"elipseIcon": `BillingCardDetails_elipseIcon__c1k3w`,
	"desc": `BillingCardDetails_desc__2dNZa`,
	"spacing": `BillingCardDetails_spacing__FeX9i`
};
export default ___CSS_LOADER_EXPORT___;
