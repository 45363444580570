// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ProfileDropdown_profileFlyout__lRG7W {
    position: absolute;
}

.ProfileDropdown_profileFlyout__lRG7W {
    width: 143px;
}

.ProfileDropdown_profileDropdown__0mPTQ {
    padding: 8px;
}

.ProfileDropdown_row__x\\+Ixf {
    display: flex;
    flex-direction: column;
    padding: 8px;
    font-family: var(--medium500);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
}

.ProfileDropdown_row__x\\+Ixf:hover {
    background-color: #F9F8FA;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileDropdown/index.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":["\n.profileFlyout {\n    position: absolute;\n}\n\n.profileFlyout {\n    width: 143px;\n}\n\n.profileDropdown {\n    padding: 8px;\n}\n\n.row {\n    display: flex;\n    flex-direction: column;\n    padding: 8px;\n    font-family: var(--medium500);\n    font-size: 14px;\n    color: rgba(0, 0, 0, 0.5);\n}\n\n.row:hover {\n    background-color: #F9F8FA;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileFlyout": `ProfileDropdown_profileFlyout__lRG7W`,
	"profileDropdown": `ProfileDropdown_profileDropdown__0mPTQ`,
	"row": `ProfileDropdown_row__x+Ixf`
};
export default ___CSS_LOADER_EXPORT___;
