import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { getFormattedDateString } from "utils/date.util";
import { useGetVideoStoryDetail, useUpdateVideoStory } from "queries/videoStory";
import { reportError } from "crashlytics";
import { setToast } from "components/Toast";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";

import SixtyButton from "components/SixtyButton";
import Panel from "components/SixtyPanel";
import PanelHeader from "components/PanelHeader";
import { useUploads } from "queries/media";
import styles from "./index.module.css";
import Loader from "components/Loader";
import UpdateForm from "./components/UpdateForm";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import BasicPageHeader from "../../../../Components/BasicHeader";
import { isEmpty, isArray } from 'lodash';

const defaultValues = {
    name: '',
    instruction: '',
 };

const UpdateStory = ({selectedStory, handleClose}) => {

const history = useNavigate();
const params = useParams();

 const formMethods = useForm({
    defaultValues,
 });

  const { id } = selectedStory;
  const { setValue } = formMethods;
  
  const { data: videoStory, isLoading, isError, refetch , isFetchedAfterMount} = useGetVideoStoryDetail(
    id
  );

  useEffect(() => {

    if (videoStory && isFetchedAfterMount) {
      
     for (const [key, value] of Object.entries({
         name: videoStory?.name,
         instruction: videoStory?.instruction,
     })) {
       setValue(key, value);
     }
   }
 
   }, [setValue, videoStory, isFetchedAfterMount]);

  const onBrowseBlogProducts = () => {    
    const path = `/admin/blogs/blog-products/${params.id}`;
    window.open(path, "_blank")
  };

  const { mutateAsync: updateVideoStory, 
          isLoading : videoLoading 
        } = useUpdateVideoStory();

  const onSubmit = async (data) => {
    try {

        const model = {
            id: id,
            name: data.name,
            instruction: data.instruction
         };

         var response = await updateVideoStory(model);

         if(response){
            refetch();
            setToast("successfully save changes");
            handleClose();
         }
        
    } catch (e) {
      reportError(
        "error in : CampaignForm: onSubmit, error: ",
        e
      );
    }
  };

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        {isLoading && (
          <div style={{width:800, height:500}} >
            <Loader/>
         </div>
        )}
      <div className={styles.campaignRequestForm} hidden={isLoading}>
        <div>
     
          {videoStory && (
            <>
              <Panel className={styles.panelWrap}>
                  <PanelHeader
                    className={styles.sellerInfoHeader}
                    title="Video Story"
                  />
                  <FormProvider {...formMethods}>
                  <form
                      className={styles.form}
                  >
                  {videoStory && (
                   <UpdateForm />
                  )}         
                  </form>
              </FormProvider>
              </Panel>
            </>
          )}

        </div>
      </div>

        <footer className={styles.footer} hidden={isLoading}>
            <SixtyButton
                type="submit"
                onClick={formMethods.handleSubmit(onSubmit)}
                className={styles.approveBtn}
                disabled={videoLoading}
                >
                {"Update"}
            </SixtyButton>

        </footer>
      </div>
    </React.Fragment>
  );
};

export default UpdateStory;
