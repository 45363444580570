// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GridHeader_gridHeader__aruzB {
  font-family: var(--medium500);
  font-size: 23px;
  color: #000;
}

.GridHeader_pageHeaderContainer__upR1Y {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 8px;
  justify-content: space-between;
  align-items: center;
  margin: 24px 24px 16px 20px;
}

.GridHeader_ButtonsContainer__9xIqI {
  display: flex;
  align-items: center;
}

.GridHeader_ButtonsContainer__9xIqI > * {
  margin-right: 8px;
}

.GridHeader_ButtonsContainer__9xIqI > *:last-child {
  margin-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/GridHeader/index.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".gridHeader {\n  font-family: var(--medium500);\n  font-size: 23px;\n  color: #000;\n}\n\n.pageHeaderContainer {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0px 8px;\n  justify-content: space-between;\n  align-items: center;\n  margin: 24px 24px 16px 20px;\n}\n\n.ButtonsContainer {\n  display: flex;\n  align-items: center;\n}\n\n.ButtonsContainer > * {\n  margin-right: 8px;\n}\n\n.ButtonsContainer > *:last-child {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridHeader": `GridHeader_gridHeader__aruzB`,
	"pageHeaderContainer": `GridHeader_pageHeaderContainer__upR1Y`,
	"ButtonsContainer": `GridHeader_ButtonsContainer__9xIqI`
};
export default ___CSS_LOADER_EXPORT___;
