// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_tabs__sT11k {
  width: calc(95vw - 250px);
  min-height: inherit;
}

.Tabs_tabList__KJfnc {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 48px;
  padding: 8px;
  padding-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tabs/index.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".tabs {\n  width: calc(95vw - 250px);\n  min-height: inherit;\n}\n\n.tabList {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n  min-height: 48px;\n  padding: 8px;\n  padding-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `Tabs_tabs__sT11k`,
	"tabList": `Tabs_tabList__KJfnc`
};
export default ___CSS_LOADER_EXPORT___;
